import { useState } from "react";
import {
    Box,
    Button,
    Grid,
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    IconButton,
    useTheme
} from "@mui/material";

// importing custom style hook
import useCustomStyles from "../customHooks/useCustomStyles";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import passwordValidOrNot from "../helpers/passwordValidationHelper";
import DialogBoxLayout from "../commonLayout/DialogBoxLayout";

const styles = (theme) => ({
    secondary_color: {
        color:  "#C7332A"
    },

    tertiary_color: {
        color: "#4DA45A"
    },

    login_title_text: {
        textAlign: 'left',
        color: '#000000',
        fontSize: '36px',
        marginBottom: '55px',
    },

    create_password_container: {
        padding: '5px',
        width: '500px'
    },

    create_password_fields: {
        marginBottom: '95px'
    },

    error_message_font_size: {
        fontSize: '18px'
    }
})

const CreatePassword = () => {
    const theme = useTheme();
    const classes = useCustomStyles(styles, theme);

    // declaring all state variables for the create password feature
    const [ adminNewPassword, setAdminNewPassword ] = useState("");
    const [ confirmedPassword, setConfirmedPassword ] = useState("");
    const [ showPassword, setShowPassword ] = useState(false);
    const [ passwordStatusMessage, setPasswordStatusMessage ] = useState("");
    const [ passwordErrorToggle, setPasswordErrorToggle ] = useState(false);
    const [ dialogBoxToggle, setDialogBoxToggle ] = useState(false);

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlePasswordChange = (event) => {
        setConfirmedPassword(event.target.value);

        if(event.target.value !== adminNewPassword) {
            setPasswordStatusMessage("Password does not match");
            setPasswordErrorToggle(true);
        } else {
            setPasswordErrorToggle(false);
            setPasswordStatusMessage("");
        }
    }

    const handleButtonClick = () => {
        // validate the password
        if(adminNewPassword.length < 10) {
            setPasswordStatusMessage("Weak Password");
            setPasswordErrorToggle(true);
        } else {
            if(passwordValidOrNot(adminNewPassword)){
                setPasswordStatusMessage("");
                setPasswordErrorToggle(false);
                setDialogBoxToggle(true);

                // redirect the admin to the login page
                
            } else {
                setPasswordStatusMessage("Weak Password");
                setPasswordErrorToggle(true);
            }
        }
    }

    return (
        <>
            { dialogBoxToggle && 
                <DialogBoxLayout 
                    dialogBoxToggle={ dialogBoxToggle }
                    setDialogBoxToggle={ setDialogBoxToggle } 
                    header = "Password created successfully"
                    content = "Re-Login with new password to access the Admin Panel"
                /> 
            }

            <Grid item xs={9} className={ classes?.create_password_container }>
                <p className={ classes?.login_title_text }>Create Password</p>

                <Box>
                    <div>
                        <div className={ classes?.create_password_fields }>
                            <FormControl sx={{ mt: 3, width: { xs: 200, md: 400, lg: 480 } }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">New Password *</InputLabel>
                                <OutlinedInput
                                    required
                                    inputProps={{
                                        style: {
                                            borderRadius: '15px',
                                            boxSizing: 'content-box',
                                            height: '27px',
                                            fontSize: '24px',
                                            color: '#7F7F7F',
                                        }
                                    }}
                                    id="outlined-adornment-password"
                                    label="Enter Password *"
                                    value={adminNewPassword}
                                    onChange={(event) => setAdminNewPassword(event.target.value)}
                                />
                            </FormControl>

                            <br />

                            <FormControl sx={{ mt: 3, width: { xs: 200, md: 400, lg: 480 } }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Confirmed Password *</InputLabel>
                                <OutlinedInput
                                    required
                                    inputProps={{
                                        style: {
                                            borderRadius: '15px',
                                            boxSizing: 'content-box',
                                            height: '27px',
                                            fontSize: '24px',
                                            color: '#7F7F7F',
                                        }
                                    }}
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end" >
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Enter Password *"
                                    value={confirmedPassword}
                                    onChange={handlePasswordChange}
                                />
                            </FormControl>

                            {passwordErrorToggle ? (
                                <div>
                                    <small className={`${classes?.error_message_font_size} ${classes?.secondary_color}`}>
                                        { passwordStatusMessage }
                                    </small>
                                </div>) : 
                                !passwordErrorToggle && dialogBoxToggle ? (
                                    <div>
                                        <small className={`${classes?.error_message_font_size} ${classes?.tertiary_color}`}>
                                            Strong Password
                                        </small>
                                    </div>
                                ) : null
                            }
                        </div>

                        <div>
                            <Button
                                sx={{
                                    py: 1.5,
                                    width: { xs: 200, md: 400, lg: 480 },
                                    backgroundColor: "#9E46E3",
                                    textTransform: 'none'
                                }}
                                onClick={handleButtonClick}
                                variant="contained"
                                type="button"
                                disabled={
                                    adminNewPassword === "" || confirmedPassword === "" || passwordStatusMessage !== ""
                                }
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </Box>
            </Grid>
        </>
        
    )
}

export default CreatePassword;
