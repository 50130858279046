import { call, put, takeEvery } from "redux-saga/effects";

import {
  CREATE_MEDITATION,
  FETCH_MEDITATION_DETAILS,
  DELETE_BY_ID,
  UPDATE_MEDITATION,
  meditationSuccess,
  meditationFailure,
  getMeditaionSuccess,
  getMeditaionFailure,
  deleteByIdSuccess,
  deleteByIdFailure,
  updateMedByIdSuccess,
  updateMedByIdFail,
} from "./action";

import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../apiServices/ApiActions";
import {
  addMeditationUrl,
  getMedUrl,
  deleteMeditationUrl,
  updateMedUrls,
} from "../../apiServices/ApiUrl";

const addMeditation = async (payload) => {
  return postRequest(addMeditationUrl, payload);
};

const getAllMeditation = async (page, search) => {
  return getRequest(
    `${getMedUrl}?songName=${search}&page=${--page}&size=${10}`,
  );
};

const deleteByIdMeditation = async (payload) => {
  return deleteRequest(deleteMeditationUrl + `${payload}`);
};

const UpdateMeditationById = async (payload, id) => {
  return putRequest(updateMedUrls + `${id}`, payload);
};

function* createMeditation(action) {
  try {
    const result = yield call(addMeditation, action.payload);

    if (result.status === 200) {
      yield put(meditationSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(meditationFailure(res));
    }
  } catch (error) {
    yield put(meditationFailure(error?.response?.data));
  }
}
function* updateMeditation(action) {
  try {
    const result = yield call(UpdateMeditationById, action.payload, action.id);

    if (result.status === 200) {
      yield put(updateMedByIdSuccess(result));
    } else {
      yield put(updateMedByIdFail(result?.response?.data));
    }
  } catch (error) {
    yield put(updateMedByIdFail(error?.response?.data));
  }
}

function* getMeditation(action) {
  try {
    const result = yield call(getAllMeditation, action.params, action.search);

    if (result.status === 200) {
      let response = result.data;
      yield put(getMeditaionSuccess(response));
    } else {
      yield put(getMeditaionFailure(result?.response?.data));
    }
  } catch (error) {
    yield put(getMeditaionFailure(error?.response?.data));
  }
}
function* deleteMeditation(action) {
  try {
    const result = yield call(deleteByIdMeditation, action.payload);

    if (result.status === 204 || result.status === 200) {
      yield put(deleteByIdSuccess(result));
    } else {
      yield put(deleteByIdFailure(result?.response?.data));
    }
  } catch (error) {
    yield put(deleteByIdFailure(error?.response?.data));
  }
}

function* meditationSaga() {
  yield takeEvery(CREATE_MEDITATION, createMeditation);
  yield takeEvery(UPDATE_MEDITATION, updateMeditation);
  yield takeEvery(FETCH_MEDITATION_DETAILS, getMeditation);
  yield takeEvery(DELETE_BY_ID, deleteMeditation);
}

export default meditationSaga;
