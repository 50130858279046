import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  createGranth,
  showUnshowDialogMessage,
  updateGranth,
} from "../../redux/granth/action";
import { truncateAtDot } from "../../helpers/truncateFileName";
import {
  Grid,
  FormControl,
  TextField,
  Button,
  useTheme,
  MenuItem,
  useMediaQuery,
  Box,
} from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useCustomStyles from "../../customHooks/useCustomStyles";
import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import DialogBoxFileUploadLayout from "../../commonLayout/DialogBoxFileUploadLayout";
import ProgressDialogLayout from "../../commonLayout/ProgressDialogLayout";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import "../../styles/granth.css";

const styles = (theme) => ({
  container: {
    backgroundColor: "#F9F3FB",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      padding: "20px 10px",
      marginTop: "20px",
      height: "400px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "0 10px 25px",
      marginTop: "45px",
      height: "600px",
    },
  },

  componentTitle: {
    fontSize: "24px",
    paddingLeft: "15px",
    fontWeight: "400",
    color: "#333333",
  },

  input_box: {
    backgroundColor: "#ffffff",
    color: "#A0A09E",
  },
});

const AddEditGranth = (props) => {
  const { action, tabs } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);

  const editRecords = JSON.parse(decodedData);

  const [granthObject, setGranthObject] = useState({
    name: "",
    description: "",
    files: "",
    languageCode: "",
  });

  // state variable used to keep a track of the different behaviour of drag and drop and normal file upload
  const [toBeUploadedFileFormat, setToBeUploadedFileFormat] = useState({
    fileType: "",
    acceptedFileFormatForDragDrop: [],
    acceptedFileFormatForNormalFileUpload: [],
  });

  const [progress, setProgress] = useState(0);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [uploadFileName, setUploadFileName] = useState("");
  const [uploadInterval, setUploadInterval] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState(false);

  // Opens the file upload dialog for the specified type
  const handleOpen = (fileType, dragDropFileFormat, normalUploadFileFormat) => {
    setToBeUploadedFileFormat({
      ...toBeUploadedFileFormat,
      fileType: fileType,
      acceptedFileFormatForDragDrop: dragDropFileFormat,
      acceptedFileFormatForNormalFileUpload: normalUploadFileFormat,
    });
    setDialogOpen(true);
  };

  // Closes the progress dialog, stops the upload progress, and resets progress
  const handleProgressDialogClose = () => {
    setGranthObject({ ...granthObject, files: "" });
    setFileName("");
    setProgressDialogOpen(false);
    setUploadInProgress(false);
    clearInterval(uploadInterval); // Stop the upload interval
    setProgress(0); // Reset the progress
  };

  // Handles file input change, sets the file name, and starts the upload process
  const handleFileChange = (event) => {
    const file = event.target ? event.target.files[0] : event;

    if (file && file.type === "application/epub+zip") {
      setUploadFileName(file.name);
      setGranthObject({ ...granthObject, files: file });
      setFileName(file.name);
      setDialogOpen(false);
      handleProgressDialogOpen();
      uploadFile(file);
      setFileError(false);
    } else {
      alert("Please upload a valid epub file.");
    }
  };

  // Opens the progress dialog and sets upload status
  const handleProgressDialogOpen = () => {
    setProgressDialogOpen(true);
    setUploadInProgress(true);
  };

  // Simulates file upload progress
  const uploadFile = (file) => {
    setProgress(0);

    const UploadProgress = () => {
      let progress = 0;

      const interval = setInterval(() => {
        progress += 10;
        setProgress(progress);

        if (progress >= 110) {
          clearInterval(interval);
          setUploadInProgress(false);
          setProgressDialogOpen(false);
        }
      }, 300);

      setUploadInterval(interval); // Save the interval ID to stop it later
    };
    UploadProgress();
  };

  const [errors, setErrors] = useState(null);

  const validateForm = () => {
    const newErrors = {};
    if (!granthObject.name) newErrors.name = "Title  is required.";
    if (!granthObject.description)
      newErrors.description = "Description is required.";
    if (!granthObject.languageCode)
      newErrors.languageCode = "Language is required.";
    if (granthObject.files.length <= 0)
      newErrors.files = "Please upload a file.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  // Handles form submission
  const handleGranthUpload = async (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    const formData = new FormData();

    formData.append("name", granthObject?.name);
    formData.append("description", granthObject?.description);
    formData.append("languageCode", granthObject?.languageCode);

    formData.append("files", granthObject.files);

    if (action === "edit") {
      props.updateGranth(formData, editRecords.id);
      props.trackButtonClick("Update Granth Button", 1);
    } else {
      props.createGranth(formData);
      props.trackButtonClick("Create Granth Button", 1);
    }
  };

  const resetInputFieldsOnApiSuccess = (successValue) => {
    if (successValue) {
      setGranthObject({
        ...granthObject,
        name: "",
        description: "",
      });
      setFileName("");
    }
  };
  const matches = useMediaQuery("(max-width:1440px)");

  // function that will be responsible for closing the dialog box
  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();
    navigate(`/content/${tabs}/view`);
  };

  const handleChangeLanguage = (val) => {
    setGranthObject({
      ...granthObject,
      languageCode: val.target.value,
    });
  };

  useEffect(() => {
    if (action === "edit") {
      let records = editRecords;
      let filesData = {};

      if (editRecords?.fileName) {
        filesData["lastModified"] = editRecords.createdAt;
        filesData["lastModifiedDate"] = editRecords.updatedAt;
        filesData["name"] = editRecords.fileName;
        filesData["size"] = editRecords.fileSize;
        filesData["type"] = editRecords.fileType;
        filesData["webkitRelativePath"] = editRecords?.filePath;
      }

      setGranthObject({
        ...granthObject,
        name: records.name,
        description: records.description,
        files: filesData,
        languageCode: records.languageCode,
      });
      setFileName(records.fileName);
    }
  }, []);

  useEffect(() => {
    resetInputFieldsOnApiSuccess(props?.successResponse);
  }, [props?.successResponse]);

  return (
    <>
      {props.successResponse && (
        <DialogBoxLayout
          dialogBoxToggle={props?.successResponse}
          setDialogBoxToggle={handleCloseSuccessBox}
          header={
            action === "edit"
              ? "Updated Successfully!"
              : "Uploaded Successfully!"
          }
          content={
            action === "edit"
              ? "You Have Edited Your File Successfully"
              : "You Have Uploaded Your File Successfully"
          }
        />
      )}

      {dialogOpen && (
        <DialogBoxFileUploadLayout
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          uploadInProgress={uploadInProgress}
          progress={progress}
          handleFileChange={handleFileChange}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
        />
      )}

      {progressDialogOpen && (
        <ProgressDialogLayout
          progressDialogOpen={progressDialogOpen}
          handleProgressDialogClose={handleProgressDialogClose}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
          uploadFileName={uploadFileName}
          uploadInProgress={uploadInProgress}
          progress={progress}
        />
      )}

      <Grid
        xs={12}
        container
        spacing={3}
        mt={matches ? 1 : 2}
        component="form"
        className={"granth_container"}
        onSubmit={handleGranthUpload}
      >
        <Grid item sm={6}>
          <FormControl
            variant="outlined"
            sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
          >
            <TextField
              className={classes?.input_box}
              id="outlined-basic"
              placeholder="Enter Title"
              label="Title"
              variant="outlined"
              size={matches ? "small" : ""}
              InputLabelProps={{ shrink: true }}
              error={!!errors?.name}
              value={granthObject?.name}
              onChange={(event) => {
                setErrors(null);
                setGranthObject({
                  ...granthObject,
                  name: event.target.value,
                });
              }}
            />
            {errors?.name && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors?.name}
              </span>
            )}
          </FormControl>
        </Grid>
        <Grid item sm={6}>
          <FormControl
            variant="outlined"
            sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
          >
            <TextField
              className={classes?.input_box}
              id="outlined-basic"
              placeholder="EPUB File"
              label="File"
              error={!!errors?.files}
              variant="outlined"
              size={matches ? "small" : ""}
              value={truncateAtDot(fileName)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <>
                    <Button
                      aria-label="upload"
                      component="label"
                      className="addFileButton"
                      onClick={() => {
                        setErrors(null);
                        handleOpen("EPUB", ["EPUB"], [".epub"]);
                      }}
                    >
                      Add File
                    </Button>
                  </>
                ),
              }}
            />
            {errors?.files && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors?.files}
              </span>
            )}
          </FormControl>
        </Grid>
        <Grid item sm={6}>
          <FormControl
            variant="outlined"
            sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
          >
            <TextField
              className={classes?.input_box}
              id="outlined-multiline-static"
              placeholder="Enter Description"
              error={!!errors?.description}
              label="Description"
              size={matches ? "small" : ""}
              InputLabelProps={{ shrink: true }}
              multiline
              rows={6}
              value={granthObject?.description}
              onChange={(event) => {
                setErrors(null);
                setGranthObject({
                  ...granthObject,
                  description: event.target.value,
                });
              }}
            />
            {errors?.description && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors?.description}
              </span>
            )}
          </FormControl>
        </Grid>
        <Grid item sm={6}>
          <FormControl
            sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
            size={matches ? "small" : ""}
          >
            <TextField
              className={classes?.input_box}
              error={!!errors?.languageCode}
              InputLabelProps={{ shrink: true }}
              id="language"
              select
              variant="outlined"
              size={matches ? "small" : ""}
              sx={{
                paddingBottom: "0px",
                "& .MuiSelect-select span::before": {
                  color: "#A0A09E",
                  content: "'Choose Language'",
                },
              }}
              label="Language"
              value={granthObject?.languageCode}
              onChange={(e) => {
                setErrors(null);
                handleChangeLanguage(e);
              }}
            >
              <MenuItem value="en_US">English</MenuItem>
              <MenuItem value="hi_IN">Hindi</MenuItem>
              <MenuItem value="pa_IN">Punjabi</MenuItem>
            </TextField>
            {errors?.languageCode && (
              <span style={{ color: "red", fontSize: "12px" }}>
                {errors?.languageCode}
              </span>
            )}
          </FormControl>
        </Grid>

        <Box className="granth_button_container">
          <Button
            variant="contained"
            type="submit"
            startIcon={<FileUploadOutlined />}
            className="granth_button"
            sx={{ fontSize: matches ? "15px" : "20px", textTransform: "none" }}
          >
            {action === "edit" ? "Update" : "Upload"}
          </Button>
        </Box>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { dialogMessage } = state.granthReducer;

  return {
    successResponse: dialogMessage,
  };
};

export default connect(mapStateToProps, {
  createGranth,
  showUnshowDialogMessage,
  updateGranth,
})(withMixpanelTracking(AddEditGranth, "Add Granth"));
