import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
  Box,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Paginate from "../commonComponent/Pagination";
import DeleteDialogLayout from "../../commonLayout/DeleteDialogLayout";
import { formatDateInAlphanumericFormat } from "../../helpers/dateHelper";
import {
  deleteEventsByID,
  getEvents,
  toggleModal,
} from "../../redux/Events/action";
import "../../styles/event.css";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import useCustomStyles from "../../customHooks/useCustomStyles";
import TableTooltip from "../commonComponent/tableTooltip";

const LanguageList = [
  { langName: "HINDI", LangCode: "hi_IN" },
  { langName: "ENGLISH", LangCode: "en_US" },
  { langName: "PUNJABI", LangCode: "pa_IN" },
];

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth: "thin",
    [theme.breakpoints.down(1400)]: {
      maxHeight: 240,
    },
    [theme.breakpoints.between(1401, 1919)]: {
      maxHeight: 390,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 500,
    },
  },

  icon_size: {
    [theme.breakpoints.down(1400)]: {
      height: "25px",
      width: "25px",
    },
    [theme.breakpoints.between(1401, 1919)]: {
      height: "30px",
      width: "30px",
    },
  },
});

const ViewEvent = (props) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const navigate = useNavigate();
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc"); // Sorting state
  const [sortedData, setSortedData] = useState([]); // Sorted data
  const [paginationObject, setPaginationObject] = useState({
    page: 1,
    rowsPerPage: 10,
    totalRowCount: 0,
  });

  const tableContainerRef = useRef(null);

  const handleDeleteData = (value) => {
    setDeleteToggle(!deleteToggle);
    setRowData(value);
  };

  const deleteRowData = (value) => {
    props.deleteEventsByID(value.id);
    props.trackButtonClick("Delete Event Button", 1, value?.id);
  };

  const handlePageChange = (event, newPage) => {
    setPaginationObject({ ...paginationObject, page: newPage });
    props.getEvents(newPage, props.searchTerm);

    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  };

  const setEventDataWithPagination = (loading) => {
    if (!loading && props?.eventsData) {
      setSortedData(props?.eventsData);

      setPaginationObject({
        ...paginationObject,
        totalRowCount: props?.page?.totalPages,
      });
    }
  };

  useEffect(() => {
    props.getEvents(1, props.searchTerm);
    setRowData([]);
  }, [props.deleteSuccess, props.searchTerm]);

  useEffect(() => {
    setEventDataWithPagination(props?.loading);
  }, [props.loading, paginationObject?.page]);

  const sortByTitle = () => {
    if (sortedData?.length > 0) {
      const isAsc = sortOrder === "asc";
      const sorted = [...sortedData].sort((a, b) =>
        isAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name),
      );
      setSortOrder(isAsc ? "desc" : "asc");
      setSortedData(sorted);
    }
  };

  const handleRowEdit = (val) => {
    const jsonData = JSON.stringify(val);

    const encodedData = encodeURIComponent(jsonData);

    navigate(`/content/event/edit?data=${encodedData}`);
  };
  const [openSuccessDelete, setOpenSucceessDelete] = useState(false);
  useEffect(() => {
    if (props.deleteSuccess) {
      setOpenSucceessDelete(true);
    }
  }, [props.deleteSuccess]);

  const closeSuccessDeleteModal = () => {
    props.toggleModal();
    setOpenSucceessDelete(false);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            elevation={0}
            className={classes?.table}
            ref={tableContainerRef}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    onClick={sortByTitle}
                    sx={{ paddingLeft: "10px !important" }}
                  >
                    Title
                    <TableSortLabel
                      active
                      direction={sortOrder}
                      onClick={sortByTitle}
                    />
                  </TableCell>
                  <TableCell>Language</TableCell>
                  <TableCell>Event Date</TableCell>
                  <TableCell>Location Name</TableCell>
                  <TableCell>Uploaded By</TableCell>
                  <TableCell>Uploaded On</TableCell>
                  <TableCell align="right" sx={{ pr: 2.5 }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedData === undefined ||
                  (sortedData?.length > 0 &&
                    sortedData.map((eventrow) => (
                      <TableRow
                        key={eventrow.id}
                        sx={{
                          border: "2px solid #F7F8F9",
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <TableTooltip title={eventrow.name} />
                        </TableCell>
                        <TableCell>
                          {
                            LanguageList.find(
                              (item) =>
                                item?.LangCode === eventrow?.languageCode,
                            )?.langName
                          }
                        </TableCell>
                        <TableCell>
                          {formatDateInAlphanumericFormat(eventrow?.dateTime)}
                        </TableCell>
                        <TableCell>
                          <TableTooltip title={eventrow.locationName} />
                        </TableCell>
                        <TableCell>Admin</TableCell>
                        <TableCell>
                          {formatDateInAlphanumericFormat(eventrow?.updatedAt)}
                        </TableCell>
                        <TableCell align="right" sx={{ p: 0 }}>
                          <img
                            onClick={() => {
                              props.setContentDataToBeUpdated(eventrow);
                              handleRowEdit(eventrow);
                              props.trackButtonClick("Edit Events Button", 1);
                            }}
                            className={classes?.icon_size}
                            src="/edit-icon.svg"
                            alt="edit-icon"
                            style={{ cursor: "pointer" }}
                          />

                          <img
                            onClick={() => handleDeleteData(eventrow)}
                            className={classes?.icon_size}
                            style={{ marginLeft: "0.8rem", cursor: "pointer" }}
                            src="/delete-icon.svg"
                            alt="delete-icon"
                          />
                        </TableCell>
                      </TableRow>
                    )))}
              </TableBody>
            </Table>
          </TableContainer>
          {sortedData?.length === 0 && (
            <Box
              sx={{
                display: "grid",
                justifyContent: "center",
                textAlign: "center",
                width: "100%",
                marginTop: "10%",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <img
                  src="/mailbox.png"
                  alt="No data"
                  style={{ width: "30px", height: "30px" }}
                />
              </Box>
              <Box sx={{}}>No data</Box>
            </Box>
          )}
          {sortedData?.length > 0 && (
            <Grid
              container
              sx={{ marginTop: "25px", justifyContent: "center" }}
            >
              <Paginate
                paginationObject={paginationObject}
                handlePageChange={handlePageChange}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <DeleteDialogLayout
        deleteToggle={deleteToggle}
        openSuccessDelete={openSuccessDelete}
        setDeleteToggle={setDeleteToggle}
        recordType="Event"
        recordRowData={rowData}
        deleteRowData={deleteRowData}
        closeSuccessDeleteModal={closeSuccessDeleteModal}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { successRsponse, eventsData, deleteSuccess, loading, page } =
    state.eventReducer;
  return {
    successRsponse,
    eventsData,
    deleteSuccess,
    loading,
    page,
  };
};

export default connect(mapStateToProps, {
  getEvents,
  deleteEventsByID,
  toggleModal,
})(withMixpanelTracking(ViewEvent, "Event"));
