import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  deleteHukumnama,
  fetchAllHukumnamaWithPagination,
  deleteToggleModal,
} from "../../redux/hukumnama/action";

import TableTooltip from "../commonComponent/tableTooltip";
import {
  Grid,
  IconButton,
  Paper,
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableSortLabel,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteDialogLayout from "../../commonLayout/DeleteDialogLayout";
import Paginate from "../commonComponent/Pagination";
import { formatDateInAlphanumericFormat } from "../../helpers/dateHelper";
import { sortedTableData } from "../../helpers/sortHelper";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import useCustomStyles from "../../customHooks/useCustomStyles";
import { getUploadStatus } from "../../redux/dashboard/action";

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth: "thin",
    [theme.breakpoints.down(1400)]: {
      maxHeight: 240,
    },
    [theme.breakpoints.between(1401, 1919)]: {
      maxHeight: 390,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 500,
    },
  },

  icon_size: {
    [theme.breakpoints.down(1400)]: {
      height: "25px",
      width: "25px",
    },
    [theme.breakpoints.between(1401, 1919)]: {
      height: "30px",
      width: "30px",
    },
  },
});

const ViewHukumnama = (props) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const navigate = useNavigate();
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [paginationObject, setPaginationObject] = useState({
    page: 1,
    rowsPerPage: 10,
    totalRowCount: 0,
  });
  const [tableRecords, setTableRecords] = useState([]);
  const [openSuccessDelete, setOpenSucceessDelete] = useState(false);
  const tableContainerRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setPaginationObject({ ...paginationObject, page: newPage });
    getAllHukumnamaData(newPage);

    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  };

  const getAllHukumnamaData = (page) => {
    props.fetchAllHukumnamaWithPagination(page);
  };

  const setHukumnamaAndPaginationData = (loadingValue) => {
    if (!loadingValue && props?.hukumnamaData?.data) {
      const { content, page } = props?.hukumnamaData?.data;

      props.setHukumnamaList(content);
      props.setRecordsList(content);
      setTableRecords(content);

      setPaginationObject({
        ...paginationObject,
        totalRowCount: page?.totalPages,
      });
    }
  };

  const deleteRowData = (value) => {
    props.deleteHukumnama(value?.id);
    props.trackButtonClick("Delete Hukumnama Button", 1, value?.id);
  };

  const handleDeleteHukumnama = (hukumnamaData) => {
    setDeleteToggle(!deleteToggle);
    setRowData(hukumnamaData);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (props?.hukumnamaList?.length !== tableRecords.length) {
      setPaginationObject({
        ...paginationObject,
        totalRowCount: 1,
      });
    } else {
      setHukumnamaAndPaginationData(false);
    }
  }, [props.hukumnamaList]);

  useEffect(() => {
    setHukumnamaAndPaginationData(props?.loading);
  }, [props?.loading]);

  useEffect(() => {
    getAllHukumnamaData(paginationObject?.page);
    setRowData([]);
    props.getUploadStatus();
    if (props.deleteSuccess) {
      setOpenSucceessDelete(true);
    }
  }, [props?.deleteSuccess]);

  const closeSuccessDeleteModal = () => {
    props.deleteToggleModal();
    setOpenSucceessDelete(false);
  };

  const handleRowEdit = (val) => {
    const jsonData = JSON.stringify(val);

    const encodedData = encodeURIComponent(jsonData);
    navigate(`/content/Hukamnama/edit?data=${encodedData}`);
  };

  return (
    <>
      {
        <DeleteDialogLayout
          deleteToggle={deleteToggle}
          setDeleteToggle={setDeleteToggle}
          recordType="Hukamnama"
          recordRowData={rowData}
          deleteRowData={deleteRowData}
          openSuccessDelete={openSuccessDelete}
          closeSuccessDeleteModal={closeSuccessDeleteModal}
        />
      }

      <Grid container xs={12} flexWrap="wrap">
        <TableContainer
          component={Paper}
          elevation={0}
          className={classes?.table}
          ref={tableContainerRef}
        >
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
              <TableRow>
                <TableCell sx={{ paddingLeft: "10px !important" }}>
                  <TableSortLabel
                    active={orderBy === "title"}
                    direction={order}
                    onClick={() => handleRequestSort("title")}
                  >
                    Title
                  </TableSortLabel>
                </TableCell>
                <TableCell>Language</TableCell>
                <TableCell>Uploaded By</TableCell>
                <TableCell>Uploaded on</TableCell>
                <TableCell align="right" sx={{ pr: 2.5 }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            {sortedTableData(props?.hukumnamaList, order, orderBy).length >
            0 ? (
              <>
                <TableBody>
                  {sortedTableData(props?.hukumnamaList, order, orderBy)?.map(
                    (hukumnama) => (
                      <TableRow
                        key={hukumnama?.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="hukumnama">
                          <TableTooltip title={hukumnama?.title} />
                        </TableCell>
                        <TableCell>
                          {hukumnama?.languageCode === "en_US"
                            ? "English"
                            : hukumnama?.languageCode === "hi_IN"
                              ? "Hindi"
                              : "Punjabi"}
                        </TableCell>
                        <TableCell>Admin</TableCell>
                        <TableCell>
                          {formatDateInAlphanumericFormat(hukumnama?.createdAt)}
                        </TableCell>
                        <TableCell align="right" sx={{ p: 0 }}>
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              props.setContentDataToBeUpdated(hukumnama);
                              handleRowEdit(hukumnama);
                              props.trackButtonClick(
                                "Edit Hukamnama Button",
                                1,
                              );
                            }}
                          >
                            <img
                              className={classes?.icon_size}
                              src="/edit-icon.svg"
                              alt="edit-icon"
                            />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              handleDeleteHukumnama(hukumnama);
                            }}
                          >
                            <img
                              className={classes?.icon_size}
                              src="/delete-icon.svg"
                              alt="delete-icon"
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </>
            ) : null}
          </Table>
        </TableContainer>
        {(props.hukumnamaList === undefined ||
          props?.hukumnamaList?.length === 0) && (
          <Box
            sx={{
              display: "grid",
              justifyContent: "center",
              textAlign: "center",
              width: "100%",
              marginTop: "10%",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <img
                src="/mailbox.png"
                alt="No data"
                style={{ width: "30px", height: "30px" }}
              />
            </Box>
            <Box sx={{}}>No data</Box>
          </Box>
        )}

        {props?.hukumnamaList?.length > 0 && (
          <Grid container sx={{ marginTop: "25px", justifyContent: "center" }}>
            <Paginate
              paginationObject={paginationObject}
              handlePageChange={handlePageChange}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { loading, hukumnamaData, deleteSuccess } = state.hukumnamaReducer;

  return {
    loading,
    hukumnamaData,
    deleteSuccess,
    setContentDataToBeUpdated: ownProps.setContentDataToBeUpdated,
  };
};

export default connect(mapStateToProps, {
  fetchAllHukumnamaWithPagination,
  deleteHukumnama,
  getUploadStatus,
  deleteToggleModal,
})(withMixpanelTracking(ViewHukumnama, "Hukumnama"));
