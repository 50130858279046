import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Divider } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import useMediaQuery from "@mui/material/useMediaQuery";
import ProgressDialogLayout from "../../commonLayout/ProgressDialogLayout";
import { connect } from "react-redux";
import {
  createQuiz,
  UpdateQuizByID,
  toggleQuiz,
} from "../../redux/quiz/action";
import { truncateAtDot } from "../../helpers/truncateFileName";

const BulkUpload = ({
  componentName,
  openBulkUpload,
  setOpenBulkUpload,
  createQuiz,
  UpdateQuizByID,
  createSuccess,
  UPDATESuccess,
  toggleQuiz,
  recordsType,
  recordsData,
}) => {
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadFileName, setUploadFileName] = useState("");
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState("");
  const [uploadInterval, setUploadInterval] = useState(null);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState("");

  const matches = useMediaQuery("(max-width:1440px)");

  const handleClose = () => {
    setOpenBulkUpload(false);
  };

  const handleProgressDialogOpen = () => {
    setProgressDialogOpen(true);
    setUploadInProgress(true);
  };

  const handleProgressDialogClose = () => {
    setProgressDialogOpen(false);
    setUploadInProgress(false);
    clearInterval(uploadInterval); // Stop the upload interval
    setProgress(0); // Reset the progress
    setFileName("");
  };

  const handleFileChange = (event) => {
    const newfile = event.target ? event.target.files[0] : event;
    if (newfile && newfile.type) {
      setFile(newfile);
      setUploadFileName(newfile.name);
      setFileName(newfile.name);
      handleProgressDialogOpen();
      uploadFile(newfile);
      setFile(newfile);
    } else {
      alert("Please upload a valid epub file.");
    }
  };

  // Simulates file upload progress
  const uploadFile = (file) => {
    setProgress(0);

    const UploadProgress = () => {
      let progress = 0;

      const interval = setInterval(() => {
        progress += 10;
        setProgress(progress);

        if (progress >= 110) {
          clearInterval(interval);
          setUploadInProgress(false);
          setProgressDialogOpen(false);
        }
      }, 300);

      setUploadInterval(interval); // Save the interval ID to stop it later
    };
    UploadProgress();
  };

  const handleUpload = async () => {
    if (!file) {
      setFileError("file required");
      return;
    }

    setFileError("");
    const newData = new FormData();
    newData.append("file", file);

    if (componentName === "quiz") {
      if (recordsType === "edit") {
        const formData = new FormData();
        formData.append("batchId", recordsData.batchId);
        const queryParams = new URLSearchParams(formData).toString();
        UpdateQuizByID(newData, queryParams);
      } else {
        createQuiz(newData);
      }
    }
  };

  const handleDownload = () => {
    // This URL points to the file in the public folder
    const fileUrl = "/Sample_quiz_file _CSI.xlsx";

    // Trigger file download
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", "Sample_quiz_file _CSI.xlsx"); // Optional: specify the download file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the DOM
  };

  useEffect(() => {
    if (createSuccess || UPDATESuccess) {
      setOpenBulkUpload(false);
      toggleQuiz();
    }
  }, [createSuccess, UPDATESuccess]);

  return (
    <>
      {progressDialogOpen && (
        <ProgressDialogLayout
          progressDialogOpen={progressDialogOpen}
          handleProgressDialogClose={handleProgressDialogClose}
          uploadFileName={truncateAtDot(uploadFileName)}
          toBeUploadedFileFormat={{ fileType: "CSV" }}
          uploadInProgress={uploadInProgress}
          progress={progress}
        />
      )}

      <Dialog open={openBulkUpload} onClose={handleClose}>
        <DialogContent>
          <Box
            justifyContent={"center"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              //   justifyContent: "center",
              width: { lg: "420px", xl: "480px" },
              height: { lg: "320px", xl: "480px" },
              borderRadius: 4,
              textAlign: "center",
            }}
          >
            {fileName ? (
              <p>{truncateAtDot(fileName)}</p>
            ) : (
              <>
                {" "}
                <FileUploader
                  name="file"
                  handleChange={(event) => {
                    handleFileChange(event);
                  }}
                  children={
                    <img
                      src="/fileUploadIcon.svg"
                      alt="fileUpload-icon"
                      style={{
                        cursor: "pointer",
                        background: "#f9f2ff",
                        borderRadius: 40,
                        height: 60,
                      }}
                    />
                  }
                />
                <Typography variant="h5" justifyContent={"center"} mt={"4%"}>
                  Drag File to Upload
                </Typography>
                <Typography variant="body2" justifyContent={"center"} mt={"2%"}>
                  {`Upload CSV File only`}
                </Typography>
                <Typography variant="body1" justifyContent={"center"} mt={"4%"}>
                  or
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  size={matches ? "small" : "large"}
                  sx={{
                    mt: "4%",
                    textTransform: "none",
                  }}
                >
                  Choose File
                  <input
                    hidden
                    type="file"
                    onChange={(event) => {
                      handleFileChange(event);
                    }}
                  />
                </Button>
                {fileError !== "" && (
                  <div style={{ color: "red", marginRight: "30px" }}>
                    {fileError}
                  </div>
                )}
              </>
            )}

            <Divider style={{ width: "100%", marginTop: 30 }} />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                textAlign: "left",
                alignItems: "center",
                width: "100%",
                marginTop: 10,
              }}
            >
              {recordsType === "Add" ? (
                <Box
                  onClick={handleDownload}
                  sx={{ width: "100%", fontSize: { lg: "14px", xl: "16px" } }}
                >
                  <Link to="#">Download Sample File</Link>
                </Box>
              ) : null}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  width: "100%",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    fontWeight: "400",
                    color: "#ffff",
                    fontSize: "12px !important",
                    height: "30px",
                    padding: "4px 20px",
                  }}
                  size={matches ? "small" : "large"}
                  onClick={handleUpload}
                >
                  {recordsType === "Add" ? "Upload" : "Update"}
                </Button>
                <Button
                  sx={{
                    //   backgroundColor: "var(--secondary-color)",
                    border: "1px solid #9E46E3",
                    textTransform: "none",
                    ":hover": {
                      color: "black !important",
                      backgroundColor: "white !important",
                      border: "1px solid #9E46E3",
                    },
                    fontWeight: "500",
                    marginLeft: "10px",
                    padding: "4px 20px",
                    color: "black",
                  }}
                  size={matches ? "small" : "large"}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  const { createSuccess, UPDATESuccess } = state.quizReducer;

  return {
    createSuccess,
    UPDATESuccess,
  };
};

export default connect(mapStateToProps, {
  createQuiz,
  UpdateQuizByID,
  toggleQuiz,
})(BulkUpload);
