import {
    Grid,
    Paper,
    useTheme,
} from "@mui/material";
import login from "../assets/images/login_image.svg";
import useCustomStyles from "../customHooks/useCustomStyles";

const styles = (theme) => ({
    primary_text_color: { color: "#7F7F7F" },
    secondary_text_color: { color: "#C7332A" },
    tertiary_text_color: { color: "#9E46E3" },
    main_container: { height: "100vh", overflow: "hidden" },
    login_title_text: {
      textAlign: "left",
      color: "#000000",
      fontSize: "36px",
      marginBottom: "55px",
    },
    login_image_container: {
      maxHeight: "100vh",
      display: { xs: "none", md: "block" },
    },
    login_fields_container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      maxHeight: "100vh",
    },
    header: { padding: "5px", width: "500px" },
    helper_text: {
      marginBottom: "25px",
      marginLeft: "2px",
      textDecoration: "none",
      textTransform: "none",
      fontSize: "17px",
    },
    message_container: { marginTop: "10px" },
    message_text: { fontSize: "12px" },
    otp_header: { padding: "5px", width: "500px" },
    otp_sub_header: { fontSize: "17px", fontStyle: "#000000", margin: "2px 0" },
    otp_helper_text: { fontSize: "16px" },
    edit_button: {
      color: "#C7332A",
      marginLeft: "25px",
      textDecoration: "none",
      textTransform: "none",
    },
    otp_input_fields: {
      display: "flex",
      flexDirection: "row",
      my: 5,
      width: { xs: 300, md: 400, lg: 480 },
    },
    otp_error_message: { marginTop: "10px", fontSize: "12px" },
    otp_resend_text: { fontSize: "12px" },
    otp_resend_button: {
      color: "#4169E1",
      textDecoration: "none",
      textTransform: "none",
    },
    otp_verify_button: { marginTop: "20px" },
});

const LoginLayout = ({ childComponent }) => {
    const theme = useTheme();
    const classes = useCustomStyles(styles, theme);

    return (
        <>
            <Grid container component="main" className={classes?.main_container}>
                <Grid className={classes?.login_image_container} item md={5}>
                    <img className="login_image" src={login} alt="login-page-image" />
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={7}
                    component={Paper}
                    square
                    className={classes?.login_fields_container}
                >
                    {childComponent()}
                </Grid>
            </Grid>
        </>
    )
}

export default LoginLayout;
