import MenuLayout from "../commonLayout/MenuLayout";
import { Grid, Box } from "@mui/material";
import activeUsers from "../assets/images/active_user.svg";
import inactiveUser from "../assets/images/inactive_user.svg";
import andriodUser from "../assets/images/andriod_user.svg";
import iOSUser from "../assets/images/iOS_users.svg";
import "../styles/appManagment.css";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getUserStats } from "../redux/dashboard/action";
import android from "../versionData/andriodVersionData.json";
import ios from "../versionData/iOSVersionData.json";
import { useEffect } from "react";
import withMixpanelTracking from "./commonComponent/withMixPanelTracking";

const AppManagement = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    props.getUserStats();
  }, []);

  const handleViewVersionClick = (data) => {
    navigate(`/ViewAppVersionHistory`, { state: data });
  };

  return (
    <>
      <Grid xs={5} md={5} lg={7}>
        <Box className={"page_title"}
          sx={{
            textWrap: "nowrap",
            paddingY: {
              lg: "10px",
              xl: "20px",
            },
          }}
        >
          App Management
        </Box>
      </Grid>
      <Grid
        xs={12}
        className="users_section"
        sx={{
          height: { sm: 300, md: 305, lg: 350 },
          padding: { sx: 5, lg: 5 },
          border: 0,
          maxHeight: "100%",
        }}
      >
        <Grid container>
          <Grid xs={4}>
            <Box className="users_count_box">
              <div>
                <img
                  className="user_count_icon "
                  src={activeUsers}
                  alt="activeUser-icon"
                />
                <p className="user_count_title"> Active App Users </p>
              </div>
              <div className="user_count_text">
                {props.userStats?.activeUsers}
              </div>
            </Box>
          </Grid>

          <Grid xs={4}>
            <Box className="version_box">
              <div>
                <img
                  className="user_count_icon "
                  src={andriodUser}
                  alt="userCount-icon"
                />
                <p className="user_count_title"> Active Android Users </p>
              </div>
              <div className="user_count_text">
                {props.userStats?.androidActiveUsers}
              </div>
              <div className="gap-measure">
                <p className="user_count_title"> Current App Version </p>
                <span className="version_text">{android[0]?.app_Version}</span>
              </div>
              <p
                onClick={() => handleViewVersionClick("Android")}
                className="user_count_subtext"
              >
                View Version History
              </p>
            </Box>
          </Grid>

          <Grid xs={4}>
            <Box className="version_box">
              <div>
                <img
                  className="user_count_icon "
                  src={iOSUser}
                  alt="userCount-icon"
                />
                <p className="user_count_title"> Active iOS Users </p>
              </div>
              <div className="user_count_text">
                {props.userStats?.iosactiveUsers}
              </div>
              <div  className="gap-measure">
                <p className="user_count_title"> Current App Version </p>
                <span className="version_text">{ios[0].app_Version}</span>
              </div>
              <p
                onClick={() => handleViewVersionClick("iOS")}
                className="user_count_subtext"
              >
                View Version History
              </p>
            </Box>
          </Grid>

          <Grid
            xs={4}
            className="margin-box-gap"         
          >
            <Box className="users_count_box">
              <div>
                <img
                  className="user_count_icon "
                  src={inactiveUser}
                  alt="inActive-user-icon"
                />
                <p className=" user_count_title"> Inactive App Users </p>
              </div>
              <div className="user_count_text">
                {props.userStats?.inActiveUsers}
              </div>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { userStats } = state.dashboardReducer;

  return {
    userStats,
  };
};

const AppManagementComp = connect(mapStateToProps, {
  getUserStats,
})(withMixpanelTracking(AppManagement, "App Management"));

const wrapper = () => {
  return (
    <MenuLayout
      childComponent={() => {
        return <AppManagementComp />;
      }}
    />
  );
};

export default wrapper;
