import React, { useEffect, useState } from "react";
import { Button, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { adminLogout, updatePolicy } from "../redux/login/action";

const TermsAndCondition = ({ setOpenModal, ...props }) => {
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const [policyStatus, setPolicyStatus] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const loggedInUserData = props?.loggedInUserData?.data;
    let TermandCondition = loggedInUserData?.termPolicyViewed;
    if (TermandCondition === false) {
      setPolicyStatus(false);
    }
  }, [props?.loggedInUserData]);

  const handleSubmit = (val) => {
    setOpenModal(false);

    if (isChecked && val === "accept") {
      props.updatePolicy();
    } else {
      props.adminLogout();
      localStorage.clear();
      navigate("/login");
    }
  };

  return (
    <>
      <div>
        <Typography variant="h6" sx={{ fontWeight: "550" }} gutterBottom>
          Terms & Conditions
        </Typography>

        <Typography variant="body2" gutterBottom>
          These Terms and Conditions ("Terms") govern your use of the admin
          panel ("Admin Panel") of CSI provided by Cubera ("Company," "we,"
          "us," or "our"). By accessing or using the Admin Panel, you agree to
          comply with and be bound by these Terms. If you do not agree to these
          Terms, you must not use the Admin Panel.
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Access and Use of the Admin Panel
        </Typography>
        <Typography variant="body2" gutterBottom>
          1.1. You are granted limited, non-transferable access to the Admin
          Panel solely for the purpose of managing and administering the
          Application.
        </Typography>
        <Typography variant="body2" gutterBottom>
          1.2. You agree to use the Admin Panel in accordance with these Terms
          and any applicable laws and regulations.
        </Typography>
        <Typography variant="body2" gutterBottom>
          1.3. You are responsible for maintaining the confidentiality of your
          account credentials (username and password) and for all activities
          that occur under your account. If you suspect unauthorized use, you
          must notify us immediately.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Administrator Responsibilities
        </Typography>
        <Typography variant="body2" gutterBottom>
          2.1. As an administrator, you are responsible for managing user
          access, configuring settings, and overseeing the operations of the
          Application as permitted by the Admin Panel's functionality.
        </Typography>

        <Typography variant="body2" gutterBottom>
          2.2. You agree not to:
        </Typography>
        <Typography variant="body2" component="ul">
          <li>Use the Admin Panel for any illegal or unauthorized purpose.</li>
          <li>
            Access, tamper with, or use non-public areas of the Admin Panel, the
            Application, or our systems.
          </li>
          <li>
            Modify or reverse-engineer any part of the Admin Panel or its
            associated services.
          </li>
        </Typography>

        <Typography variant="body2" gutterBottom>
          2.3. You shall ensure that your use of the Admin Panel does not
          disrupt, interfere with, or compromise the security, functionality, or
          integrity of the Application or its underlying infrastructure.
        </Typography>
        <Typography variant="h6" gutterBottom>
          3. Data Privacy and Security
        </Typography>
        <Typography variant="body2" gutterBottom>
          3.1. You agree to handle all data accessed or managed through the
          Admin Panel in compliance with applicable data privacy laws, including
          [insert relevant data protection laws, e.g., GDPR, CCPA].
        </Typography>
        <Typography variant="body2" gutterBottom>
          3.2. You are responsible for implementing appropriate security
          measures to protect the data accessed through the Admin Panel from
          unauthorized access, disclosure, or misuse.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Intellectual Property
        </Typography>
        <Typography variant="body2" gutterBottom>
          4.1. All intellectual property rights in the Admin Panel, including
          but not limited to its content, software, and related documentation,
          are owned by Cubera or its licensors.
        </Typography>
        <Typography variant="body2" gutterBottom>
          4.2. You are granted a limited, non-exclusive, revocable license to
          use the Admin Panel as part of your role as an administrator, subject
          to these Terms.
        </Typography>
        <Typography variant="body2" gutterBottom>
          4.3. You must not reproduce, distribute, modify, or create derivative
          works based on any part of the Admin Panel without our express written
          consent.
        </Typography>
        <Typography variant="h6" gutterBottom>
          5. Termination of Access
        </Typography>
        <Typography variant="body2" gutterBottom>
          5.1. We reserve the right to terminate or suspend your access to the
          Admin Panel at any time, without notice, for any reason, including
          violation of these Terms or any other agreement between you and the
          Company.
        </Typography>
        <Typography variant="body2" gutterBottom>
          5.2. Upon termination, you must cease all use of the Admin Panel and
          any related services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Limitation of Liability
        </Typography>
        <Typography variant="body2" gutterBottom>
          6.1. To the fullest extent permitted by law, the Company shall not be
          liable for any indirect, incidental, special, consequential, or
          punitive damages arising out of or related to your use of the Admin
          Panel.
        </Typography>
        <Typography variant="body2" gutterBottom>
          6.2. The Admin Panel is provided "as is" and "as available," and we
          make no warranties or representations, express or implied, regarding
          its functionality, reliability, or availability.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Indemnification
        </Typography>
        <Typography variant="body2" gutterBottom>
          7.1. You agree to indemnify and hold harmless Cubera, its officers,
          directors, employees, and agents from and against any claims,
          liabilities, damages, losses, or expenses, including reasonable
          attorneys' fees, arising out of or in connection with your use of the
          Admin Panel or your violation of these Terms.
        </Typography>
        <Typography variant="h6" gutterBottom>
          8. Modifications to the Terms
        </Typography>
        <Typography variant="body2" gutterBottom>
          8.1. We reserve the right to modify these Terms at any time. Changes
          will be effective immediately upon posting the revised Terms in the
          Admin Panel.
        </Typography>
        <Typography variant="body2" gutterBottom>
          8.2. Your continued use of the Admin Panel after the changes take
          effect constitutes your acceptance of the modified Terms.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Governing Law and Dispute Resolution
        </Typography>
        <Typography variant="body2" gutterBottom>
          9.1. These Terms shall be governed by and construed in accordance with
          the laws of [insert jurisdiction].
        </Typography>
        <Typography variant="body2" gutterBottom>
          9.2. Any disputes arising from or related to these Terms shall be
          resolved exclusively in the courts of [insert jurisdiction].
        </Typography>

        <Typography variant="h6" gutterBottom>
          10. Contact Information
        </Typography>
        <Typography variant="body2" gutterBottom>
          If you have any questions or concerns about these Terms or your use of
          the Admin Panel, please contact us at:{" "}
          <a href="mailto:support@cubera.co">support@cubera.co</a>
        </Typography>
        <Typography variant="h6" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body2" gutterBottom>
          This Privacy Policy ("Policy") describes how Cubera ("Company," "we,"
          "us," or "our") collects, uses, and protects the information obtained
          from users ("you" or "user") of the CSI Admin Panel ("Admin Panel").
          By using the Admin Panel, you agree to the terms of this Policy.
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body2" gutterBottom>
          1.1 Account Information: We collect information that you provide to us
          when creating an account, such as your username, email address, and
          password.
        </Typography>
        <Typography variant="body2" gutterBottom>
          1.2 Usage Data: We may collect information related to your use of the
          Admin Panel, such as IP addresses, device information, browser types,
          and pages accessed.
        </Typography>
        <Typography variant="body2" gutterBottom>
          1.3 Log Data: Our servers may automatically collect certain log files,
          which can include information such as your IP address, browser type,
          referring/exit pages, and timestamps of your activities.
        </Typography>
        <Typography variant="body2" gutterBottom>
          1.4 Communication Data: If you contact us for support or with
          questions, we collect your communication details to respond
          appropriately.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body2" gutterBottom>
          2.1 Provide and Maintain Services: We use the information collected to
          manage and operate the Admin Panel, ensuring smooth and efficient
          access.
        </Typography>
        <Typography variant="body2" gutterBottom>
          2.2 User Support: Information provided helps us troubleshoot issues
          and provide user support.
        </Typography>
        <Typography variant="body2" gutterBottom>
          2.3 Security: We may use collected data to detect, prevent, and
          address security incidents or illegal activities.
        </Typography>
        <Typography variant="body2" gutterBottom>
          2.4 Legal Compliance: We may use your information to comply with
          applicable laws, regulations, and legal processes.
        </Typography>
        <Typography variant="body2" gutterBottom>
          2.5 Improvement of Services: We may analyze data to improve the
          functionality and performance of the Admin Panel.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. Sharing of Information
        </Typography>
        <Typography variant="body2" gutterBottom>
          3.1 Service Providers: We may share your data with third-party service
          providers who help us operate the Admin Panel, such as hosting
          services or customer support tools.
        </Typography>
        <Typography variant="body2" gutterBottom>
          3.2 Legal Requirements: We may disclose your information if required
          by law or to respond to legal processes, such as subpoenas, government
          requests, or to enforce our rights.
        </Typography>
        <Typography variant="body2" gutterBottom>
          3.3 Business Transfers: In the event of a merger, acquisition, or sale
          of assets, your information may be transferred as part of that
          transaction, subject to this Policy.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Data Security
        </Typography>
        <Typography variant="body2" gutterBottom>
          We take reasonable precautions to protect the information that we
          collect from unauthorized access, disclosure, alteration, or
          destruction. These measures include technical, administrative, and
          physical safeguards. However, no system can guarantee absolute
          security.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Data Retention
        </Typography>
        <Typography variant="body2" gutterBottom>
          We retain your information as long as it is necessary for the purposes
          outlined in this Policy, including legal obligations and business
          requirements. Upon account termination, we may retain limited
          information to comply with applicable legal obligations.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Your Rights
        </Typography>
        <Typography variant="body2" gutterBottom>
          6.1 Access and Update: You may access and update your personal
          information by logging into the Admin Panel or contacting us directly.
        </Typography>
        <Typography variant="body2" gutterBottom>
          6.2 Deletion: You can request the deletion of your personal
          information. We will take reasonable steps to comply, except where we
          need to retain the information for legal or legitimate business
          purposes.
        </Typography>

        <Typography variant="h6" gutterBottom>
          7. Cookies and Tracking Technologies
        </Typography>
        <Typography variant="body2" gutterBottom>
          We may use cookies and other tracking technologies to collect
          information about your usage of the Admin Panel to enhance user
          experience, monitor performance, and analyze trends. You can manage
          your cookie preferences through your browser settings.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8.Children's Privacy
        </Typography>
        <Typography variant="body2" gutterBottom>
          The Admin Panel is intended for use by administrators and is not
          directed at individuals under the age of 18. We do not knowingly
          collect personal information from children.
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Changes to This Policy
        </Typography>
        <Typography variant="body2" gutterBottom>
          We may update this Privacy Policy from time to time. Any changes will
          be posted within the Admin Panel, and we encourage you to review the
          Policy periodically. Your continued use of the Admin Panel after
          changes are made will be considered acceptance of those changes.
        </Typography>
        <Typography variant="h6" gutterBottom>
          10. Governing Law
        </Typography>
        <Typography variant="body2" gutterBottom>
          This Privacy Policy shall be governed by and construed in accordance
          with the laws of [insert jurisdiction].
        </Typography>
        <Typography variant="h6" gutterBottom>
          11. Contact Us
        </Typography>
        <Typography variant="body2" gutterBottom>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at: Email :{" "}
          <a href="mailto:support@cubera.co">support@cubera.co</a>
        </Typography>
        {!policyStatus ? (
          <>
            <FormControlLabel
              control={
                <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
              }
              label="By continuing, you agree to our Terms and Conditions."
            />
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "10px",
              }}
            >
              <Button
                style={{
                  color: "white",
                  background: isChecked ? "#9E46E3" : "gray",
                  textTransform: "none",
                  fontSize: "14px !important",
                  fontWeight: 500,
                  height: "32px",
                }}
                onClick={() => handleSubmit("accept")}
                disabled={!isChecked}
              >
                Continue
              </Button>
              <Button
                style={{
                  color: "black",
                  border: "1px solid gray",
                  background: "white",
                  marginLeft: "10px",
                  textTransform: "none",
                  fontSize: "14px !important",
                  fontWeight: 500,
                  height: "32px",
                }}
                onClick={() => handleSubmit("cancel")}
              >
                Cancel
              </Button>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { data } = state.loginReducer;
  return {
    loggedInUserData: data,
  };
};

export default connect(mapStateToProps, { updatePolicy, adminLogout })(
  TermsAndCondition,
);
