import {
  CREATE_MEDITATION,
  MEDITATION_SUCCESS,
  MEDITATION_FAILURE,
  FETCH_MEDITATION_DETAILS_FAILURE,
  FETCH_MEDITATION_DETAILS_SUCCESS,
  FETCH_MEDITATION_DETAILS,
  DELETE_BY_ID,
  DELETE_BY_ID_SUCCESS,
  DIALOG_MESSAGE,
  DELETE_BY_ID_FAILURE,
  UPDATE_MEDITATION,
  UPDATE_MEDITATION_SUCCESS,
  UPDATE_MEDITATION_FAILURE,
  DELETE_TOGGLE_MODAL_STATUS,
} from "./action";

const initialState = {
  dialogMessage: false,
  loading: false,
  error: "",
  data: [],
  mediTationData: [],
  deleteSuccess: false,
};

export const meditationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_MEDITATION:
      return {
        ...state,
        data: [],
        error: "",
        dialogMessage: false,
        loading: true,
      };

    case MEDITATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: "",
        dialogMessage: true,
        loading: false,
      };

    case MEDITATION_FAILURE:
      return {
        ...state,
        data: [],
        error: action.payload,
        dialogMessage: false,
        loading: false,
      };
    case UPDATE_MEDITATION:
      return {
        ...state,

        error: "",
        loading: true,
        dialogMessage: false,
      };

    case UPDATE_MEDITATION_SUCCESS:
      return {
        ...state,

        error: "",
        loading: false,
        dialogMessage: true,
      };

    case UPDATE_MEDITATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        dialogMessage: false,
      };
    case FETCH_MEDITATION_DETAILS:
      return {
        ...state,

        loading: true,
        error: "",
      };
    case FETCH_MEDITATION_DETAILS_SUCCESS:
      return {
        ...state,
        mediTationData: action.payload,
        loading: false,
        error: "",
      };
    case FETCH_MEDITATION_DETAILS_FAILURE:
      return {
        ...state,
        mediTationData: [],
        loading: false,
        error: action.payload,
      };
    case DELETE_BY_ID:
      return {
        ...state,

        deleteSuccess: false,
      };
    case DELETE_BY_ID_SUCCESS:
      return {
        ...state,

        deleteSuccess: true,
      };
    case DELETE_BY_ID_FAILURE:
      return {
        ...state,

        deleteSuccess: false,
      };
    case DELETE_TOGGLE_MODAL_STATUS:
      return {
        ...state,

        deleteSuccess: false,
      };
    case DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: false,
      };

    default:
      return state;
  }
};
