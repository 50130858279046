import { combineReducers } from "redux";
import { communityReducer } from "./community/reducer";
import { eventReducer } from "./Events/reducer";
import { granthReducer } from "./granth/reducer";
import { hukumnamaReducer } from "./hukumnama/reducer";
import { loginReducer } from "./login/reducer";
import { meditationReducer } from "./meditation/reducer";
import { nitnemReducer } from "./nitnem/reducer";
import { languageReducer } from "./language/reducer";
import { volunteerReducer } from "./volunteer/reducer";
import { helpsReducer } from "./help-centre/reducer";
import { userReducer } from "./usermanagement/reducer";
import { kirtanReducer } from "./kirtan/reducer";
import { gurudwaraReducer } from "./gurudwara/reducer";
import { profileReducer } from "./userprofile/reducer";
import { quizReducer } from "./quiz/reducer";
import { dashboardReducer } from "./dashboard/reducer";
import { deleteRequestReducer } from "./delete-request/reducer";
import { singerReducer } from "./singer/reducer";
import { albumReducer } from "./album/reducer";
import { snackbarReducer } from "./snackbar/reducer";
import { paneReducer } from "./navigationaPane/reducer";

import { geoLocationReducer } from "./geoLocation/reducer";
const rootReducer = combineReducers({
  geoLocationReducer,
  communityReducer,
  eventReducer,
  granthReducer,
  hukumnamaReducer,
  loginReducer,
  meditationReducer,
  userReducer,
  nitnemReducer,
  languageReducer,
  volunteerReducer,
  helpsReducer,
  kirtanReducer,
  gurudwaraReducer,
  profileReducer,
  quizReducer,
  dashboardReducer,
  deleteRequestReducer,
  albumReducer,
  singerReducer,
  snackbarReducer,
  paneReducer,
});

export default rootReducer;
