import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  TableSortLabel,
  Pagination,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import MenuLayout from "../commonLayout/MenuLayout";
import { connect } from "react-redux";
import { getHelps, updateHelps } from "../redux/help-centre/action";
import dayjs from "dayjs";
import "../styles/feedbackForm.css";
import SearchInput from "./commonComponent/SearchInput";
import withMixpanelTracking from "./commonComponent/withMixPanelTracking";
import { handleExport } from "./commonComponent/downloadCSV";
import useCustomStyles from "../customHooks/useCustomStyles";

const getStatusColor = (status) => {
  switch (status) {
    case "NEW":
      return "blue";
    case "PENDING":
      return "orange";
    case "DONE":
      return "green";
    default:
      return "blue";
  }
};

const CustomSelect = styled(Select)(({ value }) => ({
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: getStatusColor(value),
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: getStatusColor(value),
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: getStatusColor(value),
  },
}));

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth: "thin",
    [theme.breakpoints.down(1400)]: {
      maxHeight: 380,
    },
    [theme.breakpoints.between(1401, 1919)]: {
      maxHeight: 450,
      fontSize: "14px !important",
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 600,
    },
  },
});

const ViewHelpCentre = (props) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const matches = useMediaQuery("(max-width:1440px)");
  const [searchKey, setSearchKey] = useState("");
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState([]);
  const [isIssueDialogOpen, setIsIssueDialogOpen] = useState(false);
  const [others, setOthers] = useState(null);
  const [comment, setComment] = useState("");
  const [currentRow, setCurrentRow] = useState(null);
  const [tableIndex, setTableIndex] = useState(0);
  const [status, setStatus] = useState("New");
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const commentsRef = useRef([]);

  //pagination
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const handleChange = (_, value) => {
    setPage(value);
  };

  const handleIssueClick = (issue) => {
    setSelectedIssue(issue.helpNames);
    setOthers(issue.otherData);
    setIsIssueDialogOpen(true);
  };

  const handleCommentChange = useCallback((event) => {
    setComment(event.target.value);
  }, []);

  const handleCommentSubmit = useCallback(
    (issue) => {
      try {
        if (status && comment) {
          props.updateHelps(
            { status, comment },
            currentRow.id,
            currentRow.userFullName,
          );
          props.trackButtonClick("Edit Feedback Button", 1);
          setComment("");
          setEditableFields({ ...editableFields, ["comment"]: "" });
          setToggleUpdate(!toggleUpdate);
        }
      } catch (err) {
        alert(err);
      }
    },
    [comment, currentRow],
  );

  const handleTableSlide = (direction) => {
    setTableIndex((prevIndex) => (prevIndex + direction + 2) % 2);
    setShowDownloadButton(direction === 1);
  };

  const [editableFields, setEditableFields] = useState({
    comment: "",
  });

  // const handleEditClick = (field, id) => {
  //   setEditableFields({
  //     ...editableFields,
  //     [field]: editableFields[field] === id ? "" : id,
  //   });
  // };

  useEffect(() => {
    const formData = new FormData();
    formData.append("page", page - 1);
    formData.append("size", 10);

    const queryParams = new URLSearchParams(formData).toString();
    props.getHelps(queryParams);
  }, [props.updatedHelpSuccess, page]);

  useEffect(() => {
    let data = props.helpsData;
    if (searchKey.trim()) {
      const lowercasedFilter = searchKey.toLowerCase();
      data = props.helpsData.filter((row) =>
        Object.values(row).some((val) =>
          String(val).toLowerCase().includes(lowercasedFilter),
        ),
      );
    }
    if (data) {
      setFilteredData(data);
      setCount(props.page?.totalPages);
    }
  }, [searchKey, props.helpsData, page]);

  const handleStatusChange = useCallback((event, row, index) => {
    setCurrentRow({ ...row, status: event.target.value });
    alert("Please enter the comment");
    setComment(row.comment);
    setStatus(event.target.value.toUpperCase());
    setEditableFields({
      ...editableFields,
      ["comment"]: index,
    });
  }, []);

  const [orderDirection, setOrderDirection] = useState("asc");

  const handleSortRequest = () => {
    if (filteredData?.length > 0) {
      const isAsc = orderDirection === "asc";
      const sorted = [...filteredData].sort((a, b) => {
        return (
          (a.userFullName?.toUpperCase() < b.userFullName?.toUpperCase()
            ? -1
            : 1) * (orderDirection === "asc" ? 1 : -1)
        );
      });
      setOrderDirection(isAsc ? "desc" : "asc");
      setFilteredData(sorted);
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, maxHeight: "100%", mx: 2, mt: 1 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
          <div className="menuBar">
            <Box
              className={"page_title"}
              sx={{
                textWrap: "nowrap",
                paddingY: {
                  lg: "10px",
                  xl: "20px",
                },
              }}
            >
              Feedback Forms
            </Box>
            <Grid
              style={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
                alignItems: "center",
              }}
            >
              <>
                <SearchInput
                  text={"Search Event"}
                  tableData={props.helpsData}
                  setSearchedTable={setFilteredData}
                />
                {showDownloadButton ? (
                  <Button
                    variant="contained"
                    sx={{
                      textAlign: "right",
                      margin: "0px 5px 0px 10px",
                    }}
                    startIcon={<FileDownloadOutlinedIcon />}
                    size="small"
                    className="feedback_download"
                    onClick={() => handleExport(filteredData)}
                  >
                    Download
                  </Button>
                ) : (
                  <></>
                )}
              </>
            </Grid>
          </div>
          <Box
            sx={{
              position: "relative",
              overflow: "hidden",
              width: "100%",
              height: "68vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                transform: `translateX(-${tableIndex * 50}%)`,
                transition: "transform 0.5s ease-in-out",
                width: "200%",
              }}
            >
              <Grid
                container
                xs={12}
                flexWrap="wrap"
                sx={{ width: "50%", marginTop: "1%" }}
              >
                <TableContainer
                  component={Paper}
                  elevation={0}
                  className={classes?.table}
                >
                  <Table aria-label="first table">
                    <TableHead
                      sx={{ backgroundColor: "#F7F8FA" }}
                      style={{
                        position: "sticky",
                        top: 0,
                        backgroundColor: "#F7F8FA",
                        zIndex: 1,
                      }}
                    >
                      <TableRow className="view_user_table_row">
                        <TableCell className="view_user_cell">ID</TableCell>
                        <TableCell className="view_user_cell">Date</TableCell>
                        <TableCell>
                          <TableSortLabel
                            active
                            direction={orderDirection}
                            sx={{ fontSize: matches? "12px":"15px",fontWeight: 500 }}
                            onClick={handleSortRequest}
                          >
                            Name
                          </TableSortLabel>
                        </TableCell>

                        <TableCell className="view_user_cell">
                          Email ID
                        </TableCell>
                        <TableCell className="view_user_cell">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            Phone Number
                            {tableIndex === 0 && (
                              <IconButton onClick={() => handleTableSlide(1)}>
                                <ArrowForwardIosIcon />
                              </IconButton>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData?.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          className="view_user_table_row"
                        >
                          <TableCell className="view_user_cell">
                            {row.id}
                          </TableCell>
                          <TableCell className="view_user_cell">
                            {dayjs(row.createdAt).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell
                            sx={{ height: "3rem" }}
                            className="view_user_cell"
                          >
                            {row.userFullName}
                          </TableCell>
                          <TableCell className="view_user_cell">
                            {row.email}
                          </TableCell>
                          <TableCell className="view_user_cell">
                            {row.phoneNumber}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid
                container
                xs={12}
                flexWrap="wrap"
                sx={{ width: "50%", marginTop: "1%" }}
              >
                <TableContainer
                  component={Paper}
                  elevation={0}
                  className={classes?.table}
                >
                  <Table sx={{ minWidth: 650 }} aria-label="second table">
                    <TableHead
                      sx={{ backgroundColor: "#F7F8FA" }}
                      style={{
                        position: "sticky",
                        top: 0,
                        backgroundColor: "#F7F8FA",
                        zIndex: 1,
                      }}
                    >
                      <TableRow>
                        <TableCell className="view_user_cell">
                          {tableIndex === 1 && (
                            <IconButton
                              sx={{ marginRight: "20px" }}
                              onClick={() => handleTableSlide(-1)}
                            >
                              <ArrowBackIosNewIcon />
                            </IconButton>
                          )}
                          Issue
                        </TableCell>
                        <TableCell className="view_user_cell">
                          Additional Details
                        </TableCell>
                        <TableCell
                          className="view_user_cell"
                          sx={{ paddingLeft: "20px !important" }}
                        >
                          Status
                        </TableCell>
                        <TableCell className="view_user_cell">
                          Comment
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData?.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          className="view_user_table_row"
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            className="view_user_cell"
                          >
                            <div
                              onClick={() => handleIssueClick(row)}
                              style={{
                                cursor: "pointer",
                                maxWidth: "200px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                marginLeft: "60px",
                              }}
                            >
                              {row.helpNames.join(", ")}
                            </div>
                          </TableCell>
                          <TableCell className="view_user_cell">
                            {row.additionalInfo}
                          </TableCell>
                          <TableCell className="view_user_cell">
                            <CustomSelect
                              required
                              className="feedback_dropdownAction"
                              InputLabelProps={{ shrink: true }}
                              labelId="action-select"
                              id="action-select"
                              value={
                                currentRow?.id === row.id
                                  ? status
                                  : row?.status
                                    ? row.status
                                    : "New"
                              }
                              onChange={(e) =>
                                handleStatusChange(e, row, index)
                              }
                              renderValue={(value) => (
                                <Typography
                                  sx={{
                                    height: matches ? "1.6rem" : "2.2rem",
                                    color: getStatusColor(value),
                                    marginTop: "0.15rem",
                                    fontSize: {
                                      lg: "14px",
                                      xl: "18px",
                                    },
                                    minWidth: "60px",
                                  }}
                                >
                                  {value}
                                </Typography>
                              )}
                            >
                              <MenuItem value="New" sx={{ color: "blue" }}>
                                New
                              </MenuItem>
                              <MenuItem
                                value="Pending"
                                sx={{ color: "orange" }}
                              >
                                Pending
                              </MenuItem>
                              <MenuItem value="Done" sx={{ color: "green" }}>
                                Done
                              </MenuItem>
                            </CustomSelect>
                          </TableCell>
                          <TextField
                            id="comment"
                            name="comment"
                            size="small"
                            variant="outlined"
                            startIcon={<EditOutlinedIcon />}
                            value={
                              editableFields.comment === index
                                ? comment
                                : row?.comment
                                  ? row.comment
                                  : " "
                            }
                            ref={(el) => (commentsRef.current[index] = el)}
                            onChange={handleCommentChange}
                            required
                            fullWidth
                            onBlur={() => handleCommentSubmit(row)}
                            disabled={
                              editableFields.comment === index ? false : true
                            }
                            InputProps={{
                              style: {
                                height: matches ? "1.5rem" : "2.2rem",
                                borderRadius: "20px",
                                paddingLeft: "0.5rem",
                                marginTop: "10px",
                              },
                              notchedOutline: {
                                borderColor:
                                  editableFields.comment === index
                                    ? "#9E46E3"
                                    : "#C9C9C9", // Border color based on state
                              },
                            }}
                          />
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Box>
          </Box>
          {filteredData?.length === 0 && (
            <Box
              sx={{
                display: "grid",
                justifyContent: "center",
                textAlign: "center",
                width: "100%",
                marginTop: "-200px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <img
                  src="/mailbox.png"
                  alt="No data"
                  style={{ width: "30px", height: "30px" }}
                />
              </Box>
              <Box sx={{}}>No data</Box>
            </Box>
          )}
          {count && filteredData?.length > 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                marginTop: "20px",
              }}
            >
              <Pagination
                count={count}
                page={page}
                size="large"
                onChange={handleChange}
              />
            </Box>
          ) : (
            ""
          )}
          <Dialog
            open={isIssueDialogOpen}
            onClose={() => setIsIssueDialogOpen(false)}
          >
            <DialogTitle>Issue Details</DialogTitle>
            <DialogContent>
              <ul>
                {selectedIssue.map((issue, index) => (
                  <li key={index}>{issue}</li>
                ))}
                <li>Others : {others}</li>
              </ul>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setIsIssueDialogOpen(false)}
                color="primary"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  const { successRsponse, helpsData, updatedHelpSuccess, page } =
    state.helpsReducer;

  return {
    successRsponse,
    helpsData,
    updatedHelpSuccess,
    page,
  };
};
const HelpCenterComp = connect(mapStateToProps, { updateHelps, getHelps })(
  withMixpanelTracking(ViewHelpCentre, "Feedback"),
);

const wrapper = () => {
  return <MenuLayout childComponent={() => <HelpCenterComp />} />;
};

export default wrapper;
