import {
  CREATE_USER,
  USER_SUCCESS,
  USER_FAILURE,
  DIALOG_MESSAGE,
  GET_SUBADMIN_USERS,
  GET_SUBADMIN_USERS_SUCCESS,
  GET_SUBADMIN_USERS_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_STATUS,
  UPDATE_USER_STATUS_FAILURE,
  UPDATE_USER_STATUS_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  GET_USER_COUNT_FAILURE,
  GET_USER_COUNT_SUCCESS,
  GET_USER_COUNT,
  DELETE_TOGGLE_MODAL_STATUS,
} from "./action";

const initialState = {
  dialogMessage: false,
  loading: false,
  error: "",
  data: [],
  subAdminUsers: [],
  userProfile: null,
  counts: null,
  mediTationData: [],
  updateUserSuccess: false,
  deleteUserSuccess: false,
  subAdminuserResponse: false,
  userPage: [],
  // deleteSuccess: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER:
      return {
        ...state,
        data: [],
        error: "",
        dialogMessage: false,
        loading: true,
      };

    case USER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: "",
        dialogMessage: true,
        loading: false,
      };

    case USER_FAILURE:
      return {
        ...state,
        data: [],
        error: action.payload,
        dialogMessage: false,
        loading: false,
      };

    case DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: false,
      };

    case GET_SUBADMIN_USERS:
      return {
        ...state,
        subAdminUsers: [],
        userPage: [],
        error: "",
        loading: true,

        subAdminuserResponse: false,
      };

    case GET_SUBADMIN_USERS_SUCCESS:
      return {
        ...state,
        subAdminUsers: action.payload,
        userPage: action.page,
        error: "",
        loading: false,

        subAdminuserResponse: true,
      };

    case GET_SUBADMIN_USERS_FAILURE:
      return {
        ...state,
        subAdminUsers: [],
        error: action.payload,
        userPage: [],
        loading: false,
        subAdminuserResponse: false,
      };
    case GET_USER_PROFILE:
      return {
        ...state,
        loading: true,
      };

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
        error: "",
        loading: false,
      };

    case GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        userProfile: null,
        error: action.payload,
        loading: false,
      };
    case GET_USER_COUNT:
      return {
        ...state,
        loading: true,
      };

    case GET_USER_COUNT_SUCCESS:
      return {
        ...state,
        counts: action.payload,
        error: "",
        loading: false,
      };

    case GET_USER_COUNT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPDATE_USER_STATUS:
      return {
        ...state,
        error: "",
        loading: true,
        updateUserSuccess: false,
      };

    case UPDATE_USER_STATUS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        updateUserSuccess: true,
      };

    case UPDATE_USER_STATUS_FAILURE:
      return {
        ...state,
        updateUserSuccess: false,
        error: action.payload,
        loading: false,
      };

    case UPDATE_USER:
      return {
        ...state,
        error: "",
        dialogMessage: false,
        loading: true,
      };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        error: "",
        dialogMessage: true,
        loading: false,
      };

    case UPDATE_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        dialogMessage: false,
        loading: false,
      };

    case DELETE_USER:
      return {
        ...state,
        deleteUserSuccess: false,
        error: "",
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        error: "",
        deleteUserSuccess: true,
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
        deleteUserSuccess: false,
      };
    case DELETE_TOGGLE_MODAL_STATUS:
      return {
        ...state,

        deleteUserSuccess: false,
      };
    default:
      return state;
  }
};
