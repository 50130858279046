export const CREATE_GURUDWARA = "CREATE_GURUDWARA";
export const CREATE_GURUDWARA_SUCCESS = "CREATE_GURUDWARA_SUCCESS";
export const CREATE_GURUDWARA_FAILURE = "CREATE_GURUDWARA_FAILURE";

export const GET_GURUDWARA = "GET_GURUDWARA";
export const GET_GURUDWARA_SUCCESS = "GET_GURUDWARA_SUCCESS";
export const GET_GURUDWARA_FAILURE = "GET_GURUDWARA_FAILURE";

export const UPDATE_GURUDWARA = "UPDATE_GURUDWARA";
export const UPDATE_GURUDWARA_SUCCESS = "UPDATE_GURUDWARA_SUCCESS";
export const UPDATE_GURUDWARA_FAILURE = "UPDATE_GURUDWARA_FAILURE";

export const UPDATE_GURUDWARA_STATUS = "UPDATE_GURUDWARA_STATUS";
export const UPDATE_GURUDWARA_STATUS_SUCCESS =
  "UPDATE_GURUDWARA_STATUS_SUCCESS";
export const UPDATE_GURUDWARA_STATUS_FAILURE =
  "UPDATE_GURUDWARA_STATUS_FAILURE";

export const DELETE_GURUDWARA = "DELETE_GURUDWARA";
export const DELETE_GURUDWARA_SUCCESS = "DELETE_GURUDWARA_SUCCESS";
export const DELETE_GURUDWARA_FAILURE = "DELETE_GURUDWARA_FAILURE";

export const DIALOG_MESSAGE = "DIALOG_MESSAGE";

export const DELETE_TOGGLE_MODAL_STATUS = "DELETE_TOGGLE_MODAL_STATUS";

export const deleteToggleModal = () => {
  return {
    type: DELETE_TOGGLE_MODAL_STATUS,
  };
};

export const getAllGURUDWARA = (payload) => {
  return {
    type: GET_GURUDWARA,
    payload,
    // payload: data,
  };
};

export const getAllGURUDWARASuccess = (data, page) => {
  return {
    type: GET_GURUDWARA_SUCCESS,
    payload: data,
    page,
  };
};

export const getAllGURUDWARAFailure = (error) => {
  return {
    type: GET_GURUDWARA_FAILURE,
    payload: error,
  };
};

export const createGurudwara = (data) => {
  return {
    type: CREATE_GURUDWARA,
    payload: data,
  };
};
export const createGURUDWARASuccess = (data) => {
  return {
    type: CREATE_GURUDWARA_SUCCESS,
    payload: data,
  };
};
export const createGurudwaraFailure = (error) => {
  return {
    type: CREATE_GURUDWARA_FAILURE,
    payload: error,
  };
};

export const updateGurudwara = (payload, id) => {
  return {
    type: UPDATE_GURUDWARA,
    payload,
    id,
  };
};

export const updateGURUDWARASuccess = (data) => {
  return {
    type: UPDATE_GURUDWARA_SUCCESS,
    payload: data,
  };
};

export const updateGurudwaraFailure = (error) => {
  return {
    type: UPDATE_GURUDWARA_FAILURE,
    payload: error,
  };
};

export const updateGurudwaraStatus = (id, status, rowName) => {
  return {
    type: UPDATE_GURUDWARA_STATUS,
    id,
    status,
    rowName,
  };
};

export const updateGURUDWARAStatusSuccess = (data) => {
  return {
    type: UPDATE_GURUDWARA_STATUS_SUCCESS,
    payload: data,
  };
};

export const updateGurudwaraStatusFailure = (error) => {
  return {
    type: UPDATE_GURUDWARA_STATUS_FAILURE,
    payload: error,
  };
};

export const deleteGurudwara = (id) => {
  return {
    type: DELETE_GURUDWARA,
    payload: id,
  };
};

export const deleteGURUDWARASuccess = () => {
  return {
    type: DELETE_GURUDWARA_SUCCESS,
  };
};

export const deleteGurudwaraFailure = (error) => {
  return {
    type: DELETE_GURUDWARA_FAILURE,
    payload: error,
  };
};

export const showUnshowDialogMessage = () => {
  return {
    type: DIALOG_MESSAGE,
  };
};
