import { call, put, takeEvery } from "redux-saga/effects";

import {
  CREATE_MEDITATION,
  FETCH_MEDITATION_DETAILS,
  DELETE_BY_ID,
  UPDATE_MEDITATION,
  meditationSuccess,
  meditationFailure,
  getMeditaionSuccess,
  getMeditaionFailure,
  deleteByIdSuccess,
  deleteByIdFailure,
  updateMedByIdSuccess,
  updateMedByIdFail,
} from "./action";

import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../apiServices/ApiActions";
import {
  addMeditationUrl,
  getMedUrl,
  deleteMeditationUrl,
  updateMedUrls,
} from "../../apiServices/ApiUrl";

const addMeditation = async (payload) => {
  return postRequest(addMeditationUrl, payload);
};

const getAllMeditation = async (page) => {
  return getRequest(`${getMedUrl}?page=${--page}&size=${10}`);
};

const deleteByIdMeditation = async (payload) => {
  return deleteRequest(deleteMeditationUrl + `${payload}`);
};

const UpdateMeditationById = async (payload, id) => {
  return putRequest(updateMedUrls + `${id}`, payload);
};

function* createMeditation(action) {
  try {
    const result = yield call(addMeditation, action.payload);

    if (result.status === 200) {
      yield put(meditationSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(meditationFailure(result));

      if (res) {
        yield put(alert(res.message));
      }
    }
  } catch (error) {
    yield put(meditationFailure(error));
  }
}
function* updateMeditation(action) {
  try {
    const result = yield call(UpdateMeditationById, action.payload, action.id);

    if (result.status === 200) {
      yield put(updateMedByIdSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(updateMedByIdFail(result));

      if (res) {
        yield put(alert(res.message));
      }
    }
  } catch (error) {
    yield put(updateMedByIdFail(error));
  }
}

function* getMeditation(action) {
  try {
    const result = yield call(getAllMeditation, action.params);

    if (result.status === 200) {
      let response = result.data;
      yield put(getMeditaionSuccess(response));
    } else {
      let res = result?.response?.data;
      yield put(getMeditaionFailure(result));

      if (res) {
        yield put(alert(res.message));
      }
    }
  } catch (error) {
    yield put(getMeditaionFailure(error));
  }
}
function* deleteMeditation(action) {
  try {
    const result = yield call(deleteByIdMeditation, action.payload);

    if (result.status === 204 || result.status === 200) {
      yield put(deleteByIdSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(deleteByIdFailure(result));

      if (res) {
        yield put(alert(res.message));
      }
    }
  } catch (error) {
    yield put(deleteByIdFailure(error));
  }
}

function* meditationSaga() {
  yield takeEvery(CREATE_MEDITATION, createMeditation);
  yield takeEvery(UPDATE_MEDITATION, updateMeditation);
  yield takeEvery(FETCH_MEDITATION_DETAILS, getMeditation);
  yield takeEvery(DELETE_BY_ID, deleteMeditation);
}

export default meditationSaga;
