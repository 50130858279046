import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import {
  Grid,
  Stack,
  Button,
  FormControl,
  TextField,
  useTheme,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  formatDateSeperatedWithHyphen,
  anyDate,
  todayDate,
} from "../../helpers/dateHelper";
import useCustomStyles from "../../customHooks/useCustomStyles";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";
import { fetchLanguage } from "../../redux/language/action";
import {
  createHukumnama,
  updateHukumnama,
  showUnshowDialogMessage,
  fetchAllHukumnamaWithPagination,
} from "../../redux/hukumnama/action";

const styles = (theme) => ({
  container: {
    backgroundColor: "#F9F3FB",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      padding: "20px 10px",
      marginTop: "20px",
      height: "400px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "0 10px 25px",
      marginTop: "45px",
      height: "600px",
    },
  },

  componentTitle: {
    fontSize: "24px",
    paddingLeft: "15px",
    fontWeight: "400",
    color: "#333333",
  },

  input_box: {
    backgroundColor: "#ffffff",
    color: "#A0A09E",
  },
});

const AddEditHukumnama = (props) => {
  const { action, tabs } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);
  const [uploadedHukumnamaLanguageList, setUploadedHukumnamaLanguageList] =
    useState([]);
  const editRecords = JSON.parse(decodedData);

  // defining all the necessary state variables
  const [hukumnamaObect, setHukumnamaObject] = useState({
    languageCode: "",
    dateValue: todayDate(),
    title: "",
    description: "",
  });

  const [errors, setErrors] = useState(null);

  const validateForm = () => {
    const newErrors = {};
    if (!hukumnamaObect.languageCode)
      newErrors.languageCode = "Language is required.";
    if (!hukumnamaObect.dateValue) newErrors.dateValue = "Date is required.";
    if (!hukumnamaObect.title) newErrors.title = "Title is required.";
    if (!hukumnamaObect.description)
      newErrors.description = "Description is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // function to add/update hukumnama
  const handleAddHukumnama = async (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    const hyphenFormattedDate = formatDateSeperatedWithHyphen(
      hukumnamaObect?.dateValue,
    );

    const newHukumnamaObject = {
      displaydate: hyphenFormattedDate,
      languageCode: hukumnamaObect?.languageCode,
      description: hukumnamaObect?.description,
      title: hukumnamaObect?.title,
    };

    if (action === "add") {
      // perform add hukumnama
      props.createHukumnama(newHukumnamaObject);
      props.trackButtonClick("Create Hukamnana Button", 1);
    } else if (action === "edit") {
      // perform edit hukumnama
      props.updateHukumnama(newHukumnamaObject, editRecords?.id);
      props.trackButtonClick("Update Hukamnama Button", 1);
    }
  };

  // function to check if there is any hukumnama created in today's date or not
  const getAllHukumnamas = () => {
    props.fetchAllHukumnamaWithPagination(1);
  };

  // function that will reset the input fields after succesing add/edit operation
  const resetInputFieldsOnCreateSuccess = (successValue) => {
    if (successValue) {
      setHukumnamaObject({
        ...hukumnamaObect,
        languageCode: "",
        title: "",
        description: "",
      });
    }
  };

  // function that will be responsible for closing the dialog box
  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();
    navigate(`/content/${tabs}/view`);
  };

  const getAllLanguagesList = () => {
    props.fetchLanguage();
  };

  const matches = useMediaQuery("(max-width:1440px)");

  const retrieveTodaysUploadedHukumnamas = (loadingValue) => {
    if (action === "add" && !loadingValue && props?.hukumnamaData?.data) {
      const hyphenFormattedDate = formatDateSeperatedWithHyphen(todayDate());
      let todayExistingHukumnamaRecords = props?.hukumnamaData?.data?.content;

      todayExistingHukumnamaRecords = todayExistingHukumnamaRecords
        ?.filter((hukumnama) => hyphenFormattedDate === hukumnama?.displaydate)
        ?.map((hukumnama) => hukumnama?.languageCode);

      if (
        todayExistingHukumnamaRecords?.length !==
        props?.languageData?.data?.length
      ) {
        setUploadedHukumnamaLanguageList(
          props?.languageData?.data?.filter(
            (language) =>
              !todayExistingHukumnamaRecords.includes(language?.code),
          ),
        );
      }
    } else if (action === "edit") {
      setHukumnamaObject({
        ...hukumnamaObect,
        dateValue: anyDate(editRecords?.displaydate),
        languageCode: editRecords?.languageCode,
        title: editRecords?.title,
        description: editRecords?.description,
      });
    }
  };

  useEffect(() => {
    if (action === "add") {
      getAllHukumnamas();
    }
    getAllLanguagesList();
  }, []);

  useEffect(() => {
    retrieveTodaysUploadedHukumnamas(props?.loading);
  }, [props?.loading, props?.languageSuccessResponse]);

  useEffect(() => {
    resetInputFieldsOnCreateSuccess(props.successResponse);
  }, [props.successResponse]);

  return (
    <>
      {props.successResponse && (
        <DialogBoxLayout
          dialogBoxToggle={props?.successResponse}
          setDialogBoxToggle={handleCloseSuccessBox}
          header={
            action === "edit"
              ? "Updated Successfully!"
              : "Uploaded Successfully!"
          }
          content={
            action === "edit"
              ? "You Have Edited Your File Successfully"
              : "You Have Uploaded Your File Successfully"
          }
        />
      )}

      <Grid
        xs={12}
        className={classes?.container}
        component="form"
        onSubmit={handleAddHukumnama}
      >
        <div>
          <Stack
            spacing={8}
            direction="column"
            sx={{ justifyContent: "space-evenly", alignItems: "center" }}
          >
            <Stack spacing={4} direction="row">
              <FormControl
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
                size={matches ? "small" : ""}
              >
                <TextField
                  className={classes?.input_box}
                  error={errors?.languageCode}
                  inputProps={{
                    readOnly: action === "add" ? false : true,
                  }}
                  InputLabelProps={{ shrink: true }}
                  id="language"
                  select
                  variant="outlined"
                  size={matches ? "small" : ""}
                  sx={{
                    paddingBottom: "0px",
                    "& .MuiSelect-select span::before": {
                      color: "#A0A09E",
                      content: "'Choose Language'",
                    },
                  }}
                  label="Language"
                  value={hukumnamaObect?.languageCode}
                  onChange={(event) => {
                    setErrors(null);
                    setHukumnamaObject({
                      ...hukumnamaObect,
                      languageCode: event.target.value,
                    });
                  }}
                >
                  {action === "add"
                    ? uploadedHukumnamaLanguageList?.length > 0 &&
                      uploadedHukumnamaLanguageList?.map((language) => (
                        <MenuItem value={language?.code}>
                          {language?.name}
                        </MenuItem>
                      ))
                    : props?.languageData?.data?.length > 0 &&
                      props?.languageData?.data.map((language) => (
                        <MenuItem value={language?.code}>
                          {language?.name}
                        </MenuItem>
                      ))}
                </TextField>
                {errors?.languageCode && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.languageCode}
                  </span>
                )}
              </FormControl>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        size: matches ? "small" : "",
                      },
                    }}
                    sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
                    className={classes?.input_box}
                    readOnly
                    InputLabelProps={{ shrink: true }}
                    label="Date *"
                    format="DD/MM/YYYY"
                    value={hukumnamaObect?.dateValue}
                    onChange={(newValue) =>
                      setHukumnamaObject({
                        ...hukumnamaObect,
                        dateValue: newValue,
                      })
                    }
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Stack>

            <Stack spacing={4} direction="row">
              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mt: 4 }}
              >
                <TextField
                  className={classes?.input_box}
                  id="outlined-multiline-static"
                  InputLabelProps={{ shrink: true }}
                  label="Content"
                  error={errors?.description}
                  placeholder="Content"
                  size={matches ? "small" : ""}
                  multiline
                  rows={6}
                  value={hukumnamaObect?.description}
                  onChange={(event) => {
                    setErrors(null);
                    setHukumnamaObject({
                      ...hukumnamaObect,
                      description: event.target.value,
                    });
                  }}
                />
                {errors?.description && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.description}
                  </span>
                )}
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mt: 4 }}
              >
                <TextField
                  className={classes?.input_box}
                  id="outlined-basic"
                  error={errors?.title}
                  size={matches ? "small" : ""}
                  InputLabelProps={{ shrink: true }}
                  label="Title"
                  placeholder="Title"
                  variant="outlined"
                  value={hukumnamaObect?.title}
                  onChange={(event) => {
                    setErrors(null);
                    setHukumnamaObject({
                      ...hukumnamaObect,
                      title: event.target.value,
                    });
                  }}
                />
                {errors?.title && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.title}
                  </span>
                )}
              </FormControl>
            </Stack>
          </Stack>

          <Grid xs={12} sx={{ mt: 3 }}>
            <Button
              variant="contained"
              type="submit"
              startIcon={<FileUploadOutlined />}
              size="small"
              sx={{
                backgroundColor: "#9E46E3",
                textTransform: "none",
                fontSize: matches ? "15px" : "20px",
                fontWeight: "500",
                marginLeft: { sm: 11, md: 52, lg: 85, xl: 110 },
              }}
            >
              {action === "edit" ? "Update" : "Upload"}
            </Button>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { dialogMessage, getHukumnamaSuccess, loading, hukumnamaData } =
    state.hukumnamaReducer;
  const { success, languageData } = state.languageReducer;

  return {
    successResponse: dialogMessage,
    getHukumnamaSuccess,
    hukumnamaData,
    loading,
    languageSuccessResponse: success,
    languageData,
    contentDataToBeUpdated: ownProps.contentDataToBeUpdated,
  };
};

export default connect(mapStateToProps, {
  createHukumnama,
  fetchAllHukumnamaWithPagination,
  updateHukumnama,
  showUnshowDialogMessage,
  fetchLanguage,
})(withMixpanelTracking(AddEditHukumnama, "Add Hukumnama"));
