import { useState, useEffect, React, useRef } from "react";
import { useNavigate } from "react-router-dom";
import DeleteDialogLayout from "../../commonLayout/DeleteDialogLayout";
import UploadKirtan from "./UploadKirtan";
import { connect } from "react-redux";
import "../../styles/kirtan.css";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import { formatDateInAlphanumericFormat } from "../../helpers/dateHelper";
import axios from "axios";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  IconButton,
  Paper,
  useTheme,
  TableSortLabel,
  Box,
} from "@mui/material";
import useCustomStyles from "../../customHooks/useCustomStyles";

import {
  getKirtanWithSinger,
  getKirtanWithAlbum,
  deleteSingerSong,
  deleteToggleModal,
} from "../../redux/kirtan/action";
import useMediaQuery from "@mui/material/useMediaQuery";

const styles = (theme) => ({
  caption: {
    ".MuiTablePagination-displayedRows": {
      fontSize: "1.15rem",
      fontWeight: 600,
      textAlign: "right",
    },
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[200],
    fontWeight: "bold",
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  expandedRow: {
    backgroundColor: theme.palette.grey[100],
  },
  collapseContent: {
    padding: theme.spacing(2),
  },
  songImage: {
    width: "100px",
    borderRadius: "4px",
  },
  albumThumbnail: {
    width: "150px",
    borderRadius: "4px",
  },
  table: {
    overflowY: "auto",
    scrollbarWidth: "thin",
    [theme.breakpoints.down(1400)]: {
      maxHeight: 210,
    },
    [theme.breakpoints.between(1401, 1919)]: {
      maxHeight: 320,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 440,
    },
  },
  icon_size: {
    [theme.breakpoints.down(1400)]: {
      height: "25px",
      width: "25px",
    },
    [theme.breakpoints.between(1401, 1919)]: {
      height: "30px",
      width: "30px",
    },
  },
});

const ViewKirtan = ({
  openModal,
  setOpenModal,
  kirtanList,
  setRecordsList,
  setKirtanList,
  getAllKirtans,
  kirtandata,
  setContentDataToBeUpdated,
  tabType,
  subTabType,
  ...props
}) => {
  const [rowData, setRowData] = useState([]);
  const theme = useTheme();
  const navigate = useNavigate();
  const classes = useCustomStyles(styles, theme);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [redordsData, setRecors] = useState([]);
  const [viewType, setViewtype] = useState();
  const [opeeditModal, setopenEditModal] = useState(false);
  const matches = useMediaQuery("(max-width:1440px)");
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("songName");
  const tableContainerRef = useRef(null);

  const handleDeleteData = (value) => {
    setDeleteToggle(!deleteToggle);
    setRowData(value);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  let sortedSingers = [];

  if (kirtanList && kirtanList?.length > 0) {
    sortedSingers = [...kirtanList].sort((a, b) => {
      if (order) {
        return (
          (a.songName.toUpperCase() < b.songName.toUpperCase() ? -1 : 1) *
          (order === "asc" ? 1 : -1)
        );
      }
      return 0;
    });
  }

  const deleteRowData = (value) => {
    props.deleteSingerSong(value.songId);
  };

  const handleCloseEditBox = () => {
    setViewtype("");
    setopenEditModal(false);
  };

  const setData = () => {
    let NewTableData = [];

    if (
      props?.kirtaWithSingerData?.length > 0 &&
      props?.kirtaWithAlbumData?.length > 0
    ) {
      let mergedData = props?.kirtaWithSingerData?.concat(
        props?.kirtaWithAlbumData,
      );

      mergedData?.map((item) => {
        let newObj = {};

        if (item?.songs?.length > 0) {
          if (item.singerId) {
            newObj["singerName"] = item.singerName;
            newObj["albumName"] = "";
            newObj["singerId"] = item.singerId;
            newObj["languageCode"] = item.languageCode;
            item.songs.map((itm) => {
              newObj = { ...newObj, ...itm };
              NewTableData.push(newObj);
            });
          } else {
            newObj["albumName"] = item.albumName;
            newObj["singerName"] = "";
            newObj["albumId"] = item.albumId;
            newObj["languageCode"] = item.languageCode;
            item.songs.map((itm) => {
              newObj = { ...newObj, ...itm };
              NewTableData.push(newObj);
            });
          }
        }
      });

      if (NewTableData?.length > 0) {
        typeof setRecordsList === "function" && setRecordsList(NewTableData);
        typeof setKirtanList === "function" && setKirtanList(NewTableData);
      } else {
        setRecordsList([]);
        setKirtanList([]);
      }
    } else {
      setRecordsList([]);
      setKirtanList([]);
    }
  };

  useEffect(() => {
    setData();
    setRowData([]);
  }, [
    props?.albumSuccessResponse,
    props?.singerSuccessResponse,
    props?.deleteSuccess,
  ]);

  useEffect(() => {
    props?.getKirtanWithSinger();
    props?.getKirtanWithAlbum();
  }, [props?.deleteSuccess, opeeditModal]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  };

  const handleRowEdit = (val) => {
    const jsonData = JSON.stringify(val);

    const encodedData = encodeURIComponent(jsonData);
    navigate(`/content/kirtan/edit?data=${encodedData}`);
  };
  const [openSuccessDelete, setOpenSucceessDelete] = useState(false);
  useEffect(() => {
    if (props.deleteSuccess) {
      setOpenSucceessDelete(true);
    }
  }, [props.deleteSuccess]);
  const closeSuccessDeleteModal = () => {
    props.deleteToggleModal();
    setOpenSucceessDelete(false);
  };

  return (
    <>
      <DeleteDialogLayout
        deleteToggle={deleteToggle}
        recordType="Kirtan"
        setDeleteToggle={setDeleteToggle}
        recordRowData={rowData}
        deleteRowData={deleteRowData}
        openSuccessDelete={openSuccessDelete}
        closeSuccessDeleteModal={closeSuccessDeleteModal}
      />

      {opeeditModal || openModal ? (
        <UploadKirtan
          closeEditModal={openModal ? setOpenModal : handleCloseEditBox}
          recordsData={redordsData}
          viewType={viewType ? "Edit" : "Add"}
        />
      ) : (
        <>
          <TableContainer
            component={Paper}
            elevation={0}
            className={classes?.table}
            ref={tableContainerRef}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
                <TableRow>
                  <TableCell sx={{ paddingLeft: "10px !important" }}>
                    <TableSortLabel
                      active={orderBy === "songName"}
                      direction={order}
                      onClick={() => handleRequestSort("songName")}
                    >
                      Song Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Singer Name</TableCell>
                  <TableCell>Album Name</TableCell>
                  <TableCell>Song Duration</TableCell>
                  <TableCell>Language</TableCell>
                  <TableCell>Uploaded By</TableCell>
                  <TableCell>Uploaded On</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedSingers
                  .slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage,
                  )
                  .map((singer) => (
                    <>
                      <TableRow>
                        <TableCell>{singer?.songName}</TableCell>
                        <TableCell>
                          {singer.singerName ? singer.singerName : "-"}
                        </TableCell>
                        <TableCell>
                          {singer.albumName ? singer.albumName : "-"}
                        </TableCell>
                        <TableCell>{singer?.songDuration}</TableCell>
                        <TableCell>English</TableCell>
                        <TableCell>Admin</TableCell>
                        <TableCell>
                          {formatDateInAlphanumericFormat(singer?.createdAt)}
                        </TableCell>
                        <TableCell align="right" sx={{ p: 0 }}>
                          <img
                            onClick={() => {
                              subTabType("Kirtan");
                              handleRowEdit(singer);
                              setContentDataToBeUpdated(singer);
                              props.trackButtonClick("Edit Kirtan Button", 1);
                            }}
                            src="/edit-icon.svg"
                            alt="delete-icon"
                            className={classes?.icon_size}
                            style={{ cursor: "pointer" }}
                          />

                          <img
                            onClick={() => handleDeleteData(singer)}
                            src="/delete-icon.svg"
                            alt="delete-icon"
                            className={classes?.icon_size}
                            style={{ marginLeft: "0.8rem", cursor: "pointer" }}
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {sortedSingers?.length === 0 && (
            <Box
              sx={{
                display: "grid",
                justifyContent: "center",
                textAlign: "center",
                width: "100%",
                marginTop: "10%",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <img
                  src="/mailbox.png"
                  alt="No data"
                  style={{ width: "30px", height: "30px" }}
                />
              </Box>
              <Box sx={{}}>No data</Box>
            </Box>
          )}
          {sortedSingers?.length > 0 && (
            <Grid
              container
              sx={{ marginTop: "25px", justifyContent: "center" }}
            >
              <Pagination
                className={classes?.caption}
                rowsPerPageOptions={[10, 20, 35]}
                component="div"
                count={Math.ceil(kirtanList?.length / 10)}
                rowsPerPage={rowsPerPage}
                page={page}
                size={matches ? "size" : "large"}
                onChange={handleChangePage}
              />
            </Grid>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    kirtaWithSingerData,
    kirtaWithAlbumData,
    deleteSuccess,
    createRespone,
    albumSuccessResponse,
    singerSuccessResponse,
  } = state.kirtanReducer;

  return {
    kirtaWithSingerData,
    kirtaWithAlbumData,
    deleteSuccess,
    createRespone,
    albumSuccessResponse,
    singerSuccessResponse,
  };
};

export default connect(mapStateToProps, {
  getKirtanWithSinger,
  getKirtanWithAlbum,
  deleteSingerSong,
  deleteToggleModal,
})(withMixpanelTracking(ViewKirtan, "Kirtan"));
