import { call, put, takeEvery } from "redux-saga/effects";

import {
  ADMIN_LOGIN,
  ADMIN_UPDATE,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  updateloginSuccess,
  updateloginFailure,
  ADMIN_LOGOUT,
  UPDATE_POLICY,
  updatePolicySuccess,
  updatePolicyFailure,
  FORGET_PASSWORD,
  forgetPasswordSuccess,
  forgetPasswordFailure,
  CREATE_PASSWORD,
  createPasswordSuccess,
  createPasswordFailure,
} from "./action";

import {
  postRequest,
  postRequestWithFormData,
  putRequest,
} from "../../apiServices/ApiActions";
import {
  loginAuthUrl,
  updateAuthUrl,
  updatePolicyUrl,
  forgetPasswordAuthUrl,
  createPasswordAuthUrl,
} from "../../apiServices/ApiUrl";
import { showSnackbar } from "../snackbar/action";

const addAdmin = async (payload) => {
  return postRequest(loginAuthUrl, payload);
};

const updateAdmin = async (payload) => {
  return postRequest(updateAuthUrl, payload);
};

const updatePolicyApi = async () => {
  return putRequest(updatePolicyUrl);
};

const forgetPasswordApi = async (formData) => {
  return postRequestWithFormData(forgetPasswordAuthUrl, formData);
};

const createPasswordApi = async (payload) => {
  return postRequest(createPasswordAuthUrl, payload);
};

function* createAdmin(action) {
  try {
    const result = yield call(addAdmin, action.payload);

    if (result.status === 200) {
      yield put(loginSuccess(result));
    } else {
      let message = "Entered email/password is incorrect!";
      yield put(loginFailure(message));
    }
  } catch (error) {
    let message = "Entered email/password is incorrect!";
    yield put(loginFailure(message));
  }
}

function* updateLogin(action) {
  try {
    const result = yield call(updateAdmin, action.payload);

    if (result.status === 200) {
      yield put(updateloginSuccess(result?.data));
    } else {
      localStorage.clear();
      action.navigate("/login");

      yield put(updateloginFailure(result));
    }
  } catch (error) {
    localStorage.clear();
    action.navigate("/login");
    yield put(updateloginFailure(error));
  }
}

function* logoutAdmin(action) {
  try {
    yield put(logoutSuccess);
  } catch (error) {}
}

function* updatePolicy(action) {
  try {
    const result = yield call(updatePolicyApi, action.payload);

    if (result.status === 200) {
      yield put(updatePolicySuccess());
    } else {
      yield put(updatePolicyFailure());
    }
  } catch (error) {
    yield put(updatePolicyFailure());
  }
}

function* forgetPassword(action) {
  try {
    const result = yield call(forgetPasswordApi, action.data);

    if (result?.status === 200) {
      yield put(forgetPasswordSuccess(result));
    } else {
      yield put(forgetPasswordFailure());
    }
  } catch (error) {
    yield put(
      showSnackbar({
        message: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.response?.data?.error,
        severity: "error",
      }),
    );
  }
}

function* createPassword(action) {
  try {
    const result = yield call(createPasswordApi, action.payload);

    if (result.status === 200) {
      yield put(createPasswordSuccess(result));
    } else {
      yield put(createPasswordFailure());
    }
  } catch (error) {
    yield put(
      showSnackbar({
        message: error?.response?.data?.message
          ? error?.response?.data?.errorCode
          : error?.response?.data?.error,
        severity: "error",
      }),
    );
  }
}

function* loginSaga() {
  yield takeEvery(ADMIN_LOGIN, createAdmin);
  yield takeEvery(ADMIN_UPDATE, updateLogin);
  yield takeEvery(ADMIN_LOGOUT, logoutAdmin);
  yield takeEvery(UPDATE_POLICY, updatePolicy);
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
  yield takeEvery(CREATE_PASSWORD, createPassword);
}

export default loginSaga;
