import {
  CREATE_COMMUNITY,
  CREATE_COMMUNITY_SUCCESS,
  CREATE_COMMUNITY_FAILURE,
  GET_COMMUNITY,
  GET_COMMUNITY_SUCCESS,
  GET_COMMUNITY_FAILURE,
  DIALOG_MESSAGE,
  UPDATE_COMMUNITY,
  UPDATE_COMMUNITY_SUCCESS,
  UPDATE_COMMUNITY_FAILURE,
  DELETE_COMMUNITY,
  DELETE_COMMUNITY_SUCCESS,
  DELETE_COMMUNITY_FAILURE,
  DELETE_TOGGLE_MODAL_STATUS,
} from "./action";

const initialState = {
  loading: false,
  error: "",
  communtyPostdata: [],
  getCommPostSuccess: false,
  dialogMessage: false,
  deleteCommunitySuccess: false,
  updateCommunitySuccess: false,
};

export const communityReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_COMMUNITY:
      return {
        ...state,
        data: [],
        error: "",
        dialogMessage: false,
        loading: true,
      };
    case CREATE_COMMUNITY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: "",
        dialogMessage: false,
        loading: false,
      };
    case CREATE_COMMUNITY_FAILURE:
      return {
        ...state,
        data: [],
        error: action.payload,
        dialogMessage: false,
        loading: false,
      };
    case GET_COMMUNITY:
      return {
        ...state,
        communtyPostdata: [],
        error: "",
        getCommPostSuccess: false,
      };
    case GET_COMMUNITY_SUCCESS:
      return {
        ...state,
        communtyPostdata: action.payload,
        error: "",
        getCommPostSuccess: true,
      };
    case GET_COMMUNITY_FAILURE:
      return {
        ...state,
        communtyPostdata: [],
        error: action.payload,
        getCommPostSuccess: false,
      };

    case UPDATE_COMMUNITY:
      return {
        ...state,
        error: "",
        dialogMessage: false,
        loading: true,
      };
    case UPDATE_COMMUNITY_SUCCESS:
      return {
        ...state,
        error: "",
        dialogMessage: true,
        loading: false,
      };
    case UPDATE_COMMUNITY_FAILURE:
      return {
        ...state,
        error: action.payload,
        dialogMessage: false,
        loading: false,
      };
    case DELETE_COMMUNITY:
      return {
        ...state,
        deleteCommunitySuccess: false,
        error: "",
      };
    case DELETE_COMMUNITY_SUCCESS:
      return {
        ...state,
        error: "",
        deleteCommunitySuccess: true,
      };
    case DELETE_COMMUNITY_FAILURE:
      return {
        ...state,
        error: action.payload,
        deleteCommunitySuccess: false,
      };
    case DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: false,
      };
    case DELETE_TOGGLE_MODAL_STATUS:
      return {
        ...state,

        deleteCommunitySuccess: false,
      };
    default:
      return state;
  }
};
