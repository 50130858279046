import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import useCustomStyles from "../customHooks/useCustomStyles";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SearchInput from "./commonComponent/SearchInput";
import withMixpanelTracking from "./commonComponent/withMixPanelTracking";

// importing all the necessary components
import MenuLayout from "../commonLayout/MenuLayout";
import AddCommunityPost from "./communityPost/AddCommunityPost";
import ViewCommunityPost from "./communityPost/ViewCommunityPost";
import AddEvent from "./event/AddEvent";
import ViewEvent from "./event/ViewEvent";
import AddEditGranth from "./granth/AddEditGranth";
import ViewGranth from "./granth/ViewGranth";
import AddEditHukumnama from "./hukumnama/AddEditHukumnama";
import HukumnamaBulkUpload from "./hukumnama/HukumnamaBulkUpload";
import ViewHukumnama from "./hukumnama/ViewHukumnama";
import AddEditMeditation from "./meditation/AddEditMeditation";
import ViewMeditation from "./meditation/ViewMeditation";
import AddEditNitnem from "./nitnem/AddEditNitnem";
import ViewNitnem from "./nitnem/ViewNitnem";
import ViewQuiz from "./Quiz/ViewQuiz";
import AddQuiz from "./Quiz/AddQuiz";
import BulkUpload from "./Quiz/BulkUpload";
import Kirtan from "./kirtan/Kirtan";
import { capitalizeWords } from "../helpers/stringFormattingHelper";
import UploadKirtan from "./kirtan/UploadKirtan";
import AddAlbum from "./kirtan/AddAlbum";
import AddSinger from "./kirtan/AddSinger";
import { connect } from "react-redux";
import { getUploadStatus } from "../redux/dashboard/action";

// creating custom styles object
const styles = (theme) => ({
  list_item_text: {
    [theme.breakpoints.down("xl")]: {
      ".MuiTypography-root": {
        fontSize: "15px",
      },
    },
    [theme.breakpoints.up("xl")]: {
      ".MuiTypography-root": {
        fontSize: "18px",
      },
    },
    [theme.breakpoints.between(1236, 1919)]: {
      fontSize: "24px",
    },
  },

  menu_title: {
    [theme.breakpoints.down("xl")]: {
      fontSize: "20px",
      fontWeight: "500",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "28px",
      fontWeight: "600",
    },
  },

  page_title: {
    fontWeight: "600",
    color: "#000000",
    lineHeight: "0",
    [theme.breakpoints.down("xl")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "32px",
    },
  },

  page_sub_title: {
    fontWeight: "500",
    color: "#4F566C",
    marginTop: "0.25rem",
    [theme.breakpoints.down("xl")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "18px",
    },
  },

  box: {
    margin: "10px 0px",
    display: "flex",
    justifyContent: "space-around",
    width: "80rem",
  },

  menuBox: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      justifyContent: "left",
    },
    [theme.breakpoints.up("xl")]: {
      justifyContent: "center",
    },
  },

  boxInside: {
    margin: "10px 0px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xl")]: {
      width: "70rem",
    },
    [theme.breakpoints.up("xl")]: {
      width: "96rem",
    },
  },

  list_item_button: {
    "&.Mui-selected": {
      backgroundColor: "#ffffff",
      borderRadius: "5px",
      height: "3rem",
      width: "auto",
      border: "3px solid #9E46E3",
      borderStyle: "hidden hidden solid hidden",
      fontSize: "30px",
    },
  },

  button: {
    textTransform: "none",
    fontWeight: "500",
    [theme.breakpoints.down(1535)]: {
      fontSize: "10px",
    },
    [theme.breakpoints.between(1536, 1919)]: {
      fontSize: "14px",
      width: "100%",
    },
    [theme.breakpoints.up(1920)]: {
      fontSize: "20px",
    },
  },

  first_button: {
    marginRight: "8px",
    color: "#414861",
  },

  second_button: {
    color: "#ffffff",
    backgroundColor: "#9E46E3",
    "&:disabled": {
      color: "#ffffff",
    },
  },

  bulk_button: {
    marginRight: "8px",
    color: "#414861",
    border: "1px solid #EBEAEB",
    ":hover": {
      border: "1px solid #414861",
      color: "inherit",
      backgroundColor: "inherit",
    },
  },

  page_heading: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  back_arrow: {
    color: "#000000",
    margin: "5px 10px 0 0",
    fontSize: "36px",
  },
});

const ContentManagement = (props) => {
  const { tabs, action } = useParams();
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const navigate = useNavigate();

  // declaring all the state variables

  // declaring all the state variables
  const [selectedMenu, setSelectedMenu] = useState(() => {
    const storedMenu = localStorage.getItem("selectedMenu");

    return storedMenu ? storedMenu : "Hukamnama";
  });

  const [openBulkUpload, setOpenBulkUpload] = useState(false);
  const [recordTypes] = useState("Add");
  const [actionType, setActionType] = useState(() => {
    const storedactionType = localStorage.getItem("actionType");

    return storedactionType ? storedactionType : "";
  });
  const [tabType, setTabType] = useState(() => {
    const storedtabType = localStorage.getItem("tabType");

    return storedtabType ? storedtabType : "";
  });
  const [dataList, setDataList] = useState([]);
  const [recordsList, setRecordsList] = useState([]);
  const [contentDataToBeUpdated, setContentDataToBeUpdated] = useState([]);

  const handleMenuButtonClick = (event, menu) => {
    setSelectedMenu(menu);
    setActionType("view");
    navigate(`/content/${menu}/${action}`);
  };

  const HandleBulkUploadClick = (val, type) => {
    setOpenBulkUpload(!openBulkUpload);
  };

  useEffect(() => {
    props.getUploadStatus();
  }, []);

  // Update tabType in localStorage whenever it changes
  useEffect(() => {
    if (tabType) {
      localStorage.setItem("tabType", tabType);
    }
  }, [tabType]);

  // Retrieve tabType from localStorage when component mounts
  useEffect(() => {
    const savedTabType = localStorage.getItem("tabType");

    if (savedTabType) {
      setTabType(savedTabType);
    }
  }, []);

  useEffect(() => {
    if (selectedMenu) {
      localStorage.setItem("selectedMenu", selectedMenu);
    }
  }, [selectedMenu]);

  // Retrieve tabType from localStorage when component mounts
  useEffect(() => {
    const savedTabType = localStorage.getItem("selectedMenu");

    if (savedTabType) {
      setSelectedMenu(savedTabType);
    }
  }, []);
  useEffect(() => {
    if (actionType) {
      localStorage.setItem("actionType", actionType);
    }
  }, [tabType]);

  // Retrieve tabType from localStorage when component mounts
  useEffect(() => {
    const savedTabType = localStorage.getItem("actionType");

    if (savedTabType) {
      setActionType(actionType);
    }
  }, []);

  useEffect(() => {
    if (tabs && action) {
      localStorage.setItem("selectedMenu", tabs);
      localStorage.setItem("actionType", action);
      setSelectedMenu(tabs);
      setActionType(action);
    }
  }, [tabs, action]);

  useEffect(() => {
    if (tabs && action) {
      setSelectedMenu(tabs);
      setActionType(action);
    }
  }, [tabs, action]);

  return (
    <>
      {openBulkUpload && (
        <BulkUpload
          openBulkUpload={openBulkUpload}
          setOpenBulkUpload={setOpenBulkUpload}
          recordsType={recordTypes}
        />
      )}

      <Box sx={{ flexGrow: 1, maxHeight: "100%", mx: 2 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
          <Grid container xs={12} className={classes?.box}>
            {actionType === "view" ? (
              <>
                <Grid xs={5} md={5} lg={7}>
                  <Box
                    className={"page_title"}
                    sx={{
                      textWrap: "nowrap",
                      paddingY: {
                        lg: "10px",
                        xl: "20px",
                      },
                    }}
                  >
                    Content Management
                  </Box>

                  <p className={classes?.page_sub_title}>
                    Get a top level view of your contents
                  </p>
                </Grid>

                <Grid
                  xs={7}
                  md={7}
                  lg={5}
                  sx={{ width: { md: 100 }, textAlign: "right" }}
                >
                  {selectedMenu !== "kirtan" && (
                    <div className="search__container">
                      <SearchInput
                        text={"Search Event"}
                        tableData={recordsList}
                        setSearchedTable={setDataList}
                      />
                    </div>
                  )}
                </Grid>

                <Grid container xs={12} className={classes?.menuBox}>
                  <Stack
                    sx={{
                      borderRadius: "12px",
                      backgroundColor: "#F7F8FA",
                    }}
                    className={classes?.boxInside}
                    direction="row"
                    useFlexGap
                    flexWrap="wrap"
                  >
                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton
                          className={classes?.list_item_button}
                          selected={selectedMenu === "Hukamnama"}
                          onClick={(event) => {
                            handleMenuButtonClick(event, "Hukamnama");
                            props.trackButtonClick("Hukamnama Tab", 1);
                          }}
                        >
                          <ListItemText
                            primary="Hukamnama"
                            className={classes?.list_item_text}
                            primaryTypographyProps={{
                              fontSize: {
                                lg: "12px !important",
                                xl: "18px !important",
                              },
                            }}
                            sx={{
                              color:
                                selectedMenu === "hukamnama"
                                  ? "#9E46E3"
                                  : "#596075",
                              textAlign: "center",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>

                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton
                          className={classes?.list_item_button}
                          selected={selectedMenu === "kirtan"}
                          onClick={(event) => {
                            handleMenuButtonClick(event, "kirtan");
                            props.trackButtonClick("Kirtan Tab", 1);
                          }}
                        >
                          <ListItemText
                            primaryTypographyProps={{
                              fontSize: {
                                lg: "12px !important",
                                xl: "18px !important",
                              },
                            }}
                            primary="Kirtan"
                            className={classes?.list_item_text}
                            sx={{
                              color:
                                selectedMenu === "kirtan"
                                  ? "#9E46E3"
                                  : "#596075",
                              textAlign: "center",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>

                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton
                          className={classes?.list_item_button}
                          selected={selectedMenu === "nitnem"}
                          onClick={(event) => {
                            handleMenuButtonClick(event, "nitnem");
                            props.trackButtonClick("Nitenam Tab", 1);
                          }}
                        >
                          <ListItemText
                            primary="Nitnem"
                            className={classes?.list_item_text}
                            primaryTypographyProps={{
                              fontSize: {
                                lg: "12px !important",
                                xl: "18px !important",
                              },
                            }}
                            sx={{
                              color:
                                selectedMenu === "nitnem"
                                  ? "#9E46E3"
                                  : "#596075",
                              textAlign: "center",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>

                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton
                          className={classes?.list_item_button}
                          selected={selectedMenu === "granth"}
                          onClick={(event) => {
                            handleMenuButtonClick(event, "granth");
                            props.trackButtonClick("Granth Tab", 1);
                          }}
                        >
                          <ListItemText
                            primary="Granth"
                            primaryTypographyProps={{
                              fontSize: {
                                lg: "12px !important",
                                xl: "18px !important",
                              },
                            }}
                            className={classes?.list_item_text}
                            sx={{
                              color:
                                selectedMenu === "granth"
                                  ? "#9E46E3"
                                  : "#596075",
                              textAlign: "center",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>

                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton
                          className={classes?.list_item_button}
                          selected={selectedMenu === "meditation"}
                          onClick={(event) => {
                            handleMenuButtonClick(event, "meditation");
                            props.trackButtonClick("Meditation Tab", 1);
                          }}
                        >
                          <ListItemText
                            primary="Meditation"
                            className={classes?.list_item_text}
                            primaryTypographyProps={{
                              fontSize: {
                                lg: "12px !important",
                                xl: "18px !important",
                              },
                            }}
                            sx={{
                              color:
                                selectedMenu === "meditation"
                                  ? "#9E46E3"
                                  : "#596075",
                              textAlign: "center",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>

                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton
                          className={classes?.list_item_button}
                          selected={selectedMenu === "event"}
                          onClick={(event) => {
                            handleMenuButtonClick(event, "event");
                            props.trackButtonClick("Event Tab", 1);
                          }}
                        >
                          <ListItemText
                            primary="Event"
                            className={classes?.list_item_text}
                            primaryTypographyProps={{
                              fontSize: {
                                lg: "12px !important",
                                xl: "18px !important",
                              },
                            }}
                            sx={{
                              color:
                                selectedMenu === "event"
                                  ? "#9E46E3"
                                  : "#596075",
                              textAlign: "center",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>

                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton
                          className={classes?.list_item_button}
                          selected={selectedMenu === "community-post"}
                          onClick={(event) => {
                            handleMenuButtonClick(event, "community-post");
                            props.trackButtonClick("Community Post Tab", 1);
                          }}
                        >
                          <ListItemText
                            primary="Community post"
                            primaryTypographyProps={{
                              fontSize: {
                                lg: "12px !important",
                                xl: "18px !important",
                              },
                            }}
                            className={classes?.list_item_text}
                            sx={{
                              color:
                                selectedMenu === "community-post"
                                  ? "#9E46E3"
                                  : "#596075",
                              textAlign: "center",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>

                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton
                          className={classes?.list_item_button}
                          selected={selectedMenu === "quiz"}
                          onClick={(event) => {
                            handleMenuButtonClick(event, "quiz");
                            props.trackButtonClick("Quiz Tab", 1);
                          }}
                        >
                          <ListItemText
                            primary="Quiz"
                            primaryTypographyProps={{
                              fontSize: {
                                lg: "12px !important",
                                xl: "18px !important",
                              },
                            }}
                            className={classes?.list_item_text}
                            sx={{
                              color:
                                selectedMenu === "quiz" ? "#9E46E3" : "#596075",
                              textAlign: "center",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Stack>
                </Grid>
              </>
            ) : (
              <Grid xs={12} className={classes?.page_heading}>
                <Link to={`/content/${tabs}/view`}>
                  <KeyboardBackspaceOutlinedIcon
                    onClick={() => {
                      setTabType(tabType);
                    }}
                    className={classes?.back_arrow}
                  />
                </Link>

                <Typography
                  component="h1"
                  className={classes?.page_title}
                  sx={{
                    width: { xs: 80, md: 100, lg: 500 },
                    textWrap: "nowrap",
                  }}
                >
                  {`${
                    actionType === "add" || actionType === "Add"
                      ? `Add ${selectedMenu === "kirtan" ? tabType : capitalizeWords(selectedMenu.split("-"))}`
                      : actionType === "edit" || actionType === "Edit"
                        ? `Edit ${selectedMenu === "kirtan" ? tabType : capitalizeWords(selectedMenu.split("-"))}`
                        : ""
                  }`}
                </Typography>
              </Grid>
            )}

            {actionType === "view" ? (
              <Grid container xs={12} sx={{ padding: "10px 0" }}>
                <Grid xs={8}>
                  <Box
                    sx={{
                      fontSize: {
                        lg: "14px",
                        xl: "16px",
                      },
                      fontWeight: 600,
                    }}
                  >
                    {capitalizeWords(selectedMenu.split("-"))}
                  </Box>
                </Grid>

                <Grid xs={4}>
                  {selectedMenu !== "quiz" &&
                  selectedMenu !== "kirtan" &&
                  selectedMenu !== "Hukamnama" ? (
                    <Button
                      sx={{ float: "right" }}
                      variant="contained"
                      startIcon={<AddCircleOutlineOutlinedIcon />}
                      size="small"
                      onClick={() => {
                        setSelectedMenu(`${selectedMenu}`);
                        setActionType("add");
                        navigate(`/content/${selectedMenu}/add`);
                        props.trackButtonClick(`Add ${selectedMenu} Button`, 1);
                      }}
                      className={`${classes?.button} ${classes?.second_button}`}
                    >{`Add ${capitalizeWords(selectedMenu.split("-"))}`}</Button>
                  ) : selectedMenu === "quiz" ? (
                    <Button
                      sx={{ float: "right" }}
                      variant="contained"
                      startIcon={<FileUploadOutlinedIcon />}
                      size="small"
                      onClick={() => {
                        HandleBulkUploadClick("Bulk Upload", selectedMenu);
                        props.trackButtonClick(
                          `Bulk Upload ${selectedMenu} Button`,
                          1,
                        );
                      }}
                      className={`${classes?.button} ${classes?.second_button}`}
                    >
                      Bulk Upload
                    </Button>
                  ) : selectedMenu === "Hukamnama" ? (
                    <Button
                      sx={{ float: "right" }}
                      variant="contained"
                      disabled={props?.uploadStatus?.data}
                      startIcon={<AddCircleOutlineOutlinedIcon />}
                      size="small"
                      onClick={() => {
                        setSelectedMenu(`${selectedMenu}`);
                        setActionType("add");
                        navigate(`/content/${selectedMenu}/add`);
                        props.trackButtonClick(`Add ${selectedMenu} Button`, 1);
                      }}
                      className={`${classes?.button} ${classes?.second_button}`}
                    >{`Add ${selectedMenu}`}</Button>
                  ) : null}
                </Grid>
              </Grid>
            ) : null}

            {/* calling the components based on which menu has been selected */}
            {(() => {
              switch (selectedMenu) {
                case "Hukamnama":
                  if (action === "view") {
                    return (
                      <ViewHukumnama
                        hukumnamaList={dataList}
                        setRecordsList={setRecordsList}
                        setHukumnamaList={setDataList}
                        setContentDataToBeUpdated={setContentDataToBeUpdated}
                      />
                    );
                  } else if (action === "add") {
                    return <AddEditHukumnama contentDataToBeUpdated={[]} />;
                  } else if (action === "edit") {
                    return (
                      <AddEditHukumnama
                        contentDataToBeUpdated={contentDataToBeUpdated}
                      />
                    );
                  }
                  break;

                case "Bulk Upload":
                  return <HukumnamaBulkUpload />;

                case "nitnem":
                  if (action === "view") {
                    return (
                      <ViewNitnem
                        nitnemList={dataList}
                        setRecordsList={setRecordsList}
                        setNitnemList={setDataList}
                        setContentDataToBeUpdated={setContentDataToBeUpdated}
                      />
                    );
                  } else if (action === "add") {
                    return <AddEditNitnem contentDataToBeUpdated={[]} />;
                  } else if (action === "edit") {
                    return (
                      <AddEditNitnem
                        contentDataToBeUpdated={contentDataToBeUpdated}
                      />
                    );
                  }
                  break;
                case "granth":
                  if (action === "view") {
                    return (
                      <ViewGranth
                        granthList={dataList}
                        setRecordsList={setRecordsList}
                        setGranthList={setDataList}
                        setContentDataToBeUpdated={setContentDataToBeUpdated}
                      />
                    );
                  } else if (action === "add") {
                    return <AddEditGranth contentDataToBeUpdated={[]} />;
                  } else if (action === "edit") {
                    return (
                      <AddEditGranth
                        contentDataToBeUpdated={contentDataToBeUpdated}
                      />
                    );
                  }
                  break;
                case "meditation":
                  if (action === "view") {
                    return (
                      <ViewMeditation
                        meditationList={dataList}
                        setRecordsList={setRecordsList}
                        setMeditationList={setDataList}
                        setContentDataToBeUpdated={setContentDataToBeUpdated}
                      />
                    );
                  } else if (action === "add") {
                    return <AddEditMeditation contentDataToBeUpdated={[]} />;
                  } else if (action === "edit") {
                    return (
                      <AddEditMeditation
                        contentDataToBeUpdated={contentDataToBeUpdated}
                      />
                    );
                  }
                  break;

                case "event":
                  if (action === "view") {
                    return (
                      <ViewEvent
                        eventList={dataList}
                        setRecordsList={setRecordsList}
                        setEventList={setDataList}
                        setContentDataToBeUpdated={setContentDataToBeUpdated}
                      />
                    );
                  } else if (action === "add") {
                    return <AddEvent contentDataToBeUpdated={[]} />;
                  } else if (action === "edit") {
                    return (
                      <AddEvent
                        contentDataToBeUpdated={contentDataToBeUpdated}
                      />
                    );
                  }
                  break;
                case "community-post":
                  if (action === "view") {
                    return (
                      <ViewCommunityPost
                        communityPostList={dataList}
                        setRecordsList={setRecordsList}
                        setCommunityPostList={setDataList}
                        setContentDataToBeUpdated={setContentDataToBeUpdated}
                      />
                    );
                  } else if (action === "add") {
                    return <AddCommunityPost contentDataToBeUpdated={[]} />;
                  } else if (action === "edit") {
                    return (
                      <AddCommunityPost
                        contentDataToBeUpdated={contentDataToBeUpdated}
                      />
                    );
                  }
                  break;

                case "quiz":
                  if (action === "view") {
                    return (
                      <ViewQuiz
                        quizList={dataList}
                        setRecordsList={setRecordsList}
                        setQuizList={setDataList}
                      />
                    );
                  } else if (action === "add") {
                    return <AddQuiz />;
                  }
                  break;

                case "kirtan" || "Singer" || "Album" || "Kirtan":
                  if (action === "view") {
                    return (
                      <Kirtan
                        kirtanList={dataList}
                        setRecordsList={setRecordsList}
                        setKirtanList={setDataList}
                        setContentDataToBeUpdated={setContentDataToBeUpdated}
                        subTabType={setTabType}
                        tabType={tabType}
                      />
                    );
                  } else if (action === "Add") {
                    if (tabType === "Kirtan") {
                      return <UploadKirtan contentDataToBeUpdated={[]} />;
                    } else if (tabType === "Singer") {
                      return <AddSinger contentDataToBeUpdated={[]} />;
                    } else if (tabType === "Album") {
                      return <AddAlbum contentDataToBeUpdated={[]} />;
                    }
                  } else if (action === "edit") {
                    if (tabType === "Kirtan") {
                      return (
                        <UploadKirtan
                          contentDataToBeUpdated={contentDataToBeUpdated}
                        />
                      );
                    } else if (tabType === "Singer") {
                      return (
                        <AddSinger
                          contentDataToBeUpdated={contentDataToBeUpdated}
                        />
                      );
                    } else if (tabType === "Album") {
                      return (
                        <AddAlbum
                          contentDataToBeUpdated={contentDataToBeUpdated}
                        />
                      );
                    }
                  }
                  break;

                default:
                  return <ViewHukumnama />;
              }
            })()}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { uploadStatus } = state.dashboardReducer;

  return {
    uploadStatus,
  };
};

const ContentMangment = connect(mapStateToProps, {
  getUploadStatus,
})(withMixpanelTracking(ContentManagement, "Content Management"));

const wrapper = () => {
  return (
    <MenuLayout
      childComponent={() => {
        return <ContentMangment />;
      }}
    />
  );
};

export default wrapper;
