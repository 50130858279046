import { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableSortLabel,
  useTheme,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteDialogLayout from "../../commonLayout/DeleteDialogLayout";
import Paginate from "../commonComponent/Pagination";
import { connect } from "react-redux";
import {
  getMeditaion,
  deleteById,
  deleteToggleModal,
} from "../../redux/meditation/action";
import { formatDateInAlphanumericFormat } from "../../helpers/dateHelper";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import useCustomStyles from "../../customHooks/useCustomStyles";

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth: "thin",
    [theme.breakpoints.down(1400)]: {
      maxHeight: 240,
    },
    [theme.breakpoints.between(1401, 1919)]: {
      maxHeight: 390,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 500,
    },
  },

  icon_size: {
    [theme.breakpoints.down(1400)]: {
      height: "25px",
      width: "25px",
    },
    [theme.breakpoints.between(1401, 1919)]: {
      height: "30px",
      width: "30px",
    },
  },
});

const ViewMeditation = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const classes = useCustomStyles(styles, theme);
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc"); // sorting state
  const [sortedData, setSortedData] = useState([]);
  const [paginationObject, setPaginationObject] = useState({
    page: 1,
    rowsPerPage: 10,
    totalRowCount: 0,
  });

  const [comparetableRecords, setCompareTableRecords] = useState([]);
  const handlePageChange = (event, newPage) => {
    setPaginationObject({ ...paginationObject, page: newPage });
    props.getMeditaion(newPage);
  };

  const setMeditationDataWithPagination = (loading) => {
    if (!loading && props?.mediTationData) {
      const { content, page } = props?.mediTationData;

      props.setMeditationList(content);
      props.setRecordsList(content);
      setCompareTableRecords(content);
      setSortedData(content);

      setPaginationObject({
        ...paginationObject,
        totalRowCount: page?.totalPages,
      });
    }
  };

  useEffect(() => {
    setSortedData(props.meditationList);
  }, [props?.meditationList]);

  useEffect(() => {
    props.getMeditaion(paginationObject?.page);
    setRowData([]);
    if (props.deleteSuccess) {
      setOpenSucceessDelete(true);
    }
  }, [props.deleteSuccess]);

  useEffect(() => {
    setMeditationDataWithPagination(props.loadingState);
  }, [props.loadingState]);

  useEffect(() => {
    if (props.meditationList?.length !== comparetableRecords?.length) {
      setPaginationObject({
        ...paginationObject,
        totalRowCount: 1,
      });
    } else {
      setMeditationDataWithPagination(false);
    }
  }, [props.meditationList]);

  const sortByTitle = () => {
    if (sortedData?.length > 0) {
      const isAsc = sortOrder === "asc";
      const sorted = [...sortedData].sort((a, b) =>
        isAsc
          ? a.songName.localeCompare(b.songName)
          : b.songName.localeCompare(a.songName),
      );
      setSortOrder(isAsc ? "desc" : "asc");
      setSortedData(sorted);
    }
  };

  const deleteRowData = (value) => {
    props.deleteById(value.id);
    props.trackButtonClick("Delete Meditation Button", 1);
  };

  const handleDeleteData = (value) => {
    setDeleteToggle(!deleteToggle);
    setRowData(value);
  };
  const [openSuccessDelete, setOpenSucceessDelete] = useState(false);

  const closeSuccessDeleteModal = () => {
    props.deleteToggleModal();
    setOpenSucceessDelete(false);
  };
  const handleRowEdit = (val) => {
    const jsonData = JSON.stringify(val);

    const encodedData = encodeURIComponent(jsonData);
    navigate(`/content/meditation/edit?data=${encodedData}`);
  };

  return (
    <>
      <DeleteDialogLayout
        deleteToggle={deleteToggle}
        recordType="Meditation"
        setDeleteToggle={setDeleteToggle}
        recordRowData={rowData}
        deleteRowData={deleteRowData}
        openSuccessDelete={openSuccessDelete}
        closeSuccessDeleteModal={closeSuccessDeleteModal}
      />

      <Grid container xs={12} flexWrap="wrap">
        <TableContainer
          component={Paper}
          elevation={0}
          className={classes?.table}
        >
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
              <TableRow>
                <TableCell
                  sx={{
                    cursor: "pointer",
                    paddingLeft: "10px !important",
                    width: "200px",
                  }}
                >
                  Title
                  <TableSortLabel
                    active
                    direction={sortOrder}
                    onClick={sortByTitle}
                  />
                </TableCell>
                <TableCell>Language</TableCell>
                <TableCell>Uploaded By</TableCell>
                <TableCell>Uploaded On</TableCell>
                <TableCell align="right" sx={{ pr: 2.5 }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData?.length > 0 &&
                sortedData.map((row) => (
                  <TableRow
                    key={row.title}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.songName}
                    </TableCell>
                    <TableCell>English</TableCell>
                    <TableCell>Admin</TableCell>
                    <TableCell>
                      {formatDateInAlphanumericFormat(row.updatedAt)}
                    </TableCell>
                    <TableCell align="right" sx={{ p: 0 }}>
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          props.setContentDataToBeUpdated(row);
                          handleRowEdit(row);
                          props.trackButtonClick("Edit Meditation Button", 1);
                        }}
                      >
                        <img
                          className={classes?.icon_size}
                          src="/edit-icon.svg"
                          alt="delete"
                          style={{ cursor: "pointer" }}
                        />
                      </IconButton>

                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDeleteData(row)}
                      >
                        <img
                          className={classes?.icon_size}
                          src="/delete-icon.svg"
                          alt="delete-icon"
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {(sortedData === undefined || sortedData?.length === 0) && (
          <Box
            sx={{
              display: "grid",
              justifyContent: "center",
              textAlign: "center",
              width: "100%",
              marginTop: "10%",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <img
                src="/mailbox.png"
                alt="No data"
                style={{ width: "30px", height: "30px" }}
              />
            </Box>
            <Box sx={{}}>No data</Box>
          </Box>
        )}
        {sortedData?.length > 0 && (
          <Grid container sx={{ marginTop: "25px", justifyContent: "center" }}>
            <Paginate
              paginationObject={paginationObject}
              handlePageChange={handlePageChange}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { dialogMessage, loading, mediTationData, deleteSuccess } =
    state.meditationReducer;

  return {
    successResponse: dialogMessage,
    loadingState: loading,
    deleteSuccess,
    mediTationData,
  };
};

export default connect(mapStateToProps, {
  getMeditaion,
  deleteById,
  deleteToggleModal,
})(withMixpanelTracking(ViewMeditation, "Meditation"));
