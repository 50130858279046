import { useParams } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { truncateAtDot } from "../../helpers/truncateFileName";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import useCustomStyles from "../../customHooks/useCustomStyles";
import DialogBoxFileUploadLayout from "../../commonLayout/DialogBoxFileUploadLayout";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import LoaderLayout from "../../commonLayout/LoaderLayout";
import ProgressDialogLayout from "../../commonLayout/ProgressDialogLayout";
import {
  createAlbum,
  updateAlbum,
  showUnshowDialogMessage,
} from "../../redux/album/action";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";

const styles = (theme) => ({
  container: {
    marginTop: "45px",
    backgroundColor: "#F9F3FB",
    borderRadius: "8px",
    padding: "0 10px 300px",
    height: "800px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  componentTitle: {
    fontSize: "24px",
    paddingLeft: "15px",
    fontWeight: "400",
    color: "#333333",
  },

  input_box: {
    backgroundColor: "#ffffff",
    color: "#A0A09E",
  },
});

const AddAlbum = ({ closeEditModal, contentDataToBeUpdated, ...props }) => {
  const { action } = useParams();
  const matches = useMediaQuery("(max-width:1440px)");
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);

  const [uploadAlbum, setuploadAlbum] = useState({
    albumName: "",
    thumbnailPath: "",
    languageCode: "",
  });

  // state variable used to keep a track of the different behaviour of drag and drop and normal file upload
  const [toBeUploadedFileFormat, setToBeUploadedFileFormat] = useState({
    fileType: "",
    acceptedFileFormatForDragDrop: [],
    acceptedFileFormatForNormalFileUpload: [],
  });

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [uploadFileName, setUploadFileName] = useState("");
  const [uploadInterval, setUploadInterval] = useState(null);
  const [fileName, setFileName] = useState("");
  const [, setAudioFileName] = useState("");

  const resetInputFieldsOnApiSuccess = useCallback(
    (successValue, loadingValue) => {
      if (successValue) {
        setLoading(false);
        setuploadAlbum((prevuploadAlbum) => ({
          ...prevuploadAlbum,
          albumName: "",
          thumbnailPath: "",
          languageCode: "",
        }));
        setFileName("");
        setAudioFileName("");
      } else {
        setLoading(loadingValue);
      }
    },
    [],
  );

  useEffect(() => {
    if (props.dialogMessage) {
      resetInputFieldsOnApiSuccess(true, "Created Successfully.");
    }
  }, [props.createRespone, props.updateResponse]);

  useEffect(() => {
    if (action === "edit") {
      let records = contentDataToBeUpdated;
      setuploadAlbum((prevuploadAlbum) => ({
        ...prevuploadAlbum,
        albumName: records.albumName,
        languageCode: records.languageCode,
      }));
    }
  }, [action]);

  const handleChangeLanguage = (val) => {
    setuploadAlbum({
      ...uploadAlbum,
      languageCode: val.target.value,
    });
  };

  const handleOpen = (fileType, dragDropFileFormat, normalUploadFileFormat) => {
    setToBeUploadedFileFormat({
      ...toBeUploadedFileFormat,
      fileType: fileType,
      acceptedFileFormatForDragDrop: dragDropFileFormat,
      acceptedFileFormatForNormalFileUpload: normalUploadFileFormat,
    });
    setDialogOpen(true);
  };

  const handleProgressDialogClose = () => {
    setProgressDialogOpen(false);
    setUploadInProgress(false);
    clearInterval(uploadInterval);
    setProgress(0);
    setFileName("");
    setAudioFileName("");
  };

  const handleFileChange = (event) => {
    const file = event.target ? event.target.files[0] : event;

    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      setUploadFileName(file.name);
      setuploadAlbum((prevuploadAlbum) => ({
        ...prevuploadAlbum,
        thumbnailPath: file,
      }));
      setFileName(file.name);
      setDialogOpen(false);
      handleProgressDialogOpen();
      uploadFile(file);
    } else {
      alert("Please upload a valid file.");
    }
  };

  const handleProgressDialogOpen = () => {
    setProgressDialogOpen(true);
    setUploadInProgress(true);
  };

  const uploadFile = (file) => {
    setProgress(0);

    const UploadProgress = () => {
      let progress = 0;

      const interval = setInterval(() => {
        progress += 10;
        setProgress(progress);

        if (progress >= 110) {
          clearInterval(interval);
          setUploadInProgress(false);
          setProgressDialogOpen(false);
        }
      }, 300);

      setUploadInterval(interval);
    };
    UploadProgress();
  };

  const handleKirtanUpload = (event) => {
    event.preventDefault();
    const formData = new FormData();

    formData.append("albumName", uploadAlbum?.albumName);
    formData.append("languageCode", uploadAlbum?.languageCode);
    if (action === "edit" && !uploadAlbum.thumbnailPath) {
      formData.append("thumbnailPath", contentDataToBeUpdated?.thumbnailPath);
    } else {
      formData.append("thumbnailPath", uploadAlbum?.thumbnailPath);
    }

    if (action === "edit") {
      props.updateAlbum(formData, contentDataToBeUpdated.id);
      props.trackButtonClick("Update Album Button", 1, uploadAlbum?.albumName);
    } else {
      props.createAlbum(formData);
      props.trackButtonClick("Create Album Button", 1, uploadAlbum?.albumName);
    }
  };

  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();
    props.HandleCloseAbum(false);
  };

  return (
    <>
      {props?.dialogMessage && (
        <DialogBoxLayout
          dialogBoxToggle={props.dialogMessage}
          setDialogBoxToggle={handleCloseSuccessBox}
          header={
            action === "edit"
              ? "Updated Successfully!"
              : "Uploaded Successfully!"
          }
          content={
            action === "edit" ?
              "You Have Edited Album Successfully" :
              "You Have Uploaded Album Successfully"
          }
        />
      )}

      <div className="kirtan__upload-container">
        {dialogOpen && (
          <DialogBoxFileUploadLayout
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            uploadInProgress={uploadInProgress}
            progress={progress}
            handleFileChange={handleFileChange}
            toBeUploadedFileFormat={toBeUploadedFileFormat}
          />
        )}

        {progressDialogOpen && (
          <ProgressDialogLayout
            progressDialogOpen={progressDialogOpen}
            handleProgressDialogClose={handleProgressDialogClose}
            uploadFileName={uploadFileName}
            uploadInProgress={uploadInProgress}
            progress={progress}
          />
        )}

        <Grid
          xs={12}
          sx={{
            width: {
              xl: "60%",
            },
            margin: {
              xl: "auto",
            },
            marginTop: {
              xl: "80px",
            },
            paddingBottom: {
              xl: "60px",
            },
          }}
          component="form"
          onSubmit={handleKirtanUpload}
        >
          <div>
            <Stack spacing={2} direction="column" gap={3}>
              <Grid container spacing={{ lg: 8, xl: 20 }}>
                <FormControl
                  variant="outlined"
                  sx={{ width: { xs: 200, md: 250, lg: 400 }, mb: 4 }}
                >
                  <TextField
                    className={classes?.input_box}
                    id="outlined-basic"
                    size={matches ? "small" : ""}
                    placeholder="Enter Album Name"
                    label="Album Name"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={uploadAlbum?.albumName}
                    onChange={(event) =>
                      setuploadAlbum({
                        ...uploadAlbum,
                        albumName: event.target.value,
                      })
                    }
                  />
                </FormControl>

                <FormControl
                  variant="outlined"
                  sx={{ width: { xs: 200, md: 250, lg: 400 }, mb: 4 }}
                >
                  <TextField
                    className={classes?.input_box}
                    id="outlined-basic"
                    placeholder="In JPEG/PNG format only*"
                    size={matches ? "small" : ""}
                    label="Album Image"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <>
                          <Button
                            aria-label="upload"
                            component="label"
                            className="addFileButton"
                            size={matches ? "small" : ""}
                            onClick={() => {
                              handleOpen(
                                "JPEG",
                                ["JPEG", "PNG"],
                                [".jpeg", ".png"],
                              );
                            }}
                          >
                            Add File
                          </Button>
                        </>
                      ),
                    }}
                    value={truncateAtDot(fileName)}
                  />
                  <p
                    style={{
                      fontFamily: "inter",
                      fontWeight: 400,
                      fontSize: "12px",
                      color: "#4c4c4c",

                      textAlign: "end",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    *In JPEG/PNG format Only
                  </p>
                </FormControl>

                <FormControl
                  variant="outlined"
                  sx={{ width: { xs: 200, md: 250, lg: 400 } }}
                  size={matches ? "small" : ""}
                >
                  <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    id="language"
                    select
                    variant="outlined"
                    size={matches ? "small" : ""}
                    sx={{
                      backgroundColor: "white",
                      paddingBottom: "0px",
                      "& .MuiSelect-select span::before": {
                        color: "#A0A09E",
                        content: "'Choose Language'",
                      },
                    }}
                    label="Language"
                    value={uploadAlbum?.languageCode}
                    onChange={(e) => handleChangeLanguage(e)}
                  >
                    <MenuItem value="en_US">English</MenuItem>
                    <MenuItem value="hi_IN">Hindi</MenuItem>
                    <MenuItem value="pa_IN">Punjabi</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Stack>

            {loading ? (
              <LoaderLayout />
            ) : (
              <div sx={{ float: "right" }}>
                <Button
                  variant="contained"
                  type="submit"
                  size="small"
                  sx={{
                    backgroundColor: "#9E46E3",
                    textTransform: "none",
                    fontSize: {
                      lg: "14px",
                      xl: "20px",
                    },
                    fontWeight: "500",
                    marginTop: "30px",
                    textAlign: "end",
                    float: "right",
                    marginLeft: "30px",
                  }}
                >
                  {action === "edit" ? "Update" : "Create"}
                </Button>
                <Button
                  variant="outlined"
                  type="submit"
                  size="small"
                  sx={{
                    color: "#9E46E3",
                    border: "1px solid #9E46E3",
                    textTransform: "none",
                    fontSize: {
                      lg: "14px",
                      xl: "20px",
                    },
                    fontWeight: "500",
                    marginTop: "30px",
                    textAlign: "end",
                    float: "right",
                    marginLeft: "30px",
                  }}
                  onClick={() => props.HandleCloseAbum(false)}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { createRespone, updateResponse, dialogMessage } = state.albumReducer;

  return {
    createRespone,
    updateResponse,
    dialogMessage,
  };
};

export default connect(mapStateToProps, {
  updateAlbum,
  createAlbum,
  showUnshowDialogMessage,
})(withMixpanelTracking(AddAlbum, "Add Album"));
