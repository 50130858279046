import { useState } from "react";
import MenuLayout from "../commonLayout/MenuLayout";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import androidData from "../versionData/andriodVersionData.json";
import iosData from "../versionData/iOSVersionData.json";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ViewAppVersionHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const [selectedMenu, setSelectedMenu] = useState(data);
  const [page, setPage] = useState(0);
  const size = 10; // Number of rows per page

  const handleMenuButtonClick = (menu) => {
    setSelectedMenu(menu);
    setPage(0); // Reset pagination on menu switch
  };

  const handleBackButtonClick = () => {
    navigate(`/app-management`);
  };

  const handleNext = (dataLength) => {
    if (page < Math.ceil(dataLength / size) - 1) {
      setPage(page + 1);
    }
  };

  const handlePrev = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const renderTable = (data) => {
    const paginatedData = data.slice(page * size, (page + 1) * size);

    return (
      <>
        <Grid container flexWrap="wrap" sx={{ width: "100%" }}>
          <TableContainer component={Paper} elevation={0}>
            <Table sx={{ minWidth: 650 }} aria-label="version history table">
              <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
                <TableRow>
                  <TableCell>Release Date</TableCell>
                  <TableCell>Version Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row.release_date}</TableCell>
                    <TableCell>{row.app_Version}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "40px",
          }}
        >
          <Box
            sx={{
              backgroundColor: "transparent",
              color:
                page === 0
                  ? "rgba(102, 102, 102, .2)"
                  : "rgba(102, 102, 102, 1)",
              cursor: page === 0 ? "default" : "pointer",
              paddingTop: "4px",
            }}
            onClick={handlePrev}
          >
            <ArrowBackIosIcon />
          </Box>

          {[...Array(Math.ceil(data.length / size))].map((_, num) => (
            <Box
              key={num}
              sx={{
                padding: "8px",
                fontWeight: page === num ? 700 : 400,
                fontSize: "16px",
                cursor: "pointer",
                margin: "auto",
                color:
                  page === num
                    ? "rgba(158, 70, 227, 1)"
                    : "rgba(102, 102, 102, 1)",
              }}
              onClick={() => setPage(num)}
            >
              {num + 1}
            </Box>
          ))}

          <Box
            sx={{
              backgroundColor: "transparent",
              paddingTop: "4px",
              color:
                page === Math.ceil(data.length / size) - 1
                  ? "rgba(102, 102, 102, .2)"
                  : "rgba(102, 102, 102, 1)",
              cursor:
                page === Math.ceil(data.length / size) - 1
                  ? "default"
                  : "pointer",
            }}
            onClick={() => handleNext(data.length)}
          >
            <ArrowForwardIosIcon />
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <Grid xs={5} md={5} lg={7} sx={{ display: "flex", alignItems: "center" }}>
        <KeyboardBackspaceIcon
          sx={{
            fontSize: "30px",
            fontWeight: 600,
            color: "black",
            cursor: "pointer",
            marginRight: 2,
          }}
          onClick={handleBackButtonClick}
        />
        <Box className={"page_title"}
          sx={{
            textWrap: "nowrap",
            paddingY: {
              lg: "10px",
              xl: "20px",
            },
          }}
        >
          App Version History
        </Box>
      </Grid>

      <Stack spacing={2} className="toggle_button" direction="row">
        <List>
          <ListItem disablePadding sx={{ justifyContent: "center" }}>
            <ListItemButton
              sx={{
                borderRadius: "12px",
                height: "45px",
                width: "120px",
              }}
              selected={selectedMenu === "Android"}
              onClick={() => handleMenuButtonClick("Android")}
            >
              <ListItemText
                primary="Android"
                sx={{
                  color: selectedMenu === "Android" ? "#9E46E3" : "black",
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>

        <List>
          <ListItem disablePadding sx={{ justifyContent: "center" }}>
            <ListItemButton
              sx={{
                borderRadius: "12px",
                height: "45px",
                width: "120px",
              }}
              selected={selectedMenu === "iOS"}
              onClick={() => handleMenuButtonClick("iOS")}
            >
              <ListItemText
                primary="iOS"
                sx={{
                  color: selectedMenu === "iOS" ? "#9E46E3" : "black",
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Stack>

      <Grid container flexWrap="wrap" sx={{ width: "100%" }}>
        {selectedMenu === "Android"
          ? renderTable(androidData)
          : renderTable(iosData)}
      </Grid>
    </>
  );
};

const wrapper = () => {
  return (
    <MenuLayout
      childComponent={() => {
        return <ViewAppVersionHistory />;
      }}
    />
  );
};

export default wrapper;
