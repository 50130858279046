export const GET_HELP_CENTER_STATUS = "GET_HELP_CENTER_STATUS";
export const GET_HELP_CENTER_STATUS_SUCCESS = "GET_HELP_CENTER_STATUS_SUCCESS";
export const GET_HELP_CENTER_STATUS_FAILURE = "GET_HELP_CENTER_STATUS_FAILURE";

export const GET_DELETE_REQUEST_STATUS = "GET_DELETE_REQUEST_STATUS";
export const GET_DELETE_REQUEST_STATUS_SUCCESS =
  "GET_DELETE_REQUEST_STATUS_SUCCESS";
export const GET_DELETE_REQUEST_STATUS_FAILURE =
  "GET_DELETE_REQUEST_STATUS_FAILURE";
export const GET_VOLUNTEER_STATUS = "GET_VOLUNTEER_STATUS";
export const GET_VOLUNTEER_STATUS_SUCCESS = "GET_VOLUNTEER_STATUS_SUCCESS";
export const GET_VOLUNTEER_STATUS_FAILURE = "GET_VOLUNTEER_STATUS_FAILURE";

export const getHelpCenterPane = () => {
  return {
    type: GET_HELP_CENTER_STATUS,
  };
};
export const getHelpCenterPaneSuccess = (data) => {
  return {
    type: GET_HELP_CENTER_STATUS_SUCCESS,
    payload: data,
  };
};
export const getHelpCenterPaneFail = (error) => {
  return {
    type: GET_HELP_CENTER_STATUS_FAILURE,
    payload: error,
  };
};
export const getDeleteRequestPane = () => {
  return {
    type: GET_DELETE_REQUEST_STATUS,
  };
};
export const getDeleteRequestPaneSuccess = (data) => {
  return {
    type: GET_DELETE_REQUEST_STATUS_SUCCESS,
    payload: data,
  };
};
export const getDeleteRequestPaneFail = (error) => {
  return {
    type: GET_DELETE_REQUEST_STATUS_FAILURE,
    payload: error,
  };
};
export const getVolunteerStatustPane = () => {
  return {
    type: GET_VOLUNTEER_STATUS,
  };
};
export const getVolunteerStatustPaneSuccess = (data) => {
  return {
    type: GET_VOLUNTEER_STATUS_SUCCESS,
    payload: data,
  };
};

export const getVolunteerStatustPaneFail = (error) => {
  return {
    type: GET_VOLUNTEER_STATUS_FAILURE,
    payload: error,
  };
};
