import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  Typography,
  useTheme,
} from "@mui/material";

import useCustomStyles from "../customHooks/useCustomStyles";
import { validateEmail } from "../helpers/InputValidation";
import LoginLayout from "../commonLayout/LoginLayout";
import { forgetPassword } from '../redux/login/action';
import { connect } from "react-redux";

import withMixpanelTracking from "./commonComponent/withMixPanelTracking";
import DialogBoxLayout from "../commonLayout/DialogBoxLayout";

const styles = (theme) => ({
  login_title_text: {
    textAlign: "left",
    color: "#000000",
    fontSize: "36px",
    marginBottom: "55px",
  },

  forgot_password_container: {
    padding: "5px",
    width: "500px",
  },

  forgot_password_message: {
    marginBottom: "25px",
    marginLeft: "12px",
    color: "#C7332A",
  },
});

const ForgotPassword = (props) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const [adminEmail, setAdminEmail] = useState("");
  const [dialogToggle, setDialogToggle] = useState(false);
  const [errors, setErrors] = useState({
    adminEmail: ""
  });

  const handleDialogBox = () => {
    setDialogToggle(!dialogToggle);
  }

  const handleSubmit = () => {
    let isValid = true;
    let newErrors = {};

    if(!adminEmail) {
      newErrors.adminEmail = "Email is required";
      isValid = false;
    } else if(!validateEmail(adminEmail)) {
      newErrors.adminEmail = "Invalid email";
      isValid = false;
    }

    setErrors(newErrors);

    if(isValid) {
      const formData = new FormData();
      formData.append("email", adminEmail);

      props.forgetPassword(formData);
    }
  }

  useEffect(() => {
    if(props?.forgetPasswordStatus) {
      handleDialogBox();
    }
  }, [props?.forgetPasswordStatus])
  

  return (
    <>
      { dialogToggle && 
          <DialogBoxLayout 
              dialogBoxToggle={ dialogToggle }
              setDialogBoxToggle={ setDialogToggle } 
              header = "We have sent reset link to the mail, kindly check your inbox"
          /> 
      }

      <Grid item xs={9} className={classes?.forgot_password_container}>
        <p className={classes?.login_title_text}>Forgot Password</p>

        <Box>
          <div>
            <div>
              <Typography variant="h6" component="h6" sx={{ mb: 2 }}>
                Enter Email We Will Send You The Password Reset Link
              </Typography>

              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 400, lg: 480 } }}
              >
                <TextField
                  fullWidth
                  required
                  type="email"
                  id="outlined-required"
                  inputProps={{
                    style: {
                      boxSizing: "content-box",
                      height: "27px",
                      fontSize: "24px",
                      color: "#7F7F7F",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                  label="E-mail Id"
                  value={adminEmail}
                  onChange={(event) => {

                    setAdminEmail(event.target.value);
                  }}
                />
                {errors.adminEmail && (
                  <Typography color="error">
                      {errors.adminEmail}
                  </Typography>
                )}
              </FormControl>

              <br />
            </div>
            <div className={classes?.forgot_password_message}>
              {/* <span>Incorrect Password</span> */}
              <br />
            </div>
            <div>
              <Button
                sx={{
                  py: 1.5,
                  width: { xs: 200, md: 400, lg: 480 },
                  backgroundColor: "#9E46E3",
                  textTransform: "none",
                }}
                variant="contained"
                type="submit"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </Box>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { success, data, error, forgetPasswordStatus } = state.loginReducer;

  return {
    error,
    forgetPasswordStatus,
    successResponse: success,
    loggedInUserData: data,
  };
};

const ForgotPasswordComponent = connect(mapStateToProps, {
  forgetPassword,
})(withMixpanelTracking(ForgotPassword, "Forgot Password Screen"));

const wrapper = () => {
  return (
    <LoginLayout
      childComponent={() => {
        return <ForgotPasswordComponent />;
      }}
    />
  );
};

export default wrapper;
