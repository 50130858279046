import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
  Box,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import TableTooltip from "../commonComponent/tableTooltip";
import DeleteDialogLayout from "../../commonLayout/DeleteDialogLayout";
import Paginate from "../commonComponent/Pagination";
import { formatDateInAlphanumericFormat } from "../../helpers/dateHelper";
import {
  deleteCommunity,
  getCommPost,
  deleteToggleModal,
} from "../../redux/community/action";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import { useNavigate } from "react-router-dom";
import useCustomStyles from "../../customHooks/useCustomStyles";

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth: "thin",
    [theme.breakpoints.down(1400)]: {
      maxHeight: 240,
    },
    [theme.breakpoints.between(1401, 1919)]: {
      maxHeight: 390,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 500,
    },
  },

  icon_size: {
    [theme.breakpoints.down(1400)]: {
      height: "25px",
      width: "25px",
    },
    [theme.breakpoints.between(1401, 1919)]: {
      height: "30px",
      width: "30px",
    },
  },
});

const ViewCommunityPost = (props) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const navigate = useNavigate();
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [paginationObject, setPaginationObject] = useState({
    page: 1,
    rowsPerPage: 10,
    totalRowCount: 0,
  });

  const [comparetableRecords, setCompareTableRecords] = useState([]);

  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const tableContainerRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setPaginationObject({ ...paginationObject, page: newPage });

    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  };

  const setCommunityDataWithPagination = (successValue) => {
    if (successValue && props?.communtyPostdata) {
      const { content, totalPages } = props?.communtyPostdata;

      setCompareTableRecords(content);
      setPaginationObject({
        ...paginationObject,
        totalRowCount: totalPages,
      });
    }
  };

  useEffect(() => {
    if (props.searchTerm) {
      props.getCommPost(1, props.searchTerm);
    } else {
      props.getCommPost(paginationObject?.page, "");
    }
  }, [props.searchTerm, paginationObject?.page]);

  useEffect(() => {
    setCommunityDataWithPagination(props?.getCommPostSuccess);
  }, [props.getCommPostSuccess, props?.communtyPostdata]);

  useEffect(() => {
    if (props.deleteCommunitySuccess) {
      props.getCommPost(paginationObject?.page, "");
      setRowData([]);
    }
  }, [props.deleteCommunitySuccess]);

  const handleDeleteData = (value) => {
    setDeleteToggle(!deleteToggle);
    setRowData(value);
  };

  const deleteRowData = (value) => {
    props.deleteCommunity(value.id);
    props.trackButtonClick("Delete Community Post Button", 1);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    let sortedList = [];
    if (comparetableRecords && comparetableRecords.length > 0) {
      sortedList = [...comparetableRecords].sort((a, b) => {
        if (order) {
          return (
            (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1) *
            (order === "asc" ? 1 : -1)
          );
        }
        return 0;
      });
    }
    setCompareTableRecords(sortedList);
  };

  const width = 300;
  const style = {
    maxWidth: width,
    borderStyle: "border-box",
    paddingLeft: "10px !important",
  };

  const handleRowEdit = (val) => {
    const jsonData = JSON.stringify(val);

    const encodedData = encodeURIComponent(jsonData);
    navigate(`/content/community-post/edit?data=${encodedData}`);
  };

  const closeSuccessDeleteModal = () => {
    props.deleteToggleModal();
  };

  return (
    <>
      {
        <DeleteDialogLayout
          deleteToggle={deleteToggle}
          setDeleteToggle={setDeleteToggle}
          recordType="Post"
          recordRowData={rowData}
          deleteRowData={deleteRowData}
          openSuccessDelete={props.deleteCommunitySuccess}
          closeSuccessDeleteModal={closeSuccessDeleteModal}
        />
      }

      <>
        <Grid container spacing={2}>
          {/* Grid item for table */}
          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              elevation={0}
              className={classes?.table}
              ref={tableContainerRef}
            >
              <Table
                stickyHeader
                sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
                  <TableRow>
                    <TableCell sx={{ paddingLeft: "10px !important" }}>
                      <TableSortLabel
                        active={orderBy === "name"}
                        direction={order}
                        onClick={() => handleRequestSort("name")}
                      >
                        Title
                      </TableSortLabel>
                    </TableCell>

                    <TableCell>Language</TableCell>
                    <TableCell>For the Date</TableCell>
                    <TableCell>Uploaded By</TableCell>
                    <TableCell>Uploaded On</TableCell>
                    <TableCell align="right" sx={{ pr: 2.5 }}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {comparetableRecords?.length > 0 &&
                    comparetableRecords.map((communityrow) => (
                      <TableRow
                        key={communityrow.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" sx={style}>
                          <TableTooltip title={communityrow?.name} />
                        </TableCell>
                        <TableCell>
                          {communityrow?.languageCode === "en_US"
                            ? "English"
                            : communityrow?.languageCode === "hi_IN"
                              ? "Hindi"
                              : "Punjabi"}
                        </TableCell>
                        <TableCell>
                          {formatDateInAlphanumericFormat(communityrow.date)}
                        </TableCell>
                        <TableCell>Admin</TableCell>
                        <TableCell>
                          {formatDateInAlphanumericFormat(
                            communityrow.updatedAt,
                          )}
                        </TableCell>
                        <TableCell align="right" sx={{ p: 0 }}>
                          <img
                            onClick={() => {
                              props.setContentDataToBeUpdated(communityrow);
                              handleRowEdit(communityrow);
                              props.trackButtonClick(
                                "Edit Community Post Button",
                                1,
                              );
                            }}
                            className={classes?.icon_size}
                            src="/edit-icon.svg"
                            alt="edit-icon"
                            style={{ cursor: "pointer" }}
                          />

                          <img
                            onClick={() => {
                              handleDeleteData(communityrow);
                            }}
                            className={classes?.icon_size}
                            style={{ marginLeft: "0.8rem", cursor: "pointer" }}
                            src="/delete-icon.svg"
                            alt="delete-icon"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {comparetableRecords?.length === 0 && (
              <Box
                sx={{
                  display: "grid",
                  justifyContent: "center",
                  textAlign: "center",
                  width: "100%",
                  marginTop: "10%",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <img
                    src="/mailbox.png"
                    alt="No data"
                    style={{ width: "30px", height: "30px" }}
                  />
                </Box>
                <Box sx={{}}>No data</Box>
              </Box>
            )}
            {comparetableRecords === undefined ||
              (comparetableRecords?.length > 0 && (
                <Grid
                  container
                  sx={{ marginTop: "25px", justifyContent: "center" }}
                >
                  <Paginate
                    paginationObject={paginationObject}
                    handlePageChange={handlePageChange}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </>
    </>
  );
};

const mapStateToProps = (state) => {
  const { communtyPostdata, getCommPostSuccess, deleteCommunitySuccess } =
    state.communityReducer;
  return {
    communtyPostdata,
    getCommPostSuccess,
    deleteCommunitySuccess,
  };
};

export default connect(mapStateToProps, {
  deleteCommunity,
  getCommPost,
  deleteToggleModal,
})(withMixpanelTracking(ViewCommunityPost, "Community Post"));
