export const CREATE_USER = "CREATE_USER";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAILURE = "USER_FAILURE";

export const DIALOG_MESSAGE = "DIALOG_MESSAGE";

export const GET_SUBADMIN_USERS = "GET_SUBADMIN_USERS";
export const GET_SUBADMIN_USERS_SUCCESS = "GET_SUBADMIN_USERS_SUCCESS";
export const GET_SUBADMIN_USERS_FAILURE = "GET_SUBADMIN_USERS_FAILURE";

export const GET_USER_COUNT = "GET_USER_COUNT";
export const GET_USER_COUNT_SUCCESS = "GET_USER_COUNT_SUCCESS";
export const GET_USER_COUNT_FAILURE = "GET_USER_COUNT_FAILURE";

export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";
export const UPDATE_USER_STATUS_FAILURE = "UPDATE_USER_STATUS_FAILURE";

export const DELETE_TOGGLE_MODAL_STATUS = "DELETE_TOGGLE_MODAL_STATUS";

export const toggleModal = () => {
  return {
    type: DELETE_TOGGLE_MODAL_STATUS,
  };
};

export const createUser = (data) => {
  return {
    type: CREATE_USER,
    payload: data,
  };
};

export const userSuccess = (data) => {
  return {
    type: USER_SUCCESS,
    payload: data,
  };
};

export const userFailure = (error) => {
  return {
    type: USER_FAILURE,
    payload: error,
  };
};

export const showUnshowDialogMessage = () => {
  return {
    type: DIALOG_MESSAGE,
  };
};

export const getSubAdminUsers = (page) => {
  return {
    type: GET_SUBADMIN_USERS,
    page,
  };
};

export const getSubAdminUsersSuccess = (data, page) => {
  return {
    type: GET_SUBADMIN_USERS_SUCCESS,
    payload: data,
    page,
  };
};

export const getSubAdminUsersFailure = (error) => {
  return {
    type: GET_SUBADMIN_USERS_FAILURE,
    payload: error,
  };
};

export const getUserCount = () => {
  return {
    type: GET_USER_COUNT,
  };
};

export const getUserCountSuccess = (data) => {
  return {
    type: GET_USER_COUNT_SUCCESS,
    payload: data,
  };
};

export const getUserCountFailure = (error) => {
  return {
    type: GET_USER_COUNT_FAILURE,
    payload: error,
  };
};

export const getUserProfile = () => {
  return {
    type: GET_USER_PROFILE,
  };
};

export const getUserProfileSuccess = (data) => {
  return {
    type: GET_USER_PROFILE_SUCCESS,
    payload: data,
  };
};

export const getUserProfileFailure = (error) => {
  return {
    type: GET_USER_PROFILE_FAILURE,
    payload: error,
  };
};

export const updateUser = (payload, id) => {
  return {
    type: UPDATE_USER,
    payload,
    id,
  };
};

export const updateUserSuccess = (data) => {
  return {
    type: UPDATE_USER_SUCCESS,
  };
};

export const updateUserFailure = (error) => {
  return {
    type: UPDATE_USER_FAILURE,
    payload: error,
  };
};

export const updateUserStatus = (id, status) => {
  return {
    type: UPDATE_USER_STATUS,
    id,
    status,
  };
};

export const updateUserStatusSuccess = (data) => {
  return {
    type: UPDATE_USER_STATUS_SUCCESS,
    payload: data,
  };
};

export const updateUserStatusFailure = (error) => {
  return {
    type: UPDATE_USER_STATUS_FAILURE,
    payload: error,
  };
};

export const deleteUser = (id) => {
  return {
    type: DELETE_USER,
    payload: id,
  };
};

export const deleteUserSuccess = () => {
  return {
    type: DELETE_USER_SUCCESS,
  };
};

export const deleteUserFails = (error) => {
  return {
    type: DELETE_USER_FAILURE,
    payload: error,
  };
};
