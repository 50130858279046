import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  Stack,
  FormControl,
  TextField,
  Button,
  useTheme,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useCustomStyles from "../../customHooks/useCustomStyles";
import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import DialogBoxFileUploadLayout from "../../commonLayout/DialogBoxFileUploadLayout";
import ProgressDialogLayout from "../../commonLayout/ProgressDialogLayout";
import LoaderLayout from "../../commonLayout/LoaderLayout";
import { fetchLanguage } from "../../redux/language/action";
import {
  createKirtanWithSinger,
  updateKirtanWithSinger,
  createKirtanWithAlbum,
  updateKirtanWithAlbum,
  getKirtanWithSinger,
  getKirtanWithAlbum,
  showUnshowDialogMessage,
} from "../../redux/kirtan/action";

import { getAllSinger } from "../../redux/singer/action";
import { getAllAlbum } from "../../redux/album/action";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddAlbum from "./AddAlbumNew";
import AddSinger from "./AddSingerNew";
import { truncateAtDot } from "../../helpers/truncateFileName";

const styles = (theme) => ({
  container: {
    marginTop: "45px",
    backgroundColor: "#F9F3FB",
    borderRadius: "8px",
    padding: "0 10px 300px",
    height: "800px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  componentTitle: {
    fontSize: "24px",
    paddingLeft: "15px",
    fontWeight: "400",
    color: "#333333",
  },

  input_box: {
    backgroundColor: "#ffffff",
    color: "#A0A09E",
  },
});

const UploadKirtan = ({ closeEditModal, ...props }) => {
  const theme = useTheme();
  const { action } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);

  const editRecords = JSON.parse(decodedData);
  const matches = useMediaQuery("(max-width:1440px)");
  const navigate = useNavigate();
  const classes = useCustomStyles(styles, theme);

  const [uploadKirtan, setUploadKirtan] = useState({
    songName: "",
    songImage: "",
    audioFile: "",
    songDuration: "",
    singerId: null,
    albumId: null,
    singerName: "",
    albumName: "",
  });

  // state variable used to keep a track of the different behaviour of drag and drop and normal file upload
  const [toBeUploadedFileFormat, setToBeUploadedFileFormat] = useState({
    fileType: "",
    acceptedFileFormatForDragDrop: [],
    acceptedFileFormatForNormalFileUpload: [],
  });

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [uploadFileName, setUploadFileName] = useState("");
  const [uploadInterval, setUploadInterval] = useState(null);
  const [fileName, setFileName] = useState("");
  const [audioFileName, setAudioFileName] = useState("");

  const [uploadType, setUploadType] = useState("");

  const resetInputFieldsOnApiSuccess = useCallback((successValue) => {
    if (successValue) {
      setLoading(props?.loading);
      setUploadKirtan((prevUploadKirtan) => ({
        ...prevUploadKirtan,
        songName: "",
        songImage: "",
        audioFile: "",
        songDuration: "",
        singerId: null,
        albumId: null,
        singerName: "",
        albumName: "",
      }));
      setFileName("");
      setAudioFileName("");
    }
  }, []);

  useEffect(() => {
    if (props?.dialogMessage) {
      resetInputFieldsOnApiSuccess(true);
    }
  }, [props.dialogMessage]);

  useEffect(() => {
    if (action === "edit") {
      let records = editRecords;

      setUploadKirtan((prevUploadKirtan) => ({
        ...prevUploadKirtan,

        songName: records.songName,

        songDuration: records.songDuration,
        singerId: records.singerId ? records.singerId : null,
        albumId: records.albumId ? records.albumId : null,
        singerName: records.singerName ? records.singerName : null,
        albumName: records.albumName ? records.albumName : null,
      }));
      setFileName("song image.jpg");
      setAudioFileName("audioSong.mp3");
    }
  }, []);

  const handleOpen = (fileType, dragDropFileFormat, normalUploadFileFormat) => {
    setToBeUploadedFileFormat({
      ...toBeUploadedFileFormat,
      fileType: fileType,
      acceptedFileFormatForDragDrop: dragDropFileFormat,
      acceptedFileFormatForNormalFileUpload: normalUploadFileFormat,
    });
    setUploadType(fileType);
    setDialogOpen(true);
  };

  const handleProgressDialogClose = () => {
    setProgressDialogOpen(false);
    setUploadInProgress(false);
    clearInterval(uploadInterval);
    setProgress(0);
    if (uploadType === "JPEG") {
      setFileName("");
    } else if (uploadType === "MP3") {
      setAudioFileName("");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target ? event.target.files[0] : event;

    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      setUploadFileName(file.name);
      setUploadKirtan((prevUploadKirtan) => ({
        ...prevUploadKirtan,
        songImage: file,
      }));
      setFileName(file.name);
      setDialogOpen(false);
      handleProgressDialogOpen();
      uploadFile(file);
    } else if (file && file.type === "audio/mpeg") {
      setUploadFileName(file.name);
      setUploadKirtan((prevUploadKirtan) => ({
        ...prevUploadKirtan,
        audioFile: file,
      }));
      setAudioFileName(file.name);
      setDialogOpen(false);
      handleProgressDialogOpen();
      uploadFile(file);
    } else {
      alert("Please upload a valid file.");
    }
  };

  const handleProgressDialogOpen = () => {
    setProgressDialogOpen(true);
    setUploadInProgress(true);
  };

  const uploadFile = (file) => {
    setProgress(0);

    const UploadProgress = () => {
      let progress = 0;

      const interval = setInterval(() => {
        progress += 10;
        setProgress(progress);

        if (progress >= 110) {
          clearInterval(interval);
          setUploadInProgress(false);
          setProgressDialogOpen(false);
        }
      }, 300);

      setUploadInterval(interval);
    };
    UploadProgress();
  };

  const [errors, setErrors] = useState({});

  const handleKirtanUpload = (event) => {
    event.preventDefault();

    const errors = {};
    if (!uploadKirtan.songName) {
      errors.songName = "Song Name is required.";
    }
    if (!uploadKirtan.songDuration) {
      errors.songDuration = "Song Duration is required.";
    }
    if (!fileName) {
      errors.songImage = "Song Image is required.";
    }
    if (!audioFileName) {
      errors.audioFile = "Audio File is required.";
    }
    if (!uploadKirtan.albumId && !uploadKirtan.singerId) {
      errors.albumOrSinger =
        "At least one of Album or Singer must be selected.";
    }

    if (Object.keys(errors).length > 0) {
      // Handle error display logic here, e.g., set state to show errors
      setErrors(errors);
      return;
    }
    const formData = new FormData();

    formData.append("songName", uploadKirtan?.songName);
    formData.append("songDuration", uploadKirtan?.songDuration);

    if (action === "edit" && !uploadKirtan.audioFile) {
      formData.append("audioFile", editRecords.songPresignedUrl);
    } else {
      formData.append("audioFile", uploadKirtan?.audioFile);
    }
    if (action === "edit" && !uploadKirtan.songImage) {
      formData.append("songImage", editRecords.thumbnailImageUrl);
    } else {
      formData.append("songImage", uploadKirtan?.songImage);
    }

    if (
      uploadKirtan?.albumId !== null &&
      action !== "edit" &&
      uploadKirtan?.albumId
    ) {
      formData.append("albumId", uploadKirtan?.albumId);
      props.createKirtanWithAlbum(formData);
      props.trackButtonClick("Create Kirtan Button", 1, editRecords?.songName);
    }
    if (
      uploadKirtan?.singerId !== null &&
      action !== "edit" &&
      uploadKirtan?.singerId
    ) {
      formData.append("singerId", uploadKirtan?.singerId);
      props.createKirtanWithSinger(formData);
      props.trackButtonClick("Create Kirtan Button", 1, editRecords?.songName);
    }
    if (action === "edit" && uploadKirtan.albumId != null) {
      formData.append("albumId", uploadKirtan?.albumId);

      props.updateKirtanWithAlbum(formData, editRecords.songId);
      props.trackButtonClick("Update Kirtan Button", 1, editRecords?.songName);
    }
    if (action === "edit" && uploadKirtan.singerId != null) {
      formData.append("singerId", uploadKirtan?.singerId);

      props.updateKirtanWithSinger(formData, editRecords.songId);
      props.trackButtonClick("Update Kirtan Button", 1, editRecords?.songName);
    }
  };

  useEffect(() => {
    props.getAllAlbum(1);
    props.getAllSinger(1);
  }, []);

  const [openSingerDialoge, setOpenSingerDialoge] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const handleCreateSong = (val) => {
    setOpenSingerDialoge(true);
    setDialogType(val);
  };
  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();

    if (action === "edit") {
      navigate("/content/kirtan/view");
    }
  };

  return (
    <>
      {props.dialogMessage ? (
        <DialogBoxLayout
          dialogBoxToggle={props.dialogMessage}
          setDialogBoxToggle={handleCloseSuccessBox}
          header={
            action === "edit"
              ? "Updated Successfully!"
              : "Uploaded Successfully!"
          }
          content={
            action === "edit"
              ? "You Have Edited Kirtan Successfully"
              : "You Have Uploaded Kirtan Successfully"
          }
        />
      ) : null}

      <div className="kirtan__upload-container">
        {dialogOpen && (
          <DialogBoxFileUploadLayout
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            uploadInProgress={uploadInProgress}
            progress={progress}
            handleFileChange={handleFileChange}
            toBeUploadedFileFormat={toBeUploadedFileFormat}
          />
        )}

        {progressDialogOpen && (
          <ProgressDialogLayout
            progressDialogOpen={progressDialogOpen}
            handleProgressDialogClose={handleProgressDialogClose}
            uploadFileName={truncateAtDot(uploadFileName)}
            uploadInProgress={uploadInProgress}
            progress={progress}
          />
        )}

        <Dialog
          open={openSingerDialoge}
          onClose={() => setOpenSingerDialoge(false)}
          maxWidth="sm"
        >
          <div style={{ backgroundColor: "#F5F5F5" }}>
            <DialogTitle>{`Add ${dialogType}`}</DialogTitle>
            <IconButton
              onClick={() => setOpenSingerDialoge(false)}
              sx={{
                position: "absolute",
                right: "2%",
                top: "4%",
                width: "3rem",
                height: "3rem",
              }}
            >
              <CloseIcon />
            </IconButton>

            <DialogContent>
              <Box>
                {dialogType === "Album" ? (
                  <AddAlbum HandleCloseAbum={setOpenSingerDialoge}></AddAlbum>
                ) : (
                  <AddSinger HandleCloseAbum={setOpenSingerDialoge} />
                )}
              </Box>
            </DialogContent>
          </div>
        </Dialog>

        <Grid
          xs={12}
          sx={{
            width: {
              xl: "60%",
            },
            margin: {
              xl: "auto",
            },
            marginTop: {
              xl: "80px",
            },
            paddingBottom: {
              xl: "60px",
            },
          }}
          component="form"
          onSubmit={handleKirtanUpload}
        >
          <div>
            <Stack
              spacing={{ lg: 1, xl: 2 }}
              direction="column"
              gap={{ lg: 1, xl: 3 }}
            >
              {action === "Add" ? (
                <Grid
                  container
                  spacing={{ lg: 8, xl: 20 }}
                  sx={{
                    borderBottom: "3px solid white",
                    paddingBottom: "20px",
                  }}
                >
                  <Grid xs={6}>
                    <FormControl
                      sx={{ width: { xs: 200, md: 250, lg: 400 } }}
                      size={matches ? "small" : ""}
                    >
                      {" "}
                      <Autocomplete
                        required
                        disabled={uploadKirtan?.singerId ? true : false}
                        sx={{ mt: 1 }}
                        options={props?.AlbumData}
                        size={matches ? "small" : ""}
                        getOptionLabel={(option) => option?.albumName}
                        className={classes?.input_box}
                        onChange={(event, newValue) => {
                          setErrors(null);
                          setUploadKirtan({
                            ...uploadKirtan,
                            albumId: newValue?.id ? newValue?.id : null,
                            albumName: newValue?.albumName
                              ? newValue?.albumName
                              : "",
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Choose Album Name"
                            error={errors?.albumOrSinger}
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            label="Album Name"
                            variant="outlined"
                          />
                        )}
                      />
                      {errors?.albumOrSinger && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors?.albumOrSinger}
                        </span>
                      )}
                      <p
                        style={{
                          fontFamily: "inter",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "#9E46E3",
                          cursor: "pointer",
                          textAlign: "end",
                          margin: "4px 0px 0px 0px",
                        }}
                        onClick={() => handleCreateSong("Album")}
                      >
                        Create album
                      </p>
                    </FormControl>
                  </Grid>
                  <Grid xs={6}>
                    {" "}
                    <FormControl
                      sx={{ width: { xs: 200, md: 250, lg: 400 } }}
                      size={matches ? "small" : ""}
                    >
                      <Autocomplete
                        required
                        error={errors?.albumOrSinger}
                        disabled={uploadKirtan?.albumId ? true : false}
                        sx={{ mt: 1 }}
                        options={props?.SingerData}
                        size={matches ? "small" : ""}
                        getOptionLabel={(option) => option?.singerName}
                        className={classes?.input_box}
                        onChange={(event, newValue) => {
                          setErrors(null);
                          setUploadKirtan({
                            ...uploadKirtan,
                            singerId: newValue?.id ? newValue?.id : null,
                            singerName: newValue?.singerName
                              ? newValue?.singerName
                              : "",
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Choose Singer Name"
                            error={errors?.albumOrSinger}
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            label="Singer Name"
                            variant="outlined"
                          />
                        )}
                      />
                      {errors?.albumOrSinger && (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors?.albumOrSinger}
                        </span>
                      )}
                      <p
                        style={{
                          fontFamily: "inter",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "#9E46E3",
                          cursor: "pointer",
                          textAlign: "end",
                          margin: "4px 0px 0px 0px",
                        }}
                        onClick={() => handleCreateSong("Singer")}
                      >
                        Create Singer
                      </p>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}

              <Grid container spacing={{ lg: 8, xl: 20 }}>
                <Grid xs={6}>
                  {" "}
                  <FormControl
                    variant="outlined"
                    sx={{ width: { xs: 200, md: 250, lg: 400 }, mb: 2 }}
                  >
                    <TextField
                      required
                      error={errors?.songImage}
                      className={classes?.input_box}
                      id="outlined-basic"
                      placeholder="Add File"
                      label="Song Image"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      size={matches ? "small" : ""}
                      InputProps={{
                        readOnly: true,
                        shrink: true,
                        endAdornment: (
                          <>
                            <Button
                              aria-label="upload"
                              component="label"
                              className="addFileButton"
                              size={matches ? "small" : ""}
                              onClick={() => {
                                setErrors(null);
                                handleOpen(
                                  "JPEG",
                                  ["JPEG", "PNG"],
                                  [".jpeg", ".png"],
                                );
                              }}
                            >
                              Add File
                            </Button>
                          </>
                        ),
                      }}
                      value={truncateAtDot(fileName)}
                    />
                    {errors?.songImage && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors?.songImage}
                      </span>
                    )}
                    <p
                      style={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "#4c4c4c",
                        textAlign: "end",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      *In JPEG/PNG format Only
                    </p>
                  </FormControl>
                </Grid>
                <Grid xs={6}>
                  <FormControl
                    variant="outlined"
                    sx={{
                      width: { xs: 200, md: 250, lg: 400 },
                    }}
                  >
                    <TextField
                      required
                      error={errors?.audioFile}
                      className={classes?.input_box}
                      id="outlined-basic"
                      size={matches ? "small" : ""}
                      placeholder="Add File"
                      label="Audio File"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <>
                            <Button
                              aria-label="upload"
                              component="label"
                              className="addFileButton"
                              size={matches ? "small" : ""}
                              onClick={() => {
                                setErrors(null);
                                handleOpen("MP3", ["MP3"], [".mp3"]);
                              }}
                            >
                              Add File
                            </Button>
                          </>
                        ),
                      }}
                      value={audioFileName ? truncateAtDot(audioFileName) : ""}
                    />
                    {errors?.audioFile && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors?.audioFile}
                      </span>
                    )}
                    <p
                      style={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "#4c4c4c",

                        textAlign: "end",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      *In MP3/MP4 format Only
                    </p>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={{ lg: 8, xl: 20 }}>
                <Grid xs={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ width: { xs: 200, md: 250, lg: 400 } }}
                  >
                    <TextField
                      required
                      error={errors?.songName}
                      className={classes?.input_box}
                      size={matches ? "small" : ""}
                      id="outlined-basic"
                      placeholder="Enter Song Name"
                      label="Song Name"
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={uploadKirtan?.songName}
                      onChange={(event) => {
                        setErrors(null);
                        setUploadKirtan({
                          ...uploadKirtan,
                          songName: event.target.value,
                        });
                      }}
                    />
                    {errors?.songName && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors?.songName}
                      </span>
                    )}
                  </FormControl>
                </Grid>
                <Grid xs={6}>
                  {" "}
                  <FormControl
                    variant="outlined"
                    sx={{ width: { xs: 200, md: 250, lg: 400 } }}
                  >
                    <TextField
                      required
                      error={errors?.songDuration}
                      className={classes?.input_box}
                      size={matches ? "small" : ""}
                      id="outlined-basic"
                      placeholder="Enter song duration"
                      label="Song Duration"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={uploadKirtan?.songDuration}
                      onChange={(event) => {
                        setErrors(null);
                        setUploadKirtan({
                          ...uploadKirtan,
                          songDuration: event.target.value,
                        });
                      }}
                    />
                    {errors?.songDuration && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors?.songDuration}
                      </span>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Stack>

            {props?.loading ? (
              <LoaderLayout />
            ) : (
              <div sx={{ float: "right" }}>
                <Button
                  variant="contained"
                  startIcon={<FileUploadOutlined />}
                  size="small"
                  sx={{
                    backgroundColor: "#9E46E3",
                    textTransform: "none",
                    fontSize: {
                      lg: "14px",
                      xl: "20px",
                    },
                    fontWeight: "500",
                    marginTop: "30px",
                    textAlign: "end",
                    float: "right",
                    marginLeft: "30px",
                  }}
                  onClick={handleKirtanUpload}
                >
                  {action === "edit" ? "Update" : "Upload"}
                </Button>
                {/* {action === "Add" ? (
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      color: "#9E46E3",
                      border: "1px solid #9E46E3",
                      fontSize: {
                        lg: "14px",
                        xl: "20px",
                      },
                      fontWeight: "500",
                      marginTop: "30px",
                      textAlign: "end",
                      float: "right",
                      position: "relative",
                    }}
                    onClick={handleAddMore}
                  >
                    Add more
                    <Box
                      sx={{
                        position: "absolute",
                        top: "44px",
                        fontSize: {
                          lg: "10px",
                          xl: "12px",
                        },
                        color: "black",
                      }}
                    >
                      *Add upto 10 Kirtans
                    </Box>
                  </Button>
                ) : null} */}
              </div>
            )}
          </div>
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    createResponse,
    updateResponse,
    loading,
    singerSuccessResponse,
    albumSuccessResponse,
    dialogMessage,
  } = state.kirtanReducer;

  const { SingerData } = state.singerReducer;
  const { AlbumData } = state.albumReducer;

  return {
    createResponse,
    updateResponse,
    loading,
    SingerData: SingerData?.content,
    AlbumData: AlbumData?.content,
    singerSuccessResponse,
    albumSuccessResponse,
    dialogMessage,
  };
};

export default connect(mapStateToProps, {
  fetchLanguage,
  createKirtanWithSinger,
  updateKirtanWithSinger,
  createKirtanWithAlbum,
  updateKirtanWithAlbum,
  getKirtanWithSinger,
  getKirtanWithAlbum,
  showUnshowDialogMessage,
  getAllAlbum,
  getAllSinger,
})(withMixpanelTracking(UploadKirtan, "Add Kirtan"));
