import {
  Box,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useCallback, useState, useRef } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import useCustomStyles from "../../customHooks/useCustomStyles";
import { connect } from "react-redux";
import {
  getRequestdetails,
  getRequestByStatus,
  updateRequestByStatus,
} from "../../redux/delete-request/action";
import "../../styles/delete.css";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import { showSnackbar } from "../../redux/snackbar/action";
import TableTooltip from "../commonComponent/tableTooltip";

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth: "thin",
    [theme.breakpoints.down(1400)]: {
      maxHeight: 230,
    },
    [theme.breakpoints.between(1401, 1919)]: {
      maxHeight: 350,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 400,
    },
  },
  page_title: {
    fontSize: "32px",
    fontWeight: "600",
    color: "#000000",
    lineHeight: "0",
  },
  page_sub_title: {
    marginTop: "22px",
    fontSize: "18px",
    fontWeight: "400",
    color: "#4F566C",
  },
  commentsField: {
    height: "2rem",
  },
  dropdownAction: {
    borderRadius: "1rem",
  },
  button: {
    textTransform: "none",
    fontSize: "20px",
    fontWeight: "500",
    borderRadius: "0.65rem",
  },
  second_button: {
    color: "#ffffff",
    backgroundColor: "#9E46E3",
  },
});

const CustomSelect = styled(Select)(({ value }) => ({
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: getStatusColor(value),
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: getStatusColor(value),
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: getStatusColor(value),
  },
}));

const getStatusColor = (status) => {
  switch (status) {
    case "APPROVED":
      return "blue";
    case "REJECTED":
      return "orange";
    default:
      return "inherit";
  }
};

const New = (props) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const tableContainerRef = useRef(null);

  const handleChange = (_, value) => {
    setPage(value);

    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  };

  const [comment, setComment] = useState("");
  const [currentRow, setCurrentRow] = useState(null);
  const [editableFields, setEditableFields] = useState({ comment: "" });
  const [tableList, setTableList] = useState([]);
  const [status, setStatus] = useState(null);
  const [toggleUpdate, setToggleUpdate] = useState(false);

  const commentsRef = useRef([]);
  const handleCommentChange = useCallback((event) => {
    setComment(event.target.value);
  }, []);

  const handleCommentSubmit = useCallback(
    (issue) => {
      try {
        if (status && comment) {
          const formData = new FormData();
          formData.append("id", currentRow.id);
          formData.append("deleteStatus", status);
          formData.append("adminComments", comment);
          props.updateRequestByStatus(formData);
          props.trackButtonClick("update Delete Request Button", 1);
          setComment("");
          setEditableFields({ ...editableFields, ["comment"]: "" });
          setToggleUpdate(!toggleUpdate);
        }
      } catch (err) {
        alert(err);
      }
    },
    [comment, currentRow],
  );
  useEffect(() => {
    const formData = new FormData();
    formData.append("searchTerm", props.searchTerm);
    formData.append("status", "APPLIED");
    if (props.searchTerm) {
      formData.append("page", 0);
    } else {
      formData.append("page", page - 1);
    }
    formData.append("size", 10);

    const queryParams = new URLSearchParams(formData).toString();

    props.getRequestByStatus(`?${queryParams}`);
  }, [props.updateByStatusSuccess, page, props.searchTerm]);

  useEffect(() => {
    if (props.getByStatusSuccess || props.updateByStatusSuccess) {
      if (props?.DeleteRByStatusData) {
        setTableList(props.DeleteRByStatusData);

        setCount(props.page);
      }
    }
  }, [props.getByStatusSuccess, props.updateByStatusSuccess, page]);
  const handleStatusChange = useCallback((event, row, index) => {
    if (event.target.value) {
      setCurrentRow({ ...row, status: event.target.value });
      alert("Please enter the comment");
      setComment(row.comment);
      setStatus(event.target.value.toUpperCase());
      setEditableFields({
        ...editableFields,
        ["comment"]: index,
      });
    }
  }, []);

  return (
    <div>
      <TableContainer
        component={Paper}
        elevation={0}
        className={classes?.table}
        ref={tableContainerRef}
      >
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="first table">
          <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
            <TableRow className="view_delete_table_row">
              <TableCell className="view_delete_cell">ID</TableCell>
              <TableCell className="view_delete_cell">Name</TableCell>
              <TableCell className="view_delete_cell">Gender</TableCell>
              <TableCell className="view_delete_cell">Email</TableCell>
              <TableCell className="view_delete_cell">Phone no.</TableCell>
              <TableCell className="view_delete_cell">Reason</TableCell>
              <TableCell className="view_delete_cell">ACTION</TableCell>
              <TableCell className="view_delete_cell">Comment</TableCell>
            </TableRow>
          </TableHead>
          {tableList.length > 0 ? (
            <TableBody>
              {tableList?.length > 0 &&
                tableList.map((row, index) => (
                  <TableRow
                    key={index}
                    className="view_delete_table_row"
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell className="view_delete_cell">{row.id}</TableCell>
                    <TableCell className="view_delete_cell">
                      {row.fullName}
                    </TableCell>

                    <TableCell className="view_delete_cell">
                      {row.gender}
                    </TableCell>
                    <TableCell className="view_delete_cell">
                      {row.email}
                    </TableCell>
                    <TableCell className="view_delete_cell">
                      {row.phoneNumber}
                    </TableCell>
                    <TableCell className="view_delete_cell">
                      <TableTooltip title={row.reasons} />
                    </TableCell>
                    <TableCell className="view_delete_cell">
                      <CustomSelect
                        required
                        className={classes?.dropdownAction}
                        sx={{ height: { lg: "1.5rem", xl: "2rem" } }}
                        InputLabelProps={{ shrink: true }}
                        labelId="action-select"
                        id="action-select"
                        value={
                          currentRow?.id === row.id
                            ? status
                            : row.status
                              ? row.status
                              : "Action"
                        }
                        onChange={(e) => handleStatusChange(e, row, index)}
                        renderValue={(value) => (
                          <Typography
                            sx={{
                              color: getStatusColor(value),
                              fontSize: { lg: "14px", xl: "18px" },
                            }}
                          >
                            {value}
                          </Typography>
                        )}
                      >
                        <MenuItem value="APPROVED" sx={{ color: "blue" }}>
                          APPROVED
                        </MenuItem>
                        <MenuItem value="REJECTED" sx={{ color: "orange" }}>
                          REJECTED
                        </MenuItem>
                      </CustomSelect>
                    </TableCell>
                    <TableCell className="view_delete_cell">
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        startIcon={<EditOutlinedIcon />}
                        value={
                          editableFields.comment === index
                            ? comment
                            : row?.comment
                              ? row.comment
                              : " "
                        }
                        ref={(el) => (commentsRef.current[index] = el)}
                        onChange={handleCommentChange}
                        required
                        id="comment"
                        name="comment"
                        onBlur={() => handleCommentSubmit(row)}
                        fullWidth
                        variant="outlined"
                        disabled={
                          editableFields.comment === index ? false : true
                        }
                        InputProps={{
                          endAdornment: (
                            <IconButton aria-label="upload" component="label">
                              <EditOutlinedIcon
                                sx={{ color: "#000000", fontSize: "16px" }}
                                onClick={() => {
                                  if (status == null) {
                                    props.showSnackbar({
                                      message: "Choose action first",
                                      severity: "info",
                                    });
                                  }
                                }}
                              />
                            </IconButton>
                          ),
                          style: {
                            borderRadius: "20px",
                            paddingLeft: "0.5rem",
                            height: "1.5rem",
                          },
                          notchedOutline: {
                            borderColor:
                              editableFields.comment === index
                                ? "#9E46E3"
                                : "#C9C9C9", // Border color based on state
                          },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow></TableRow>
            </TableBody>
          ) : null}
        </Table>
        {tableList?.length === 0 && (
          <Box
            sx={{
              display: "grid",
              justifyContent: "center",
              textAlign: "center",
              marginTop: "30px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <img
                src="/mailbox.png"
                alt="No data"
                style={{ width: "30px", height: "30px" }}
              />
            </Box>
            <Box sx={{}}>No data</Box>
          </Box>
        )}
      </TableContainer>

      {count && tableList?.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            marginTop: "16px",
          }}
        >
          <Pagination
            count={count}
            page={page}
            size="large"
            onChange={handleChange}
          />
        </Box>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    getByStatusSuccess,
    page,
    updateByStatusSuccess,
    DeleteRByStatusData,
  } = state.deleteRequestReducer;
  return {
    getByStatusSuccess,

    updateByStatusSuccess,
    DeleteRByStatusData,
    page,
  };
};
export default connect(mapStateToProps, {
  getRequestdetails,
  getRequestByStatus,
  updateRequestByStatus,
  showSnackbar,
})(withMixpanelTracking(New, "Delete Request"));
