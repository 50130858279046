const userAccessToken = localStorage.getItem("token");

export const config = {
  headers: { Authorization: `Bearer ${userAccessToken}` },
};

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const dashoardUrl = BASE_URL + "/thought/date-range?endDate=2024-08-05";

export const loginAuthUrl = BASE_URL + "/auth/login";

export const forgetPasswordAuthUrl = BASE_URL + "/auth/forgot-password";

export const createPasswordAuthUrl = BASE_URL + "/auth/reset-password";

export const updateAuthUrl = BASE_URL + "/auth/refreshToken";

export const updatePolicyUrl = BASE_URL + "/auth/user/termpolicyViewed";

export const fetchLanguageUrl = BASE_URL + "/language";

// hukumnama apis
export const getAllHukumnamaURL = BASE_URL + "/thought/date-range?endDate=";

export const addHukumnamaUrl = BASE_URL + "/thought/save";

export const getAllHukumnamaWithPaginationUrl =
  BASE_URL + "/thought/getAll/searchHukumnamas";

export const updateHukumnamaUrl = BASE_URL + "/thought/update/";

export const deleteHukumnamaUrl = BASE_URL + "/thought/deleteById";

// meditation apis
export const addMeditationUrl = BASE_URL + "/self/reflections/upload";

export const getMedUrl = BASE_URL + "/self/reflections/search";

export const deleteMeditationUrl = BASE_URL + "/self/reflections/delete/";

export const updateMedUrls = BASE_URL + "/self/reflections/update/";

// nitnem apis
export const addNitnemUrl = BASE_URL + "/reading/save";

export const updateNitnemUrl = BASE_URL + "/reading/update";

export const fetchAllNitnemWithPaginationUrl = BASE_URL + "/reading/search";

export const deleteNitnemUrl = BASE_URL + "/reading/delete";

export const fetchAParticularNitnemUrl = BASE_URL + "/reading/admin";

// community post apis
export const communityPostURL = BASE_URL + "/wealth/save";

export const getCommunityPostUrl = BASE_URL + "/wealth/search";

export const deleteCommunityPostUrl = BASE_URL + "/wealth/delete/";

export const updateCommunityPostUrl = BASE_URL + "/wealth/update";

// event apis
export const createEventsUrl = BASE_URL + "/doc/file/uploads";

export const saveEventsUrl = BASE_URL + "/event/save";

export const getEventsUrl = BASE_URL + "/event/search";

export const deleteEventsUrl = BASE_URL + "/event/delete/";

// granth apis
export const addGranthUrl = BASE_URL + "/doc/book/save";

export const deleteGranthUrl = BASE_URL + "/doc/book/";

export const getGranthUrls = BASE_URL + "/doc/book/admin/search";

export const upDateGranthUrls = BASE_URL + "/doc/book/update/";

// help center apis
export const getHelpsUrl = BASE_URL + "/user/profiles/user-help/all";

export const updateHelpsUrl = BASE_URL + "/user/profiles/user-help/update/";

// user management api's
export const addUserUrl = BASE_URL + "/auth/register/sub-admin";

export const updateUserUrl = BASE_URL + "/auth/user/update/sub-admin";

export const deleteUserUrl = BASE_URL + "/auth/user/sub-admin/";

// profile apis
export const addProfileUrl = BASE_URL + "/user/profiles/update";

//Quiz apis
export const addQuizUrls = BASE_URL + "/quiz/upload";

export const getQuizUrls = BASE_URL + "/quiz/showUploaded";

export const updateQuizUrls = BASE_URL + "/quiz/update?";

export const downloadQuizUrls = BASE_URL + "/quiz/downloadXlsx?";

//Volunteer

export const getVolunteerListUrls =
  BASE_URL + "/event/admin/get-event-volunteers/";

export const getVolunteeByStatusUrls = BASE_URL + "/event/event/getByStatus/";

export const updateVolunteerStatusUrls = BASE_URL + "/event/volunteer/";

//Delete-Request

export const getDeleteRequestUrls =
  BASE_URL + "/user/profiles/deleted/accounts";

export const getDeleteRequestByStatusUrls =
  BASE_URL + "/user/profiles/search/deleted/accounts";

export const updateDeleteRequestUrls =
  BASE_URL + "/user/profiles/deleteRequest/status";

//Album-api

export const getAllAlubumUrls = BASE_URL + "/songs/albums/all/albums";

export const createAllAlubumUrls = BASE_URL + "/songs/albums/upload";

export const updateAlubumUrls = BASE_URL + "/songs/albums/update/";

export const deleteAlubumUrls = BASE_URL + "/songs/albums/";

//Singer-api

export const getAllSingerUrls = BASE_URL + "/songs/all/singers";

export const createAllSingerUrls = BASE_URL + "/songs/singer/upload";

export const updateSingerUrls = BASE_URL + "/songs/singer/update/";

export const deleteSingerUrls = BASE_URL + "/songs/singer/";

//Kirtan
export const uploadKirtanWithSongUrl =
  BASE_URL + "/songs/upload/singerWithSong";

export const uploadKirtanWithAlbumUrl =
  BASE_URL + "/songs/albums/upload/albumWithSong";

export const updateKirtanWithSongUrl = BASE_URL + "/songs/update/singer/";

export const updateKirtanWithAlbumUrl =
  BASE_URL + "/songs/albums/update/album/";

export const getAllKirtanWithAlbum = BASE_URL + "/songs/albums/getAlbums";

export const getAllKirtanWithSinger = BASE_URL + "/songs/singers";

export const deleteSongWitlAlbumUrls = BASE_URL + "/songs/singers";

export const deleteSongWitlSingerUrls = BASE_URL + "/songs/delete/";

//pane-api

export const getVolunteerStatusUrls =
  BASE_URL + "/event/volunteers/status/count?status=APPLIED";

export const getdeleteRequestStatusUrls =
  BASE_URL + "/user/profiles/deleteRequests/count?status=APPLIED";

export const getHelpCenterStatusUrls =
  BASE_URL + "/user/profiles/user-help/count?status=NEW";
