import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "../../styles/SearchInput.css";

const SearchInput = ({ tableData, setSearchedTable, setSearchTerm }) => {
  const [searched, setSearched] = useState("");

  useEffect(() => {
    requestSearch();
  }, [searched]);
  useEffect(() => {
    setSearched("");
  }, [tableData]);
  useEffect(() => {
    const normalizedSearchKey = String(searched);

    const handler = setTimeout(() => {
      if (typeof setSearchTerm === "function")
        setSearchTerm(normalizedSearchKey);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searched]);

  const requestSearch = () => {
    if (tableData && tableData?.length > 0) {
      const filteredData = tableData?.filter((row) => {
        if (row.name && row.locationName) {
          return Object.values(row).some((value) =>
            value?.toString().toLowerCase().includes(searched.toLowerCase()),
          );
        } else if (row.songName && (row.albumName || row.singerName)) {
          return Object.values(row).some((value) =>
            value?.toString().toLowerCase().includes(searched.toLowerCase()),
          );
        } else if (row.name) {
          return row?.name
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase());
        } else if (row.songName) {
          return row?.songName
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase());
        } else if (row.title) {
          return row?.title
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase());
        } else {
          return Object.values(row).some((value) =>
            value?.toString().toLowerCase().includes(searched.toLowerCase()),
          );
        }
      });

      setSearchedTable(filteredData);
    }
  };

  return (
    <div className="search__input--container">
      <input
        className="search__input"
        value={searched}
        placeholder="Search"
        onChange={(e) => {
          setSearched(e.target.value);
        }}
      />
      <SearchIcon className="search__input--icon" color="#666666" />
    </div>
  );
};

export default SearchInput;
