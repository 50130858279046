import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TableSortLabel,
} from "@mui/material";

import Paginate from "../commonComponent/Pagination";
import DeleteDialogLayout from "../../commonLayout/DeleteDialogLayout";
import { formatDateSeperatedWithHyphen } from "../../helpers/dateHelper";
import "../../styles/kirtan.css";
import AddSinger from "./AddSinger";

import { connect } from "react-redux";

import {
  getAllSinger,
  deleteSinger,
  deleteToggleModal,
} from "../../redux/singer/action";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import { useTheme } from "@emotion/react";
import useCustomStyles from "../../customHooks/useCustomStyles";
const LanguageList = [
  { langName: "HINDI", LangCode: "hi_IN" },
  { langName: "ENGLISH", LangCode: "en_US" },
  { langName: "PUNJABI", LangCode: "pa_IN" },
];

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth: "thin",
    [theme.breakpoints.down(1400)]: {
      maxHeight: 210,
    },
    [theme.breakpoints.between(1401, 1919)]: {
      maxHeight: 320,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 440,
    },
  },

  icon_size: {
    [theme.breakpoints.down(1400)]: {
      height: "25px",
      width: "25px",
    },
    [theme.breakpoints.between(1401, 1919)]: {
      height: "30px",
      width: "30px",
    },
  },
});

const ViewSinger = ({
  openModal,
  setOpenModal,
  singerList,
  setRecordsList,
  setSingerList,
  getAllSinger,
  setContentDataToBeUpdated,
  ...props
}) => {
  const theme = useTheme();

  const navigate = useNavigate();
  const classes = useCustomStyles(styles, theme);
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [redordsData, setRecors] = useState([]);
  const [viewType, setViewtype] = useState();
  const [opeeditModal, setopenEditModal] = useState(false);
  const [paginationObject, setPaginationObject] = useState({
    page: 1,
    rowsPerPage: 10,
    totalRowCount: 0,
  });

  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const tableContainerRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setPaginationObject({ ...paginationObject, page: newPage });
    getAllSinger(newPage);

    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  };

  const handleDeleteData = (value) => {
    setDeleteToggle(!deleteToggle);
    setRowData(value);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  let sortedSingers = [];
  if (singerList && singerList.length > 0) {
    sortedSingers = [...singerList].sort((a, b) => {
      if (order) {
        return (
          (a.singerName.toUpperCase() < b.singerName.toUpperCase() ? -1 : 1) *
          (order === "asc" ? 1 : -1)
        );
      }
      return 0;
    });
  }

  const handleCloseEditBox = () => {
    setViewtype("");
    setopenEditModal(false);
  };

  const deleteRowData = (value) => {
    props.deleteSinger(value.id);
  };

  const setSingerDataAndPagination = (loadingValue) => {
    if (!loadingValue && props?.SingerData) {
      const { content, page } = props?.SingerData;

      setSingerList(content);
      setRecordsList(content);

      setPaginationObject({
        ...paginationObject,
        totalRowCount: page?.totalPages,
      });
    }
  };

  useEffect(() => {
    setSingerDataAndPagination(props?.loading);
  }, [props.loading]);

  useEffect(() => {
    getAllSinger(paginationObject?.page);
    setRowData([]);
    if (props.deleteResponse) {
      setOpenSucceessDelete(true);
    }
  }, [props.deleteResponse, props.updateResponse]);

  const [openSuccessDelete, setOpenSucceessDelete] = useState(false);

  const closeSuccessDeleteModal = () => {
    props.deleteToggleModal();
    setOpenSucceessDelete(false);
  };

  const handleRowEdit = (val) => {
    const jsonData = JSON.stringify(val);

    const encodedData = encodeURIComponent(jsonData);
    navigate(`/content/kirtan/edit?data=${encodedData}`);
  };
  return (
    <>
      <DeleteDialogLayout
        deleteToggle={deleteToggle}
        recordType="Singer"
        setDeleteToggle={setDeleteToggle}
        recordRowData={rowData}
        deleteRowData={deleteRowData}
        openSuccessDelete={openSuccessDelete}
        closeSuccessDeleteModal={closeSuccessDeleteModal}
      />

      {opeeditModal || openModal ? (
        <AddSinger
          closeEditModal={openModal ? setOpenModal : handleCloseEditBox}
          recordsData={redordsData}
          viewType={viewType ? "Edit" : "Add"}
        />
      ) : (
        <>
          <TableContainer
            component={Paper}
            elevation={0}
            className={classes?.table}
            ref={tableContainerRef}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
                <TableRow>
                  <TableCell
                    sx={{
                      cursor: "pointer",
                      paddingLeft: "10px !important",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === "singerName"}
                      direction={order}
                      onClick={() => handleRequestSort("singerName")}
                    >
                      Singer Name
                    </TableSortLabel>
                  </TableCell>

                  <TableCell>Language</TableCell>
                  <TableCell>Uploaded By</TableCell>
                  <TableCell>Uploaded On</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedSingers?.length > 0 &&
                  sortedSingers?.map((row) => (
                    <TableRow
                      key={row.title}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.singerName}
                      </TableCell>

                      <TableCell>
                        {
                          LanguageList.filter(
                            (item) => item.LangCode === row?.languageCode,
                          )[0]?.langName
                        }
                      </TableCell>
                      <TableCell>Admin</TableCell>
                      <TableCell>
                        {" "}
                        {formatDateSeperatedWithHyphen(row.updatedAt)}
                      </TableCell>
                      <TableCell align="right" sx={{ p: 0 }}>
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            setContentDataToBeUpdated(row);
                            handleRowEdit(row);
                            props.trackButtonClick("Edit Singer Button", 1);
                          }}
                        >
                          <img
                            src="/edit-icon.svg"
                            alt="delete-icon"
                            className={classes?.icon_size}
                          />
                        </IconButton>

                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteData(row)}
                        >
                          <img
                            src="/delete-icon.svg"
                            alt="delete-icon"
                            className={classes?.icon_size}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {sortedSingers?.length === 0 && (
            <Box
              sx={{
                display: "grid",
                justifyContent: "center",
                textAlign: "center",
                width: "100%",
                marginTop: "10%",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <img
                  src="/mailbox.png"
                  alt="No data"
                  style={{ width: "30px", height: "30px" }}
                />
              </Box>
              <Box sx={{}}>No data</Box>
            </Box>
          )}
          {sortedSingers?.length > 0 && (
            <Grid
              container
              sx={{ marginTop: "25px", justifyContent: "center" }}
            >
              <Paginate
                paginationObject={paginationObject}
                handlePageChange={handlePageChange}
              />
            </Grid>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { SingerData, createRespone, updateResponse, deleteResponse, loading } =
    state.singerReducer;
  return {
    SingerData,
    createRespone,
    updateResponse,
    deleteResponse,
    loading,
  };
};

export default connect(mapStateToProps, {
  getAllSinger,
  deleteSinger,
  deleteToggleModal,
})(withMixpanelTracking(ViewSinger, "Singer"));
