import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Slider from "react-slick";

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const PreviewCommunityPost = ({
  name,
  date,
  description,
  images,
  previewUrls,
  onClose,
  handlePost,
  EditRecordsData,
}) => {
  const [showScheduleBox, setShowScheduleBox] = useState(false);
  const [postData, setPostData] = useState({
    date: "",
    time: "",
  });
  const handleScheduleOpen = () => {
    setShowScheduleBox(true);
  };

  const handleUpdate = () => {
    setShowScheduleBox(false);
  };

  const handleScheduleClose = () => {
    setShowScheduleBox(false);
  };

  const handleChange = (e) => {
    setPostData({
      ...postData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setShowScheduleBox(false);
    onClose();
  };

  return (
    <Dialog open onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Typography variant="h6" sx={{ flex: 1, ml: 2, mt: 2 }}>
              Title: {name}
            </Typography>
            <Typography variant="h6" sx={{ mr: 2, mt: 2 }}>
              Date: {date}
            </Typography>
          </Box>

          <Box sx={{ mb: 2, ml: 2 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Description:
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={2}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={description}
              InputProps={{
                readOnly: true,
              }}
              sx={{
                backgroundColor: "white",
                overflowY: "auto",
                scrollbarWidth: "thin",
                maxHeight: "150px",
              }}
            />
          </Box>

          <Box sx={{ flex: 1, mb: 2 }}>
            {images.length > 1 ? (
              <Slider {...sliderSettings}>
                {previewUrls.map((url, index) => (
                  <Box key={index} display="flex" justifyContent="center">
                    <img
                      src={url}
                      alt={`Preview ${index}`}
                      style={{ maxWidth: "100%" }}
                    />
                  </Box>
                ))}
              </Slider>
            ) : (
              <Box display="flex" justifyContent="center">
                <img
                  src={previewUrls[0]}
                  alt="Preview"
                  style={{ maxWidth: "100%" }}
                />
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: 2,
            }}
          >
            {/* <IconButton
              onClick={handleScheduleOpen}
              aria-label="schedule"
              sx={{ marginRight: 2 }}
          >
              <AccessTimeIcon sx={{ color: '#9E46E3' }} />
          </IconButton> */}
            <Button
              variant="contained"
              sx={{ backgroundColor: "#9E46E3" }}
              size="large"
              onClick={handlePost}
            >
              Post
            </Button>
          </Box>

          {/* Schedule Post Box */}
          {/* {showScheduleBox && (
            <Box padding={"10%"}
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        backgroundColor: 'rgba(158, 70, 227, 0.35)',
                        padding: 4,
                        borderRadius: 2,
                        boxShadow: 3,
                        width: '400px'
                    }}
                >

                    <Typography variant="h6" sx={{ color: "white"}}>Schedule Post</Typography>
                    <TextField
                        fullWidth
                        type="date"
                        label="Date"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        sx={{ my: 2, backgroundColor: "white", color: "black" }}
                        value={postData.date}
                        onChange={handleChange}
                        required
                        id="scheduleDate"
                        name="date"
                    />
                    <TextField
                        fullWidth
                        type="time"
                        label="Time"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        sx={{ my: 2, backgroundColor: "white", color: "black" }}
                        value={postData.time}
                        onChange={handleChange}
                        required
                        id="scheduleTime"
                        name="time"
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button 
                            onClick={handleScheduleClose} 
                            variant="contained" 
                            sx={{ 
                                backgroundColor: "#F9F3FB", 
                                color: "black" 
                            }}
                        >
                            Back
                        </Button>
                        <Button 
                            onClick={handleUpdate} 
                            variant="contained" 
                            sx={{ 
                                backgroundColor: "black" 
                            }}
                        >
                            Update
                        </Button>
                    </Box>
                </Box>
            </Box>
        )} */}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewCommunityPost;
