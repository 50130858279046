import {
  CREATE_GURUDWARA,
  CREATE_GURUDWARA_FAILURE,
  CREATE_GURUDWARA_SUCCESS,
  DELETE_GURUDWARA,
  DELETE_GURUDWARA_FAILURE,
  DELETE_GURUDWARA_SUCCESS,
  GET_GURUDWARA,
  GET_GURUDWARA_FAILURE,
  GET_GURUDWARA_SUCCESS,
  UPDATE_GURUDWARA,
  UPDATE_GURUDWARA_FAILURE,
  UPDATE_GURUDWARA_SUCCESS,
  DIALOG_MESSAGE,
  UPDATE_GURUDWARA_STATUS_SUCCESS,
  UPDATE_GURUDWARA_STATUS,
  UPDATE_GURUDWARA_STATUS_FAILURE,
  DELETE_TOGGLE_MODAL_STATUS,
} from "./action";

const initialState = {
  loading: false,
  error: "",
  data: [],
  createRespone: false,
  updateResponse: false,
  deleteResponse: false,
  dialogMessage: false,
  page: [],
};

export const gurudwaraReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_GURUDWARA:
      return {
        ...state,
        loading: true,
        dialogMessage: false,
      };
    case CREATE_GURUDWARA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        dialogMessage: true,
        createResponse: true,
      };
    case CREATE_GURUDWARA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        createResponse: false,
        dialogMessage: false,
      };
    case GET_GURUDWARA:
      return {
        ...state,
        loading: true,
        dialogMessage: false,
        createRespone: false,
        updateResponse: false,
        data: [],
        page: [],
      };
    case GET_GURUDWARA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        page: action.page,
        error: "",
        loading: false,
      };
    case GET_GURUDWARA_FAILURE:
      return {
        ...state,
        error: action.payload,
        data: [],
        page: [],
        loading: false,
      };
    case UPDATE_GURUDWARA:
      return {
        ...state,
        loading: true,
        updateResponse: false,
        dialogMessage: false,
      };
    case UPDATE_GURUDWARA_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        dialogMessage: true,
        updateResponse: true,
      };
    case UPDATE_GURUDWARA_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        dialogMessage: false,
        updateResponse: false,
      };
    case UPDATE_GURUDWARA_STATUS:
      return {
        ...state,
        loading: true,
        dialogMessage: false,
      };
    case UPDATE_GURUDWARA_STATUS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        dialogMessage: true,
      };
    case UPDATE_GURUDWARA_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        dialogMessage: false,
        updateResponse: false,
      };
    case DELETE_GURUDWARA:
      return {
        ...state,
        loading: true,
      };
    case DELETE_GURUDWARA_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        deleteResponse: true,
      };
    case DELETE_GURUDWARA_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        deleteResponse: false,
      };
    case DIALOG_MESSAGE:
      return {
        ...state,
        dialogMessage: false,
      };
    case DELETE_TOGGLE_MODAL_STATUS:
      return {
        ...state,

        deleteResponse: false,
      };
    default:
      return state;
  }
};
