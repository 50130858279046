import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { connect } from "react-redux";
import {
  createGurudwara,
  showUnshowDialogMessage,
  updateGurudwara,
} from "../../redux/gurudwara/action";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { FileUploadOutlined } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { showSnackbar } from "../../redux/snackbar/action";
import {
  getGeoLocation,
  closeGeoLocation,
} from "../../redux/geoLocation/action";
import MapComponent from "./mapComponent";

const FieldName = [
  {
    key: "name",
    keyName: "Gurudwara Name",
    errorMessage: "Gurudwara name is required",
  },
  {
    key: "pinCode",
    keyName: "Pin Code",
    placeholder: "Type Pincode",
    errorMessage: "Pincode is required",
  },
  {
    key: "address",
    keyName: "Address",
    placeholder: "Type Address",
    errorMessage: "Address is required",
  },
];

const AddGurudwara = (props) => {
  const [userData, setUserData] = useState({
    name: "",
    pinCode: "",
    address: "",
    latitude: "",
    longitude: "",
  });

  const matches = useMediaQuery("(max-width:1440px)");

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const editRecords = Object.fromEntries(query.entries());
  const navigate = useNavigate();
  const { action } = useParams();
  const [formErrors, setFormErrors] = useState({});

  const [openMap, setOpenMap] = useState(false);
  const [disableGeoLoc, setDisableGeoLoc] = useState(true);

  useEffect(() => {
    if (action === "edit") {
      const records = editRecords;

      setUserData({
        ...userData,
        ["name"]: records.name,
        ["pinCode"]: records.pinCode,
        ["address"]: records.address,
        ["latitude"]: records.latitude,
        ["longitude"]: records.longitude,
      });
    }
  }, []);

  const handleChange = (e) => {
    setFormErrors({});
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errors = {};

    FieldName.forEach((field) => {
      if (!userData[field.key]) {
        errors[field.key] = `${field.keyName} is required`;
      }
    });
    if (!userData.latitude) errors.latitude = true;
    if (!userData.longitude) errors.longitude = true;
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      if (action === "edit") {
        props.updateGurudwara({
          ...userData,
          locationName: userData.name,
          id: editRecords.id,
          dioceseId: 0,
        });
        props.trackButtonClick("Update Gurudwara Button", 1);
      } else {
        props.createGurudwara({ ...userData, dioceseId: 0 });
        props.trackButtonClick("Create Gurudwara Button", 1);
      }
    }
  };

  const handleGetLocation = () => {
    if (userData?.address && userData?.pinCode) {
      props.getGeoLocation(userData?.address, userData?.pinCode);
    }
  };

  const handleCloseGeoLocation = () => {
    setOpenMap(false);
    props.closeGeoLocation();
  };

  useEffect(() => {
    if (userData?.address && userData?.pinCode) {
      setDisableGeoLoc(false);
    }
  }, [userData?.address, userData?.pinCode]);

  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();

    navigate(`/gurudwara/view`);
  };

  const resetInputFieldsOnApiSuccess = (successValue) => {
    if (successValue) {
      setUserData({
        name: "",
        landmark: "",
        pinCode: "",
        address: "",
      });
    }
  };

  useEffect(() => {
    if (props?.successResponse) {
      resetInputFieldsOnApiSuccess(props?.successResponse);
    }
  }, [props?.successResponse]);

  useEffect(() => {
    if (props?.geoLocationSuccess) {
      let data = props.geoLocationData;

      setUserData({
        ...userData,
        latitude: data.lat,
        longitude: data.lon,
      });
      setOpenMap(true);
    }
  }, [props.geoLocationSuccess]);

  useEffect(() => {
    if (props?.geoLocationSuccess) {
      setOpenMap(true);
    }
  }, [userData?.latitude]);

  return (
    <>
      {props.successResponse && (
        <DialogBoxLayout
          dialogBoxToggle={props?.successResponse}
          setDialogBoxToggle={handleCloseSuccessBox}
          header={
            action === "edit"
              ? "Updated Successfully!"
              : "Uploaded Successfully!"
          }
          content={
            action === "edit"
              ? "You Have Edited Gurudwara Successfully"
              : "You Have Uploaded Gurudwara Successfully"
          }
        />
      )}
      <MapComponent
        open={openMap}
        handleDialogClose={handleCloseGeoLocation}
        geoData={userData}
        setUserData={setUserData}
      />

      <Grid
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <KeyboardBackspaceIcon
          style={{
            fontSize: "30px",
            fontWeight: 600,
            color: "black",
            fontFamily: "inter",
            cursor: "pointer",
            marginRight: 10,
          }}
          onClick={() => {
            navigate(`/gurudwara/view`, { replace: true });
          }}
        />
        <Typography
          style={{
            fontSize: matches ? "1.1rem" : "1.5rem",
            fontWeight: 600,
            color: "black",
            fontFamily: "inter",
          }}
        >
          {action === "edit" ? " Edit Gurudwara" : "Add Gurudwara"}
        </Typography>
      </Grid>
      <Grid
        xs={12}
        sx={{
          backgroundColor: "#F9F3FB",
          borderRadius: 2,
          width: "auto",
          overflowY: "auto",
          scrollbarWidth: "thin",
        }}
      >
        <Box
          component="form"
          onSubmit={handleFormSubmit}
          sx={{ overflow: "auto", height: "80vh", scrollbarWidth: "thin" }}
        >
          <Grid
            container
            spacing={8}
            columnSpacing={8}
            sx={{ padding: matches ? "20px" : "100px", marginTop: "0px" }}
          >
            {FieldName.map((item) => (
              <Grid item xs={12} sm={6} key={item.key}>
                <TextField
                  fullWidth
                  id={item.key}
                  name={item.key}
                  label={`${item.keyName}*`}
                  placeholder={`Enter ${item.keyName}`}
                  size={matches ? "small" : ""}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  sx={{ backgroundColor: "white", borderRadius: "10px" }}
                  value={userData[item.key]}
                  onChange={handleChange}
                  rows={item.keyName === "Address" ? 4 : undefined}
                  multiline={item.keyName === "Address"}
                  error={!!formErrors[item?.key]}
                  type={item.key === "pinCode" ? "number" : ""}
                />
                {formErrors[item.key] && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {item.errorMessage}
                  </span>
                )}
              </Grid>
            ))}
            <Grid item container sm={6} spacing={8} columnSpacing={4}>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <FormControl variant="outlined">
                  <TextField
                    size={matches ? "small" : ""}
                    id="outlined-basic"
                    placeholder="Latitude"
                    label="Latitude"
                    sx={{ backgroundColor: "#ffffff", color: "#A0A09E" }}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={!!formErrors?.latitude}
                    value={userData?.latitude}
                    onChange={(event) => {
                      setFormErrors({});
                      setUserData({
                        ...userData,
                        latitude: event.target.value,
                      });
                    }}
                  />
                  {formErrors?.latitude && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      Latitude is required
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined">
                  <TextField
                    size={matches ? "small" : ""}
                    id="outlined-basic"
                    placeholder="Longitude"
                    label="Longitude"
                    variant="outlined"
                    error={!!formErrors?.longitude}
                    sx={{ backgroundColor: "#ffffff", color: "#A0A09E" }}
                    InputLabelProps={{ shrink: true }}
                    value={userData?.longitude}
                    onChange={(event) => {
                      setFormErrors({});
                      setUserData({
                        ...userData,
                        longitude: event.target.value,
                      });
                    }}
                  />
                  {formErrors?.longitude && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      Longitude is required
                    </span>
                  )}
                </FormControl>

                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "1.5rem",
                    justifyContent: "end",
                    opacity: disableGeoLoc ? 0.5 : 1,
                    pointerEvents: disableGeoLoc ? "none" : "auto",
                    marginTop: "4px",
                  }}
                >
                  {props.geoLoader ? (
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        color: "#6c14b0",
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      Fetching Geo Location
                    </p>
                  ) : (
                    <>
                      <img
                        alt="coordinates"
                        src="/co-ordinates.svg"
                        style={{
                          height: "25px",
                          width: "25px",
                          cursor: disableGeoLoc ? "default" : "pointer",
                          marginRight: "4px",
                        }}
                        onClick={handleGetLocation}
                      />
                      <p
                        style={{
                          padding: 0,
                          margin: 0,
                          color: disableGeoLoc ? "#ccc" : "#6c14b0",
                          fontSize: 14,
                          fontWeight: 500,
                          cursor: disableGeoLoc ? "default" : "pointer",
                        }}
                        onClick={handleGetLocation}
                      >
                        Get Coordinates
                      </p>
                    </>
                  )}
                </span>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="end"
              gap={"20px"}
              sx={{ marginTop: { lg: 0, xl: "100px" } }}
            >
              <Button
                type="submit"
                variant="contained"
                startIcon={
                  action === "Add" ? (
                    <AddCircleOutlineOutlinedIcon />
                  ) : (
                    <FileUploadOutlined />
                  )
                }
                sx={{
                  textTransform: "none",
                  fontSize: { lg: "15px", xl: "20px" },
                  paddingX: { xl: "30px" },
                  fontWeight: "400",
                }}
                size={matches ? "small" : "large"}
              >
                {action === "Add" ? "Create" : "Update"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    data,
    dialogMessage,
    createGurudwara,
    createResponse,
    updateGurudwara,
    updateResponse,
    error,
  } = state.gurudwaraReducer;

  const { geoLocationData, geoLocationSuccess, geoLoader } =
    state.geoLocationReducer;

  return {
    data,
    createGurudwara,
    createResponse,
    updateGurudwara,
    updateResponse,
    error,
    successResponse: dialogMessage,
    geoLocationData,
    geoLocationSuccess,
    geoLoader,
  };
};

export default connect(mapStateToProps, {
  createGurudwara,
  updateGurudwara,
  showUnshowDialogMessage,
  showSnackbar,
  getGeoLocation,
  closeGeoLocation,
})(withMixpanelTracking(AddGurudwara, " Add Gurudwara"));
