import {
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  styled,
  useTheme,
  useMediaQuery,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { truncateAtDot } from "../../helpers/truncateFileName";
import InputAdornment from "@mui/material/InputAdornment";
import { FileUploadOutlined } from "@mui/icons-material";
import { format } from "date-fns";
import React, { useEffect, useState, useRef, useCallback } from "react";
import useCustomStyles from "../../customHooks/useCustomStyles";
import { postEvents, saveEvents, updateEvents } from "../../apiServices/event";
import DialogBoxFileUploadLayout from "../../commonLayout/DialogBoxFileUploadLayout";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import ProgressDialogLayout from "../../commonLayout/ProgressDialogLayout";
import { formatDateInUTC, tomorrowDate } from "../../helpers/dateHelper";
import { getAllGURUDWARAWithoutSearch } from "../../redux/gurudwara/action";
import { connect } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { createEvents, getEvents } from "../../redux/Events/action";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/react-datePicker.css";
import { showSnackbar } from "../../redux/snackbar/action";
import EventIcon from "@mui/icons-material/Event";
import { debounce } from "lodash";

const DatePickerTextField = styled(TextField)({
  backgroundColor: "white",
  "& .MuiInputBase-root": {
    backgroundColor: "white",
  },
  "& .MuiInputBase-input": {
    padding: "10px 14px",
  },
});
const styles = (theme) => ({
  container: {
    backgroundColor: "#F9F3FB",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    scrollbarWidth: "thin",
    [theme.breakpoints.down(1400)]: {
      padding: "10px 10px",
      marginTop: "25px",
      height: "65vh",
      overflowY: "auto",
    },
    [theme.breakpoints.between(1401, 1919)]: {
      padding: "10px 10px",
      marginTop: "25px",
      height: "460px",
      overflowY: "auto",
    },
    [theme.breakpoints.up(1920)]: {
      padding: "0 20px 25px",
      marginTop: "20px",
      height: "700px",
    },
  },

  ContainerMargin: {
    [theme.breakpoints.down(1400)]: {
      marginTop: "4%",
    },
    [theme.breakpoints.between(1401, 1919)]: {
      marginTop: "5%",
    },
    [theme.breakpoints.up(1920)]: {
      marginTop: "10%",
    },
  },

  componentTitle: {
    fontSize: "24px",
    paddingLeft: "15px",
    fontWeight: "400",
    color: "#333333",
  },

  input_box: {
    backgroundColor: "#ffffff",
    color: "#A0A09E",
  },
});

const AddEvent = (props) => {
  const formatDateinUTC = formatDateInUTC(tomorrowDate());
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);
  const editRecords = JSON.parse(decodedData);
  const { action, tabs } = useParams();
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const [gurudwaraData, setGurudwaraData] = useState([]);
  const [errors, setErrors] = useState(null);
  const matches = useMediaQuery("(max-width:1440px)");
  const [eventData, setEventData] = useState({
    name: "",
    thumbnailData: [],
    filesData: [],
    dateTime: formatDateinUTC,
    localTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    description: "",
    locationId: "",
    languageCode: "",
    uploadType: "EVENT",
    locationName: "",
  });

  const [toBeUploadedFileFormat, setToBeUploadedFileFormat] = useState({
    fileType: "",
    acceptedFileFormatForDragDrop: [],
    acceptedFileFormatForNormalFileUpload: [],
  });

  // State to manage file names for thumbnail and image
  const [thumbnailFileName, setThumbnailFileName] = useState("");
  const [imageFileNames, setimageFileNames] = useState([]);
  const [thumbnailError, setThumbnailError] = useState(false);
  const [imageError, setImageError] = useState(false);

  // State to control visibility of schedule box and dialogs
  const [dialogOpen, setDialogOpen] = useState(false);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [dialogBoxToggle, setDialogBoxToggle] = useState(false);

  // State to manage upload progress and status
  const [progress, setProgress] = useState(0);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadFileName, setUploadFileName] = useState("");
  const [uploadInterval, setUploadInterval] = useState(null);

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");

  // State to determine the type of file to be uploaded
  const [uploadType, setUploadType] = useState("");
  const datepickerRef = useRef(); // Create a ref for DatePicker
  const openDatePicker = () => {
    datepickerRef.current.setOpen(true); // Manually open DatePicker
  };

  const handleOpen = (
    type,
    fileType,
    dragDropFileFormat,
    normalUploadFileFormat,
  ) => {
    setToBeUploadedFileFormat({
      ...toBeUploadedFileFormat,
      fileType: fileType,
      acceptedFileFormatForDragDrop: dragDropFileFormat,
      acceptedFileFormatForNormalFileUpload: normalUploadFileFormat,
    });
    setUploadType(type);
    setDialogOpen(true);
  };

  const handleDateChange = (newValue) => {
    const utcDate = newValue ? formatDateInUTC(newValue) : null;
    setEventData({
      ...eventData,
      dateTime: utcDate,
    });
  };

  useEffect(() => {
    if (props?.guruDwaraData?.length > 0) {
      let activeData = props?.guruDwaraData.filter(
        (item) => item.active === true,
      );
      setGurudwaraData(activeData);
    } else {
      setGurudwaraData([]);
    }
  }, [props.guruDwaraData]);

  const handleProgressDialogClose = () => {
    setProgressDialogOpen(false);
    setUploadInProgress(false);
    clearInterval(uploadInterval);
    setProgress(0);
    if (uploadType === "thumbnailData") {
      setThumbnailFileName("");
    } else if (uploadType === "filesData") {
      setimageFileNames([]);
    }
  };

  const handleFileChange = (e) => {
    let files = e.target ? e.target.files : e;

    if (uploadType === "thumbnailData") {
      files = e.target ? e.target.files[0] : e;

      // Ensure only one file is allowed for thumbnailData
      if (files.length > 1) {
        alert("Only one file is allowed for thumbnail.");
        return;
      }

      if (
        files &&
        (files.type === "image/jpeg" || files.type === "image/png")
      ) {
        setEventData({
          ...eventData,
          thumbnailData: [...eventData.thumbnailData, files],
        });

        setThumbnailFileName(files.name);
        setDialogOpen(false);
        setProgress(0);
        setUploadFileName(files.name);
        handleProgressDialogOpen();
        uploadFile(files);
        setThumbnailError(false);
      } else {
        alert("Please upload a valid jpeg/png file for thumbnail.");
      }
    } else if (uploadType === "filesData") {
      // Ensure only up to 3 files are allowed for filesData
      const newFiles = Array.from(files);

      // Check if the total number of files exceeds the limit
      if (newFiles.length > 3) {
        alert("You can only upload up to 3 files.");
        return;
      }

      const validFiles = newFiles.filter(
        (file) => file.type === "image/jpeg" || file.type === "image/png",
      );

      if (validFiles.length > 0) {
        setEventData({
          ...eventData,
          filesData: [...eventData.filesData, ...validFiles],
        });
        setimageFileNames((prevFiles) => [
          ...prevFiles,
          ...validFiles.map((files) => truncateAtDot(files.name)),
        ]);
        setDialogOpen(false);
        setProgress(0);
        setUploadFileName(validFiles.map((file) => file.name).join(", "));
        handleProgressDialogOpen();
        uploadFile(validFiles);
        setImageError(false);
      } else {
        alert("Please upload valid jpeg/png files.");
      }
    }
  };

  const handleChangeLanguage = (val) => {
    setEventData({
      ...eventData,
      languageCode: val.target.value,
    });
  };

  const handleProgressDialogOpen = () => {
    setProgressDialogOpen(true);
    setUploadInProgress(true);
  };

  const uploadFile = (file) => {
    setProgress(0);
    const UploadProgress = () => {
      let progress = 0;
      const interval = setInterval(() => {
        progress += 10;
        setProgress(progress);
        if (progress >= 110) {
          clearInterval(interval);
          setUploadInProgress(false);
          setProgressDialogOpen(false);
        }
      }, 300);
      setUploadInterval(interval);
    };
    UploadProgress();
  };

  const handleChange = (e) => {
    setErrors(null);
    setEventData({
      ...eventData,
      [e.target.name]: e.target.value,
    });
  };

  const createEventData = async (uploadedFiles) => {
    const addEventData = {};

    addEventData["description"] = eventData.description;
    addEventData["locationId"] = eventData.locationId;
    addEventData["localTimeZone"] = eventData.localTimeZone;
    addEventData["languageCode"] = eventData.languageCode;
    addEventData["name"] = eventData.name;
    addEventData["dateTime"] = eventData.dateTime;
    if (eventData.filesData.length > 0) {
      const filteredImages = uploadedFiles.filter(
        (file) => file.fileName !== thumbnailFileName,
      );
      addEventData["filesData"] = filteredImages;
    } else {
      addEventData["filesData"] = editRecords.filesData;
    }
    if (eventData.thumbnailData.length > 0) {
      const filteredThumbnailImage = uploadedFiles.filter(
        (file) => file.fileName === thumbnailFileName,
      );
      addEventData["thumbnailData"] = filteredThumbnailImage[0];
    } else {
      addEventData["thumbnailData"] = editRecords.thumbnailUrl;
    }

    try {
      let final;
      if (action === "edit") {
        final = await updateEvents(addEventData, editRecords?.id);
      } else {
        final = await saveEvents(addEventData);
      }

      if (final.status === 200 || final.status === 201) {
        setDialogBoxToggle(true);
        setEventData({
          ...eventData,
          name: "",
          localTimeZone: "",
          dateTime: formatDateinUTC,
          description: "",
        });
        setThumbnailFileName("");
        setimageFileNames([]);
      } else {
        props.showSnackbar({
          message: final?.response?.data?.message,
          severity: "error",
        });
      }
    } catch (error) {
      props.showSnackbar({
        message: error?.response?.data?.message,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    if (action === "edit") {
      let records = editRecords;

      setEventData({
        ...eventData,
        name: records.name,
        description: records.description,
        localTimeZone: records.localTimeZone,
        dateTime: records.dateTime,
        languageCode: records.languageCode,
        locationName: records.locationName,
        locationId: records.locationId,
      });
      if (records?.filesData && records?.filesData.length > 0) {
        let newArr = [];

        records?.filesData.map((item) =>
          newArr.push(truncateAtDot(item?.fileName)),
        );
        setimageFileNames(newArr);
      }
      setThumbnailFileName(records.thumbnailUrl?.fileName);
    }
  }, [action]);

  useEffect(() => {
    if (props.successRsponse) {
      setEventData({
        ...eventData,
        name: "",
        localTimeZone: "",
        dateTime: formatDateinUTC,
        description: "",
        languageCode: "",
      });
      setThumbnailFileName("");
      setimageFileNames([]);
    }
  }, [props.successRsponse]);

  const validateForm = () => {
    const newErrors = {};
    if (!eventData.name) newErrors.name = "Title  is required.";
    if (thumbnailFileName === "")
      newErrors.thumbnailData = "Thumbnail is required.";
    if (!eventData.languageCode)
      newErrors.languageCode = "Language is required.";

    if (!eventData.locationId) newErrors.locationId = "Gurudwara is required.";
    if (!eventData.dateTime) newErrors.dateTime = "Date & Time is required.";
    if (imageFileNames.length === 0)
      newErrors.filesData = "Please upload a file.";
    if (eventData.description <= 0)
      newErrors.description = "Description is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    setDialogBoxToggle(false);
    const formData = new FormData();

    formData.append("languageCode", eventData.languageCode);
    formData.append("uploadType", eventData.uploadType);
    if (eventData.filesData.length > 0) {
      eventData.filesData.forEach((file) => {
        formData.append("files", file, file.name);
      });
    }
    if (eventData.thumbnailData.length > 0) {
      eventData.thumbnailData.forEach((file) => {
        formData.append("files", file, file.name);
      });
    }

    try {
      let result = {};
      if (
        eventData.filesData.length > 0 ||
        eventData.thumbnailData.length > 0
      ) {
        result = await postEvents(formData);
      }

      if (result.status === 200 || result.status === 201 || action === "edit") {
        createEventData(result?.data);
        setEventData({
          ...eventData,
          name: "",
          localTimeZone: "",
          dateTime: formatDateinUTC,
          description: "",
        });
        setThumbnailFileName("");
        setimageFileNames([]);
        props.trackButtonClick("Create Event Button", 1);
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        alert(error?.response?.data?.message);
      }
    }
  };

  const handleCloseModel = () => {
    navigate(`/content/${tabs}/view`);
  };

  useEffect(() => {
    setLoading(true);
    let pageSize;
    if (searchTerm) {
      pageSize = 1;
    } else {
      pageSize = page;
    }
    let pageUrl = `search=${searchTerm}&page=${pageSize - 1}&size=10`;

    props.getAllGURUDWARAWithoutSearch(pageUrl);
  }, [page, searchTerm]);

  useEffect(() => {
    setLoading(false);
    if (props?.AllGurudwaraWithoutSearch?.length > 0) {
      setOptions((prevOptions) =>
        page === 1
          ? props.AllGurudwaraWithoutSearch
          : [...prevOptions, ...props.AllGurudwaraWithoutSearch],
      );
    }
  }, [props.AllGurudwaraWithoutSearch]);

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const isBottom = scrollHeight - scrollTop - clientHeight < 1;

    if (isBottom && !inputValue) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
    }, 500),
    [],
  );

  const handleInputChange = (event, value) => {
    setInputValue(value);
    debouncedSearch(value);
    setPage(1);
  };
  useEffect(() => {
    let pageUrl = `search=${eventData?.locationName}&page=0&size=10`;

    if (eventData?.locationName) {
      props.getAllGURUDWARAWithoutSearch(pageUrl);
    }
  }, [eventData?.locationName]);

  return (
    <>
      {dialogBoxToggle && (
        <DialogBoxLayout
          dialogBoxToggle={dialogBoxToggle}
          setDialogBoxToggle={setDialogBoxToggle}
          header={
            action === "edit"
              ? "Updated Successfully!"
              : "Uploaded Successfully!"
          }
          content={
            action === "edit"
              ? "You Have Edited Your File Successfully"
              : "You Have Uploaded Your File Successfully"
          }
          handleCloseModel={handleCloseModel}
        />
      )}

      {dialogOpen && (
        <DialogBoxFileUploadLayout
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          uploadInProgress={uploadInProgress}
          progress={progress}
          handleFileChange={handleFileChange}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
          uploadType={uploadType}
        />
      )}

      {progressDialogOpen && (
        <ProgressDialogLayout
          progressDialogOpen={progressDialogOpen}
          handleProgressDialogClose={handleProgressDialogClose}
          uploadFileName={uploadFileName}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
          uploadInProgress={uploadInProgress}
          progress={progress}
        />
      )}

      <Grid
        xs={12}
        className={classes?.container}
        component="form"
        onSubmit={handleFormSubmit}
      >
        <Stack
          spacing={2}
          direction="column"
          gap={1}
          className={classes?.ContainerMargin}
        >
          <Stack
            spacing={2}
            direction="row"
            gap={3}
            sx={{ justifyContent: "space-evenly", alignItems: "center" }}
          >
            <FormControl
              variant="outlined"
              sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
            >
              <TextField
                fullWidth
                label="Title"
                error={!!errors?.name}
                placeholder="Enter Title"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                size={matches ? "small" : ""}
                sx={{ backgroundColor: "white" }}
                value={eventData.name}
                onChange={handleChange}
                id="title"
                name="name"
              />
              {errors?.name && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.name}
                </span>
              )}
            </FormControl>

            <FormControl
              variant="outlined"
              sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
            >
              <TextField
                fullWidth
                id="thumbnail"
                name="thumbnailData"
                InputLabelProps={{ shrink: true }}
                error={!!errors?.thumbnailData}
                label="Thumbnail"
                placeholder="Add File"
                variant="outlined"
                sx={{ backgroundColor: "white" }}
                size={matches ? "small" : ""}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <Button
                      aria-label="upload"
                      component="label"
                      className="addFileButton"
                      onClick={() => {
                        setErrors(null);
                        handleOpen(
                          "thumbnailData",
                          "JPEG",
                          ["JPEG", "PNG"],
                          [".jpeg, .png"],
                        );
                      }}
                    >
                      Add File
                    </Button>
                  ),
                }}
                value={truncateAtDot(thumbnailFileName)}
              />
              {errors?.thumbnailData && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.thumbnailData}
                </span>
              )}
            </FormControl>
          </Stack>

          <Stack
            spacing={2}
            direction="row"
            gap={3}
            sx={{ justifyContent: "space-evenly", alignItems: "center" }}
          >
            <FormControl
              variant="outlined"
              sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
              size={matches ? "small" : ""}
            >
              <DatePickerTextField
                label="Date & Time"
                value={
                  eventData.dateTime
                    ? format(eventData?.dateTime, "MM/dd/yyyy h:mm aa")
                    : ""
                }
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={openDatePicker}>
                        <EventIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
              <DatePicker
                minDate={tomorrowDate()}
                selected={eventData.dateTime}
                ref={datepickerRef}
                onChange={handleDateChange}
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
                portalId="root-portal"
                customInput={
                  <div
                    style={{
                      position: "absolute",
                      opacity: 0,
                      pointerEvents: "none",
                    }}
                  >
                    <input />
                  </div>
                }
              />
            </FormControl>

            <FormControl
              variant="outlined"
              sx={{
                width: { xs: 200, md: 250, lg: 380, xl: 480 },
                marginTop: "-1.5rem !important",
              }}
            >
              <TextField
                fullWidth
                id="image"
                name="filesData"
                error={!!errors?.filesData}
                label="Image"
                InputLabelProps={{ shrink: true }}
                placeholder="Add File"
                size={matches ? "small" : ""}
                variant="outlined"
                sx={{ backgroundColor: "white" }}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <Button
                      aria-label="upload"
                      component="label"
                      className="addFileButton"
                      onClick={() =>
                        handleOpen(
                          "filesData",
                          "JPEG",
                          ["JPEG", "PNG"],
                          [".jpeg, .png"],
                        )
                      }
                    >
                      Add File
                    </Button>
                  ),
                }}
                value={imageFileNames.join(", ")}
              />
              {errors?.filesData && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.filesData}
                </span>
              )}
            </FormControl>
          </Stack>

          <Stack
            spacing={2}
            direction="row"
            gap={3}
            sx={{ justifyContent: "space-evenly", alignItems: "center" }}
          >
            <FormControl
              variant="outlined"
              sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
            >
              <TextField
                fullWidth
                label="Description"
                multiline
                error={!!errors?.description}
                InputLabelProps={{ shrink: true }}
                placeholder="Enter Description"
                rows={5}
                size={matches ? "small" : ""}
                variant="outlined"
                sx={{ backgroundColor: "white" }}
                value={eventData.description}
                onChange={handleChange}
                id="description"
                name="description"
              />
              {errors?.description && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors?.description}
                </span>
              )}
            </FormControl>

            <Stack spacing={2} direction="column" gap={3} marginTop={"10%"}>
              <FormControl
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
                size={matches ? "small" : ""}
              >
                <TextField
                  className={classes?.input_box}
                  error={!!errors?.languageCode}
                  InputLabelProps={{ shrink: true }}
                  id="language"
                  select
                  variant="outlined"
                  size={matches ? "small" : ""}
                  sx={{
                    paddingBottom: "0px",
                    "& .MuiSelect-select span::before": {
                      color: "#A0A09E",
                      content: "'Choose Language'",
                    },
                  }}
                  label="Language"
                  value={eventData?.languageCode}
                  onChange={(e) => handleChangeLanguage(e)}
                >
                  <MenuItem value="en_US">English</MenuItem>
                  <MenuItem value="hi_IN">Hindi</MenuItem>
                  <MenuItem value="pa_IN">Punjabi</MenuItem>
                </TextField>
                {errors?.languageCode && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.languageCode}
                  </span>
                )}
              </FormControl>

              <FormControl
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
                size={matches ? "small" : ""}
              >
                <Autocomplete
                  id="gurudwara"
                  name="gurudwara"
                  options={options || []}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    options?.find(
                      (item) => item.id === eventData?.locationId,
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setErrors(null);
                    setEventData({
                      ...eventData,
                      locationId: newValue ? newValue.id : "",
                      locationName: newValue ? newValue.name : "",
                    });
                  }}
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Gurudwara"
                      variant="outlined"
                      size={matches ? "small" : ""}
                      placeholder="Choose Gurudwara"
                      InputLabelProps={{
                        shrink: true,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      sx={{
                        backgroundColor: "white",
                        paddingBottom: "0px",
                      }}
                      className={classes?.input_box}
                    />
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  fullWidth
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  )}
                  ListboxProps={{
                    onScroll: handleScroll,
                    style: {
                      maxHeight: 200,
                      overflowY: "auto",
                      scrollbarWidth: "thin",
                    },
                  }}
                  error={errors?.locationId}
                />
                {errors?.locationId && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.locationId}
                  </span>
                )}
              </FormControl>
            </Stack>
          </Stack>
        </Stack>

        <Grid xs={12} sx={{ mt: 4, mb: 2 }}>
          <Button
            type="submit"
            variant="contained"
            startIcon={<FileUploadOutlined />}
            sx={{
              backgroundColor: "#9E46E3",
              textTransform: "none",
              fontSize: matches ? "15px" : "20px",
              fontWeight: "500",
              marginLeft: { sm: 11, md: 52, lg: 87, xl: 113 },
            }}
            size="small"
          >
            {action === "edit" ? "Update" : "Upload"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { successRsponse, eventsData } = state.eventReducer;
  const { AllGurudwaraWithoutSearch } = state.gurudwaraReducer;
  return {
    successRsponse,
    eventsData,

    AllGurudwaraWithoutSearch,
  };
};
export default connect(mapStateToProps, {
  getAllGURUDWARAWithoutSearch,
  createEvents,
  getEvents,
  showSnackbar,
})(withMixpanelTracking(AddEvent, "Add Event"));
