export const CREATE_GRANTH = "CREATE_GRANTH";
export const GRANTH_SUCCESS = "GRANTH_SUCCESS";
export const GRANTH_FAILURE = "GRANTH_FAILURE";
export const DIALOG_MESSAGE = "DIALOG_MESSAGE";

export const DELETE_GRANTH = "DELETE_GRANTH";
export const DELETE_GRANTH_SUCCESS = "DELETE_GRANTH_SUCCESS";
export const DELETE_GRANTH_FAILURE = "DELETE_GRANTH_FAILURE";

export const GET_GRANTH_DETAILS = "GET_GRANTH_DETAILS";
export const GET_GRANTH_SUCCESS_DETAILS = "GET_GRANTH_SUCCESS_DETAILS";
export const GET_GRANTH_FAILURE_DETAILS = "GET_GRANTH_FAILURE_DETAILS";

export const GET_GRANTH_DETAILS_BYID = "GET_GRANTH_DETAILS_BYID";
export const GET_GRANTH_SUCCESS_DETAILS_BYID =
  "GET_GRANTH_SUCCESS_DETAILS_BYID";
export const GET_GRANTH_FAILURE_DETAILS_BYID =
  "GET_GRANTH_FAILURE_DETAILS_BYID";

export const UPDATE_GRANTH = "UPDATE_GRANTH";
export const UPDATE_GRANTH_SUCCESS = "UPDATE_GRANTH_SUCCESS";
export const UPDATE_GRANTH_FAILURE = "UPDATE_GRANTH_FAILURE";

export const DELETE_TOGGLE_MODAL_STATUS = "DELETE_TOGGLE_MODAL_STATUS";

export const deleteToggleModal = () => {
  return {
    type: DELETE_TOGGLE_MODAL_STATUS,
  };
};

export const createGranth = (data) => {
  return {
    type: CREATE_GRANTH,
    payload: data,
  };
};

export const granthSuccess = (data) => {
  return {
    type: GRANTH_SUCCESS,
    payload: data,
  };
};

export const granthFailure = (error) => {
  return {
    type: GRANTH_FAILURE,
    payload: error,
  };
};
export const updateGranth = (data, id) => {
  return {
    type: UPDATE_GRANTH,
    payload: data,
    id: id,
  };
};

export const updategranthSuccess = (data) => {
  return {
    type: UPDATE_GRANTH_SUCCESS,
    payload: data,
  };
};

export const updategranthFailure = (error) => {
  return {
    type: UPDATE_GRANTH_FAILURE,
    payload: error,
  };
};

export const showUnshowDialogMessage = () => {
  return {
    type: DIALOG_MESSAGE,
  };
};

export const deleteGranth = (id) => {
  return {
    type: DELETE_GRANTH,
    payload: id,
  };
};
export const deleteGranthSuccess = (id) => {
  return {
    type: DELETE_GRANTH_SUCCESS,
    payload: id,
  };
};
export const deleteGranthFails = (error) => {
  return {
    type: DELETE_GRANTH_FAILURE,
    payload: error,
  };
};

export const getGranthdetails = (urlParameters) => {
  return {
    type: GET_GRANTH_DETAILS,
    params: urlParameters,
  };
};

export const getGranthdetailsSuccess = (data) => {
  return {
    type: GET_GRANTH_SUCCESS_DETAILS,
    payload: data,
  };
};
export const getGranthdetailsFail = (error) => {
  return {
    type: GET_GRANTH_FAILURE_DETAILS,
    payload: error,
  };
};

export const getGranthdetailsByID = () => {
  return {
    type: GET_GRANTH_DETAILS_BYID,
  };
};

export const getGranthdetailsSuccessByID = (data) => {
  return {
    type: GET_GRANTH_SUCCESS_DETAILS_BYID,
    payload: data,
  };
};
export const getGranthdetailsFailByID = (error) => {
  return {
    type: GET_GRANTH_FAILURE_DETAILS_BYID,
    payload: error,
  };
};
