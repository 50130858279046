import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import MenuLayout from "../../commonLayout/MenuLayout";
import "../../styles/delete.css";
import SearchInput from "../commonComponent/SearchInput";
import New from "./New";
import Approved from "./Approved";
import Rejected from "./Rejected";
import { connect } from "react-redux";
import { getRequestdetails } from "../../redux/delete-request/action";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";

const DeleteRequest = (props) => {
  const [selectedMenu, setSelectedMenu] = useState("New");
  const [totalCount, setTotalCount] = useState(0);
  const [pending, setPending] = useState(0);
  const [searchedTabledata, setSearchedTable] = useState([]);

  const handleMenuButtonClick = (event, menu) => {
    setSelectedMenu(menu);
  };

  useEffect(() => {
    props.getRequestdetails();
  }, []);

  useEffect(() => {
    if (props.getByStatusSuccess) {
      let totalCount =
        props?.pendingRequest + props?.RejectedRequest + props?.ApprovedRequest;

      let Pending = props?.pendingRequest;
      setPending(Pending);

      setTotalCount(totalCount);
    }
  }, [props.getByStatusSuccess]);

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="DeleteRequestContainer">
      <h2 className="title">Delete Account Requests</h2>
      <div className="top__container">
        <div className="box1">
          <div className="box__main">
            <img
              className="delete_user_count_icon "
              src="/delete-request.svg"
              alt="delete-request-icon"
            />
            <div style={{ color: "#4C4C4C" }}>Number of Delete Requests</div>
          </div>
          <div className="delete-stat" style={{ marginTop: "1rem" }}>
            {totalCount}
          </div>
        </div>
        <div className="box2">
          <div className="box__main">
            <img
              className="delete_user_count_icon"
              src="/pending-actions.svg"
              alt="pending-actions-icon"
            />
            <div className="card-heading">Pending Actions</div>
          </div>
          <div className="delete-stat" style={{ marginTop: "1rem" }}>
            {pending}
          </div>
        </div>
      </div>

      <div className="tab__search__container">
        <div className="tabs">
          <Stack
            spacing={2}
            sx={{
              borderRadius: "12px",
              backgroundColor: "#F7F8FA",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "auto",

              p: 1,
              textAlign: "center",
            }}
            className="deleteRequest-tabs-text"
            direction="row"
          >
            {/*All users section  */}
            <List>
              <ListItem
                disablePadding
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#F7F8FA",
                }}
              >
                <ListItemButton
                  sx={{
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",

                    width: "156px",
                    textAlign: "center",
                  }}
                  //   className={classes?.list_item_button}
                  className="deleteRequest-tabs-stack"
                  selected={selectedMenu === "New"}
                  onClick={(event) => {
                    handleMenuButtonClick(event, "New");
                  }}
                >
                  <ListItemText
                    primary="New"
                    sx={
                      selectedMenu === "New"
                        ? { color: "#9E46E3" }
                        : { color: "black" }
                    }
                  />
                </ListItemButton>
              </ListItem>
            </List>

            {/* Active users section */}
            <List>
              <ListItem
                disablePadding
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  //   backgroundColor: "#F7F8FA",
                }}
              >
                <ListItemButton
                  sx={{
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",

                    width: "156px",
                    textAlign: "center",
                  }}
                  //   className={classes?.list_item_button}
                  className="deleteRequest-tabs-stack"
                  selected={selectedMenu === "Approved"}
                  onClick={(event) => {
                    handleMenuButtonClick(event, "Approved");
                  }}
                >
                  <ListItemText
                    primary="Approved"
                    sx={
                      selectedMenu === "Approved"
                        ? { color: "#9E46E3" }
                        : { color: "black" }
                    }
                  />
                </ListItemButton>
              </ListItem>
            </List>

            {/* Inactive users section  */}
            <List>
              <ListItem
                disablePadding
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#F7F8FA",
                }}
              >
                <ListItemButton
                  sx={{
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "center",

                    width: "156px",
                    textAlign: "center",
                  }}
                  //   className={classes?.list_item_button}
                  className="deleteRequest-tabs-stack"
                  selected={selectedMenu === "Rejected"}
                  onClick={(event) => {
                    handleMenuButtonClick(event, "Rejected");
                  }}
                >
                  <ListItemText
                    primary="Rejected"
                    sx={
                      selectedMenu === "Rejected"
                        ? { color: "#9E46E3" }
                        : { color: "black" }
                    }
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Stack>
        </div>
        <div className="delete-Search-bar">
          <SearchInput text={"Search"} setSearchTerm={setSearchTerm} />
        </div>
      </div>
      {(() => {
        switch (selectedMenu) {
          case "New":
            return <New searchTerm={searchTerm} />;
          case "Approved":
            return <Approved searchTerm={searchTerm} />;
          case "Rejected":
            return <Rejected searchTerm={searchTerm} />;
          default:
            return <New searchTerm={searchTerm} />;
        }
      })()}
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    deleteRequestData,
    DeleteRByStatusData,
    getDeleteRSuccess,
    updateByStatusSuccess,
    pendingRequest,
    RejectedRequest,
    ApprovedRequest,
    getByStatusSuccess,
  } = state.deleteRequestReducer;
  return {
    deleteRequestData,
    DeleteRByStatusData,
    getDeleteRSuccess,
    updateByStatusSuccess,
    pendingRequest,
    RejectedRequest,
    ApprovedRequest,
    getByStatusSuccess,
  };
};

const DeleteRequested = connect(mapStateToProps, { getRequestdetails })(
  withMixpanelTracking(DeleteRequest, "Delete Request Tabs"),
);

const wrapper = () => {
  return (
    <MenuLayout
      childComponent={() => {
        return <DeleteRequested />;
      }}
    />
  );
};

export default wrapper;
