export const CREATE_KIRTAN_WITH_SINGER = "CREATE_KIRTAN_WITH_SINGER";
export const CREATE_KIRTAN_WITH_SINGER_SUCCESS =
  "CREATE_KIRTAN_WITH_SINGER_SUCCESS";
export const CREATE_KIRTAN_WITH_SINGER_FAILURE =
  "CREATE_KIRTAN_WITH_SINGER_FAILURE";

export const UPDATE_KIRTAN_WITH_SINGER = "UPDATE_KIRTAN_WITH_SINGER";
export const UPDATE_KIRTAN_WITH_SINGER_SUCCESS =
  "UPDATE_KIRTAN_WITH_SINGER_SUCCESS";
export const UPDATE_KIRTAN_WITH_SINGER_FAILURE =
  "UPDATE_KIRTAN_WITH_SINGER_FAILURE";

export const CREATE_KIRTAN_WITH_ALBUM = "CREATE_KIRTAN_WITH_ALBUM";
export const CREATE_KIRTAN_WITH_ALBUM_SUCCESS =
  "CREATE_KIRTAN_WITH_ALBUM_SUCCESS";
export const CREATE_KIRTAN_WITH_ALBUM_FAILURE =
  "CREATE_KIRTAN_WITH_ALBUM_FAILURE";

export const UPDATE_KIRTAN_WITH_ALBUM = "UPDATE_KIRTAN_WITH_ALBUM";
export const UPDATE_KIRTAN_WITH_ALBUM_SUCCESS =
  "UPDATE_KIRTAN_WITH_ALBUM_SUCCESS";
export const UPDATE_KIRTAN_WITH_ALBUM_FAILURE =
  "UPDATE_KIRTAN_WITH_ALBUM_FAILURE";

export const GET_KIRTAN_WITH_ALBUM = "GET_KIRTAN_WITH_ALBUM";
export const GET_KIRTAN_WITH_ALBUM_SUCCESS = "GET_KIRTAN_WITH_ALBUM_SUCCESS";
export const GET_KIRTAN_WITH_ALBUM_FAILURE = "GET_KIRTAN_WITH_ALBUM_FAILURE";

export const GET_KIRTAN_WITH_SINGER = "GET_KIRTAN_WITH_SINGER";
export const GET_KIRTAN_WITH_SINGER_SUCCESS = "GET_KIRTAN_WITH_SINGER_SUCCESS";
export const GET_KIRTAN_WITH_SINGER_FAILURE = "GET_KIRTAN_WITH_SINGER_FAILURE";

export const DELETE_SONG_SINGER = "DELETE_SONG_SINGER";
export const DELETE_SONG_SINGER_SUCCESS = "DELETE_SONG_SINGER_SUCCESS";
export const DELETE_SONG_SINGER_FAILURE = "DELETE_SONG_SINGER_FAILURE";

export const DELETE_SONG_ALBUM = "DELETE_SONG_ALBUM";
export const DELETE_SONG_ALBUM_SUCCESS = "DELETE_SONG_ALBUM_SUCCESS";
export const DELETE_SONG_ALBUM_FAILURE = "DELETE_SONG_ALBUM_FAILURE";

export const DELETE_TOGGLE_MODAL_STATUS = "DELETE_TOGGLE_MODAL_STATUS";

export const deleteToggleModal = () => {
  return {
    type: DELETE_TOGGLE_MODAL_STATUS,
  };
};

export const DIALOG_MESSAGE = "DIALOG_MESSAGE";

export const showUnshowDialogMessage = () => {
  return {
    type: DIALOG_MESSAGE,
  };
};

export const createKirtanWithSinger = (payload) => {
  return {
    type: CREATE_KIRTAN_WITH_SINGER,
    payload,
  };
};

export const createKirtanWithSingerSuccess = () => {
  return {
    type: CREATE_KIRTAN_WITH_SINGER_SUCCESS,
  };
};

export const createKirtanWithSingerFail = () => {
  return {
    type: CREATE_KIRTAN_WITH_SINGER_FAILURE,
  };
};

export const updateKirtanWithSinger = (payload, id) => {
  return {
    type: UPDATE_KIRTAN_WITH_SINGER,
    payload,
    id,
  };
};

export const updateKirtanWithSingerSuccess = () => {
  return {
    type: UPDATE_KIRTAN_WITH_SINGER_SUCCESS,
  };
};

export const updateKirtanWithSingerFail = () => {
  return {
    type: UPDATE_KIRTAN_WITH_SINGER_FAILURE,
  };
};

export const createKirtanWithAlbum = (payload) => {
  return {
    type: CREATE_KIRTAN_WITH_ALBUM,
    payload,
  };
};

export const createKirtanWithAlbumSuccess = () => {
  return {
    type: CREATE_KIRTAN_WITH_ALBUM_SUCCESS,
  };
};

export const createKirtanWithAlbumFail = () => {
  return {
    type: CREATE_KIRTAN_WITH_ALBUM_FAILURE,
  };
};

export const updateKirtanWithAlbum = (payload, id) => {
  return {
    type: UPDATE_KIRTAN_WITH_ALBUM,
    payload,
    id,
  };
};

export const updateKirtanWithAlbumSuccess = () => {
  return {
    type: UPDATE_KIRTAN_WITH_ALBUM_SUCCESS,
  };
};

export const updateKirtanWithAlbumFail = () => {
  return {
    type: UPDATE_KIRTAN_WITH_ALBUM_FAILURE,
  };
};

export const getKirtanWithAlbum = () => {
  return {
    type: GET_KIRTAN_WITH_ALBUM,
  };
};

export const getKirtanWithAlbumSuccess = (payload) => {
  return {
    type: GET_KIRTAN_WITH_ALBUM_SUCCESS,
    payload,
  };
};

export const getKirtanWithAlbumFail = () => {
  return {
    type: GET_KIRTAN_WITH_ALBUM_FAILURE,
  };
};

export const getKirtanWithSinger = () => {
  return {
    type: GET_KIRTAN_WITH_SINGER,
  };
};

export const getKirtanWithSingerSuccess = (payload) => {
  return {
    type: GET_KIRTAN_WITH_SINGER_SUCCESS,
    payload,
  };
};

export const getKirtanWithSingerFail = () => {
  return {
    type: GET_KIRTAN_WITH_SINGER_FAILURE,
  };
};

export const deleteAlbumSong = (id) => {
  return {
    type: DELETE_SONG_ALBUM,
    payload: id,
  };
};

export const deleteAlbumSongSuccess = () => {
  return {
    type: DELETE_SONG_ALBUM_SUCCESS,
  };
};

export const deleteAlbumSongFailure = (error) => {
  return {
    type: DELETE_SONG_ALBUM_FAILURE,
    payload: error,
  };
};

export const deleteSingerSong = (id) => {
  return {
    type: DELETE_SONG_SINGER,
    id,
  };
};

export const deleteSingerSongSuccess = () => {
  return {
    type: DELETE_SONG_SINGER_SUCCESS,
  };
};

export const deleteSingerSongFailure = (error) => {
  return {
    type: DELETE_SONG_SINGER_FAILURE,
    payload: error,
  };
};
