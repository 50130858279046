import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import DialogBoxFileUploadLayout from "../../commonLayout/DialogBoxFileUploadLayout";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import ProgressDialogLayout from "../../commonLayout/ProgressDialogLayout";
import { formatDateInUTC } from "../../helpers/dateHelper";
import { connect } from "react-redux";
import { createEvents, getEvents } from "../../redux/Events/action";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
const AddQuiz = (props) => {
  const formatDateinUTC = formatDateInUTC();
  const [eventData, setEventData] = useState({
    name: "",
    thumbnailData: "",
    time: "",
    filesData: [],
    date: formatDateinUTC,
    description: "",
    locationId: 1,
    languageCode: "en_US",
    uploadType: "EVENT",
  });

  const [toBeUploadedFileFormat, setToBeUploadedFileFormat] = useState({
    fileType: "",
    acceptedFileFormatForDragDrop: [],
    acceptedFileFormatForNormalFileUpload: [],
  });

  // State to manage file names for thumbnail and image
  const [thumbnailFileName, setThumbnailFileName] = useState("");
  const [imageFileNames, setimageFileNames] = useState([]);

  // State to control visibility of schedule box and dialogs
  const [showScheduleBox, setShowScheduleBox] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [dialogBoxToggle, setDialogBoxToggle] = useState(false);

  // State to manage upload progress and status
  const [progress, setProgress] = useState(0);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadFileName, setUploadFileName] = useState("");
  const [uploadInterval, setUploadInterval] = useState(null);

  // State to determine the type of file to be uploaded
  const [uploadType, setUploadType] = useState("");

  const handleOpen = (
    type,
    fileType,
    dragDropFileFormat,
    normalUploadFileFormat,
  ) => {
    setToBeUploadedFileFormat({
      ...toBeUploadedFileFormat,
      fileType: fileType,
      acceptedFileFormatForDragDrop: dragDropFileFormat,
      acceptedFileFormatForNormalFileUpload: normalUploadFileFormat,
    });
    setUploadType(type);
    setDialogOpen(true);
  };

  const handleDateChange = (newValue) => {
    const utcDate = newValue ? formatDateInUTC(newValue) : null;
    setEventData({
      ...eventData,
      date: utcDate,
    });
  };

  const handleProgressDialogClose = () => {
    setProgressDialogOpen(false);
    setUploadInProgress(false);
    clearInterval(uploadInterval);
    setProgress(0);
    if (uploadType === "thumbnailData") {
      setThumbnailFileName("");
    } else if (uploadType === "filesData") {
      setimageFileNames([]);
    }
  };

  const handleScheduleOpen = () => {
    setShowScheduleBox(true);
  };

  const handleScheduleClose = () => {
    setShowScheduleBox(false);
  };

  const handleUpdate = () => {
    setShowScheduleBox(false);
  };

  const handleFileChange = (e) => {
    let files = e.target ? e.target.files : e;

    if (uploadType === "thumbnailData") {
      files = e.target ? e.target.files[0] : e;

      // Ensure only one file is allowed for thumbnailData
      if (files.length > 1) {
        alert("Only one file is allowed for thumbnail.");
        return;
      }

      if (
        files &&
        (files.type === "image/jpeg" || files.type === "image/png")
      ) {
        setEventData({ ...eventData, thumbnailData: files });
        setThumbnailFileName(files.name);
        setDialogOpen(false);
        setProgress(0);
        setUploadFileName(files.name);
        handleProgressDialogOpen();
        uploadFile(files);
      } else {
        alert("Please upload a valid jpeg/png file for thumbnail.");
      }
    } else if (uploadType === "filesData") {
      // Ensure only up to 3 files are allowed for filesData
      const newFiles = Array.from(files);

      // Check if the total number of files exceeds the limit
      if (newFiles.length > 3) {
        alert("You can only upload up to 3 files.");
        return;
      }

      const validFiles = newFiles.filter(
        (file) => file.type === "image/jpeg" || file.type === "image/png",
      );

      if (validFiles.length > 0) {
        setEventData({
          ...eventData,
          filesData: [...eventData.filesData, ...validFiles],
        });
        setimageFileNames((prevFiles) => [
          ...prevFiles,
          ...validFiles.map((files) => files.name),
        ]);
        setDialogOpen(false);
        setProgress(0);
        setUploadFileName(validFiles.map((file) => file.name).join(", "));
        handleProgressDialogOpen();
        uploadFile(validFiles);
      } else {
        alert("Please upload valid jpeg/png files.");
      }
    }
  };

  const handleProgressDialogOpen = () => {
    setProgressDialogOpen(true);
    setUploadInProgress(true);
  };

  const uploadFile = (file) => {
    setProgress(0);
    const UploadProgress = () => {
      let progress = 0;
      const interval = setInterval(() => {
        progress += 10;
        setProgress(progress);
        if (progress >= 110) {
          clearInterval(interval);
          setUploadInProgress(false);
          setProgressDialogOpen(false);
        }
      }, 300);
      setUploadInterval(interval);
    };
    UploadProgress();
  };

  const handleChange = (e) => {
    setEventData({
      ...eventData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setEventData({
      ...eventData,
      name: "",
      time: "",
      date: formatDateinUTC,
      description: "",
    });
    setThumbnailFileName("");
    setimageFileNames([]);
  }, [props.successRsponse]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setDialogBoxToggle(false);

    const formData = new FormData();

    formData.append("languageCode", eventData.languageCode);
    formData.append("uploadType", eventData.uploadType);

    eventData.filesData.forEach((file) => {
      formData.append("files", file, file.name);
    });

    props.createEvents(formData);
  };

  useEffect(() => {
    props.getEvents();
  }, []);

  return (
    <>
      {dialogBoxToggle && (
        <DialogBoxLayout
          dialogBoxToggle={dialogBoxToggle}
          setDialogBoxToggle={setDialogBoxToggle}
          header="Uploaded Successfully!"
          content="You have Uploaded Your File Successfully"
        />
      )}

      {dialogOpen && (
        <DialogBoxFileUploadLayout
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          uploadInProgress={uploadInProgress}
          progress={progress}
          handleFileChange={handleFileChange}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
          uploadType={uploadType}
        />
      )}

      {progressDialogOpen && (
        <ProgressDialogLayout
          progressDialogOpen={progressDialogOpen}
          handleProgressDialogClose={handleProgressDialogClose}
          uploadFileName={uploadFileName}
          uploadInProgress={uploadInProgress}
          progress={progress}
        />
      )}

      <Grid
        xs={12}
        sx={{
          backgroundColor: "#F9F3FB",
          padding: 4,
          borderRadius: 2,
          marginTop: "45px",
        }}
      >
        <Box component="form" sx={{ mt: 2 }} onSubmit={handleFormSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Title"
                placeholder="Enter Title"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                sx={{ backgroundColor: "white" }}
                value={eventData.name}
                onChange={handleChange}
                required
                id="title"
                name="name"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="thumbnail"
                name="thumbnailData"
                InputLabelProps={{ shrink: true }}
                label="Thumbnail"
                placeholder="Add File"
                variant="outlined"
                required
                sx={{ backgroundColor: "white" }}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <IconButton
                      aria-label="upload"
                      component="label"
                      onClick={() =>
                        handleOpen(
                          "thumbnailData",
                          "JPEG",
                          ["JPEG", "PNG"],
                          [".jpeg, .png"],
                        )
                      }
                    >
                      <CreateNewFolderOutlinedIcon sx={{ color: "#000000" }} />
                    </IconButton>
                  ),
                }}
                value={thumbnailFileName}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Time"
                placeholder="Enter Time"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                sx={{ backgroundColor: "white", color: "black" }}
                value={eventData.time}
                onChange={handleChange}
                required
                id="time"
                name="time"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="image"
                name="filesData"
                label="Image"
                multiline
                rows={2}
                InputLabelProps={{ shrink: true }}
                placeholder="Add File"
                variant="outlined"
                required
                sx={{ backgroundColor: "white" }}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <IconButton
                      aria-label="upload"
                      component="label"
                      onClick={() =>
                        handleOpen(
                          "filesData",
                          "JPEG",
                          ["JPEG", "PNG"],
                          [".jpeg, .png"],
                        )
                      }
                    >
                      <CreateNewFolderOutlinedIcon sx={{ color: "#000000" }} />
                    </IconButton>
                  ),
                }}
                value={imageFileNames.join(", ")}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    required
                    id="scheduleDate"
                    label="Date *"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    value={dayjs(eventData.date)}
                    sx={{
                      backgroundColor: "white",
                      width: "100%",
                      color: "#000000",
                    }}
                    format="DD/MM/YYYY"
                    disablePast
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </DemoContainer>
              </LocalizationProvider>

              <TextField
                fullWidth
                label="Location"
                placeholder="Enter Location"
                variant="outlined"
                type="number"
                InputLabelProps={{ shrink: true }}
                sx={{ backgroundColor: "white", mt: 4 }}
                value={eventData.locationId}
                onChange={handleChange}
                required
                id="location"
                name="locationId"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Description"
                multiline
                InputLabelProps={{ shrink: true }}
                placeholder="Enter Description"
                rows={5}
                variant="outlined"
                sx={{ backgroundColor: "white" }}
                value={eventData.description}
                onChange={handleChange}
                required
                id="description"
                name="description"
              />
            </Grid>

            <Grid item xs={12} display="flex" justifyContent={"flex-end"}>
              <IconButton onClick={handleScheduleOpen} sx={{ mr: "6%" }}>
                <AccessTimeIcon />
              </IconButton>

              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#9E46E3",
                }}
                size="large"
              >
                Upload
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {/* Schedule Post Box */}
      {showScheduleBox && (
        <Box
          padding={"10%"}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "rgba(158, 70, 227, 0.35)",
              padding: 4,
              borderRadius: 2,
              boxShadow: 3,
              width: "400px",
            }}
          >
            <Typography variant="h6" sx={{ color: "white" }}>
              Schedule Post
            </Typography>
            <TextField
              fullWidth
              type="date"
              label="Date"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              sx={{ my: 2, backgroundColor: "white", color: "black" }}
              value={eventData.date}
              onChange={handleChange}
              required
              id="scheduleDate"
              name="date"
            />
            <TextField
              fullWidth
              type="time"
              label="Time"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              sx={{ my: 2, backgroundColor: "white", color: "black" }}
              value={eventData.time}
              onChange={handleChange}
              required
              id="scheduleTime"
              name="time"
            />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                onClick={handleScheduleClose}
                variant="contained"
                sx={{
                  backgroundColor: "#F9F3FB",
                  color: "black",
                }}
              >
                Back
              </Button>
              <Button
                onClick={handleUpdate}
                variant="contained"
                sx={{
                  backgroundColor: "black",
                }}
              >
                Update
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { successRsponse, eventsData } = state.eventReducer;
  return {
    successRsponse,
    eventsData,
  };
};

export default connect(mapStateToProps, { createEvents, getEvents })(
  withMixpanelTracking(AddQuiz, "Add Quiz"),
);
