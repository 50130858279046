import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import MenuLayout from "../commonLayout/MenuLayout";
import {
  getVolunteerdetails,
  getVolunteerDownloads,
} from "../redux/volunteer/action";
import AppliedVol from "./Volunteer/applied";
import ApprovedVol from "./Volunteer/Approved";
import Overview from "./Volunteer/Overview";
import RejectedVol from "./Volunteer/Rejected";
import SearchInput from "./commonComponent/SearchInput";
import "../../src/styles/volunteer.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const Volunteer = (props) => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem("myValue");
    return storedValue !== null ? JSON.parse(storedValue) : 0;
  });
  const navigate = useNavigate();
  const [overviewList, setOverviewList] = useState([]);
  const [AppliedList, setAppliedList] = useState([]);
  const [ApprovedList, setApprovedList] = useState([]);
  const [RejectedList, setRejectedList] = useState([]);
  const { action } = useParams();
  const [id, setId] = useState(null);
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBackView = () => {
    navigate(`/volunteer/view`);
  };

  useEffect(() => {
    if (location.pathname !== "/volunteer/formsView") {
      setValue(0);
      localStorage.setItem("myValue", JSON.stringify(0));
    }
  }, [location?.pathname]);

  useEffect(() => {
    localStorage.setItem("myValue", JSON.stringify(value));
  }, [value]);

  const HandleSetTable = (val) => {
    if (action === "view") {
      setOverviewList(val);
    }
    if (value === 0 && action === "formsView") {
      setAppliedList(val);
    }
    if (value === 1 && action === "formsView") {
      setApprovedList(val);
    }
    if (value === 2 && action === "formsView") {
      setRejectedList(val);
    }
  };
  const [searchList, setSearchList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const HandlesearchList = (val) => {
    setSearchList(val);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, maxHeight: "100%", mx: 2 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
          <div className="headingName">
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {action === "formsView" ? (
                <KeyboardBackspaceOutlinedIcon
                  style={{
                    color: "#000000",
                    margin: "5px 10px 0 0",
                    fontSize: "36px",
                    cursor: "pointer",
                  }}
                  onClick={handleBackView}
                />
              ) : null}
              <Box
                className={"page_title"}
                sx={{
                  textWrap: "nowrap",
                  paddingY: {
                    lg: "10px",
                    xl: "20px",
                  },
                }}
              >
                Volunteer Forms
              </Box>
            </span>
            <Grid
              style={{ display: "flex", justifyContent: "end", width: "100%" }}
            >
              <SearchInput
                text={"Search"}
                tableData={searchList}
                setSearchedTable={HandleSetTable}
                setSearchTerm={setSearchTerm}
              />
            </Grid>
          </div>

          {action === "view" ? (
            <Overview
              searchList={HandlesearchList}
              searchedList={overviewList}
              searchTerm={searchTerm}
            />
          ) : (
            <>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    style={{
                      marginBottom: 10,
                    }}
                    className="volunteer_tabs_grid"
                  >
                    <Tab
                      label="Applied"
                      className="VolunteerTabs"
                      style={{
                        background: value === 0 ? "#FFFFFF" : null,
                        color: value === 0 ? "#9e46e3" : "black",
                      }}
                    />
                    <Tab
                      label="Approved"
                      className="VolunteerTabs"
                      style={{
                        background: value === 1 ? "#FFFFFF" : null,
                        color: value === 1 ? "#9e46e3" : "black",
                      }}
                    />
                    <Tab
                      label="Rejected"
                      className="VolunteerTabs"
                      style={{
                        background: value === 2 ? "#FFFFFF" : null,
                        color: value === 2 ? "#9e46e3" : "black",
                      }}
                    />
                  </Tabs>
                </Grid>
                <Grid item>
                  <button
                    className="volunteer_Download_Button"
                    onClick={() => {
                      let s = "";
                      if (value === 0) {
                        s = "APPLIED";
                      } else if (value === 1) {
                        s = "APPROVED";
                      } else {
                        s = "REJECTED";
                      }
                      props.getVolunteerDownloads(s, id);
                    }}
                  >
                    <SaveAltIcon sx={{ marginRight: "10px" }} />
                    Download
                  </button>
                </Grid>
              </Grid>
              {value === 0 ? (
                <AppliedVol
                  searchList={HandlesearchList}
                  searchedList={AppliedList}
                  setId={setId}
                />
              ) : value === 1 ? (
                <ApprovedVol
                  searchList={HandlesearchList}
                  searchedList={ApprovedList}
                  setId={setId}
                />
              ) : (
                <RejectedVol
                  searchList={HandlesearchList}
                  searchedList={RejectedList}
                  setId={setId}
                />
              )}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  const { volunteerData, getVolunteerSuccess, getVolunteerDownload } =
    state.volunteerReducer;
  return {
    volunteerData,
    getVolunteerSuccess,
    getVolunteerDownload,
  };
};
const Volnteer = connect(mapStateToProps, {
  getVolunteerdetails,
  getVolunteerDownloads,
})(Volunteer);
const wrapper = () => {
  return (
    <MenuLayout
      childComponent={() => {
        return <Volnteer />;
      }}
    />
  );
};

export default wrapper;
