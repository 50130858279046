export const CREATE_MEDITATION = "CREATE_MEDITATION";
export const MEDITATION_SUCCESS = "MEDITATION_SUCCESS";
export const MEDITATION_FAILURE = "MEDITATION_FAILURE";

export const FETCH_MEDITATION_DETAILS = "FETCH_MEDITATION_DETAILS";
export const FETCH_MEDITATION_DETAILS_SUCCESS =
  "FETCH_MEDITATION_DETAILS_SUCCESS";
export const FETCH_MEDITATION_DETAILS_FAILURE =
  "FETCH_MEDITATION_DETAILS_FAILURE";

export const DIALOG_MESSAGE = "DIALOG_MESSAGE";

export const DELETE_BY_ID = "DELETE_BY_ID";
export const DELETE_BY_ID_SUCCESS = "DELETE_BY_ID_SUCCESS";
export const DELETE_BY_ID_FAILURE = "DELETE_BY_ID_FAILURE";

export const UPDATE_MEDITATION = "UPDATE_MEDITATION";
export const UPDATE_MEDITATION_SUCCESS = "UPDATE_MEDITATION_SUCCESS";
export const UPDATE_MEDITATION_FAILURE = "UPDATE_MEDITATION_FAILURE";

export const DELETE_TOGGLE_MODAL_STATUS = "DELETE_TOGGLE_MODAL_STATUS";

export const deleteToggleModal = () => {
  return {
    type: DELETE_TOGGLE_MODAL_STATUS,
  };
};

export const updateMedById = (data, id) => {
  return {
    type: UPDATE_MEDITATION,
    payload: data,
    id: id,
  };
};
export const updateMedByIdSuccess = () => {
  return {
    type: UPDATE_MEDITATION_SUCCESS,
  };
};
export const updateMedByIdFail = (err) => {
  return {
    type: UPDATE_MEDITATION_FAILURE,
    payload: err,
  };
};

export const deleteById = (id) => {
  return {
    type: DELETE_BY_ID,
    payload: id,
  };
};
export const deleteByIdSuccess = () => {
  return {
    type: DELETE_BY_ID_SUCCESS,
  };
};
export const deleteByIdFailure = (err) => {
  return {
    type: DELETE_BY_ID_FAILURE,
    payload: err,
  };
};
export const getMeditaion = (urlParameters) => {
  return {
    type: FETCH_MEDITATION_DETAILS,
    params: urlParameters,
  };
};
export const getMeditaionSuccess = (data) => {
  return {
    type: FETCH_MEDITATION_DETAILS_SUCCESS,
    payload: data,
  };
};
export const getMeditaionFailure = (err) => {
  return {
    type: FETCH_MEDITATION_DETAILS_FAILURE,
    payload: err,
  };
};
export const createMeditation = (data) => {
  return {
    type: CREATE_MEDITATION,
    payload: data,
  };
};

export const meditationSuccess = (data) => {
  return {
    type: MEDITATION_SUCCESS,
    payload: data,
  };
};

export const meditationFailure = (error) => {
  return {
    type: MEDITATION_FAILURE,
    payload: error,
  };
};

export const showUnshowDialogMessage = () => {
  return {
    type: DIALOG_MESSAGE,
  };
};
