import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  deleteNitnem,
  fetchAllNitnemWithPagination,
  deleteToggleModal,
} from "../../redux/nitnem/action";
import {
  Grid,
  Box,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableSortLabel,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TableTooltip from "../commonComponent/tableTooltip";
import DeleteDialogLayout from "../../commonLayout/DeleteDialogLayout";
import { formatDateInAlphanumericFormat } from "../../helpers/dateHelper";
import Paginate from "../commonComponent/Pagination";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import useCustomStyles from "../../customHooks/useCustomStyles";

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth: "thin",
    [theme.breakpoints.down(1400)]: {
      maxHeight: 240,
    },
    [theme.breakpoints.between(1401, 1919)]: {
      maxHeight: 390,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 500,
    },
  },

  icon_size: {
    [theme.breakpoints.down(1400)]: {
      height: "25px",
      width: "25px",
    },
    [theme.breakpoints.between(1401, 1919)]: {
      height: "30px",
      width: "30px",
    },
  },
});

const ViewNitnem = (props) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const navigate = useNavigate();
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [paginationObject, setPaginationObject] = useState({
    page: 1,
    rowsPerPage: 10,
    totalRowCount: 0,
  });
  const [orderDirection, setOrderDirection] = useState("asc");
  const [sortedData, setSortedData] = useState([]);
  const [comparetableRecords, setCompareTableRecords] = useState([]);
  const tableContainerRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setPaginationObject({ ...paginationObject, page: newPage });
    getAllNitnemData(newPage);

    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  };

  const getAllNitnemData = (page) => {
    props.fetchAllNitnemWithPagination(page);
  };

  const setNitnemAndPaginationData = (loadingValue) => {
    if (!loadingValue && props?.nitnemData?.data) {
      const { content, page } = props?.nitnemData?.data;

      props.setNitnemList(content);
      props.setRecordsList(content);
      setCompareTableRecords(content);
      setSortedData(content);
      setPaginationObject({
        ...paginationObject,
        totalRowCount: page?.totalPages,
      });
    }
  };

  const handleSortRequest = () => {
    if (sortedData?.length > 0) {
      const isAsc = orderDirection === "asc";
      const sorted = [...sortedData].sort((a, b) =>
        isAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name),
      );
      setOrderDirection(isAsc ? "desc" : "asc");
      setSortedData(sorted);
    }
  };

  const deleteRowData = (value) => {
    props.deleteNitnem(value?.id);
    props.trackButtonClick("Delete Nitenam Button", 1);
  };

  const handleDeleteNitnem = (nitnemData) => {
    setDeleteToggle(!deleteToggle);
    setRowData(nitnemData);
  };

  useEffect(() => {
    setSortedData(props.nitnemList);
  }, [props?.nitnemList]);

  useEffect(() => {
    setNitnemAndPaginationData(props?.loading);
  }, [props?.loading]);

  useEffect(() => {
    if (props.nitnemList?.length !== comparetableRecords?.length) {
      setPaginationObject({
        ...paginationObject,
        totalRowCount: 1,
      });
    } else {
      setNitnemAndPaginationData(false);
    }
  }, [props.nitnemList]);

  useEffect(() => {
    getAllNitnemData(paginationObject?.page);
    setRowData([]);
    if (props.deleteSuccess) {
      setOpenSucceessDelete(true);
    }
  }, [props.deleteSuccess, props.updateResponse]);

  const [openSuccessDelete, setOpenSucceessDelete] = useState(false);

  const closeSuccessDeleteModal = () => {
    props.deleteToggleModal();
    setOpenSucceessDelete(false);
  };

  const handleRowEdit = (val) => {
    const jsonData = JSON.stringify(val);

    const encodedData = encodeURIComponent(jsonData);
    navigate(`/content/nitnem/edit?data=${encodedData}`);
  };

  return (
    <>
      <DeleteDialogLayout
        deleteToggle={deleteToggle}
        setDeleteToggle={setDeleteToggle}
        recordType="Nitnem"
        recordRowData={rowData}
        deleteRowData={deleteRowData}
        openSuccessDelete={openSuccessDelete}
        closeSuccessDeleteModal={closeSuccessDeleteModal}
      />

      <Grid container xs={12} flexWrap="wrap">
        <TableContainer
          component={Paper}
          elevation={0}
          className={classes?.table}
          ref={tableContainerRef}
        >
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
              <TableRow>
                <TableCell sx={{ paddingLeft: "10px !important" }}>
                  <TableSortLabel
                    active
                    direction={orderDirection}
                    onClick={handleSortRequest}
                  >
                    Title
                  </TableSortLabel>
                </TableCell>
                <TableCell>Language</TableCell>
                <TableCell>Time Of Day</TableCell>
                <TableCell>Uploaded By</TableCell>
                <TableCell>Uploaded on</TableCell>
                <TableCell align="right" sx={{ pr: 2.5 }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedData === undefined ||
                (sortedData?.length > 0 &&
                  sortedData.map((nitnem) => (
                    <TableRow
                      key={nitnem?.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <TableTooltip title={nitnem?.name} />
                      </TableCell>
                      <TableCell>
                        {nitnem?.languageCode === "en_US"
                          ? "English"
                          : nitnem?.languageCode === "hi_IN"
                            ? "Hindi"
                            : "Punjabi"}
                      </TableCell>
                      <TableCell>
                        {nitnem?.prayerType === "MORNING_TIME"
                          ? "Morning"
                          : nitnem?.prayerType === "EVENING_TIME"
                            ? "Evening"
                            : nitnem?.prayerType === "NIGHT_TIME"
                              ? "Night"
                              : ""}
                      </TableCell>
                      <TableCell>Admin</TableCell>
                      <TableCell>
                        {formatDateInAlphanumericFormat(nitnem?.updatedAt)}
                      </TableCell>
                      <TableCell align="right" sx={{ p: 0 }}>
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            props.setContentDataToBeUpdated(nitnem);
                            handleRowEdit(nitnem);
                            props.trackButtonClick("Edit Nitenam Button", 1);
                          }}
                        >
                          <img
                            className={classes?.icon_size}
                            src="/edit-icon.svg"
                            alt="edit-icon"
                          />
                        </IconButton>

                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            handleDeleteNitnem(nitnem);
                          }}
                        >
                          <img
                            className={classes?.icon_size}
                            src="/delete-icon.svg"
                            alt="edit-icon"
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )))}
            </TableBody>
          </Table>
        </TableContainer>
        {sortedData?.length === 0 && (
          <Box
            sx={{
              display: "grid",
              justifyContent: "center",
              textAlign: "center",
              width: "100%",
              marginTop: "10%",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <img
                src="/mailbox.png"
                alt="No data"
                style={{ width: "30px", height: "30px" }}
              />
            </Box>
            <Box sx={{}}>No data</Box>
          </Box>
        )}
        {sortedData?.length > 0 && (
          <Grid container sx={{ marginTop: "25px", justifyContent: "center" }}>
            <Paginate
              paginationObject={paginationObject}
              handlePageChange={handlePageChange}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { loading, nitnemData, deleteSuccess } = state.nitnemReducer;

  return {
    loading,
    nitnemData,
    deleteSuccess,
    setContentToBeUpdated: ownProps.setContentToBeUpdated,
  };
};

export default connect(mapStateToProps, {
  fetchAllNitnemWithPagination,
  deleteNitnem,
  deleteToggleModal,
})(withMixpanelTracking(ViewNitnem, "Nitenam"));
