import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { truncateAtDot } from "../../helpers/truncateFileName";
import {
  createMeditation,
  showUnshowDialogMessage,
  updateMedById,
} from "../../redux/meditation/action";

import {
  Button,
  FormControl,
  Grid,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useCustomStyles from "../../customHooks/useCustomStyles";

import DialogBoxFileUploadLayout from "../../commonLayout/DialogBoxFileUploadLayout";
import DialogBoxLayout from "../../commonLayout/DialogBoxLayout";
import LoaderLayout from "../../commonLayout/LoaderLayout";
import ProgressDialogLayout from "../../commonLayout/ProgressDialogLayout";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";

const styles = (theme) => ({
  container: {
    backgroundColor: "#F9F3FB",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xl")]: {
      padding: "20px 10px",
      marginTop: "20px",
      height: "350px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "0 10px 25px",
      marginTop: "45px",
      height: "600px",
    },
  },

  componentTitle: {
    fontSize: "24px",
    paddingLeft: "15px",
    fontWeight: "400",
    color: "#333333",
  },

  input_box: {
    backgroundColor: "#ffffff",
    color: "#A0A09E",
  },

  odd_input_box: {
    color: "#A0A09E",
    [theme.breakpoints.down(1536)]: {
      marginLeft: "65px",
    },
  },
});

const AddEditMeditation = (props) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const encodedData = query.get("data");
  const decodedData = decodeURIComponent(encodedData);
  const matches = useMediaQuery("(max-width:1440px)");
  const editRecords = JSON.parse(decodedData);

  const navigate = useNavigate();
  const theme = useTheme();
  const { action, tabs } = useParams();

  const classes = useCustomStyles(styles, theme);
  const [uploadType, setUploadType] = useState("");

  const [meditationObject, setMeditationObject] = useState({
    songName: "",
    audioFileUrl: "",
    songImageUrl: "",
    duration: "",
  });

  // state variable used to keep a track of the different behaviour of drag and drop and normal file upload
  const [toBeUploadedFileFormat, setToBeUploadedFileFormat] = useState({
    fileType: "",
    acceptedFileFormatForDragDrop: [],
    acceptedFileFormatForNormalFileUpload: [],
  });

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [uploadFileName, setUploadFileName] = useState("");
  const [uploadInterval, setUploadInterval] = useState(null);
  const [fileName, setFileName] = useState("");
  const [audioFileName, setAudioFileName] = useState("");
  const [fileError, setFileError] = useState("");
  const [audioError, setAudioError] = useState("");

  // Opens the file upload dialog for the specified type
  const handleOpen = (fileType, dragDropFileFormat, normalUploadFileFormat) => {
    setToBeUploadedFileFormat({
      ...toBeUploadedFileFormat,
      fileType: fileType,
      acceptedFileFormatForDragDrop: dragDropFileFormat,
      acceptedFileFormatForNormalFileUpload: normalUploadFileFormat,
    });
    setDialogOpen(true);
    setFileError("");
    setUploadType(fileType);
  };

  // Closes the progress dialog, stops the upload progress, and resets progress
  const handleProgressDialogClose = () => {
    setProgressDialogOpen(false);
    setUploadInProgress(false);
    clearInterval(uploadInterval); // Stop the upload interval
    setProgress(0); // Reset the progress
    if (uploadType === "JPEG") {
      setFileName("");
    } else if (uploadType === "MP3") {
      setAudioFileName("");
    }
  };

  // Handles file input change, sets the file name, and starts the upload process
  const handleFileChange = (event) => {
    const file = event.target ? event.target.files[0] : event;

    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      setUploadFileName(file.name);
      setMeditationObject({ ...meditationObject, songImageUrl: file });
      setFileName(file.name);
      setDialogOpen(false);
      handleProgressDialogOpen();
      uploadFile(file);
    } else if (file && file.type === "audio/mpeg") {
      setUploadFileName(file.name);
      setMeditationObject({ ...meditationObject, audioFileUrl: file });
      setAudioFileName(file.name);
      setDialogOpen(false);
      handleProgressDialogOpen();
      uploadFile(file);
    } else {
      alert("Please upload a valid file.");
    }
  };

  // Opens the progress dialog and sets upload status
  const handleProgressDialogOpen = () => {
    setProgressDialogOpen(true);
    setUploadInProgress(true);
  };

  // Simulates file upload progress
  const uploadFile = (file) => {
    setProgress(0);

    const UploadProgress = () => {
      let progress = 0;

      const interval = setInterval(() => {
        progress += 10;
        setProgress(progress);

        if (progress >= 110) {
          clearInterval(interval);
          setUploadInProgress(false);
          setProgressDialogOpen(false);
        }
      }, 300);

      setUploadInterval(interval); // Save the interval ID to stop it later
    };
    UploadProgress();
  };

  const [errors, setErrors] = useState(null);

  const validateForm = () => {
    const newErrors = {};
    if (!meditationObject.songName)
      newErrors.songName = "Song Name  is required.";
    if (!meditationObject.audioFileUrl)
      newErrors.audioFileUrl = "Audio File is required.";
    if (!meditationObject.songImageUrl)
      newErrors.songImageUrl = "Song Image is required.";
    if (meditationObject.duration <= 0)
      newErrors.duration = "Duration is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleMeditationUpload = (event) => {
    event.preventDefault();

    if (!validateForm()) return;

    setLoading(true);

    const formData = new FormData();

    formData.append("songName", meditationObject?.songName);
    formData.append("songDuration", meditationObject?.duration);
    formData.append("audioFileUrl", meditationObject?.audioFileUrl);
    formData.append("songImageUrl", meditationObject?.songImageUrl);

    if (action === "edit") {
      props.updateMedById(formData, editRecords.id);
      props.trackButtonClick("Update Meditation Button", 1);
    } else {
      props.createMeditation(formData);
      props.trackButtonClick("Create Meditaion Button", 1);
    }
  };

  const resetInputFieldsOnApiSuccess = () => {};

  // function that will be responsible for closing the dialog box
  const handleCloseSuccessBox = () => {
    props.showUnshowDialogMessage();
    navigate(`/content/${tabs}/view`);
  };

  useEffect(() => {
    setMeditationObject({ ...meditationObject, songName: "", duration: "" });
    setFileName("");
  }, [props?.successResponse]);

  useEffect(() => {
    if (action === "edit") {
      setMeditationObject({
        ...meditationObject,
        songName: editRecords?.songName,
        duration: editRecords?.songDuration,
        songImageUrl: editRecords.songImageUrl,

        audioFileUrl: editRecords.audioFileUrl,
      });

      setFileName(editRecords?.songImageUrl ? "thumbnail" : "");
      setAudioFileName(editRecords?.fileName);
    }
  }, []);

  return (
    <>
      {props?.successResponse && (
        <DialogBoxLayout
          dialogBoxToggle={props.successResponse}
          setDialogBoxToggle={handleCloseSuccessBox}
          header={
            action === "edit"
              ? "Updated Successfully!"
              : "Uploaded Successfully!"
          }
          content={
            action === "edit"
              ? "You Have Edited Your File Successfully"
              : "You Have Uploaded Your File Successfully"
          }
        />
      )}

      {dialogOpen && (
        <DialogBoxFileUploadLayout
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          uploadInProgress={uploadInProgress}
          progress={progress}
          handleFileChange={handleFileChange}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
        />
      )}

      {progressDialogOpen && (
        <ProgressDialogLayout
          progressDialogOpen={progressDialogOpen}
          handleProgressDialogClose={handleProgressDialogClose}
          toBeUploadedFileFormat={toBeUploadedFileFormat}
          uploadFileName={truncateAtDot(uploadFileName)}
          uploadInProgress={uploadInProgress}
          progress={progress}
        />
      )}

      <Grid
        xs={12}
        className={classes?.container}
        component="form"
        onSubmit={handleMeditationUpload}
      >
        <div>
          <Stack spacing={2} direction="column" gap={3}>
            <Stack
              spacing={2}
              direction="row"
              gap={3}
              sx={{ justifyContent: "space-evenly", alignItems: "center" }}
            >
              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mt: 4 }}
              >
                <TextField
                  className={classes?.input_box}
                  error={!!errors?.songName}
                  id="outlined-basic"
                  placeholder="Enter Title"
                  label="Title"
                  variant="outlined"
                  size={matches ? "small" : ""}
                  InputLabelProps={{ shrink: true }}
                  value={meditationObject?.songName}
                  onChange={(event) => {
                    setErrors(null);
                    setMeditationObject({
                      ...meditationObject,
                      songName: event.target.value,
                    });
                  }}
                />
                {errors?.songName && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.songName}
                  </span>
                )}
              </FormControl>

              <FormControl
                variant="outlined"
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 } }}
              >
                <TextField
                  className={classes?.input_box}
                  error={!!errors?.audioFileUrl}
                  id="outlined-basic"
                  placeholder="In mp3 Format"
                  label="Audio"
                  variant="outlined"
                  size={matches ? "small" : ""}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <>
                        <Button
                          aria-label="upload"
                          component="label"
                          className="addFileButton"
                          onClick={() => {
                            setErrors(null);
                            handleOpen("MP3", ["MP3"], [".mp3"]);
                          }}
                        >
                          Add File
                        </Button>
                      </>
                    ),
                  }}
                  value={truncateAtDot(audioFileName)}
                />
                {errors?.audioFileUrl && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.audioFileUrl}
                  </span>
                )}
              </FormControl>
            </Stack>
            <Stack
              spacing={2}
              direction="row"
              gap={3}
              sx={{ justifyContent: "space-evenly", alignItems: "center" }}
            >
              <FormControl
                variant="outlined"
                className={classes?.odd_input_box}
                sx={{ width: { xs: 200, md: 250, lg: 380, xl: 480 }, mb: 2 }}
              >
                <TextField
                  id="outlined-basic"
                  className={classes?.input_box}
                  error={!!errors?.songImageUrl}
                  placeholder="In JPEG\PNG Format"
                  label="Thumbnail Image"
                  variant="outlined"
                  size={matches ? "small" : ""}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <>
                        <Button
                          aria-label="upload"
                          component="label"
                          className="addFileButton"
                          onClick={() => {
                            setErrors(null);
                            handleOpen(
                              "JPEG",
                              ["JPEG", "PNG"],
                              [".jpeg", ".png"],
                            );
                          }}
                        >
                          Add File
                        </Button>
                      </>
                    ),
                  }}
                  value={truncateAtDot(fileName)}
                />
                {errors?.songImageUrl && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.songImageUrl}
                  </span>
                )}
              </FormControl>

              <FormControl
                variant="outlined"
                className={classes?.odd_input_box}
                sx={{
                  width: { xs: 200, md: 250, lg: 380, xl: 480 },
                  mb: 2,
                }}
              >
                <TextField
                  id="outlined-basic"
                  error={!!errors?.duration}
                  placeholder="Enter Song Duration"
                  label="Song Duration"
                  variant="outlined"
                  sx={{ backgroundColor: "white" }}
                  size={matches ? "small" : ""}
                  InputLabelProps={{ shrink: true }}
                  value={meditationObject?.duration}
                  onChange={(event) => {
                    setErrors(null);
                    setMeditationObject({
                      ...meditationObject,
                      duration: event.target.value,
                    });
                  }}
                />
                {errors?.duration && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors?.duration}
                  </span>
                )}
              </FormControl>
            </Stack>
          </Stack>

          <Grid xs={12} sx={{ mt: 3 }}>
            {props.loading ? (
              <LoaderLayout />
            ) : (
              <Button
                variant="contained"
                type="submit"
                startIcon={<FileUploadOutlined />}
                size="small"
                sx={{
                  backgroundColor: "#9E46E3",
                  textTransform: "none",
                  fontSize: matches ? "15px" : "20px",
                  fontWeight: "500",
                  marginLeft: { sm: 11, md: 52, lg: 87, xl: 112 },
                }}
              >
                {action === "edit" ? "Update" : "Upload"}
              </Button>
            )}
          </Grid>
        </div>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { dialogMessage, loading } = state.meditationReducer;

  return {
    successResponse: dialogMessage,
    loading,
  };
};

export default connect(mapStateToProps, {
  createMeditation,
  showUnshowDialogMessage,
  updateMedById,
})(withMixpanelTracking(AddEditMeditation, "Add Meditation"));
