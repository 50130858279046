// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.granth_container {
  padding: 50px;
  background-color: var(--primary-color);
  border-radius: 8px;
  height: 134%;
  width: 100%;
  margin-top: 300px;
}

.granth_button {
  background-color: var(--secondary-color);
  text-transform: none;
  font-size: 30px;
  font-weight: 500;
  height: 40px;
  width: 140px;
}

.granth_button_container {
  display: grid;
  width: 86%;
  justify-content: end;
  margin-top: 50px;
}

@media (max-width: 1440px) {
  .granth_container {
    padding: 30px;
    background-color: var(--primary-color);
    border-radius: 8px;
    height: 94%;
    width: 100%;
  }

  .granth_button {
    background-color: var(--secondary-color);
    text-transform: none;
    font-size: 16px;
    font-weight: 500;
    height: 30px;
    font-weight: 400;
    font-size: 12px;
    width: 120px;
  }

  .granth_button_container {
    display: grid;
    width: 98%;
    justify-content: end;
    margin-top: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/granth.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sCAAsC;EACtC,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,wCAAwC;EACxC,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,aAAa;EACb,UAAU;EACV,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;IACb,sCAAsC;IACtC,kBAAkB;IAClB,WAAW;IACX,WAAW;EACb;;EAEA;IACE,wCAAwC;IACxC,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,YAAY;EACd;;EAEA;IACE,aAAa;IACb,UAAU;IACV,oBAAoB;IACpB,gBAAgB;EAClB;AACF","sourcesContent":[".granth_container {\n  padding: 50px;\n  background-color: var(--primary-color);\n  border-radius: 8px;\n  height: 134%;\n  width: 100%;\n  margin-top: 300px;\n}\n\n.granth_button {\n  background-color: var(--secondary-color);\n  text-transform: none;\n  font-size: 30px;\n  font-weight: 500;\n  height: 40px;\n  width: 140px;\n}\n\n.granth_button_container {\n  display: grid;\n  width: 86%;\n  justify-content: end;\n  margin-top: 50px;\n}\n\n@media (max-width: 1440px) {\n  .granth_container {\n    padding: 30px;\n    background-color: var(--primary-color);\n    border-radius: 8px;\n    height: 94%;\n    width: 100%;\n  }\n\n  .granth_button {\n    background-color: var(--secondary-color);\n    text-transform: none;\n    font-size: 16px;\n    font-weight: 500;\n    height: 30px;\n    font-weight: 400;\n    font-size: 12px;\n    width: 120px;\n  }\n\n  .granth_button_container {\n    display: grid;\n    width: 98%;\n    justify-content: end;\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
