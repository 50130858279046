import React, { useEffect, useState } from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Button, Grid, Pagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useMediaQuery from "@mui/material/useMediaQuery";
import { connect } from "react-redux";
import MenuLayout from "../commonLayout/MenuLayout";
import withMixpanelTracking from "./commonComponent/withMixPanelTracking";
import {
  getLeaderboards,
  getTodayTask,
  getUploadStatus,
  getUserStats,
} from "../redux/dashboard/action";
import android from "../versionData/andriodVersionData.json";
import ios from "../versionData/iOSVersionData.json";

import "../../src/styles/dashboard.css";
import { getUserCount } from "../redux/usermanagement/action";

const Dashboard = (props) => {
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [paginatedData, setPaginatedData] = useState([]);
  const [count, setCount] = useState(0);
  const size = 5;
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token !== null && token) {
      props.getUploadStatus(); // to get the hukumnama status upload
      props.getTodayTask(); // to get todays tasks
      props.getLeaderboards();
      props.getUserStats();
      props.getUserCount();
    }
  }, [token]);

  useEffect(() => {
    const temp = [...props.leaderboards];
    temp.sort((a, b) => b.coinsEarned - a.coinsEarned);
    temp.forEach((obj, index) => {
      obj.id = index + 1;
      obj.level = Math.floor(obj.coinsEarned / 100);
    });
    const slicedArray = [...temp.slice(0, 25)];
    setCount(Math.ceil(slicedArray?.length / size));
    setTableData(slicedArray);
    setPaginatedData([...slicedArray.slice((page - 1) * size, page * size)]);
  }, [props.leaderboards]);

  useEffect(() => {
    setPaginatedData([...tableData.slice((page - 1) * size, page * size)]);
  }, [page]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const matches = useMediaQuery("(max-width:1280px)");

  return (
    <>
      <Box className="gnj_dashboard">
        <Box className="gnj_dashboard_dashboard">
          <Box className="dashboard-title">Dashboard</Box>
          <Box className="desc-title">GNJ</Box>
        </Box>
        <Box className="gnj_dashboard_content">
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { sm: "4px", md: "10px" },
                padding: { sm: "4px", md: "10px" },
              }}
            >
              <Box>
                <img
                  src="/gnj-dashboard.svg"
                  alt="gnj-dashboard"
                  className="gnj-logo"
                />
              </Box>
              <Box className="desc">
                Effortlessly manage and share daily Hukamnama, Kirtan, Nitnem,
                and inspirational content. Create practical guides and share
                community events. Our user-friendly tools ensure a respectful
                space for all to learn and grow together.
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="gnj_dashboard_task">
          {" "}
          {/* Ensure parent has a defined height */}
          <Box sx={{ height: "100%" }}>
            <Box className="today-task-title" sx={{ marginTop: "-30px" }}>
              User Completed Today’s Task
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                height: "100%",
              }}
            >
              {" "}
              {/* Set full height for column flex */}
              <Box className="today-task-box" sx={{ flex: 1 }}>
                {" "}
                {/* 1/3 height */}
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <Box className="today-task-icon">
                    <img
                      src="/meditation.svg"
                      alt="today-task"
                      className="today-task-image-1"
                    />
                  </Box>
                  <Box className="today-task-heading-title">Meditation</Box>
                </Box>
                <Box className="today-task-heading-stat">
                  {props.tasks?.data?.todayTask?.usersCompletedSelfReflection}
                </Box>
              </Box>
              <Box className="today-task-box" sx={{ flex: 1 }}>
                {" "}
                {/* 1/3 height */}
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <Box className="today-task-icon">
                    <img
                      src="/share.svg"
                      alt="share"
                      className="today-task-image-2"
                    />
                  </Box>
                  <Box className="today-task-heading-title">
                    Share to friend
                  </Box>
                </Box>
                <Box className="today-task-heading-stat">
                  {props.tasks?.data?.todayTask?.usersCompletedShareApp}
                </Box>
              </Box>
              <Box className="today-task-box" sx={{ flex: 1 }}>
                {" "}
                {/* 1/3 height */}
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <Box className="today-task-icon">
                    <img
                      src="/quiz.svg"
                      alt="quiz"
                      className="today-task-image-3"
                    />
                  </Box>
                  <Box className="today-task-heading-title">Quiz</Box>
                </Box>
                <Box className="today-task-heading-stat">
                  {props.tasks?.data?.todayTask?.usersCompletedQuiz}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="gnj_dashboard_active">
          <Box className="box">
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Box className="logo-box">
                <img
                  src="/active-user.svg"
                  alt="active-user"
                  className="logo-box-image"
                />
              </Box>
              <Box className="box-title">Active users</Box>
            </Box>
            <Box className="box-stat">{props.userStats?.activeUsers}</Box>
          </Box>
        </Box>
        <Box className="gnj_dashboard_inactive">
          <Box className="box">
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Box className="logo-box">
                <img
                  alt="inactive"
                  src="/inactive-user.svg"
                  className="logo-box-image"
                />
              </Box>
              <Box className="box-title">Inactive users</Box>
            </Box>
            <Box className="box-stat">{props.userStats?.inActiveUsers}</Box>
          </Box>
        </Box>
        <Box className="gnj_dashboard_sub">
          <Box sx={{ height: "100%" }}>
            <Box className="box">
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                  alignItems: "center",
                }}
              >
                <Box className="logo-box">
                  <img
                    alt="sub-admin"
                    src="/sub-admin-user.svg"
                    className="logo-box-image"
                  />
                </Box>
                <Box className="box-title" sx={{ fontSize: "16px" }}>
                  Sub admin users
                </Box>
              </Box>
              <Box className="box-stat">
                {props.counts?.totalSubAdminUsers?.totalSubAdminCount}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="gnj_dashboard_version">
          <Box className="box-4">
            <Box className="box-title">Current app version</Box>
            <Box className="box-version">
              <Box>
                <Box className="version-title">{android[0]?.app_Version}</Box>
                <Box className="version-content">Android</Box>
              </Box>
              <Box>
                <Box className="version-title">{ios[0].app_Version}</Box>
                <Box className="version-content">iOS</Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="gnj_dashboard_daily">
          <Box className="daily-task">
            <Box className="daily-task-heading">
              <Box className="daily-task-title">Daily Task</Box>
              <Box>
                {!props?.uploadStatus?.data ? (
                  <Button
                    variant="contained"
                    sx={{
                      background: "rgba(221, 158, 35, 1)",
                      "&.MuiButtonBase-root:hover": {
                        bgcolor: "rgba(221, 158, 35, 1)",
                      },
                      textTransform: "none",
                    }}
                    className="btn-text"
                    endIcon={
                      <CheckCircleIcon
                        sx={{ color: "rgba(227, 227, 227, 1)" }}
                        fontSize="24px"
                        className="status_btn"
                      />
                    }
                  >
                    Pending
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      background: "rgba(255, 255, 255, 1)",
                      color: "rgba(30, 30, 30, 1)",
                      "&.MuiButtonBase-root:hover": {
                        bgcolor: "transparent",
                        color: "black",
                      },
                      textTransform: "none",
                    }}
                    className="btn-text"
                    endIcon={
                      <CheckCircleIcon
                        fontSize="24px"
                        sx={{ color: "rgba(77, 164, 90, 1)" }}
                        className="status_btn"
                      />
                    }
                  >
                    Completed
                  </Button>
                )}
              </Box>
            </Box>
            <Box className="daily-task-content">
              Today's Hukamnama in respective languages (English, Hindi,
              Punjabi)
            </Box>
          </Box>
        </Box>
        <Box className="gnj_dashboard_top">
          <Box sx={{ height: "100%" }}>
            <Box className="leaderboard-title">Top 25 users</Box>
            <Box sx={{ height: "100%" }}>
              <TableContainer
                component={Paper}
                sx={{ height: "75%", fontSize: "10px !important" }}
              >
                <Table aria-label="a dense table" sx={{ height: "100%" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          backgroundColor: "rgba(249, 242, 255, 1) !important",
                          height: "20% !important",
                          padding: "4px !important",
                        }}
                        align="left"
                      >
                        Rank
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          backgroundColor: "rgba(249, 242, 255, 1) !important",
                          height: "20% !important",
                          padding: "4px !important",
                        }}
                      >
                        User Name
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          backgroundColor: "rgba(249, 242, 255, 1) !important",
                          height: "20% !important",
                          padding: "4px !important",
                        }}
                      >
                        Level
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ position: "relative" }}>
                    {paginatedData.length > 0 &&
                      paginatedData.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{
                              color: "rgba(16, 16, 21, 1)",
                              fontWeight: "700 !important",
                              fontSize: "24px",
                              padding: "4px !important",
                              height: "12px !important",
                            }}
                          >
                            {row.id}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              height: "12px !important",
                              padding: "4px !important",
                            }}
                          >
                            {row.userName}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              height: "12px !important",
                              padding: "4px !important",
                            }}
                          >
                            {row.level}
                          </TableCell>
                        </TableRow>
                      ))}
                    {paginatedData?.length === 0 && (
                      <Box
                        sx={{
                          display: "grid",
                          justifyContent: "center",
                          textAlign: "center",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <img
                            src="/mailbox.png"
                            alt="No data"
                            style={{ width: "30px", height: "30px" }}
                          />
                        </Box>
                        <Box sx={{}}>No data</Box>
                      </Box>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {count ? (
                <Box
                  sx={{
                    display: "flex",
                    margin: "auto",
                    marginTop: "10px",
                    alignItems: "stretch",
                    justifyContent: "center",
                  }}
                >
                  <Pagination
                    count={count}
                    page={page}
                    size={matches ? "small" : "large"}
                    onChange={handleChange}
                  />
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  const { uploadStatus, tasks, leaderboards, userStats } =
    state.dashboardReducer;

  const { counts } = state.userReducer;

  return {
    tasks,
    leaderboards,
    uploadStatus,
    userStats,
    counts,
  };
};

const DashboardComp = connect(mapStateToProps, {
  getUploadStatus,
  getTodayTask,
  getLeaderboards,
  getUserStats,
  getUserCount,
})(withMixpanelTracking(Dashboard, "DashBoard Screen"));

const wrapper = () => {
  return (
    <MenuLayout
      childComponent={() => {
        return <DashboardComp />;
      }}
    />
  );
};

export default wrapper;
