import {
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  getRequestdetails,
  getRequestByStatus,
  updateRequestByStatus,
} from "../../redux/delete-request/action";
import "../../styles/delete.css";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import TableTooltip from "../commonComponent/tableTooltip";
import useCustomStyles from "../../customHooks/useCustomStyles";

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth: "thin",
    [theme.breakpoints.down(1400)]: {
      maxHeight: 230,
    },
    [theme.breakpoints.between(1401, 1919)]: {
      maxHeight: 350,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 400,
    },
  },
});

const Rejected = (props) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const [tableList, setTableList] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const tableContainerRef = useRef(null);

  const handleChange = (_, value) => {
    setPage(value);

    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    const formData = new FormData();
    formData.append("searchTerm", props.searchTerm);
    formData.append("status", "REJECTED");
    if (props.searchTerm) {
      formData.append("page", 0);
    } else {
      formData.append("page", page - 1);
    }
    formData.append("size", 10);

    const queryParams = new URLSearchParams(formData).toString();

    props.getRequestByStatus(`?${queryParams}`);
  }, [props.updateByStatusSuccess, page, props.searchTerm]);

  useEffect(() => {
    if (props.getByStatusSuccess || props.updateByStatusSuccess) {
      if (props?.DeleteRByStatusData) {
        setTableList(props.DeleteRByStatusData);

        setCount(props.page);
      }
    }
  }, [props.getByStatusSuccess, props.updateByStatusSuccess, page]);
  return (
    <div>
      <TableContainer
        component={Paper}
        elevation={0}
        className={classes?.table}
        ref={tableContainerRef}
      >
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="first table">
          <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
            <TableRow className="view_delete_table_row">
              <TableCell className="view_delete_cell">ID</TableCell>
              <TableCell className="view_delete_cell">Name</TableCell>
              <TableCell className="view_delete_cell">Gender</TableCell>
              <TableCell className="view_delete_cell">Email</TableCell>
              <TableCell className="view_delete_cell">Phone no.</TableCell>
              <TableCell className="view_delete_cell">Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableList?.length > 0 &&
              tableList.map((row, index) => (
                <TableRow
                  key={index}
                  className="view_delete_table_row"
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell className="view_delete_cell">{row.id}</TableCell>
                  <TableCell className="view_delete_cell">
                    {row.fullName}
                  </TableCell>
                  <TableCell className="view_delete_cell">
                    {row.gender}
                  </TableCell>
                  <TableCell className="view_delete_cell">
                    {row.email}
                  </TableCell>
                  <TableCell className="view_delete_cell">
                    {row.phoneNumber}
                  </TableCell>
                  <TableCell className="view_delete_cell">
                    <TableTooltip title={row.reasons} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {tableList?.length === 0 && (
        <Box
          sx={{
            display: "grid",
            justifyContent: "center",
            textAlign: "center",
            marginTop: "30px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <img
              src="/mailbox.png"
              alt="No data"
              style={{ width: "30px", height: "30px" }}
            />
          </Box>
          <Box sx={{}}>No data</Box>
        </Box>
      )}
      {count && tableList.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            marginTop: "16px",
          }}
        >
          <Pagination
            count={count}
            page={page}
            size="large"
            onChange={handleChange}
          />
        </Box>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    getByStatusSuccess,

    updateByStatusSuccess,
    DeleteRByStatusData,
    page,
  } = state.deleteRequestReducer;
  return {
    getByStatusSuccess,
    updateByStatusSuccess,
    DeleteRByStatusData,
    page,
  };
};

export default connect(mapStateToProps, {
  getRequestdetails,
  getRequestByStatus,
  updateRequestByStatus,
})(withMixpanelTracking(Rejected, " Rejected Delete Request"));
