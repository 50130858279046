import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import "../../src/styles/account.css";
import { apiClient } from "../apiServices/AxiosClient";
import AddIcon from "../assets/images/AddIcon.svg";
import withMixpanelTracking from "../components/commonComponent/withMixPanelTracking";
import { validatePhoneNumber } from "../helpers/InputValidation";
import { showSnackbar } from "../redux/snackbar/action";
import { getUserProfile } from "../redux/usermanagement/action";
import {
  createProfile,
  showUnshowDialogMessage,
  updateUserProfile,
} from "../redux/userprofile/action";

const NavBarLayout = (props) => {
  const matches = useMediaQuery("(max-width:1440px)");
  const [image, setImage] = useState(() => {
    let data = localStorage.getItem("profileResponse");

    if ((data !== "null" || data !== null) && data) {
      let res = JSON.parse(data);
      return res !== "null" || res !== null
        ? res?.data?.profilePicturePath
        : "";
    }
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [imgageUpload, setImageUpload] = useState(() => {
    let data = localStorage.getItem("profileResponse");
    if ((data !== "null" || data !== null) && data) {
      let res = JSON.parse(data);
      return res !== "null" || res !== null
        ? res?.data?.profilePicturePath
        : "";
    }
  });
  const [profileData, setProfileData] = useState(() => {
    let data = localStorage.getItem("profileResponse");

    if ((data !== "null" || data !== null) && data) {
      let res = JSON.parse(data);
      return {
        fullName: res?.data?.fullName || "",
        email: res?.data?.email || "",
        countryCode: res?.data?.countryCode || "+91",
        phoneNumber: res?.data?.phoneNumber || "",
        address: res?.data?.address || "",
        profilePicture: res?.data?.profilePicturePath || "",
        role: res?.data?.role || "Admin",
      };
    } else {
      return {
        fullName: "",
        email: "",
        countryCode: "+91",
        phoneNumber: "",
        address: "",
        profilePicture: "",
        role: "Admin",
      };
    }
  });

  const [res, setRes] = useState(() => {
    let data = localStorage.getItem("profileResponse");
    if ((data !== "null" || data !== null) && data) {
      let res = JSON.parse(data);
      return res ? res : null;
    }
  });
  const [editableFields, setEditableFields] = useState({
    fullName: false,
    address: false,
    phoneNumber: false,
  });

  const [errors, setErrors] = useState({
    fullName: false,
    fullNameLength: false,
    address: false,
    phoneNumber: false,
    email: false,
  });

  const [disableUpdateButton, setDisableUpdateButton] = useState(true);

  useEffect(() => {
    localStorage.removeItem("profileData");
    localStorage.removeItem("profileResponse");
    async function fetchProfileData() {
      try {
        const res = await apiClient.get("user/profiles");
        if (res?.status === 200) {
          localStorage.setItem("profileData", true);
          localStorage.setItem("profileResponse", JSON.stringify(res));
          setRes(res);
          setProfileData({
            fullName: res?.data?.fullName || "",
            email: res?.data?.email || "",
            countryCode: res?.data?.countryCode || "+91",
            phoneNumber: res?.data?.phoneNumber || "",
            address: res?.data?.address || "",
            profilePicture: res?.data?.profilePicturePath || "",
            role: res?.data?.role || "Admin",
          });
          setImageUpload(res?.data?.profilePicturePath || "");
          setImage(res?.data?.profilePicturePath || "");
        } else {
          localStorage.setItem("profileResponse", null);
          localStorage.setItem("profileData", false);
          props.showSnackbar({
            message: res?.message,
            severity: "error",
          });
        }
      } catch (err) {
        localStorage.setItem("profileResponse", null);
        localStorage.setItem("profileData", false);
        props.showSnackbar({
          message:
            err.message || "An error occurred while fetching profile data.",
          severity: "error",
        });
      }
    }
    fetchProfileData();
  }, [props.successResponse]);

  const handleChange = (e) => {
    setErrors({
      fullName: false,
      address: false,
      phoneNumber: false,
      email: false,
    });
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditClick = (field) => {
    setEditableFields({
      ...editableFields,
      [field]: !editableFields[field],
    });
    setDisableUpdateButton(false);
    props.trackButtonClick("Edit Profile Button", 1);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const imageUrl = URL.createObjectURL(file);
    setImage(imageUrl);
    setProfileData({
      ...profileData,
      profilePicture: file,
    });
    setDisableUpdateButton(false);

    return () => URL.revokeObjectURL(imageUrl);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newErrors = {
      fullName: !profileData.fullName,
      fullNameLength: profileData.fullName.length > 30,
      address: !profileData.address,
      phoneNumber: !validatePhoneNumber(profileData.phoneNumber),
      email: !/\S+@\S+\.\S+/.test(profileData.email),
    };

    setErrors(newErrors);

    if (Object.values(newErrors).includes(true)) {
      return;
    }
    if (!Object.values(newErrors).includes(true)) {
      const formData = new FormData();
      formData.append("profilePicture", profileData.profilePicture);
      formData.append("address", profileData.address);
      formData.append("fullName", profileData.fullName);
      formData.append("email", profileData.email);
      formData.append("countryCode", profileData.countryCode);
      formData.append("phoneNumber", profileData.phoneNumber);

      props.updateUserProfile(formData);

      props.trackButtonClick("Update Profile Button", 1);
      setOpenDialog(false);
    }
    setEditableFields({
      fullName: false,
      address: false,
      phoneNumber: false,
    });
    setDisableUpdateButton(true);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    props.trackButtonClick("View Profile Button", 1);
  };

  const handleCloseDialog = () => {
    setProfileData({
      fullName: res.data?.fullName || "",
      email: res.data?.email || "",
      countryCode: res.data?.countryCode || "+91",
      phoneNumber: res.data?.phoneNumber || "",
      address: res.data?.address || "",
      profilePicture: res.data?.profilePicturePath || "",
      role: res.data?.role || "Admin",
    });
    setDisableUpdateButton(true);
    setImageUpload(res.data?.profilePicturePath || "");
    setImage(res.data?.profilePicturePath || "");
    setEditableFields({
      fullName: false,
      address: false,
      phoneNumber: false,
    });
    setErrors({
      fullName: false,
      fullNameLength: false,
      address: false,
      phoneNumber: false,
      email: false,
    });
    setOpenDialog(false);
  };

  const anchorRef = useRef(null);

  useEffect(() => {
    props.getUserProfile();
  }, [props.successResponse]);

  return (
    <>
      <div className="navbarMain">
        {/* Navbar section */}
        <Grid sx={{ display: "flex", alignItems: "center" }}>
          <h2 className="greeting">Sat Sri Akaal</h2>
          <h2 className="greeting-logo" style={{}}>
            &#128591;
          </h2>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          ref={anchorRef}
        >
          <Avatar
            sx={{
              bgcolor: "rgba(158, 70, 227, 1)",
              cursor: "pointer",
              mr: "10px",
            }}
            alt="user-profile-image"
            onClick={handleOpenDialog}
            src={imgageUpload}
            className="user_image"
          >
            {profileData?.fullName?.charAt(0)}
          </Avatar>
          <div>
            <p className="user_name">{profileData?.fullName}</p>
          </div>
        </Grid>
      </div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        sx={{ borderRadius: "8px" }}
      >
        <DialogTitle sx={{ m: 0, p: 2, mt: "2%" }}>
          <Typography
            fontFamily="Inter"
            sx={{
              fontSize: {
                lg: "18px",
                xl: "28px",
                fontWeight: "700",
              },
            }}
          >
            My Profile
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: "2%",
              top: "3%",
              ":hover": { backgroundColor: "#82858c" },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box
            justifyContent={"center"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: { lg: "400px", xl: "500px" },
              maxWidth: { lg: "400px", xl: "500px" },
              p: {
                lg: 1,
                xl: 4,
              },
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <Stack
              spacing={{ lg: 1, xl: 2 }}
              direction="column"
              gap={{ lg: 1, xl: 3 }}
            >
              <Stack
                spacing={{ lg: 1, xl: 3 }}
                direction="column"
                gap={{ lg: 1, xl: 3 }}
              >
                <FormControl
                  variant="outlined"
                  sx={{
                    width: "auto",
                    height: "auto",
                  }}
                >
                  <Grid xs={12} xl={2}>
                    <Box className="account__content-avatar">
                      {image && (
                        <img
                          src={image}
                          alt="user avatar"
                          className="account__content-image"
                        />
                      )}
                      <Box className="account__content-plus">
                        <img
                          src={AddIcon}
                          alt="plus icon"
                          className="account__content-plusIcon"
                        />
                        <input
                          type="file"
                          className="account__content-upload"
                          onChange={handleImageUpload}
                          title="Edit profile picture"
                        />
                      </Box>
                    </Box>
                  </Grid>
                </FormControl>
                <Stack
                  spacing={{ lg: 1, xl: 2 }}
                  direction="column"
                  gap={{ lg: 2, xl: 3 }}
                >
                  <TextField
                    fullWidth
                    required
                    label="Name"
                    placeholder="Enter Name"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    sx={{ backgroundColor: "white", marginBottom: "20px" }}
                    name="fullName"
                    value={profileData?.fullName}
                    disabled={!editableFields.fullName}
                    onChange={handleChange}
                    size={matches ? "small" : ""}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          aria-label="edit"
                          component="label"
                          onClick={() => handleEditClick("fullName")}
                        >
                          <EditOutlinedIcon
                            sx={{
                              color: !editableFields.fullName
                                ? "#9F9F9C"
                                : "#000000",
                            }}
                          />
                        </IconButton>
                      ),
                    }}
                    className="input_box"
                    error={errors.fullName || errors.fullNameLength}
                    helperText={
                      (errors.fullName && "Name is required.") ||
                      (errors.fullNameLength &&
                        "Name should contain at max 30 characters.")
                    }
                  />

                  <TextField
                    fullWidth
                    required
                    label="Email"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    sx={{ backgroundColor: "white", marginBottom: "20px" }}
                    name="email"
                    value={profileData?.email}
                    disabled
                    size={matches ? "small" : ""}
                    InputProps={{
                      readOnly: true,
                    }}
                    className="input_box"
                    error={errors.email}
                    helperText={errors.email && "Invalid email address."}
                  />

                  <TextField
                    fullWidth
                    required
                    label="Address"
                    placeholder="Enter Address"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    sx={{ backgroundColor: "white", marginBottom: "20px" }}
                    name="address"
                    size={matches ? "small" : ""}
                    value={profileData?.address}
                    disabled={!editableFields.address}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          aria-label="edit"
                          component="label"
                          onClick={() => handleEditClick("address")}
                        >
                          <EditOutlinedIcon
                            sx={{
                              color: !editableFields.address
                                ? "#9F9F9C"
                                : "#000000",
                            }}
                          />
                        </IconButton>
                      ),
                    }}
                    className="input_box"
                    error={errors.address}
                    helperText={errors.address && "Address is required."}
                  />
                </Stack>

                <Stack
                  spacing={{ lg: 1, xl: 2 }}
                  direction="row"
                  gap={{ lg: 2, xl: 3 }}
                >
                  <FormControl variant="outlined" sx={{ width: "35%", mt: 4 }}>
                    <TextField
                      fullWidth
                      required
                      label="Role"
                      size={matches ? "small" : ""}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      sx={{ backgroundColor: "white", marginBottom: "20px" }}
                      name="role"
                      value={profileData.role}
                      disabled
                      InputProps={{
                        readOnly: true,
                      }}
                      className="input_box"
                    />
                  </FormControl>

                  <FormControl
                    variant="outlined"
                    sx={{ width: "65%", mt: 4 }}
                    error={errors.phoneNumber}
                  >
                    <TextField
                      fullWidth
                      required
                      label="Phone no."
                      size={matches ? "small" : ""}
                      variant="outlined"
                      name="phoneNumber"
                      value={profileData.phoneNumber}
                      onChange={handleChange}
                      sx={{ backgroundColor: "white" }}
                      InputLabelProps={{ shrink: true }}
                      disabled={!editableFields.phoneNumber}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span
                              style={{
                                marginRight: "8px",
                                borderRight: "1px solid #ccc",
                                paddingRight: "10px",
                              }}
                            >
                              +91
                            </span>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="edit"
                              component="label"
                              onClick={() => handleEditClick("phoneNumber")}
                            >
                              <EditOutlinedIcon
                                sx={{
                                  color: !editableFields.phoneNumber
                                    ? "#9F9F9C"
                                    : "#000000",
                                }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={errors.phoneNumber}
                    />
                    {errors.phoneNumber && (
                      <FormHelperText>
                        Please enter a valid 10-digit phone number.
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  gap={3}
                  justifyContent="center"
                >
                  <Button
                    className="profile_update_button"
                    variant="contained"
                    justifyContent="flex-end"
                    disabled={disableUpdateButton}
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  const { userProfile } = state.userReducer;
  const { dialogMessage } = state.profileReducer;
  return {
    userProfile,
    successResponse: dialogMessage,
  };
};

export default connect(mapStateToProps, {
  createProfile,
  updateUserProfile,
  showUnshowDialogMessage,
  getUserProfile,
  showSnackbar,
})(withMixpanelTracking(NavBarLayout, "Navbar"));
