export const GET_VOLUNTEER_DETAILS = "GET_VOLUNTEER_DETAILS";
export const GET_VOLUNTEER_SUCCESS_DETAILS = "GET_VOLUNTEER_SUCCESS_DETAILS";
export const GET_VOLUNTEER_FAILURE_DETAILS = "GET_VOLUNTEER_FAILURE_DETAILS";

export const GET_VOLUNTEER_DOWNLOAD = "GET_VOLUNTEER_DOWNLOAD";
export const GET_VOLUNTEER_DOWNLOAD_SUCCESS = "GET_VOLUNTEER_DOWNLOAD_SUCCESS";
export const GET_VOLUNTEER_DOWNLOAD_FAILURE = "GET_VOLUNTEER_DOWNLOAD_FAILURE";
export const GET_VOLUNTEER_BY_STATUS = "GET_VOLUNTEER_BY_STATUS";
export const GET_VOLUNTEER_BY_STATUS_SUCCESS =
  "GET_VOLUNTEER_BY_STATUS_SUCCESS";
export const GET_VOLUNTEER_BY_STATUS_FAILURE =
  "GET_VOLUNTEER_BY_STATUS_FAILURE";

export const UPDATE_VOLUNTEER_BY_STATUS = "UPDATE_VOLUNTEER_BY_STATUS";
export const UPDATE_VOLUNTEER_BY_STATUS_SUCCESS =
  "UPDATE_VOLUNTEER_BY_STATUS_SUCCESS";
export const UPDATE_VOLUNTEER_BY_STATUS_FAILURE =
  "UPDATE_VOLUNTEER_BY_STATUS_FAILURE";

export const GET_VOLUNTEER_LIST = "GET_VOLUNTEER_LIST";
export const GET_VOLUNTEER_LIST_SUCCESS = "GET_VOLUNTEER_LIST_SUCCESS";
export const GET_VOLUNTEER_LIST_FAILURE = "GET_VOLUNTEER_LIST_FAILURE";
export const VOL_PAGE_COUNT = "VOL_PAGE_COUNT";

export const getVolunteerdetails = (payload) => {
  return {
    type: GET_VOLUNTEER_DETAILS,
    payload,
  };
};

export const getPagecount = (data) => {
  return {
    type: VOL_PAGE_COUNT,
    payload: data,
  };
};

export const getVolunteerdetailsSuccess = (data, page) => {
  return {
    type: GET_VOLUNTEER_SUCCESS_DETAILS,
    payload: data,
    page,
  };
};
export const getVolunteerdetailsFail = (error) => {
  return {
    type: GET_VOLUNTEER_FAILURE_DETAILS,
    payload: error,
  };
};

export const getVolunteerDownloads = (status, id) => {
  return {
    type: GET_VOLUNTEER_DOWNLOAD,
    payload: { status, id },
  };
};

export const getVolunteerDownloadSuccess = (data) => {
  return {
    type: GET_VOLUNTEER_DOWNLOAD_SUCCESS,
    payload: data,
  };
};

export const getVolunteerDownloadFailure = (error) => {
  return {
    type: GET_VOLUNTEER_DOWNLOAD_FAILURE,
    payload: error,
  };
};

export const getVolunteerList = (id) => {
  return { type: GET_VOLUNTEER_LIST, payload: id };
};
export const getVolunteerListSuccess = (data, page) => {
  return { type: GET_VOLUNTEER_LIST_SUCCESS, payload: data, page };
};
export const getVolunteerListFail = (err) => {
  return { type: GET_VOLUNTEER_LIST_FAILURE, payload: err };
};

export const getVolunteerByStatus = (id) => {
  return { type: GET_VOLUNTEER_BY_STATUS, payload: id };
};
export const getVolunteerByStatusSuccess = (data) => {
  return { type: GET_VOLUNTEER_BY_STATUS_SUCCESS, payload: data };
};
export const getVolunteerByStatusFail = (err) => {
  return { type: GET_VOLUNTEER_BY_STATUS_FAILURE, payload: err };
};

export const updateVolunteerByStatus = (id, status) => {
  return { type: UPDATE_VOLUNTEER_BY_STATUS, id, status };
};
export const updateVolunteerByStatusSuccess = () => {
  return { type: UPDATE_VOLUNTEER_BY_STATUS_SUCCESS };
};
export const updateVolunteerByStatusFail = (err) => {
  return { type: UPDATE_VOLUNTEER_BY_STATUS_FAILURE, payload: err };
};
