import { call, put, takeEvery } from "redux-saga/effects";
import {
  createGurudwara,
  deleteGurudwara,
  getAllGurudwara,
  updateGurudwara,
  getGurudwaraCount,
  updateGurudwaraStatus,
  getAllGurudwaraWithoutSearch,
} from "../../apiServices/gurudwara";
import {
  CREATE_GURUDWARA,
  createGurudwaraFailure,
  createGURUDWARASuccess,
  DELETE_GURUDWARA,
  deleteGurudwaraFailure,
  deleteGURUDWARASuccess,
  GET_GURUDWARA,
  getAllGURUDWARAFailure,
  getAllGURUDWARASuccess,
  UPDATE_GURUDWARA,
  UPDATE_GURUDWARA_STATUS,
  updateGurudwaraFailure,
  updateGURUDWARAStatusSuccess,
  updateGurudwaraStatusFailure,
  updateGURUDWARASuccess,
  getGurudwaraCountFailure,
  getGurudwaraCountSuccess,
  GET_GURUDWARA_COUNT,
  GET_GURUDWARA_WITHOUT_SEARCH,
  getAllGURUDWARAWithoutSearchSuccess,
  getAllGURUDWARAWithoutSearchFailure,
} from "./action";
import { showSnackbar } from "../snackbar/action";
import { getRequest } from "../../apiServices/ApiActions";

const createCall = async (payload) => {
  return createGurudwara(payload);
};
const getCall = async (payload) => {
  return getAllGurudwara(payload);
};
const updateCall = async (payload) => {
  return updateGurudwara(payload);
};
const updateStatusCall = async (id, status) => {
  return updateGurudwaraStatus(id, status);
};
const deleteCall = async (payload) => {
  return deleteGurudwara(payload);
};
const getCount = async () => {
  return getGurudwaraCount();
};
const getGurudwara = async (payload) => {
  return getAllGurudwara(payload);
};

function* createGURUDWARA(action) {
  try {
    const result = yield call(createCall, action.payload);
    if (result.status === 200) {
      yield put(createGURUDWARASuccess(result));
    } else {
      yield put(createGurudwaraFailure(result?.response?.data));
    }
  } catch (error) {
    yield put(createGurudwaraFailure(error?.response?.data));
  }
}
function* getGURUDWARA(action) {
  try {
    const result = yield call(getCall, action.payload);

    if (result.status === 200) {
      yield put(
        getAllGURUDWARASuccess(result?.data?.content, result?.data?.page),
      );
    } else {
      yield put(getAllGURUDWARAFailure(result?.data));
    }
  } catch (error) {
    yield put(getAllGURUDWARAFailure(error?.data));
  }
}
function* getGURUDWARAWithoutSearch(action) {
  try {
    const result = yield call(getGurudwara, action.payload);

    if (result.status === 200) {
      yield put(
        getAllGURUDWARAWithoutSearchSuccess(
          result?.data?.content,
          result?.data?.page,
        ),
      );
    } else {
      yield put(getAllGURUDWARAWithoutSearchFailure(result?.data));
    }
  } catch (error) {
    yield put(getAllGURUDWARAWithoutSearchFailure(error?.data));
  }
}
function* getgurudwaraCount(action) {
  try {
    const result = yield call(getCount, action.payload);

    if (result.status === 200) {
      yield put(getGurudwaraCountSuccess(result?.data));
    } else {
      yield put(getGurudwaraCountFailure(result?.response?.data));
    }
  } catch (error) {
    yield put(getGurudwaraCountFailure(error?.response?.data));
  }
}

function* updateGURUDWARAStatus(action) {
  try {
    const result = yield call(updateStatusCall, action.id, action.status);

    if (result.status === 200) {
      yield put(updateGURUDWARAStatusSuccess(result));

      yield put(
        showSnackbar({
          message: `${action.rowName} ${action.status === false ? "Deactivated" : "Activated"} successfully.`,
          severity: "success",
        }),
      );
    } else {
      yield put(updateGurudwaraStatusFailure(result?.response?.data));
    }
  } catch (error) {
    yield put(updateGurudwaraStatusFailure(error?.response?.data));
  }
}
function* updateGURUDWARA(action) {
  try {
    const result = yield call(updateCall, action.payload, action.id);

    if (result.status === 200) {
      yield put(updateGURUDWARASuccess(result));
    } else {
      yield put(updateGurudwaraFailure(result?.response?.data));
    }
  } catch (error) {
    yield put(updateGurudwaraFailure(error?.response?.data));
  }
}
function* deleteGURUDWARA(action) {
  try {
    const result = yield call(deleteCall, action.payload);
    if (result.status === 204 || result.status === 200) {
      yield put(deleteGURUDWARASuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(deleteGurudwaraFailure(res));
    }
  } catch (error) {
    yield put(deleteGurudwaraFailure(error?.response?.data));
  }
}

function* gurudwaraSaga() {
  yield takeEvery(GET_GURUDWARA, getGURUDWARA);
  yield takeEvery(GET_GURUDWARA_WITHOUT_SEARCH, getGURUDWARAWithoutSearch);
  yield takeEvery(UPDATE_GURUDWARA, updateGURUDWARA);
  yield takeEvery(CREATE_GURUDWARA, createGURUDWARA);
  yield takeEvery(DELETE_GURUDWARA, deleteGURUDWARA);
  yield takeEvery(GET_GURUDWARA_COUNT, getgurudwaraCount);
  yield takeEvery(UPDATE_GURUDWARA_STATUS, updateGURUDWARAStatus);
}
export default gurudwaraSaga;
