import { useEffect, useRef, useState } from "react";
import {
  Grid,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableSortLabel,
  useTheme,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteDialogLayout from "../../commonLayout/DeleteDialogLayout";
import Paginate from "../commonComponent/Pagination";
import {
  deleteGranth,
  getGranthdetails,
  deleteToggleModal,
} from "../../redux/granth/action";
import { formatDateInAlphanumericFormat } from "../../helpers/dateHelper";
import { connect } from "react-redux";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import useCustomStyles from "../../customHooks/useCustomStyles";

const LanguageList = [
  { langName: "HINDI", LangCode: "hi_IN" },
  { langName: "ENGLISH", LangCode: "en_US" },
  { langName: "PUNJABI", LangCode: "pa_IN" },
];

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth: "thin",
    [theme.breakpoints.down(1400)]: {
      maxHeight: 240,
    },
    [theme.breakpoints.between(1401, 1919)]: {
      maxHeight: 390,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 500,
    },
  },

  icon_size: {
    [theme.breakpoints.down(1400)]: {
      height: "25px",
      width: "25px",
    },
    [theme.breakpoints.between(1401, 1919)]: {
      height: "30px",
      width: "30px",
    },
  },
});

const ViewGranth = (props) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const navigate = useNavigate();
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [paginationObject, setPaginationObject] = useState({
    page: 1,
    rowsPerPage: 10,
    totalRowCount: 0,
  });
  const [orderDirection, setOrderDirection] = useState("asc");
  const [sortedData, setSortedData] = useState([]);
  const [comparetableRecords, setCompareTableRecords] = useState([]);
  const tableContainerRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setPaginationObject({ ...paginationObject, page: newPage });
    props.getGranthdetails(newPage);

    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  };

  const setGranthDataWithPagination = (loading) => {
    if (!loading && props?.granthData) {
      const { content, page } = props?.granthData;

      props.setGranthList(content);
      props.setRecordsList(content);
      setSortedData(content);
      setCompareTableRecords(content);
      setPaginationObject({
        ...paginationObject,
        totalRowCount: page?.totalPages,
      });
    }
  };

  const handleSortRequest = () => {
    if (sortedData?.length > 0) {
      const isAsc = orderDirection === "asc";
      const sorted = [...sortedData].sort((a, b) =>
        isAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name),
      );
      setOrderDirection(isAsc ? "desc" : "asc");
      setSortedData(sorted);
    }
  };

  useEffect(() => {
    setSortedData(props.granthList);
  }, [props?.granthList]);

  useEffect(() => {
    props.getGranthdetails(paginationObject?.page);
  }, [props.getGranthdetails]);

  useEffect(() => {
    setGranthDataWithPagination(props.loading);
  }, [props.loading]);

  useEffect(() => {
    if (props.granthList?.length !== comparetableRecords?.length) {
      setPaginationObject({
        ...paginationObject,
        totalRowCount: 1,
      });
    } else {
      setGranthDataWithPagination(false);
    }
  }, [props.granthList]);

  useEffect(() => {
    if (props.deleteGranthsucces || props.updateGranthSuccess) {
      props.getGranthdetails(paginationObject?.page);
      setRowData([]);
    }
  }, [props.deleteGranthsucces, props.updateGranthSuccess]);

  const handleDeleteData = (value) => {
    setDeleteToggle(!deleteToggle);
    setRowData(value);
  };

  const deleteRowData = (value) => {
    props.deleteGranth(value.id);
    props.trackButtonClick("Delete Granth Button", 1, value?.id);
  };
  const handleRowEdit = (val) => {
    const jsonData = JSON.stringify(val);

    const encodedData = encodeURIComponent(jsonData);
    navigate(`/content/granth/edit?data=${encodedData}`);
  };

  const [openSuccessDelete, setOpenSucceessDelete] = useState(false);
  useEffect(() => {
    if (props.deleteGranthsucces) {
      setOpenSucceessDelete(true);
    }
  }, [props.deleteGranthsucces]);

  const closeSuccessDeleteModal = () => {
    props.deleteToggleModal();
    setOpenSucceessDelete(false);
  };
  return (
    <>
      <DeleteDialogLayout
        deleteToggle={deleteToggle}
        setDeleteToggle={setDeleteToggle}
        recordType="Granth"
        recordRowData={rowData}
        deleteRowData={deleteRowData}
        openSuccessDelete={openSuccessDelete}
        closeSuccessDeleteModal={closeSuccessDeleteModal}
      />
      <Grid container xs={12} flexWrap="wrap">
        <TableContainer
          component={Paper}
          elevation={0}
          className={classes?.table}
          ref={tableContainerRef}
        >
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
              <TableRow>
                <TableCell
                  sx={{ width: "200px", paddingLeft: "10px !important" }}
                >
                  <TableSortLabel
                    active
                    direction={orderDirection}
                    onClick={handleSortRequest}
                  >
                    Book Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>Language</TableCell>
                <TableCell>Uploaded By</TableCell>
                <TableCell>Uploaded on</TableCell>
                <TableCell align="right" sx={{ pr: 2.5 }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedData === undefined ||
                (sortedData?.length > 0 &&
                  sortedData.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell>
                        {
                          LanguageList.filter(
                            (item) => item.LangCode === row?.languageCode,
                          )[0]?.langName
                        }
                      </TableCell>
                      <TableCell>Admin</TableCell>
                      <TableCell>
                        {formatDateInAlphanumericFormat(row.createdAt)}
                      </TableCell>
                      <TableCell align="right" sx={{ p: 0 }}>
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            props.setContentDataToBeUpdated(row);
                            handleRowEdit(row);
                            props.trackButtonClick("Edit Granth Button", 1);
                          }}
                        >
                          <img
                            className={classes?.icon_size}
                            src="/edit-icon.svg"
                            alt="edit"
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>

                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteData(row)}
                        >
                          <img
                            className={classes?.icon_size}
                            src="/delete-icon.svg"
                            alt="delete-icon"
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )))}
            </TableBody>
          </Table>
        </TableContainer>
        {(sortedData?.length === 0 || sortedData === undefined) && (
          <Box
            sx={{
              display: "grid",
              justifyContent: "center",
              textAlign: "center",
              width: "100%",
              marginTop: "10%",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <img
                src="/mailbox.png"
                alt="No data"
                style={{ width: "30px", height: "30px" }}
              />
            </Box>
            <Box sx={{}}>No data</Box>
          </Box>
        )}
        {sortedData?.length > 0 && (
          <Grid container sx={{ marginTop: "25px", justifyContent: "center" }}>
            <Paginate
              paginationObject={paginationObject}
              handlePageChange={handlePageChange}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    deleteGranthsucces,
    granthDataById,
    granthData,
    loading,
    updateGranthSuccess,
    getGrantSucces,
  } = state.granthReducer;

  return {
    deleteGranthsucces,
    granthDataById,
    granthData,
    loading,
    updateGranthSuccess,
    getGrantSucces,
  };
};

export default connect(mapStateToProps, {
  deleteGranth,
  getGranthdetails,
  deleteToggleModal,
})(withMixpanelTracking(ViewGranth, "Granth"));
