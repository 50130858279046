export const GET_REQUEST = "GET_REQUEST";
export const GET_REQUEST_SUCCESS_DETAILS = "GET_REQUEST_SUCCESS_DETAILS";
export const GET_REQUEST_FAILURE_DETAILS = "GET_REQUEST_FAILURE_DETAILS";

export const GET_REQUEST_BY_STATUS = "GET_REQUEST_BY_STATUS";
export const GET_REQUEST_BY_STATUS_SUCCESS = "GET_REQUEST_BY_STATUS_SUCCESS";
export const GET_REQUEST_BY_STATUS_FAILURE = "GET_REQUEST_BY_STATUS_FAILURE";

export const UPDATE_REQUEST_BY_STATUS = "UPDATE_REQUEST_BY_STATUS";
export const UPDATE_REQUEST_BY_STATUS_SUCCESS =
  "UPDATE_REQUEST_BY_STATUS_SUCCESS";
export const UPDATE_REQUEST_BY_STATUS_FAILURE =
  "UPDATE_REQUEST_BY_STATUS_FAILURE";

export const getRequestdetails = (payload) => {
  return {
    type: GET_REQUEST,
    payload,
  };
};

export const getRequestdetailsSuccess = (data) => {
  return {
    type: GET_REQUEST_SUCCESS_DETAILS,
    payload: data,
  };
};
export const getRequestdetailsFail = (error) => {
  return {
    type: GET_REQUEST_FAILURE_DETAILS,
    payload: error,
  };
};

export const getRequestByStatus = (status) => {
  return { type: GET_REQUEST_BY_STATUS, payload: status };
};
export const getRequestByStatusSuccess = (
  data,

  page,
  appliedCount,
  approvedCount,
  rejectedCount,
) => {
  return {
    type: GET_REQUEST_BY_STATUS_SUCCESS,
    payload: data,

    page,
    appliedCount,
    approvedCount,
    rejectedCount,
  };
};
export const getRequestByStatusFail = (err) => {
  return { type: GET_REQUEST_BY_STATUS_FAILURE, payload: err };
};

export const updateRequestByStatus = (payload) => {
  return { type: UPDATE_REQUEST_BY_STATUS, payload };
};
export const updateRequestByStatusSuccess = () => {
  return { type: UPDATE_REQUEST_BY_STATUS_SUCCESS };
};
export const updateRequestByStatusFail = (err) => {
  return { type: UPDATE_REQUEST_BY_STATUS_FAILURE, payload: err };
};
