// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-datepicker-container {
  position: relative;
  margin-top: 20px;
}

.floating-label {
  position: absolute;
  left: 12px;
  top: 12px;
  background-color: white;
  padding: 0 4px;
  transition: all 0.2s ease-out;
  color: #888;
  pointer-events: none;
  font-size: 16px;
}

.date_time_picker_f {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.shrink {
  top: -8px;
  left: 8px;
  font-size: 12px;
  color: #3f51b5;
}

.custom-datepicker-container
  .react-datepicker-wrapper
  input:focus
  + .floating-label {
  top: -8px;
  left: 8px;
  font-size: 12px;
  color: #3f51b5;
}
`, "",{"version":3,"sources":["webpack://./src/styles/react-datePicker.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,uBAAuB;EACvB,cAAc;EACd,6BAA6B;EAC7B,WAAW;EACX,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,SAAS;EACT,SAAS;EACT,eAAe;EACf,cAAc;AAChB;;AAEA;;;;EAIE,SAAS;EACT,SAAS;EACT,eAAe;EACf,cAAc;AAChB","sourcesContent":[".custom-datepicker-container {\n  position: relative;\n  margin-top: 20px;\n}\n\n.floating-label {\n  position: absolute;\n  left: 12px;\n  top: 12px;\n  background-color: white;\n  padding: 0 4px;\n  transition: all 0.2s ease-out;\n  color: #888;\n  pointer-events: none;\n  font-size: 16px;\n}\n\n.date_time_picker_f {\n  width: 100%;\n  padding: 12px;\n  font-size: 16px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  outline: none;\n}\n\n.shrink {\n  top: -8px;\n  left: 8px;\n  font-size: 12px;\n  color: #3f51b5;\n}\n\n.custom-datepicker-container\n  .react-datepicker-wrapper\n  input:focus\n  + .floating-label {\n  top: -8px;\n  left: 8px;\n  font-size: 12px;\n  color: #3f51b5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
