export const CREATE_ALBUM = "CREATE_ALBUM";
export const CREATE_ALBUM_SUCCESS = "CREATE_ALBUM_SUCCESS";
export const CREATE_ALBUM_FAILURE = "CREATE_ALBUM_FAILURE";

export const GET_ALBUM = "GET_ALBUM";
export const GET_ALBUM_SUCCESS = "GET_ALBUM_SUCCESS";
export const GET_ALBUM_FAILURE = "GET_ALBUM_FAILURE";

export const UPDATE_ALBUM = "UPDATE_ALBUM";
export const UPDATE_ALBUM_SUCCESS = "UPDATE_ALBUM_SUCCESS";
export const UPDATE_ALBUM_FAILURE = "UPDATE_ALBUM_FAILURE";

export const DELETE_ALBUM = "DELETE_ALBUM";
export const DELETE_ALBUM_SUCCESS = "DELETE_ALBUM_SUCCESS";
export const DELETE_ALBUM_FAILURE = "DELETE_ALBUM_FAILURE";

export const DELETE_TOGGLE_MODAL_STATUS = "DELETE_TOGGLE_MODAL_STATUS";

export const deleteToggleModal = () => {
  return {
    type: DELETE_TOGGLE_MODAL_STATUS,
  };
};

export const DIALOG_MESSAGE = "DIALOG_MESSAGE";

export const showUnshowDialogMessage = () => {
  return {
    type: DIALOG_MESSAGE,
  };
};

export const getAllAlbum = (urlParameters) => {
  return {
    type: GET_ALBUM,
    params: urlParameters,
  };
};

export const getAllAlbumSuccess = (data) => {
  return {
    type: GET_ALBUM_SUCCESS,
    payload: data,
  };
};

export const getAllAlbumFailure = (error) => {
  return {
    type: GET_ALBUM_FAILURE,
    payload: error,
  };
};

export const createAlbum = (data) => {
  return {
    type: CREATE_ALBUM,
    payload: data,
  };
};
export const createAlbumSuccess = (data) => {
  return {
    type: CREATE_ALBUM_SUCCESS,
    payload: data,
  };
};
export const createAlbumFailure = (error) => {
  return {
    type: CREATE_ALBUM_FAILURE,
    payload: error,
  };
};

export const updateAlbum = (payload, id) => {
  return {
    type: UPDATE_ALBUM,
    payload,
    id,
  };
};

export const updateAlbumSuccess = (data) => {
  return {
    type: UPDATE_ALBUM_SUCCESS,
    payload: data,
  };
};

export const updateAlbumFailure = (error) => {
  return {
    type: UPDATE_ALBUM_FAILURE,
    payload: error,
  };
};

export const deleteAlbum = (id) => {
  return {
    type: DELETE_ALBUM,
    payload: id,
  };
};

export const deleteAlbumSuccess = () => {
  return {
    type: DELETE_ALBUM_SUCCESS,
  };
};

export const deleteAlbumFailure = (error) => {
  return {
    type: DELETE_ALBUM_FAILURE,
    payload: error,
  };
};
