import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import activeUsers from "../assets/images/active_user.svg";
import subAdminUsers from "../assets/images/subAdminUsers.svg";
import MenuLayout from "../commonLayout/MenuLayout";
import AddUser from "../components/users/AddUser";
import ViewUsers from "../components/users/ViewUsers";
import { getSubAdminUsers, getUserCount } from "../redux/usermanagement/action";
import "../styles/user-management.css";
import withMixpanelTracking from "./commonComponent/withMixPanelTracking";
import { useNavigate, useParams } from "react-router-dom";
import SearchInput from "./commonComponent/SearchInput";

const UserManagement = (props) => {
  const { action } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const [allUserData, setAlluserData] = useState([]);

  const [activeData, setActiveData] = useState([]);
  const [InactiveData, setInActiveData] = useState([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const handleMenuButtonClick = (event, menu) => {
    navigate(`/user-management/Add`, { replace: true });
    props.trackButtonClick("Add User Button", 1);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(1);
    setSearchTerm("");
    props.trackButtonClick("User Tabs Button", 1);
  };

  const handlePageChange = (count) => {
    setPage(count);
  };

  const handleEditUser = (val) => {
    const jsonData = JSON.stringify(val);

    const encodedData = encodeURIComponent(jsonData);

    navigate(`/user-management/edit?data=${encodedData}`, { replace: true });
    props.trackButtonClick("Edit User Button", 1);
  };
  const handleCloseUser = () => {
    navigate(`/user-management/view`, { replace: true });
  };

  useEffect(() => {
    props.getUserCount();
  }, [props.dialogMessage, props.deleteUserSuccess]);

  const [totalAppUsers, setTotalAppUsers] = useState(0);
  const [totalSubAdminUsers, setTotalSubAdminUsers] = useState(
    props.userStats?.inActiveUser,
  );
  useEffect(() => {
    const activeUsers =
      Number(props.counts?.totalSubAdminUsers?.totalActiveUsers) || 0;
    const inActiveUsers =
      Number(props.counts?.totalSubAdminUsers?.totalInactiveUsers) || 0;

    setTotalAppUsers(activeUsers + inActiveUsers);
    setTotalSubAdminUsers(
      props.counts?.totalSubAdminUsers?.totalSubAdminCount || 0,
    );
  }, [props.counts]);
  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  useEffect(() => {
    let status = value === 0 ? "" : value === 1 ? "true" : "false";

    let pageUrl = `page=${page - 1}&size=10&search=${searchTerm}&accountEnabled=${status}`;
    props.getSubAdminUsers(pageUrl);
  }, [
    props.dialogMessage,
    props.deleteUserSuccess,
    props.updateUserSuccess,
    searchTerm,
    page,
    value,
  ]);
  useEffect(() => {
    if (props.subAdminuserResponse && props?.subAdminUsers?.length > 0) {
      if (value === 0) {
        setAlluserData(props?.subAdminUsers);
      } else if (value == 1) {
        setActiveData(props?.subAdminUsers);
      } else {
        setInActiveData(props?.subAdminUsers);
      }
    } else {
      setAlluserData([]);

      setActiveData([]);

      setInActiveData([]);
    }
  }, [props.subAdminuserResponse, props.subAdminUsers]);

  return (
    <>
      <Box
        sx={{
          height: "90%",
          paddingX: "20px",
        }}
      >
        <Grid
          container
          rowSpacing={0}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{
            height: action === "Add" || (action === "edit" && "90%"),
          }}
        >
          <Grid
            container
            rowSpacing={0}
            columnSpacing={{ xs: 5, sm: 1, md: 1 }}
            justifyContent="flex-start"
          >
            {action === "view" ? (
              <>
                <Grid xs={5} md={5} lg={7}>
                  <Box
                    className={"page_title"}
                    sx={{
                      textWrap: "nowrap",
                      paddingY: {
                        lg: "10px",
                        xl: "20px",
                      },
                    }}
                  >
                    User Management
                  </Box>
                  <p className={"page_sub_title"}>
                    Get a top level view of your users
                  </p>
                </Grid>

                <div className="user_mangement_search">
                  <SearchInput text={"Search"} setSearchTerm={setSearchTerm} />
                </div>

                {/* Total App users section */}
                <Grid
                  xs={2.85}
                  className={"user-management-card"}
                  sx={{
                    border: "0",
                    borderRadius: "12px",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      className="user-managementcount-icon"
                      src={activeUsers}
                      alt="active-user-icon"
                    />
                    <Box className={"user-managementcount_title"}>
                      Total App Users
                    </Box>
                  </Box>

                  <Box>
                    <span className={"user-managementcount_text"}>
                      {totalAppUsers}
                    </span>
                  </Box>

                  <Box sx={{ width: "100%" }}>
                    <Box className={"user_update_details"}>
                      Last update: {dayjs().format("D MMMM, hh:mm A")}
                    </Box>
                  </Box>
                </Grid>

                {/* Total sub admin users */}
                <Grid
                  xs={2.85}
                  className={"user-management-card"}
                  sx={{
                    border: "0",
                    borderRadius: "12px",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <img
                      className="user-managementcount-icon"
                      src={subAdminUsers}
                      alt="subAdmin-user-icon"
                    />
                    <Box className={"user-managementcount_title"}>
                      Sub Admin Users
                    </Box>
                  </Box>

                  <Box>
                    <span className={"user-managementcount_text"}>
                      {totalSubAdminUsers}
                    </span>
                  </Box>

                  <Box sx={{ width: "100%" }}>
                    <Box className={"user_update_details"}>
                      Last update: {dayjs().format("D MMMM, hh:mm A")}
                    </Box>
                  </Box>
                </Grid>

                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      className="user-management-tabs"
                    >
                      <Tab
                        label="All Users"
                        className="user-management-tab"
                        style={{
                          background: value === 0 ? "#FFFFFF" : null,
                          color: value === 0 ? "#9e46e3" : "black",
                        }}
                      />
                      <Tab
                        label="Active"
                        className="user-management-tab"
                        style={{
                          background: value === 1 ? "#FFFFFF" : null,
                          color: value === 1 ? "#9e46e3" : "black",
                        }}
                      />
                      <Tab
                        label="Inactive"
                        className="user-management-tab"
                        style={{
                          background: value === 2 ? "#FFFFFF" : null,
                          color: value === 2 ? "#9e46e3" : "black",
                        }}
                      />
                    </Tabs>
                  </Grid>

                  {/* Download section */}
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      startIcon={<AddCircleOutlineOutlinedIcon />}
                      onClick={(event) =>
                        handleMenuButtonClick(event, "Add User")
                      }
                      sx={{ textTransform: "none" }}
                      className={`add_user`}
                    >
                      Add User
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid
                xs={5}
                md={5}
                lg={7}
                style={{ display: "flex", alignItems: "center" }}
              >
                <KeyboardBackspaceIcon
                  style={{
                    fontSize: "30px",
                    fontWeight: 600,
                    color: "black",
                    fontFamily: "inter",
                    cursor: "pointer",
                    marginRight: 10,
                  }}
                  onClick={handleCloseUser}
                />
                <Typography
                  component="h1"
                  className={"page_title"}
                  sx={{
                    width: { xs: 80, md: 100, lg: 500 },
                    textWrap: "nowrap",
                    fontSize: {
                      xl: "24px",
                    },
                    fontWeight: 600,
                  }}
                >
                  {action === "edit" ? "Edit User" : "Add user details"}
                </Typography>
              </Grid>
            )}

            {/* Calling the components */}

            {(() => {
              switch (action) {
                case "view":
                  return (
                    <ViewUsers
                      data={
                        value === 0
                          ? allUserData
                          : value === 1
                            ? activeData
                            : InactiveData
                      }
                      value={value}
                      handlePageChange={handlePageChange}
                      handleEdit={handleEditUser}
                      Tabchanges={page}
                    />
                  );

                case "Add":
                  return <AddUser />;

                case "edit":
                  return <AddUser />;

                default:
                  return (
                    <ViewUsers
                      handleEdit={handleEditUser}
                      handlePageChange={handlePageChange}
                      Tabchanges={page}
                    />
                  );
              }
            })()}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    subAdminUsers,
    dialogMessage,
    subAdminuserResponse,
    counts,
    deleteUserSuccess,

    updateUserSuccess,
  } = state.userReducer;

  return {
    subAdminUsers,
    counts,
    dialogMessage,
    subAdminuserResponse,
    deleteUserSuccess,
    updateUserSuccess,
  };
};

const UserManagementComp = connect(mapStateToProps, {
  getSubAdminUsers,
  getUserCount,
})(withMixpanelTracking(UserManagement, "User Management"));

const wrapper = () => {
  return (
    <MenuLayout
      childComponent={() => {
        return <UserManagementComp />;
      }}
    />
  );
};

export default wrapper;
