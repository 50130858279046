import { Tooltip, styled } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(249, 242, 255, 1)",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));
const truncateAtDot = (filename) => {
  return filename?.length > 25 ? filename?.slice(0, 25) + "..." : filename;
};

const TableTooltip = (props) => {
  return (
    <LightTooltip title={props?.title}>
      {truncateAtDot(props?.title)}
    </LightTooltip>
  );
};
export default TableTooltip;
