import React, { useEffect, useRef, useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Grid,
  Tooltip,
  TableSortLabel,
  useTheme,
  Box,
} from "@mui/material";
import Paginate from "../commonComponent/Pagination";
import { connect } from "react-redux";
import { getQuiz, downloadQuizByID } from "../../redux/quiz/action";
import BulkUpload from "./BulkUpload";
import { formatDateInAlphanumericFormat } from "../../helpers/dateHelper";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import useCustomStyles from "../../customHooks/useCustomStyles";

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth: "thin",
    [theme.breakpoints.down(1400)]: {
      maxHeight: 240,
    },
    [theme.breakpoints.between(1401, 1919)]: {
      maxHeight: 390,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 500,
    },
  },

  icon_size: {
    [theme.breakpoints.down(1400)]: {
      height: "25px",
      width: "25px",
    },
    [theme.breakpoints.between(1401, 1919)]: {
      height: "30px",
      width: "30px",
    },
  },
});

const ViewQuiz = (props) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const [openBulkUpload, setOpenBulkUpload] = useState(false);
  const [recordTypes, setRecordType] = useState("");
  const [records, setRecords] = useState([]);
  const [paginationObject, setPaginationObject] = useState({
    page: 1,
    rowsPerPage: 10,
    totalRowCount: 0,
  });

  const [comparetableRecords, setCompareTableRecords] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const tableContainerRef = useRef(null);

  const handlePageChange = (event, newPage) => {
    setPaginationObject({ ...paginationObject, page: newPage });
    props.getQuiz(newPage);

    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  };

  const setQuizDataWithPagination = (loading) => {
    if (!loading && props?.QUIZData) {
      const { content, totalPages } = props?.QUIZData;

      props.setQuizList(content);
      props.setRecordsList(content);
      setCompareTableRecords(content);

      setPaginationObject({
        ...paginationObject,
        totalRowCount: totalPages,
      });
    }
  };

  useEffect(() => {
    props.getQuiz(paginationObject?.page);
  }, [props.getQuiz, props.createSuccess, props.UPDATESuccess]);

  useEffect(() => {
    setQuizDataWithPagination(props?.loading);
  }, [props.loading]);

  useEffect(() => {
    if (props.quizList?.length !== comparetableRecords?.length) {
      setPaginationObject({
        ...paginationObject,
        totalRowCount: 1,
      });
    } else {
      setQuizDataWithPagination(false);
    }
  }, [props.quizList]);
  const handleEditUpload = (type, val) => {
    setOpenBulkUpload(true);
    setRecordType("edit");
    setRecords(val);
  };

  const handleDownloadUpdate = (val) => {
    const formData = new FormData();
    formData.append("batchId", val);
    const queryParams = new URLSearchParams(formData).toString();

    props.downloadQuizByID(queryParams);
    props.trackButtonClick("Download Quiz Button", 1);
  };

  const totalNumber = (list) => {
    let count = 0;
    if (list?.length > 0) {
      let n = list.map((item) => {
        if (item.count) {
          count = count + item.count;
        }
      });

      return count;
    } else {
      return count;
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  let sortedList = [];
  if (props.quizList && props.quizList.length > 0) {
    sortedList = [...props.quizList].sort((a, b) => {
      if (order) {
        return (
          (a.createdBy.toUpperCase() < b.createdBy.toUpperCase() ? -1 : 1) *
          (order === "asc" ? 1 : -1)
        );
      }
      return 0;
    });
  }

  return (
    <>
      {openBulkUpload && (
        <BulkUpload
          openBulkUpload={openBulkUpload}
          setOpenBulkUpload={setOpenBulkUpload}
          recordsType={recordTypes}
          recordsData={records}
        />
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            elevation={0}
            className={classes?.table}
            ref={tableContainerRef}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
                <TableRow>
                  <TableCell sx={{ paddingLeft: "10px !important" }}>
                    Total Questions
                  </TableCell>
                  <TableCell>Languages</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "createdBy"}
                      direction={order}
                      onClick={() => handleRequestSort("createdBy")}
                    >
                      Uploaded By
                    </TableSortLabel>
                  </TableCell>

                  <TableCell>Uploaded On</TableCell>
                  <TableCell align="right" sx={{ pr: 2.5 }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedList?.length > 0 &&
                  sortedList.map((eventrow) => (
                    <TableRow
                      key={eventrow.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {totalNumber(eventrow.languages)}
                      </TableCell>
                      <TableCell>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {eventrow?.languages?.map((item) => (
                            <span
                              style={{
                                display: "flex",
                              }}
                            >
                              <p className="QuizTableLangCount">
                                {item.name} :
                              </p>
                              <p className="QuizTableLangCount">{item.count}</p>
                            </span>
                          ))}
                        </div>
                      </TableCell>
                      <TableCell>{eventrow.createdBy}</TableCell>
                      <TableCell>
                        {formatDateInAlphanumericFormat(eventrow.createdAt)}
                      </TableCell>
                      <TableCell align="right" sx={{ pr: 2.5 }}>
                        {" "}
                        <Tooltip title="Download" placement="top">
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              handleDownloadUpdate(eventrow.batchId);
                              props.trackButtonClick("Download Quiz Button", 1);
                            }}
                          >
                            <img
                              className={classes?.icon_size}
                              src="/quiz-download-icon.svg"
                              alt="edit-icon"
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Upload" placement="top">
                          {" "}
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              handleEditUpload("edit", eventrow);
                              props.trackButtonClick("Upload Quiz Button", 1);
                            }}
                          >
                            <img
                              className={classes?.icon_size}
                              src="/QuizUpload.svg"
                              alt="edit-icon"
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {sortedList === undefined ||
            (sortedList?.length === 0 && (
              <Box
                sx={{
                  display: "grid",
                  justifyContent: "center",
                  textAlign: "center",
                  width: "100%",
                  marginTop: "10%",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <img
                    src="/mailbox.png"
                    alt="No data"
                    style={{ width: "30px", height: "30px" }}
                  />
                </Box>
                <Box sx={{}}>No data</Box>
              </Box>
            ))}
          {sortedList?.length > 0 && (
            <Grid
              container
              sx={{ marginTop: "25px", justifyContent: "center" }}
            >
              <Paginate
                paginationObject={paginationObject}
                handlePageChange={handlePageChange}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const { createSuccess, UPDATESuccess, getSuccess, QUIZData, loading } =
    state.quizReducer;

  return {
    createSuccess,
    UPDATESuccess,
    getSuccess,
    QUIZData,
    loading,
  };
};

export default connect(mapStateToProps, {
  getQuiz,

  downloadQuizByID,
})(withMixpanelTracking(ViewQuiz, "Quiz"));
