import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Badge,
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  SvgIcon,
  useTheme,
} from "@mui/material";

// importing custom style hook
import useCustomStyles from "../customHooks/useCustomStyles";

// importing logo icons
import BackHandOutlinedIcon from "@mui/icons-material/BackHandOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SpeedIcon from "@mui/icons-material/Speed";
import WallpaperOutlinedIcon from "@mui/icons-material/WallpaperOutlined";
import { connect } from "react-redux";
import DashboardLogo from "../assets/gnjDashboard2.png";
import { adminLogout } from "../redux/login/action";
import NavBarLayout from "./NavBarLayout";

import GlobalSnackbar from "../components/commonComponent/commonSnackbar";
import withMixpanelTracking from "../components/commonComponent/withMixPanelTracking";
import {
  getDeleteRequestPane,
  getHelpCenterPane,
  getVolunteerStatustPane,
} from "../redux/navigationaPane/action";

// creating custom styles object
const styles = (theme) => ({
  drawer_logo_container: {
    height: 100,
    width: 100,
  },

  list_item_button: {
    "&.Mui-selected": {
      borderRadius: "8px",
      backgroundColor: "#ffffff",
      width: "400px",
      color: "#9E46E3",
    },
  },
});

const MenuLayout = ({
  childComponent,
  volunteerData,
  helpCenterData,
  deleteRequestData,
  ...props
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  // setting up the drawer width

  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const token = localStorage.getItem("token");

  const [selectedMenu, setSelectedMenu] = useState(
    location?.pathname?.split("/")[1] || "dashboard",
  );

  const [DlCount, setDlCount] = useState(0);
  const [FdCount, setFdCount] = useState(0);
  const [VlCount, setVlCount] = useState(0);

  const handleMenuButtonClick = (event, menu) => {
    setSelectedMenu(menu);
    navigate(`/${menu}`);
    props.trackButtonClick(`${menu} Sidebar Button`, 1);
  };

  const handleLogout = () => {
    props.adminLogout();
    localStorage.clear();
    props.trackButtonClick("Logout Button", 1);
    navigate("/login");
  };
  useEffect(() => {
    setVlCount(volunteerData?.count);
    setDlCount(deleteRequestData?.count);
    setFdCount(helpCenterData?.count);
  }, [volunteerData?.count, helpCenterData?.count, deleteRequestData?.count]);

  useEffect(() => {
    if (token !== null && token) {
      props.getVolunteerStatustPane();
      props.getDeleteRequestPane();
      props.getHelpCenterPane();
    }
  }, [token]);

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#F7F8FA",
        height: "100vh",
        pr: 3,
      }}
    >
      <CssBaseline />

      {/* sidebar menus */}
      <Drawer
        sx={{
          mt: 5,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            border: "none",
            px: 2,
            backgroundColor: "#F7F8FA",

            boxSizing: "border-box",
          },
        }}
        className="DrawerList"
        variant="permanent"
        anchor="left"
      >
        <div>
          <img
            src={DashboardLogo}
            alt="dashboard-logo-image"
            style={{ height: 80, width: 80, marginTop: 20, marginLeft: 70 }}
          />
        </div>

        {/* menus */}
        <List>
          <ListItem disablePadding>
            <ListItemButton
              className={classes?.list_item_button}
              selected={selectedMenu === "dashboard"}
              onClick={(event) => {
                handleMenuButtonClick(event, "dashboard");
              }}
            >
              <ListItemIcon>
                <SvgIcon sx={{ marginLeft: "20px" }}>
                  <SpeedIcon
                    sx={selectedMenu === "dashboard" && { color: "#9E46E3" }}
                  />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                className="MenuItemText"
                sx={{ "&.MuiTypography-root": { fontSize: "24px !important" } }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              className={classes?.list_item_button}
              selected={
                selectedMenu === "user-management/view" ||
                selectedMenu === "user-management"
              }
              onClick={(event) => {
                handleMenuButtonClick(event, "user-management/view");
              }}
            >
              <ListItemIcon>
                <SvgIcon sx={{ marginLeft: "20px" }}>
                  <PeopleAltOutlinedIcon
                    sx={
                      (selectedMenu === "user-management/view" ||
                        selectedMenu === "user-management") && {
                        color: "#9E46E3",
                      }
                    }
                  />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary="User Management"
                sx={{ fontSize: "36px" }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              className={classes?.list_item_button}
              selected={selectedMenu === "app-management"}
              onClick={(event) => {
                handleMenuButtonClick(event, "app-management");
              }}
            >
              <ListItemIcon>
                <SvgIcon sx={{ marginLeft: "20px" }}>
                  <GridViewOutlinedIcon
                    sx={
                      selectedMenu === "app-management" && { color: "#9E46E3" }
                    }
                  />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary="App Management"
                sx={{ fontSize: "36px" }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              className={classes?.list_item_button}
              selected={
                selectedMenu === "gurudwara/view" ||
                selectedMenu === "gurudwara"
              }
              onClick={(event) => {
                handleMenuButtonClick(event, "gurudwara/view");
              }}
            >
              <ListItemIcon>
                <SvgIcon sx={{ marginLeft: "20px" }}>
                  <LocationOnOutlinedIcon
                    sx={
                      (selectedMenu === "gurudwara/view" ||
                        selectedMenu === "gurudwara") && {
                        color: "#9E46E3",
                      }
                    }
                  />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary="Gurudwaras" sx={{ fontSize: "36px" }} />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              className={classes?.list_item_button}
              selected={
                selectedMenu === "content/Hukamnama/view" ||
                selectedMenu === "content"
              }
              onClick={(event) => {
                handleMenuButtonClick(event, "content/Hukamnama/view");
              }}
            >
              <ListItemIcon>
                <SvgIcon sx={{ marginLeft: "20px" }}>
                  <WallpaperOutlinedIcon
                    sx={
                      (selectedMenu === "content/Hukamnama/view" ||
                        selectedMenu === "content") && {
                        color: "#9E46E3",
                      }
                    }
                  />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary="Content Management"
                sx={{ fontSize: "36px" }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              sx={{
                paddingRight: { lg: "25px", xl: "15px" },
              }}
              className={classes?.list_item_button}
              selected={
                selectedMenu === "volunteer" ||
                selectedMenu === "volunteer/view"
              }
              onClick={(event) => {
                handleMenuButtonClick(event, "volunteer/view");
              }}
            >
              <ListItemIcon>
                <SvgIcon sx={{ marginLeft: "20px" }}>
                  <BackHandOutlinedIcon
                    sx={
                      (selectedMenu === "volunteer" ||
                        selectedMenu === "volunteer/view") && {
                        color: "#9E46E3",
                      }
                    }
                  />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary={`Volunteer Forms`}
                sx={{ fontSize: "36px" }}
              />
              <Badge
                sx={{
                  "& .MuiBadge-badge": {
                    color: "#ffffff",
                    backgroundColor: "#9E46E3",
                  },
                }}
                badgeContent={VlCount}
                max={999}
              ></Badge>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              sx={{
                paddingRight: { lg: "25px", xl: "15px" },
              }}
              className={classes?.list_item_button}
              selected={selectedMenu === "feedback"}
              onClick={(event) => {
                handleMenuButtonClick(event, "feedback");
              }}
            >
              <ListItemIcon>
                {selectedMenu === "feedback" ? (
                  <img
                    src="/feedback-menu-purple.svg"
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      marginLeft: "20px",
                    }}
                    alt="feedback"
                  />
                ) : (
                  <img
                    src="/feedback-menu.svg"
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      marginLeft: "20px",
                    }}
                    alt="feedback"
                  />
                )}
              </ListItemIcon>
              <ListItemText
                primary={`Feedback Forms`}
                sx={{ fontSize: "36px" }}
              />
              <Badge
                sx={{
                  "& .MuiBadge-badge": {
                    color: "#ffffff",
                    backgroundColor: "#9E46E3",
                  },
                }}
                badgeContent={FdCount}
                max={999}
              ></Badge>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              sx={{
                paddingRight: { lg: "25px", xl: "15px" },
              }}
              className={classes?.list_item_button}
              selected={selectedMenu === "delete-requests"}
              onClick={(event) => {
                handleMenuButtonClick(event, "delete-requests");
              }}
            >
              <ListItemIcon>
                <SvgIcon sx={{ marginLeft: "20px" }}>
                  <DeleteOutlineOutlinedIcon
                    sx={
                      selectedMenu === "delete-requests" && { color: "#9E46E3" }
                    }
                  />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary={`Delete Requests`}
                sx={{ fontSize: "36px" }}
              />
              <Badge
                sx={{
                  "& .MuiBadge-badge": {
                    color: "#ffffff",
                    backgroundColor: "#9E46E3",
                  },
                }}
                badgeContent={DlCount}
                max={999}
              ></Badge>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              className={classes?.list_item_button}
              selected={selectedMenu === "Log out"}
              onClick={handleLogout}
            >
              <ListItemIcon>
                <SvgIcon sx={{ marginLeft: "20px" }}>
                  <LogoutOutlinedIcon
                    sx={selectedMenu === "Log out" && { color: "#9E46E3" }}
                  />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText primary="Log out" sx={{ fontSize: "36px" }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>

      <Box
        position="fixed"
        elevation={0}
        sx={{
          marginRight: "20px",
          marginTop: "5px",
        }}
        className="navBar_Section"
      >
        <NavBarLayout />
      </Box>

      {/* components showing based on the selected menu */}
      <Box
        component={Paper}
        elevate={6}
        sx={{
          flexGrow: 1,
          bgcolor: "#ffffff",
          p: 3,
          borderRadius: "5px",
          marginTop: "60px",
          maxHeight: "89vh",
          [theme.breakpoints.down("xl")]: {
            minWidth: "960px",
          },
          [theme.breakpoints.up("xl")]: {
            minWidth: "890px",
          },
        }}
      >
        {childComponent()}
      </Box>
      <GlobalSnackbar />
    </Box>
  );
};

const mapStateToProps = (state) => {
  const { success, data } = state.loginReducer;
  const {
    volunteerData,
    helpCenterData,
    deleteRequestData,
    helpcenterSuccess,
    voluntterSuccess,
    deleteRequestSuccess,
  } = state.paneReducer;

  return {
    successResponse: success,
    loggedInUserData: data,
    volunteerData,
    helpCenterData,
    deleteRequestData,
    helpcenterSuccess,
    voluntterSuccess,
    deleteRequestSuccess,
  };
};

export default connect(mapStateToProps, {
  adminLogout,
  getVolunteerStatustPane,
  getDeleteRequestPane,
  getHelpCenterPane,
})(withMixpanelTracking(MenuLayout, "Sidebar Menu"));
