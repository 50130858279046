import { apiClient } from "./AxiosClient";

export const getSubAdminsFromApi = async (page) => {
  return await apiClient.get(`/auth/search?${page}`);
};

export const getUserStatsFromApi = async () => {
  return await apiClient.get(`/admin/user-stats`);
};

export const getUserProfileFromApi = async () => {
  return await apiClient.get(`/user/profiles/getprofile`);
};

export const updateUserProfileFromApi = async (data) => {
  return await apiClient.post(`/user/profiles/update`, data);
};

export const updateUserStatusFromApi = async (id, status) => {
  return await apiClient.put(
    `/auth/user/activeStatus?id=${id}&status=${status}`,
  );
};
