import { call, put, takeEvery } from "redux-saga/effects";

import {
  CREATE_USER,
  userSuccess,
  userFailure,
  getSubAdminUsersSuccess,
  getSubAdminUsersFailure,
  GET_SUBADMIN_USERS,
  UPDATE_USER,
  updateUserSuccess,
  updateUserFailure,
  DELETE_USER,
  deleteUserFails,
  deleteUserSuccess,
  UPDATE_USER_STATUS,
  updateUserStatusSuccess,
  updateUserStatusFailure,
  GET_USER_PROFILE,
  getUserProfileSuccess,
  getUserProfileFailure,
  GET_USER_COUNT,
  getUserCountSuccess,
  getUserCountFailure,
} from "./action";

import {
  postRequest,
  putRequest,
  deleteRequest,
} from "../../apiServices/ApiActions";

import {
  addUserUrl,
  updateUserUrl,
  deleteUserUrl,
} from "../../apiServices/ApiUrl";
import {
  getSubAdminsFromApi,
  getUserProfileFromApi,
  getUserStatsFromApi,
  updateUserStatusFromApi,
} from "../../apiServices/app-management";
import { showSnackbar } from "../snackbar/action";

const addUser = async (payload) => {
  return await postRequest(addUserUrl, payload);
};

const getSubAdminsApi = async (page) => {
  return getSubAdminsFromApi(page);
};

const getUserStatsApi = async () => {
  return getUserStatsFromApi();
};

const getUserProfileApi = async () => {
  return getUserProfileFromApi();
};

const updateUserbyId = (payload) => {
  return putRequest(updateUserUrl, payload);
};

const deleteUserData = async (payload) => {
  return deleteRequest(deleteUserUrl + `${payload}`);
};

const updateUserStatusApi = async (id, status) => {
  return updateUserStatusFromApi(id, status);
};

function* createUser(action) {
  try {
    const result = yield call(addUser, action.payload);
    if (result.status === 200) {
      yield put(userSuccess(result));
      yield put(
        showSnackbar({
          message: "User create successfully",
          severity: "success",
        }),
      );
    } else {
      let res = result?.response?.data;
      yield put(userFailure(res));
    }
  } catch (error) {
    yield put(userFailure(error?.response?.data));
  }
}

function* getSubAdminsResponse(action) {
  try {
    const result = yield call(getSubAdminsApi, action.page);

    if (result.status === 200) {
      yield put(
        getSubAdminUsersSuccess(result.data?.content, result?.data?.page),
      );
    } else {
      yield put(getSubAdminUsersFailure(result?.response?.data));
    }
  } catch (error) {
    yield put(getSubAdminUsersFailure(error?.response?.data));
  }
}

function* getUserProfileResponse() {
  try {
    const result = yield call(getUserProfileApi);
    if (result.status === 200) {
      yield put(getUserProfileSuccess(result.data));
    } else {
      yield put(getUserProfileFailure(result?.response?.data));
    }
  } catch (error) {
    yield put(getUserProfileFailure(error?.response?.data));
  }
}

function* getUserStatsResponse() {
  try {
    const result = yield call(getUserStatsApi);

    if (result.status === 200) {
      yield put(getUserCountSuccess(result.data));
    } else {
      yield put(getUserCountFailure(result?.response?.data));
    }
  } catch (error) {
    yield put(getUserCountFailure(error?.response?.data));
  }
}

function* updateUser(action) {
  try {
    const result = yield call(updateUserbyId, action.payload);

    if (result.status === 200) {
      yield put(updateUserSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(updateUserFailure(res));
    }
  } catch (error) {
    yield put(updateUserFailure(error?.response?.data));
  }
}

function* deleteUser(action) {
  try {
    const result = yield call(deleteUserData, action.payload);
    if (result.status === 204 || result.status === 200) {
      yield put(deleteUserSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(deleteUserFails(res));
    }
  } catch (error) {
    yield put(deleteUserFails(error?.response?.data));
  }
}

function* updateUserStatusResponse(action) {
  try {
    const result = yield call(updateUserStatusApi, action.id, action.status);
    if (result.status === 200) {
      yield put(updateUserStatusSuccess(result));
      yield put(
        showSnackbar({
          message: "User status changed Successfully.",
          severity: "success",
        }),
      );
    } else {
      yield put(updateUserStatusFailure(result?.response?.data));
    }
  } catch (error) {
    yield put(updateUserStatusFailure(error?.response?.data));
  }
}

function* userSaga() {
  yield takeEvery(CREATE_USER, createUser);
  yield takeEvery(GET_SUBADMIN_USERS, getSubAdminsResponse);
  yield takeEvery(GET_USER_COUNT, getUserStatsResponse);
  yield takeEvery(GET_USER_PROFILE, getUserProfileResponse);
  yield takeEvery(UPDATE_USER, updateUser);
  yield takeEvery(DELETE_USER, deleteUser);
  yield takeEvery(UPDATE_USER_STATUS, updateUserStatusResponse);
}

export default userSaga;
