import { call, put, takeEvery } from "redux-saga/effects";
import {
  getAllAlubumUrls,
  updateAlubumUrls,
  deleteAlubumUrls,
  createAllAlubumUrls,
} from "../../apiServices/ApiUrl";
import {
  getRequest,
  putRequest,
  deleteRequest,
  postRequest,
} from "../../apiServices/ApiActions";
import {
  CREATE_ALBUM,
  DELETE_ALBUM,
  GET_ALBUM,
  UPDATE_ALBUM,
  getAllAlbumSuccess,
  getAllAlbumFailure,
  createAlbumSuccess,
  createAlbumFailure,
  updateAlbumSuccess,
  updateAlbumFailure,
  deleteAlbumSuccess,
  deleteAlbumFailure,
} from "./action";
import { showSnackbar } from "../snackbar/action";

const createCall = async (payload) => {
  return postRequest(createAllAlubumUrls, payload);
};
const getCall = async (page) => {
  return getRequest(`${getAllAlubumUrls}?page=${--page}&size=${10}`);
};
const updateCall = async (payload, id) => {
  return putRequest(updateAlubumUrls + `${id}`, payload);
};
const deleteCall = async (payload) => {
  return deleteRequest(deleteAlubumUrls + `${payload}`);
};

function* createAlbums(action) {
  try {
    const result = yield call(createCall, action.payload);
    if (result.status === 200) {
      yield put(createAlbumSuccess(result));
    } else {
      yield put(createAlbumFailure(result));
    }
  } catch (error) {
    yield put(createAlbumFailure(error));
  }
}
function* getAlbums(action) {
  try {
    const result = yield call(getCall, action.params);

    if (result.status === 200) {
      let response = result.data;
      yield put(getAllAlbumSuccess(response));
    } else {
      yield put(getAllAlbumFailure(result));
    }
  } catch (error) {
    yield put(getAllAlbumFailure(error));
  }
}
function* updateAlbums(action) {
  try {
    const result = yield call(updateCall, action.payload, action.id);

    if (result.status === 200) {
      yield put(updateAlbumSuccess(result));
    } else {
      yield put(updateAlbumFailure(result));
    }
  } catch (error) {
    yield put(updateAlbumFailure(error));
  }
}
function* deleteAlbums(action) {
  try {
    const result = yield call(deleteCall, action.payload);
    if (result.status === 204 || result.status === 200) {
      yield put(deleteAlbumSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(deleteAlbumFailure(result));
      yield put(
        showSnackbar({
          message: res ? res.message : "Error while deleting.",
          severity: "error",
        }),
      );
    }
  } catch (error) {
    yield put(deleteAlbumFailure(error));
  }
}

function* albumSaga() {
  yield takeEvery(GET_ALBUM, getAlbums);
  yield takeEvery(UPDATE_ALBUM, updateAlbums);
  yield takeEvery(CREATE_ALBUM, createAlbums);
  yield takeEvery(DELETE_ALBUM, deleteAlbums);
}
export default albumSaga;
