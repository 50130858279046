import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { useEffect, useState } from "react";
import { formatDateSeperatedWithSlash } from "../../helpers/dateHelper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { connect } from "react-redux";
import DeleteDialogLayout from "../../commonLayout/DeleteDialogLayout";
import {
  deleteGurudwara,
  getAllGURUDWARA,
  updateGurudwaraStatus,
  deleteToggleModal,
  getGurudwaraCount,
} from "../../redux/gurudwara/action";
import "../../styles/gurudwara.css";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import AntSwitch from "./AntSwitch";
import UpdateStatusDialog from "../../commonLayout/UpdateStatusDialog";
import DeleteDialogBoxLayout from "../../commonLayout/DeleteDialogBoxLayout";
import TableTooltip from "../commonComponent/tableTooltip";
import useCustomStyles from "../../customHooks/useCustomStyles";

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth: "thin",
    [theme.breakpoints.down(1400)]: {
      maxHeight: 240,
    },
    [theme.breakpoints.between(1401, 1919)]: {
      maxHeight: 400,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 500,
    },
  },
});

const Overview = (props) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const [searchKey, setSearchKey] = useState("");
  const [deleteToggle, setDeleteToggle] = useState(false);
  const [gurudwaraCount, setGurudwaraCount] = useState(0);
  const [activeGurudwaras, setActiveGurudwaras] = useState(0);
  const [, setFilteredData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [debouncedSearchKey, setDebouncedSearchKey] = useState("");

  const size = 5;

  const [state, setState] = useState(null);
  const [statusState, setStatusState] = useState(false);
  const [currStatus, setCurrStatus] = useState(null);
  const [id, setId] = useState(null);
  const [updateStatusToggle, setUpdateStatusToggle] = useState(false);
  const [rowName, setRowName] = useState("");

  const handleStatus = (row) => {
    setUpdateStatusToggle(true);
    setId(row.id);
    setRowName(row.name);

    if (!row.active) {
      setState("Activate");
      setCurrStatus(true);
    } else {
      setState("Deactivate");
      setCurrStatus(false);
    }
  };

  useEffect(() => {
    if (id) {
      props.updateGurudwaraStatus(id, currStatus, rowName);

      props.trackButtonClick("Update Status Gurudwara Button", 1);
    }
    setStatusState(false);
    setId(null);
    setRowName("");
  }, [statusState]);

  const handleEditRowData = (Value) => {
    props.handleEdit(Value);
  };

  useEffect(() => {
    props.getGurudwaraCount();
  }, [props.getGurudwaraCount, props.successResponse]);

  const handleDeleteData = (value) => {
    setDeleteToggle(!deleteToggle);
    setRowData(value);
  };

  const deleteRowData = (value) => {
    props.deleteGurudwara(value.id);
    props.trackButtonClick("Delete Gurudwara Button", 1);
    if (props.error) {
      alert("Error");
    }
  };

  const handleSearch = (e) => {
    setSearchKey(e.target.value);
  };

  useEffect(() => {
    // Convert searchKey to a string to ensure consistency
    const normalizedSearchKey = String(searchKey);

    const handler = setTimeout(() => {
      // Set the debounced search key only if it's valid (non-empty)

      setDebouncedSearchKey(normalizedSearchKey);
      setPage(1);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchKey]);

  useEffect(() => {
    let pageUrl = `search=${debouncedSearchKey}&page=${page - 1}&size=10`;
    props.getAllGURUDWARA(pageUrl);
  }, [props.deleteResponse, props.successResponse, debouncedSearchKey, page]);

  // useEffect(() => {
  //   let activeGurudwaras = 0;
  //   props?.data?.forEach((row) => {
  //     if (row.active) {
  //       activeGurudwaras++;
  //     }
  //   });

  //   setActiveGurudwaras(activeGurudwaras);
  //   setGurudwaraCount(props?.data?.length);
  // }, [props.data]);

  useEffect(() => {
    if (props?.data?.length > 0) {
      setPaginatedData(props?.data);
      setCount(props?.page?.totalPages);
    } else {
      setPaginatedData([]);
      setCount([]);
    }
  }, [page, props.data]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const matches = useMediaQuery("(max-width:1440px)");

  const closeSuccessDeleteModal = () => {
    props.deleteToggleModal();
  };
  return (
    <>
      {deleteToggle && (
        <DeleteDialogLayout
          deleteToggle={deleteToggle}
          recordType="Gurudwara"
          setDeleteToggle={setDeleteToggle}
          recordRowData={rowData}
          deleteRowData={deleteRowData}
        />
      )}
      {updateStatusToggle && (
        <UpdateStatusDialog
          state={state}
          recordType="Gurudwara"
          deleteToggle={updateStatusToggle}
          setDeleteToggle={setUpdateStatusToggle}
          setStatusState={setStatusState}
        />
      )}
      {props.deleteResponse && (
        <DeleteDialogBoxLayout
          deletedialogBoxToggle={props.deleteResponse}
          closeDialogBox={closeSuccessDeleteModal}
          header={`Gurudwara Details`}
          content="Deleted Successfully"
        />
      )}
      <Box sx={{ mx: 2 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 5, sm: 2, md: 1 }}
            justifyContent="flex-start"
          >
            <Grid
              xs={4}
              sm={6}
              md={2.85}
              className={"gurudwara_users_section"}
              sx={{
                borderRadius: "12px",
                margin: "25px 5px",
                p: 2,
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box>
                  <img
                    className="gurudwara_user_count_icon"
                    src="/totalGurudwara.svg"
                    alt="total-Gurudwara-icon"
                  />
                </Box>

                <Box className={"gurudwara_user_count_title"}>
                  Total Gurudwaras
                </Box>
              </Box>

              <div>
                <span className={"gurudwara_user_count_text"}>
                  {props?.gurudwaraCount?.totalLocations
                    ? props?.gurudwaraCount?.totalLocations
                    : 0}
                </span>
              </div>
            </Grid>

            <Grid
              xs={5}
              sm={6}
              md={3.5}
              className={"gurudwara_users_section"}
              sx={{
                borderRadius: "12px",
                margin: "25px 5px",
                p: 2,
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                ml: "1%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box>
                  <img
                    className="gurudwara_user_count_icon "
                    src="/activeGurudwara.svg"
                    alt="active-Gurudwara-icon"
                  />
                </Box>
                <Box className={"gurudwara_user_count_title"}>
                  Active Gurudwara On App
                </Box>
              </Box>
              <div>
                <span className={"gurudwara_user_count_text"}>
                  {props?.gurudwaraCount?.activeLocations
                    ? props?.gurudwaraCount?.activeLocations
                    : 0}
                </span>
              </div>
            </Grid>
          </Grid>
          <Grid
            style={{ display: "flex", justifyContent: "end", width: "100%" }}
          >
            <div className="gurudwara-search-bar">
              <IconButton
                type="button"
                className="search_icon-gurudwara"
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
              <InputBase
                className="search_placeholder"
                placeholder="Search"
                value={searchKey}
                onChange={handleSearch}
              />
            </div>
          </Grid>
          <Grid
            container
            xs={12}
            flexWrap="wrap"
            mt="2%"
            sx={{ width: "100%" }}
          >
            <TableContainer
              component={Paper}
              elevation={0}
              className={classes?.table}
            >
              <Table aria-label="simple table" stickyHeader>
                <TableHead sx={{ backgroundColor: "#F7F8FA" }}>
                  <TableRow className="gurudwara_table_row">
                    <TableCell className="gurudwara_user_cell">
                      Gurudwara Name
                    </TableCell>
                    <TableCell className="gurudwara_user_cell">
                      Address
                    </TableCell>
                    <TableCell className="gurudwara_user_cell">
                      Pin Code
                    </TableCell>
                    <TableCell className="gurudwara_user_cell">
                      Created By
                    </TableCell>
                    <TableCell className="gurudwara_user_cell">
                      Created On
                    </TableCell>
                    <TableCell className="gurudwara_user_cell">
                      Action
                    </TableCell>
                    <TableCell className="gurudwara_user_cell"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedData.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className="gurudwara_user_cell"
                      >
                        <TableTooltip title={row.name} />
                      </TableCell>
                      <TableCell className="gurudwara_user_cell">
                        <TableTooltip title={row.address} />
                      </TableCell>
                      <TableCell className="gurudwara_user_cell">
                        {row.pinCode}
                      </TableCell>
                      <TableCell className="gurudwara_user_cell">
                        Admin
                      </TableCell>
                      <TableCell className="gurudwara_user_cell">
                        {formatDateSeperatedWithSlash(row.createdAt)}
                      </TableCell>
                      <TableCell sx={{ p: 0 }} className="gurudwara_user_cell">
                        <img
                          src="/edit-icon.svg"
                          alt="edit"
                          className="gurudwara_edit_icon"
                          onClick={() => handleEditRowData(row)}
                        />
                        {/* <img
                          src="/delete-icon.svg"
                          alt="delete"
                          className="gurudwara_delete_icon"
                          onClick={() => {
                            handleDeleteData(row);
                          }}
                        /> */}
                      </TableCell>
                      <TableCell className="gurudwara_user_cell">
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <AntSwitch
                            defaultChecked
                            inputProps={{ "aria-label": "ant design" }}
                            onChange={() => handleStatus(row)}
                            checked={row?.active}
                          />
                          {row.active ? (
                            <p
                              style={{
                                padding: 0,
                                margin: 4,
                                color: "#9E46E3",
                              }}
                            >
                              Active
                            </p>
                          ) : (
                            <p
                              style={{
                                padding: 0,
                                margin: 4,
                                color: "#4F566C",
                              }}
                            >
                              Inactive
                            </p>
                          )}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        {paginatedData === undefined ||
          (paginatedData.length === 0 && (
            <Box
              sx={{
                display: "grid",
                justifyContent: "center",
                textAlign: "center",
                width: "100%",
                marginTop: "10%",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <img
                  src="/mailbox.png"
                  alt="No data"
                  style={{ width: "30px", height: "30px" }}
                />
              </Box>
              <Box sx={{}}>No data</Box>
            </Box>
          ))}
        {count ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Pagination
              count={count}
              page={page}
              size={matches ? "small" : "large"}
              onChange={handleChange}
            />
          </Box>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    data,
    getAllGURUDWARA,
    deleteGurudwara,
    deleteResponse,
    updateGurudwaraStatus,
    dialogMessage,
    page,
    gurudwaraCount,
  } = state.gurudwaraReducer;

  return {
    data,
    page,
    getAllGURUDWARA,
    deleteGurudwara,
    deleteResponse,
    updateGurudwaraStatus,
    successResponse: dialogMessage,
    gurudwaraCount,
  };
};

export default connect(mapStateToProps, {
  getAllGURUDWARA,
  deleteGurudwara,
  updateGurudwaraStatus,
  deleteToggleModal,
  getGurudwaraCount,
})(withMixpanelTracking(Overview, " Gurudwara"));
