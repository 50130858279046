import { call, put, takeEvery } from "redux-saga/effects";

import {
  CREATE_GRANTH,
  granthSuccess,
  granthFailure,
  DELETE_GRANTH,
  deleteGranthFails,
  deleteGranthSuccess,
  GET_GRANTH_DETAILS_BYID,
  GET_GRANTH_DETAILS,
  getGranthdetailsFailByID,
  getGranthdetailsSuccessByID,
  getGranthdetailsFail,
  getGranthdetailsSuccess,
  updategranthSuccess,
  updategranthFailure,
  UPDATE_GRANTH,
} from "./action";

import {
  postRequest,
  deleteRequest,
  getRequest,
  putRequest,
} from "../../apiServices/ApiActions";
import {
  addGranthUrl,
  deleteGranthUrl,
  getGranthUrls,
  upDateGranthUrls,
} from "../../apiServices/ApiUrl";

const addGranth = async (payload) => {
  return postRequest(addGranthUrl, payload);
};

const deleteGranthData = async (payload) => {
  return deleteRequest(deleteGranthUrl + `${payload}`);
};

const getgranthDetails = async (page) => {
  return getRequest(`${getGranthUrls}?page=${--page}&size=${10}`);
};

const updateGranthbyId = (payload, id) => {
  return putRequest(upDateGranthUrls + `${id}`, payload);
};

function* createGranth(action) {
  try {
    const result = yield call(addGranth, action.payload);

    if (result.status === 200) {
      yield put(granthSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(granthFailure(res));

   
    }
  } catch (error) {
    yield put(granthFailure(error?.response?.data));
  }
}

function* updateGranth(action) {
  try {
    const result = yield call(updateGranthbyId, action.payload, action.id);

    if (result.status === 200) {
      yield put(updategranthSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(updategranthFailure(res));
     
    }
  } catch (error) {
    yield put(updategranthFailure(error?.response?.data));
  }
}

function* deleteGranth(action) {
  try {
    const result = yield call(deleteGranthData, action.payload);

    if (result.status === 204 || result.status === 200) {
      yield put(deleteGranthSuccess(result));
    } else {
      let res = result?.response?.data;
      yield put(deleteGranthFails(res));
    }
  } catch (error) {
    yield put(deleteGranthFails(error?.response?.data));
  }
}
function* getGranth(action) {
  try {
    const result = yield call(getgranthDetails, action.params);

    if (result.status === 200) {
      let response = result.data;
      yield put(getGranthdetailsSuccess(response));
    } else {
      let res = result?.response?.data;
      yield put(getGranthdetailsFail(res));

     
    }
  } catch (error) {
    yield put(getGranthdetailsFail(error?.response?.data));
  }
}

function* getGranthById(action) {
  try {
    const result = yield call(deleteGranthData, action.payload);

    if (result.status === 200) {
      yield put(getGranthdetailsSuccessByID(result));
    } else {
      let res = result?.response?.data;
      yield put(getGranthdetailsFailByID(res));

  
    }
  } catch (error) {
    yield put(getGranthdetailsFailByID(error?.response?.data));
  }
}

function* granthSaga() {
  yield takeEvery(CREATE_GRANTH, createGranth);
  yield takeEvery(DELETE_GRANTH, deleteGranth);

  yield takeEvery(GET_GRANTH_DETAILS, getGranth);
  yield takeEvery(GET_GRANTH_DETAILS_BYID, getGranthById);
  yield takeEvery(UPDATE_GRANTH, updateGranth);
}

export default granthSaga;
