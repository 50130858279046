import {
  CREATE_EVENTS,
  CREATE_EVENTS_SUCCESS,
  CREATE_EVENTS_FAILURE,
  GET_EVENTS,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  DELETE_EVENTS,
  DELETE_EVENTS_SUCCESS,
  DELETE_EVENTS_FAILURE,
  TOGGLE_MODAL_STATUS,
} from "./action";
const initialState = {
  loading: false,
  error: "",
  eventsData: [],
  successRsponse: false,
  deleteSuccess: false,
  page: [],
};

export const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_EVENTS:
      return {
        ...state,

        error: "",
        loading: true,
      };

    case CREATE_EVENTS_SUCCESS:
      return {
        ...state,

        error: "",
        loading: false,
        successRsponse: true,
      };
    case CREATE_EVENTS_FAILURE:
      return {
        ...state,

        error: action.payload,
        loading: false,
      };
    case DELETE_EVENTS:
      return {
        ...state,

        error: "",
        deleteSuccess: false,
      };

    case DELETE_EVENTS_SUCCESS:
      return {
        ...state,

        error: "",

        deleteSuccess: true,
      };
    case DELETE_EVENTS_FAILURE:
      return {
        ...state,

        error: action.payload,
        deleteSuccess: false,
      };

    case GET_EVENTS:
      return {
        ...state,
        eventsData: [],
        loading: true,
        page: [],
      };
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        eventsData: action.payload,
        page: action.page,
        error: "",
        loading: false,
      };
    case GET_EVENTS_FAILURE:
      return {
        ...state,
        eventsData: [],
        page: [],
        error: action.payload,
        loading: false,
      };
    case TOGGLE_MODAL_STATUS:
      return {
        ...state,

        deleteSuccess: false,
      };
    default:
      return state;
  }
};
