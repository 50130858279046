import {
    Box,
    Button,
    Grid,
    TextField,
    FormControl,
    Typography,
    useTheme
} from "@mui/material";

// importing custom style hook
import useCustomStyles from "../customHooks/useCustomStyles";

// creating custom styles object
const styles = (theme) => ({
    login_title_text: {
        textAlign: 'left',
        color: '#000000',
        fontSize: '36px',
        marginBottom: '55px',
    },

    forgot_password_container: {
        padding: '5px', 
        width: '500px'
    },

    forgot_password_message: {
        marginBottom: '25px', 
        marginLeft: '12px', 
        color: '#C7332A'
    }
})

const ForgotPassword = ({ adminEmail, setAdminEmail, generateOTPToggle, setGenerateOTPToggle, sendOTP }) => {
    const theme = useTheme();
    const classes = useCustomStyles(styles, theme);

    return (
        <Grid item xs={9} className={ classes?.forgot_password_container }>
            <p className={ classes?.login_title_text }>
                Forgot Your Password?
            </p>

            <Box noValidate>
                <div>
                    <div>
                        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                            Enter Your Email-Id and we'll send you an OTP!
                        </Typography>

                        <FormControl variant="outlined" sx={{ width: { xs: 200, md: 400, lg: 480 } }}>
                            <TextField
                                fullWidth
                                required
                                type="email"
                                id="outlined-required"
                                inputProps={{ 
                                    style: {
                                        boxSizing: 'content-box', 
                                        height: '27px', 
                                        fontSize: '24px', 
                                        color: '#7F7F7F',
                                    }
                                }}
                                InputProps={{
                                    style: {
                                        borderRadius: '15px'
                                    }
                                }}
                                label="E-mail Id"
                                value={ adminEmail }
                                onChange={(event) => { setAdminEmail(event.target.value) }}
                            />
                        </FormControl>

                        <br />
                    </div>
                    <div className={ classes?.forgot_password_message }>
                        {/* <span>Incorrect Password</span> */}
                        <br />
                    </div>
                    <div>
                        <Button
                            sx={{ 
                                    py: 1.5, 
                                    width: { xs: 200, md: 400, lg: 480 }, 
                                    backgroundColor: "#9E46E3", 
                                    textTransform: 'none' 
                                }}
                            variant="contained"
                            type="submit"
                            onClick={ () => { setGenerateOTPToggle(!generateOTPToggle); sendOTP() }}
                            disabled= { (adminEmail === "") }
                        >
                            Send OTP
                        </Button>
                    </div>
                </div>
            </Box>
        </Grid>
    )
}

export default ForgotPassword;
