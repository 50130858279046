import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_HELPS,
  getHelpsFailure,
  getHelpsSuccess,
  UPDATE_HELPS,
  updateHelpsPutFailure,
  updateHelpsPutSuccess,
} from "./action";

import { getRequest, putRequest } from "../../apiServices/ApiActions";
import { getHelpsUrl, updateHelpsUrl } from "../../apiServices/ApiUrl";
import { showSnackbar } from "../snackbar/action";

const getPost = async (payload) => {
  return getRequest(getHelpsUrl + `?${payload}`);
};
const updatePost = async (payload, id) => {
  return putRequest(updateHelpsUrl + `${id}`, payload);
};

function* updateHELPS(action) {
  try {
    const Result = yield call(updatePost, action.payload, action.id);
    const { status, comment } = action.payload;

    if (Result.status === 200) {
      yield put(updateHelpsPutSuccess(Result));
      yield put(
        showSnackbar({
          message: `Feedback forms for ${action.userFullName} with status-${status} and comments-${comment} is updated successfully.`,
          severity: "success",
        }),
      );
    } else {
      yield put(updateHelpsPutFailure(Result));
    }
  } catch (error) {
    yield put(updateHelpsPutFailure(error));
  }
}

function* getHELPS(action) {
  try {
    const Result = yield call(getPost, action.payload);

    if (Result.status === 200) {
      let response = Result.data;
      yield put(getHelpsSuccess(response));
    } else {
      yield put(getHelpsFailure(Result));
    }
  } catch (error) {
    yield put(getHelpsFailure(error));
  }
}

function* helpsSaga() {
  yield takeEvery(GET_HELPS, getHELPS);
  yield takeEvery(UPDATE_HELPS, updateHELPS);
}
export default helpsSaga;
