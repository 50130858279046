// MapComponent.js
import React from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import "leaflet/dist/leaflet.css";
import ReactDOMServer from "react-dom/server";
import L from "leaflet";

const MapComponent = ({ open, handleDialogClose, geoData, setUserData }) => {
  const customIconHtml = ReactDOMServer.renderToString(
    <AddLocationIcon style={{ fontSize: "30px", color: "red" }} />,
  );
  const customMarkerIcon = L.divIcon({
    html: customIconHtml,
    className: "custom-marker",
    iconSize: [30, 30],
    popupAnchor: [0, -15],
  });

  const MapClickHandler = () => {
    useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        setUserData({
          ...geoData,
          latitude: lat,
          longitude: lng,
        });
      },
    });
    return null;
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleDialogClose()}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Geolocation Map</DialogTitle>
        <DialogContent>
          {geoData && (
            <MapContainer
              center={[geoData?.latitude, geoData?.longitude]}
              zoom={13}
              style={{ height: "400px", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker
                position={[geoData?.latitude, geoData?.longitude]}
                icon={customMarkerIcon}
              >
                <Popup>
                  {`Latitude: ${geoData?.latitude}, Longitude: ${geoData?.longitude}`}
                </Popup>
              </Marker>
              <MapClickHandler />
            </MapContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MapComponent;
