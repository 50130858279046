import { call, put, takeEvery } from "redux-saga/effects";

import {
  CREATE_NITNEM,
  nitnemSuccess,
  nitnemFailure,
  FETCH_ALL_NITNEM,
  fetchAllNitnemSuccess,
  fetchAllNitnemFailure,
  UPDATE_NITNEM,
  updateNitnemSuccess,
  updateNitnemFailure,
  DELETE_NITNEM,
  deleteNitnemSuccess,
  deleteNitnemFailure,
} from "./action";

import {
  postRequest,
  deleteRequest,
  putRequest,
  getRequest,
} from "../../apiServices/ApiActions";

import {
  addNitnemUrl,
  updateNitnemUrl,
  fetchAllNitnemWithPaginationUrl,
  deleteNitnemUrl,
} from "../../apiServices/ApiUrl";

const addNitnem = async (payload) => {
  return await postRequest(addNitnemUrl, payload);
};

const getAllNitnem = async (page, search) => {
  return await getRequest(
    `${fetchAllNitnemWithPaginationUrl}?searchTerm=${search}&page=${--page}&size=${10}`,
  );
};

const editNitnem = async (payload, params) => {
  return await putRequest(`${updateNitnemUrl}/${params}`, payload);
};

const removeNitnem = async (id) => {
  return await deleteRequest(`${deleteNitnemUrl}/${id}`);
};

function* createNitnem(action) {
  try {
    const result = yield call(addNitnem, action.payload);

    if (result.status === 200) {
      yield put(nitnemSuccess(result));
    } else {
      yield put(nitnemFailure(result?.response?.data));
    }
  } catch (error) {
    yield put(nitnemFailure(error?.response?.data));
  }
}

function* fetchAllNitnemWithPagination(action) {
  try {
    const result = yield call(getAllNitnem, action.params, action.search);

    if (result.status === 200) {
      yield put(fetchAllNitnemSuccess(result));
    } else {
      yield put(fetchAllNitnemFailure(result?.response?.data));
    }
  } catch (error) {
    yield put(fetchAllNitnemFailure(error?.response?.data));
  }
}

function* updateNitnem(action) {
  try {
    const result = yield call(editNitnem, action.payload, action.params);

    if (result.status === 200) {
      yield put(updateNitnemSuccess(result));
    } else {
      yield put(updateNitnemFailure(result?.response?.data));
    }
  } catch (error) {
    yield put(updateNitnemFailure(error?.response?.data));
  }
}

function* deleteNitnem(action) {
  try {
    const result = yield call(removeNitnem, action.params);

    if (result.status === 204) {
      yield put(deleteNitnemSuccess(result));
    } else {
      yield put(deleteNitnemFailure(result?.response?.data));
    }
  } catch (error) {
    yield put(deleteNitnemFailure(error?.response?.data));
  }
}

function* nitnemSaga() {
  yield takeEvery(CREATE_NITNEM, createNitnem);
  yield takeEvery(UPDATE_NITNEM, updateNitnem);
  yield takeEvery(FETCH_ALL_NITNEM, fetchAllNitnemWithPagination);
  yield takeEvery(DELETE_NITNEM, deleteNitnem);
}

export default nitnemSaga;
