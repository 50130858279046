import axios from "axios";
import { apiClient } from "./AxiosClient";
const userAccessToken = localStorage.getItem("token");

const config = {
  headers: {
    Authorization: `Bearer ${userAccessToken}`,
  },
};
const config3 = {
  headers: {
    Authorization: `Bearer ${userAccessToken}`,
    "Content-Type": "application/json",
  },
};

const languageCode = "en_US";
const config2 = {
  headers: { Authorization: `Bearer ${userAccessToken}` },
  params: { languageCode },
};

const config4 = {
  headers: {
    Authorization: `Bearer ${userAccessToken}`,
    "Content-Type": "multipart/form-data",
  },
}

export const postRequest = async (apiUrl, payload) => {
  const data = await apiClient
    .post(apiUrl, payload, config);

  return data;
}

export const postRequestWithFormData = async(apiUrl, formData) => {
  const data = await apiClient
    .post(apiUrl, formData, config4);

  return data;
}

export const putRequest = async (apiUrl, payload) =>
  apiClient
    .put(apiUrl, payload, config)
    .then((res) => res)
    .catch((err) => err);

export const deleteRequest = async (apiUrl) =>
  apiClient
    .delete(apiUrl, config)
    .then((res) => res)
    .catch((err) => err);

export const getRequest = async (apiUrl) =>
  apiClient
    .get(apiUrl, config)
    .then((res) => res)
    .catch((err) => err);

export const getRequestWithparams = async (apiUrl) =>
  apiClient
    .get(apiUrl, config2)
    .then((res) => res)
    .catch((err) => err);
export const putDeleteRquest = async (apiUrl, payload) =>
  apiClient
    .put(apiUrl, payload, config3)
    .then((res) => res)
    .catch((err) => err);
