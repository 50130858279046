import {
  Box,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { formatDateSeperatedWithSlash } from "../../helpers/dateHelper";
import withMixpanelTracking from "../commonComponent/withMixPanelTracking";
import { connect } from "react-redux";
import {
  getVolunteerdetails,
  getPagecount,
} from "../../redux/volunteer/action";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useCustomStyles from "../../customHooks/useCustomStyles";

const styles = (theme) => ({
  table: {
    overflowY: "auto",
    scrollbarWidth: "thin",
    [theme.breakpoints.down(1400)]: {
      maxHeight: 360,
    },
    [theme.breakpoints.between(1401, 1919)]: {
      maxHeight: 550,
    },
    [theme.breakpoints.up(1920)]: {
      maxHeight: 650,
    },
  },
});

const Overview = (props) => {
  const theme = useTheme();
  const classes = useCustomStyles(styles, theme);
  const [tableList, setTableList] = useState([]);
  const [page, setPage] = useState(() => {
    return props.pageCountVol ? props.pageCountVol : 1;
  });
  const [count, setCount] = useState(0);
  const [size] = useState(10);
  const [comparedTableList, setComparedTableList] = useState([]);
  const navigate = useNavigate();
  const handleViewData = (val) => {
    navigate(`/volunteer/formsView?id=${val?.id}`);
    props.trackButtonClick("View Volunteer Button", 1);
  };

  useEffect(() => {
    props.getVolunteerdetails();
  }, [props.getVolunteerdetails]);

  useEffect(() => {
    if (props.getVolunteerSuccess && props.volunteerData.length > 0) {
      setCount(Math.ceil(props.volunteerData?.length / size));
      setTableList([
        ...props.volunteerData.slice((page - 1) * size, page * size),
      ]);
      setComparedTableList([
        ...props.volunteerData.slice((page - 1) * size, page * size),
      ]);
      // setComparedTableList(props.volunteerData);

      props.searchList(props.volunteerData);
      // setCount(props.page.totalPages);
    } else {
      props.searchList([]);
      setComparedTableList([]);
      setTableList([]);
      setCount();
    }
  }, [props.getVolunteerSuccess, page]);

  useEffect(() => {
    setTableList(props.searchedList);
    if (props.searchedList.length !== comparedTableList.length) {
      setCount(Math.ceil(props.searchedList?.length / size));
    } else {
      setCount(Math.ceil(props.volunteerData?.length / size));
    }
  }, [props.searchedList]);

  const handleChange = (event, value) => {
    setPage(value);
    props.getPagecount(value);
  };

  return (
    <>
      <Grid container xs={12} flexWrap="wrap" sx={{ mt: 1.5 }}>
        <TableContainer
          component={Paper}
          elevation={0}
          className={classes?.table}
        >
          <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow className="view_volunteer_table_row">
                <TableCell className="view_volunteer_cell">
                  Event Name
                </TableCell>
                <TableCell className="view_volunteer_cell">Date</TableCell>
                <TableCell className="view_volunteer_cell">Location</TableCell>

                <TableCell align="right" className="view_volunteer_cell">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tableList &&
                tableList.map((row) => (
                  <TableRow
                    key={row.title}
                    className="view_volunteer_table_row"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell className="view_volunteer_cell">
                      {row.name}
                    </TableCell>
                    <TableCell className="view_volunteer_cell">
                      {formatDateSeperatedWithSlash(row.createdAt)}
                    </TableCell>
                    <TableCell className="view_volunteer_cell">
                      {row.locationName}
                    </TableCell>

                    <TableCell align="right">
                      <button
                        className="action-Button"
                        style={{
                          border: "1px solid #4169E1",
                          color: "#4169E1",
                        }}
                        onClick={() => handleViewData(row)}
                      >
                        View Data
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {tableList?.length === 0 && (
            <Box
              sx={{
                display: "grid",
                justifyContent: "center",
                textAlign: "center",
                width: "100%",
                marginTop: "10%",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <img
                  src="/mailbox.png"
                  alt="No data"
                  style={{ width: "30px", height: "30px" }}
                />
              </Box>
              <Box sx={{}}>No data</Box>
            </Box>
          )}
        </TableContainer>
        {count && tableList?.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              marginTop: "20px",
            }}
          >
            <Pagination
              count={count}
              page={page}
              size="large"
              onChange={handleChange}
            />
          </Box>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    volunteerData,
    getVolunteerSuccess,
    getVolunteerDownload,
    page,
    pageCountVol,
  } = state.volunteerReducer;
  return {
    volunteerData,
    getVolunteerSuccess,
    getVolunteerDownload,
    pageCountVol,
    page,
  };
};

export default connect(mapStateToProps, { getVolunteerdetails, getPagecount })(
  withMixpanelTracking(Overview, "Volunteer"),
);
